import { Form, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { TenantsSelect } from "../../../../components/TenantsSelect";
import { getMyTenant } from "../../../../redux/selectors/my-tenant";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";
import { EditorMode } from "../../hooks/useStudyPlanEditor/useDerivedFields";

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const TenantField = () => {
  const myTenant = useSelector(getMyTenant);
  return _.isNil(myTenant) ? (
    <StudyPlanEditorContext.Consumer>
      {({ editor: e }) => (
        <Form.Item
          label="Tenant"
          required
          extra={
            _.isEqual(EditorMode.EDIT, e.mode) ? (
              <div className="pt-2">
                <Typography.Text type="warning">This field cannot be modified.</Typography.Text>
              </div>
            ) : _.isEqual(EditorMode.TENANT_ADMIN_CREATION, e.mode) ? (
              <div className="pt-2">
                <Typography.Text type="warning">This field is locked due to tenant restrictions.</Typography.Text>
              </div>
            ) : null
          }
        >
          <TenantsSelect
            allowClear
            hideExtraValues
            hideTenantsWithoutStudyPlanAccess
            value={e.tenantId.value!}
            onChange={e.tenantId.setValue}
            disabled={_.includes(
              [
                EditorMode.TENANT_ADMIN_CREATION,
                EditorMode.EDIT
                //
              ],
              e.mode
            )}
          />
        </Form.Item>
      )}
    </StudyPlanEditorContext.Consumer>
  ) : null;
};
