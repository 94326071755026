import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Flex, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../../../i18n";
import { doFetchTenantDashboard } from "../../../../redux/actions/dashboard/tenant/fetch";
import { isFetching } from "../../../../redux/selectors/dashboard/tenant";
import { useFilterTags } from "./useFilterTags";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const FilterTags = () => {
  const dispatch = useDispatch<any>();
  const filterTags = useFilterTags();
  const isLoading = useSelector(isFetching);

  return filterTags?.length > 0 ? (
    <Flex align="center" gap={4} wrap className="mb-2">
      <Typography.Text strong>{MESSAGES.FilteredBy}</Typography.Text>

      {_.map(filterTags, ({ text, onClear, name }, idx) => (
        <Button
          size="small"
          key={`filterTag-${name}.${idx}`}
          data-qa-label={`filter-tag-${name}`}
          icon={<CloseCircleOutlined />}
          disabled={isLoading}
          onClick={onClear}
        >
          {text}
        </Button>
      ))}

      <Button
        size="small"
        type="text"
        data-qa-label={`filter-tag-reset`}
        disabled={isLoading}
        icon={<CloseOutlined />}
        onClick={() => {
          dispatch(doFetchTenantDashboard(true));
        }}
      >
        {MESSAGES.ResetFilters}
      </Button>
    </Flex>
  ) : null;
};
