import { Form, Input } from "antd";
import React from "react";
import { FormControllerContext, UserInfoStatus } from "./hooks/userFormController";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const LastName = () => (
  <FormControllerContext.Consumer>
    {({ lastName, userInfoStatus }) => (
      <Form.Item
        required
        label="Last Name"
        wrapperCol={{ xs: 24, xl: 12 }}
        rules={[
          {
            required: true,
            message: "Please input you last name!"
          }
        ]}
      >
        <Input
          value={lastName.value ?? ""}
          onChange={e => {
            lastName.setValue(e.currentTarget.value);
          }}
          placeholder="Enter your last name"
          disabled={userInfoStatus.isIn([
            UserInfoStatus.PENDING_VERIFICATION,
            UserInfoStatus.UNAVAILABLE,
            UserInfoStatus.AVAILABLE_BUT_IMMUTABLE
            //
          ])}
        />
      </Form.Item>
    )}
  </FormControllerContext.Consumer>
);
