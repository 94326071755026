import _ from "lodash";
import React from "react";
import { MESSAGES } from "../../../../i18n";
import { initialExamNumberByReportType } from "../settings";
import { ExamNumber, ExamNumberByReportType, ReportType } from "../types";

// -------------------------------------------------------------------------------------------------
// - Hook
// -------------------------------------------------------------------------------------------------

export const useExamNumber = (reportType: ReportType) => {
  const [examNumberByReportType, setExamNumberByReportType] =
    React.useState<ExamNumberByReportType>(initialExamNumberByReportType);

  const isMiniExam = _.includes(
    [
      ReportType.MINI_EXAMS_ANSWERS_REPORT,
      ReportType.MINI_EXAMS_PERFORMANCE_REPORT,
      ReportType.MINI_EXAMS_SCORE_REPORT
    ],
    reportType
  );

  return {
    value: examNumberByReportType,
    valueForCurReportType: !!reportType ? examNumberByReportType[reportType] : null,
    isAvailableForReportType: _.includes(_.keys(examNumberByReportType), reportType),
    setValue: (reportType: ReportType, option: string) => {
      setExamNumberByReportType(previous => ({ ...previous, [reportType]: option }));
    },

    reset: () => {
      setExamNumberByReportType(initialExamNumberByReportType);
    },

    optionsLabel: isMiniExam ? MESSAGES.SelectMiniExamNumber : MESSAGES.SelectSimulatedExamNumber,

    options: [
      { label: "#1", value: ExamNumber._1 },
      { label: "#2", value: ExamNumber._2 },
      ...(isMiniExam ? [{ label: "#3", value: ExamNumber._3 }] : [])
    ]
  };
};
