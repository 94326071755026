import { SYSTEM_CONFIG } from "./system-config";

const ENV_MAP: Record<Enviroment, string> = {
  prod: "https://backoffice.cpa.becker.com",
  qa: "https://backoffice.cpa-qa.becker.com",
  dev: "https://backoffice.cpa-qa.becker.com",
  local: "https://local.becker.com"
};

const curEnv: Enviroment = (SYSTEM_CONFIG.environment?.toLowerCase() as Enviroment) || "qa";

export const CPA_BASE_URL = ENV_MAP[curEnv];
