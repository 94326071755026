import { InfoCircleTwoTone } from "@ant-design/icons";
import { Card, Col, Flex, Row, Tooltip, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { altIdsByProduct, sectionAltIdsOrder } from "../../../../../constants/courses";
import { MESSAGES, formatMsg } from "../../../../../i18n";
import { getActiveProduct } from "../../../../../redux/selectors/active-product";
import { isFetchCompleted } from "../../../../../redux/selectors/dashboard/tenant";
import { PassRateChartBySection } from "./PassRateChartBySection";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const PassRateBySection = () => {
  const dataReady = useSelector(isFetchCompleted);
  const activeProduct = useSelector(getActiveProduct);
  return (
    <Card
      loading={!dataReady}
      styles={{
        body: { height: "100%", display: "flex", flexDirection: "column" }
      }}
    >
      <Row>
        <Col className="flex">
          <Typography.Text className="uppercase" type="secondary" style={{ minHeight: "30px" }}>
            {MESSAGES.PassRateBySection}{" "}
            <Tooltip title={formatMsg(MESSAGES.PassRateBySectionInfo, activeProduct.toUpperCase())}>
              <InfoCircleTwoTone />
            </Tooltip>
          </Typography.Text>
        </Col>
      </Row>
      <Flex wrap align="center">
        {_.chain(altIdsByProduct[activeProduct])
          .sortBy(altId => _.indexOf(sectionAltIdsOrder, altId))
          .map(altId => <PassRateChartBySection key={altId} altId={altId} />)
          .value()}
      </Flex>
    </Card>
  );
};
