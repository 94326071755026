import { Alert } from "../../../services/nav-api/alerts-writer/types";
import {
  ADMIN_FETCH_ALERTS_COMPLETED,
  ADMIN_FETCH_ALERTS_FAILED,
  ADMIN_FETCH_ALERTS_STARTED
} from "../../actions/alerts-writer/fetch";
import { ACTIVATE_ALERTS_FILTER, SET_ALERTS_FILTER } from "../../actions/alerts-writer/filters";
import {
  ALERTS_OPERATION_COMPLETION,
  ALERTS_OPERATION_ERROR_DISCARD,
  ALERTS_OPERATION_FINISH_SAVE,
  ALERTS_OPERATION_FINISH_SAVE_WITH_ERROR,
  ALERTS_OPERATION_START,
  ALERTS_OPERATION_START_SAVE,
  ALERTS_OPERATION_UPDATE
} from "../../actions/alerts-writer/operation";
import { CHANGE_ALERTS_PAGINATION } from "../../actions/alerts-writer/pagination";
import { RdxFetchStatus } from "../../types/status";
import { createReducer } from "../../utils/redux-utils";
import { initialState } from "./constants";
import {
  AlertsFilters,
  AlertsOperation,
  RdxAlertsWriterState,
  RdxFetchCompletionPayload,
  RdxFetchFailedPayload,
  RdxPaginationPayload
} from "./types";

// -------------------------------------------------------------------------------------------------
// - Reducer
// -------------------------------------------------------------------------------------------------

export default createReducer(initialState, {
  // -----------------------------------------------------------------------------------------------
  // -
  // - Filters
  // -
  // -----------------------------------------------------------------------------------------------

  [SET_ALERTS_FILTER]: (state, filters: AlertsFilters): RdxAlertsWriterState => ({
    ...state,
    filters: {
      ...state.filters,
      ...filters
    }
  }),

  [ACTIVATE_ALERTS_FILTER]: (state): RdxAlertsWriterState => ({
    ...state,
    activeFilters: {
      ...state.filters
    }
  }),

  // -----------------------------------------------------------------------------------------------
  // -
  // - Fetch
  // -
  // -----------------------------------------------------------------------------------------------

  [ADMIN_FETCH_ALERTS_STARTED]: (state): RdxAlertsWriterState => ({
    ...state,
    status: RdxFetchStatus.LOADING
  }),

  [ADMIN_FETCH_ALERTS_COMPLETED]: (state, { alerts, total }: RdxFetchCompletionPayload): RdxAlertsWriterState => ({
    ...state,
    status: RdxFetchStatus.COMPLETED,
    error: undefined,
    alerts,
    paging: { ...state.paging, totalItems: total }
  }),

  [ADMIN_FETCH_ALERTS_FAILED]: (state, { error }: RdxFetchFailedPayload): RdxAlertsWriterState => ({
    ...state,
    status: RdxFetchStatus.FAILED,
    error
  }),

  // -----------------------------------------------------------------------------------------------
  // -
  // - Pagination
  // -
  // -----------------------------------------------------------------------------------------------

  [CHANGE_ALERTS_PAGINATION]: (state, { paging }: RdxPaginationPayload): RdxAlertsWriterState => ({
    ...state,
    paging
  }),

  // -----------------------------------------------------------------------------------------------
  // -
  // - Operations
  // -
  // -----------------------------------------------------------------------------------------------

  [ALERTS_OPERATION_START]: (state, operation: AlertsOperation): RdxAlertsWriterState => ({
    ...state,
    operation
  }),

  [ALERTS_OPERATION_COMPLETION]: (state): RdxAlertsWriterState => ({
    ...state,
    operation: undefined
  }),

  [ALERTS_OPERATION_UPDATE]: ({ operation: o, ...otherKeyVal }, alert: Partial<Alert>): RdxAlertsWriterState => ({
    ...otherKeyVal,
    operation: { ...o!, alert: { ...o?.alert!, ...alert } }
  }),

  [ALERTS_OPERATION_START_SAVE]: ({ operation: o, ...otherKeyVal }): RdxAlertsWriterState => ({
    ...otherKeyVal,
    operation: { ...o!, status: RdxFetchStatus.LOADING }
  }),

  [ALERTS_OPERATION_FINISH_SAVE]: ({ operation: o, ...otherKeyVal }): RdxAlertsWriterState => ({
    ...otherKeyVal,
    operation: { ...o!, status: RdxFetchStatus.NONE }
  }),

  [ALERTS_OPERATION_FINISH_SAVE_WITH_ERROR]: (
    { operation: o, ...otherKeyVal },
    error: string
  ): RdxAlertsWriterState => ({
    ...otherKeyVal,
    operation: { ...o!, status: RdxFetchStatus.NONE, error }
  }),

  [ALERTS_OPERATION_ERROR_DISCARD]: ({ operation: o, ...otherKeyVal }): RdxAlertsWriterState => ({
    ...otherKeyVal,
    operation: { ...o!, error: undefined }
  })
});
