import React from "react";
import { SimulatedExamsReportOption } from "../../../../services/nav-api/student-roster/types";
import { initialAttemptByReportType, initialMultipleAttemptsByReportType } from "../settings";
import { AttemptByReportType, MultipleAttemptsByReportType, ReportType } from "../types";

// -------------------------------------------------------------------------------------------------
// - Hook (Single Attempt)
// -------------------------------------------------------------------------------------------------

export const useAttemptByReportType = (reportType: ReportType) => {
  const [attemptByReportType, setAttemptByReportType] = React.useState<AttemptByReportType>(initialAttemptByReportType);

  return {
    value: attemptByReportType,
    valueForCurReportType: !!reportType ? attemptByReportType[reportType] : null,
    isAvailableForReportType: !!reportType && !!attemptByReportType[reportType],

    setValue: (reportType: ReportType, option: SimulatedExamsReportOption) => {
      setAttemptByReportType(previous => ({ ...previous, [reportType]: option }));
    },

    reset: () => {
      setAttemptByReportType(initialAttemptByReportType);
    }
  };
};

// -------------------------------------------------------------------------------------------------
// - Hook (Multiple Attempts)
// -------------------------------------------------------------------------------------------------

export const useMultipleAttemptsByReportType = (reportType: ReportType) => {
  const [multipleAttemptsIncludedByReportType, setMultipleAttemptsIncludedByReportType] =
    React.useState<MultipleAttemptsByReportType>(initialMultipleAttemptsByReportType);

  return {
    value: multipleAttemptsIncludedByReportType,
    values: [
      { label: "First", value: SimulatedExamsReportOption.FirstAttempt },
      { label: "Last", value: SimulatedExamsReportOption.LastAttempt },
      { label: "Best", value: SimulatedExamsReportOption.BestAttempt }
    ],
    valueForCurReportType: !!reportType ? multipleAttemptsIncludedByReportType[reportType] : null,
    isAvailableForReportType: initialMultipleAttemptsByReportType[reportType] != null,
    setValue: (reportType: ReportType, option: SimulatedExamsReportOption[]) => {
      setMultipleAttemptsIncludedByReportType(previous => ({ ...previous, [reportType]: option }));
    },

    reset: () => {
      setMultipleAttemptsIncludedByReportType(initialMultipleAttemptsByReportType);
    }
  };
};
