import { Action } from "../../types/action";
import { FETCH_UNAUTH_STARTED } from "./actions";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doStartFetchUnauth = (): Action => ({
  type: FETCH_UNAUTH_STARTED
});
