import { AltID } from "../../../constants/courses";

export interface GetSimulatedStudyPlan {
  sectionAltId: string;
  startDate: string;
  examDate: string;
  estimatedDate: boolean;
  pace: number;
  includePracticeTest: boolean;
  includeFlashcard: boolean;
  includeFR: boolean;
  weekDayHours: number[];
  daysOff: string[];

  plannedExamDate?: number;
  reviewDaysBeforeExam?: number;
}

export enum StudyPlanDay {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY"
}

export interface StudyPlanCalendar<T = string> {
  day: T;
  placements: {
    taskId: string;
    quarterHours: number;
  }[];
}

export interface StudyPlanTask {
  id: string;
  type: "module" | "practicetest" | "flashcard" | "miniexam" | "simexam" | "finalreview" | "custom";
  state: string;
  examIndex?: number;
  invalid?: boolean;
  name?: string;
  edr?: number;

  moduleId?: string;
  moduleAltId?: string;
  moduleAbbrev?: string;

  unitId?: string;
  unitAltId?: string;
  unitAbbrev?: string;

  moduleStatus?: {
    conceptVideosProgress?: number;
    essaysProgress?: number;
    mcqsProgress?: number;
    simulationsProgress?: number;
  };
}

export interface StudyPlan {
  today: string;
  settings?: {
    startDate: string;
    examDate: string;
    estimatedDate: boolean;
    pace: number;
    studyHoursByWeekday: { [key in StudyPlanDay]: number };
    daysOff: string[];
    reviewDaysBeforeExam: number;
    includePracticeTest?: boolean;
    includeFlashcard?: boolean;
    includeFR?: boolean;
  };
  tasks: StudyPlanTask;
  calendar: StudyPlanCalendar[];
  warnings?: {
    canUpdateContent: boolean;
    mismatchedExamDate?: string;
  };
}

export interface GetSimulatedStudyPlanRSP {
  error?: string;
  plan?: StudyPlan;
}

interface StudentTag {
  id: number;
  name: string;
  parent?: StudentTag;
}

export interface StudyPlansListItem {
  id: number;
  name: string;
  tags: StudentTag[];
  status: "draft" | "published" | "archived";
  sectionAltId: AltID;
  lockUntil: string;
  tenant: {
    id: number;
    name: string;
  };
  settings: {
    // same as ER study plan settings
    startDate: string;
    examDate: string;
    estimatedDate: boolean;
    pace: number;
    weekDayHours: number[]; // first index is Monday, last is Sunday
    daysOff: number[];
    reviewDaysBeforeExam: number;
    includePracticeTest?: boolean;
    includeFlashcard?: boolean;
    includeFR?: boolean;
  };

  calendar: StudyPlanCalendar[];
  tasks: {
    id: string;
    type: "module" | "practicetest" | "flashcard" | "miniexam" | "simexam" | "finalreview" | "custom";
    state: string;
    moduleId?: string;
    unitId?: string;
    examIndex?: number;
    invalid?: boolean;
    name?: string;
    edr?: number;
    moduleStatus?: {
      // applicable to type module
      conceptVideosProgress?: number;
      essaysProgress?: number;
      mcqsProgress?: number;
      simulationsProgress?: number;
    };
  }[];
  created: string;
  updated?: number;
}

export interface GetStudyPlansListRQ {
  name: string;
  status: Array<"draft" | "published" | "archived">;
  sectionAltId: AltID | AltID[];

  tenantId: number;
  tenant: number;
  limit: number;
  offset: number;
  sort: string;
}

export interface GetStudyPlansListRSP {
  studyPlans: Array<StudyPlansListItem>;
  total: number;
}

export interface CreateStudyPlanRQ {
  sectionAltId: string;
  name: string;
  tagIds: number[];
  lockUntil: string;
  settings: {
    startDate: string;
    examDate: string;
    estimatedDate: boolean;
    pace: number;
    weekDayHours: number[]; // first index is Monday, last is Sunday
    daysOff: string[];
    reviewDaysBeforeExam: number;
    includePracticeTest?: boolean;
    includeFlashcard?: boolean;
    includeFR?: boolean;
    plannedExamDate?: number;
  };
}
