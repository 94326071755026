import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { GetTenantTagsResponse } from "./types";

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const getTenantTags = async (tenantId: number): Promise<GetTenantTagsResponse> => {
  const url = `/rest/admin/tenants/${tenantId}/tags`;
  return HttpClient.get(url, createHeaders());
};

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const createTenantTag = async (tenantId: number, name: string, parentId?: number): Promise<{ id: number }> => {
  const url = `/rest/admin/tenants/${tenantId}/tags`;
  return HttpClient.post(url, createHeaders(), { name, parentId });
};

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const deleteTenantTag = async (tenantId: number, tagId: number): Promise<void> => {
  const url = `/rest/admin/tenants/${tenantId}/tags/${tagId}`;
  return HttpClient.del(url, createHeaders());
};

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const updateTenantTag = async (
  tenantId: number,
  tagId: number,
  name: string,
  parentId?: number
): Promise<void> => {
  const url = `/rest/admin/tenants/${tenantId}/tags/${tagId}`;
  return HttpClient.put(url, createHeaders(), { name, parentId });
};

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const moveStudentsToTag = async (tenantId: number, srcTagId: number, destTagId: number): Promise<void> => {
  const url = `/rest/admin/tenants/${tenantId}/tags/${destTagId}/students`;
  return HttpClient.put(url, createHeaders(), { moveFromTagId: srcTagId });
};

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const cloneTag = async (tenantId: number, tagId: number, intoTagId: number): Promise<void> => {
  const url = `/rest/admin/tenants/${tenantId}/tags/${tagId}/clone`;
  return HttpClient.post(url, createHeaders(), { intoTagId });
};
