import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { GlobalCounters } from "./types";

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const fetchGlobalCounters = async (): Promise<GlobalCounters> =>
  HttpClient.get("/rest/admin/global/counters", createHeaders());
