import { Badge, Typography } from "antd";
import React from "react";
import { StudyPlanTask } from "../../../../../../services/nav-api/studyPlans/model";
import { StudyCalendarUtils } from "../../../../hooks/useStudyPlanEditor/utils/StudyCalendarUtils";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarDayCellTaskFinalReview = (props: { task: StudyPlanTask & { quarterHours?: number } }) => (
  <li
    key={`final-review-${props.task.id}`}
    title={`Final Review: ${StudyCalendarUtils.formatDuration(props.task.quarterHours! * 15)}`}
  >
    <Badge color="#9D59DD" text={<Typography.Text strong>Final Review</Typography.Text>} />
  </li>
);
