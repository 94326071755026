import { TeamOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { SIDER_MAX_WIDTH } from "../../../constants/ui-constants";
import { MESSAGES } from "../../../i18n";
import { ActiveUser } from "../../../redux/selectors/active-user";
import { routes } from "../../../routes";
import { NavigationDrawerOptionKey } from "../types";
import menuItemMyAccountLogout from "./MIAccountLogout";
import menuItemMyAccountNotifications from "./MIAccountNotifications";
import menuItemMyAccountPrivacy from "./MIAccountPrivacy";
import menuItemMyAccountUserGuide from "./MIAccountUserGuide";
import { AlertsBadge } from "./common";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default (activeUser: ActiveUser | undefined) => [
  {
    key: "profile",
    icon: <TeamOutlined />,
    label: (
      <AlertsBadge
        maxWidth={SIDER_MAX_WIDTH - 100}
        qaLabel="menu-user-details"
      >{`${activeUser!.firstName} ${activeUser!.lastName}`}</AlertsBadge>
    ),
    children: [
      {
        key: NavigationDrawerOptionKey.PROFILE_MYACCOUNT,
        label: (
          <Link to={routes.profile.resolve()} data-qa-label="menu-my-account">
            <span>{MESSAGES.NavMyAccount}</span>
          </Link>
        )
      },
      ...menuItemMyAccountNotifications,
      ...menuItemMyAccountUserGuide,
      ...menuItemMyAccountPrivacy,
      ...menuItemMyAccountLogout
    ]
  }
];
