import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Modal, Table } from "antd";
import React from "react";
import { handleCellText, handleDateCell } from "../../components/TableCellRender";
import { MESSAGES } from "../../i18n";
import { Partner } from "../../services/nav-api/partners/types";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const PartnersTable = (props: {
  tenantId: number;
  loading: boolean;
  partners?: Partner[];
  onDelete: (partnerId: number) => void;
  onAdd: () => void;
}) => (
  <Table
    bordered
    rowKey="id"
    size="small"
    pagination={false}
    loading={props.loading}
    dataSource={props.partners || []}
    footer={() => (
      <Flex justify="end">
        <Button size="small" type="primary" icon={<PlusOutlined />} onClick={props.onAdd}>
          {MESSAGES.AssignPartner}
        </Button>
      </Flex>
    )}
    columns={[
      {
        title: "Name",
        dataIndex: "name",
        render: handleCellText,
        width: "60%"
      },
      {
        title: "Type",
        dataIndex: "type",
        render: handleCellText,
        width: "15%"
      },
      {
        title: "Segment ID",
        dataIndex: "refKey",
        render: handleCellText,
        width: "15%"
      },
      {
        title: "Creation date",
        dataIndex: "created",
        render: handleDateCell,
        width: "15%"
      },
      {
        width: 20,
        dataIndex: "id",
        render: (id: number) => (
          <Button
            danger
            type="link"
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: MESSAGES.TenantPartnerRemove,
                okText: MESSAGES.YesLabel,
                cancelText: MESSAGES.NoLabel,
                okType: "danger",
                onOk: () => {
                  props.onDelete(id);
                }
              });
            }}
          />
        )
      }
    ]}
  />
);
