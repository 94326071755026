import _ from "lodash";
import { createSelector } from "reselect";
import { doFetchAlerts } from "../actions/alerts-reader/fetch";
import { getHasAcceptedEula } from "../selectors/accepted-eula";
import { getActiveUser } from "../selectors/active-user";
import { getAlertsStatus } from "../selectors/alerts-reader/alerts";
import { ThunkAction } from "../types/action";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";

// -------------------------------------------------------------------------------------------------
// - Reactor
// -------------------------------------------------------------------------------------------------

export const reFetchAlerts: (state: RdxStoreState) => ThunkAction | undefined = createSelector(
  getAlertsStatus,
  getActiveUser,
  getHasAcceptedEula,
  (status, myself, hasAcceptedEula) => {
    if (_.includes([RdxFetchStatus.NONE, RdxFetchStatus.STALE], status) && !!myself && hasAcceptedEula) {
      return doFetchAlerts();
    }

    return undefined;
  }
);
