import { Button, Card, Flex, Result, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router";
import { BaseLayout } from "../../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../../components/NavigationDrawer/types";
import { MESSAGES } from "../../../i18n";
import { routes } from "../../../routes";
import { StudyPlanCalendar } from "./components/StudyPlanCalendar";
import { StudyPlanSummary } from "./components/StudyPlanSummary";
import { StudyPlanCustomTaskEditor } from "./components/StudyPlanTaskEditor";
import { StudyPlanViewerHeader } from "./components/StudyPlanViewerHeader";
import {
  StudyPlanCustomTasksEditorContext,
  useStudyPlanCustomTasksEditor
} from "./hooks/useStudyPlanCustomTasksEditor";
import { StudyPlanContext, useStudyPlanViewer as useStudyPlanViewerData } from "./hooks/useStudyPlanViewerData";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlansViewer = () => {
  const navigateTo = useNavigate();
  const studyPlanViewerData = useStudyPlanViewerData();
  const studyPlanTaskEditor = useStudyPlanCustomTasksEditor(studyPlanViewerData);
  return (
    <StudyPlanContext.Provider value={studyPlanViewerData}>
      <StudyPlanCustomTasksEditorContext.Provider value={studyPlanTaskEditor}>
        <BaseLayout
          activeNavDrawerKey={NavigationDrawerOptionKey.STUDY_PLAN_VIEWER}
          breadcrumb={[
            {
              name: MESSAGES.StudyPlansTitle,
              href: routes.studyPlans.resolve()
            },
            {
              name: (
                <Typography.Text>
                  Details of{" "}
                  <Typography.Text strong italic>
                    {_.isNil(studyPlanViewerData.id) //
                      ? "Study Plan"
                      : `${studyPlanViewerData.summary.name}`}
                  </Typography.Text>
                </Typography.Text>
              )
            }
          ]}
        >
          <StudyPlanViewerHeader />
          <Flex vertical gap={12}>
            {studyPlanViewerData.error ? (
              <Card>
                <Result
                  status="404"
                  title={studyPlanViewerData.error.status}
                  subTitle="Sorry, something went wrong."
                  extra={
                    <Button
                      type="primary"
                      onClick={() => {
                        navigateTo(routes.studyPlans.resolve());
                      }}
                    >
                      Back to study plans.
                    </Button>
                  }
                />
              </Card>
            ) : (
              <>
                <StudyPlanSummary />
                <StudyPlanCalendar />
                <StudyPlanCustomTaskEditor />
              </>
            )}
          </Flex>
        </BaseLayout>
      </StudyPlanCustomTasksEditorContext.Provider>
    </StudyPlanContext.Provider>
  );
};
