import { createSelector } from "reselect";

import { Tenant } from "../../services/nav-api/tenants/types";
import { RdxStoreState } from "../types/state";
import { getMyTenant } from "./my-tenant";

export const getStudentFilterTenantId: (state: RdxStoreState) => number | undefined = createSelector(
  getMyTenant,
  (state: RdxStoreState) => state.studentsList.workingFilter.tenantId,
  (tenant: Tenant | undefined, workingFilterTenantId?: number) => (tenant ? tenant.id : workingFilterTenantId)
);
