import _ from "lodash";
import { createSelector } from "reselect";
import { TenantProduct } from "../../services/nav-api/tenants/types";
import { RdxStoreState } from "../types/state";
import { getIsGlobalUser } from "./permissions/is-global-user";

export const getProductSwitcherOptions: (state: RdxStoreState) =>
  | {
      active: TenantProduct;
      otherProducts: TenantProduct[];
    }
  | undefined = createSelector(
  (state: RdxStoreState) => state.myself,
  getIsGlobalUser,
  (myself, isGlobalUser) => {
    if (isGlobalUser) {
      return {
        active: myself?.userSettings?.activeProduct!,
        otherProducts: _.filter(_.values(TenantProduct), product => product !== myself?.userSettings?.activeProduct)
      };
    }

    if (!myself.user) {
      return;
    }

    if (!myself.user.tenant?.products?.length) {
      return;
    }

    return {
      active: myself?.userSettings?.activeProduct!,
      otherProducts: myself.user.tenant.products.filter(product => product !== myself?.userSettings?.activeProduct)
    };
  }
);
