import { compile } from "path-to-regexp";

export const studyPlansEdit = (() => {
  const path = "/studyplans/:id/edit";
  const toPath = compile(path);
  return {
    path,
    resolve(params: Partial<Record<string, string | string[]>>) {
      return toPath(params);
    }
  };
})();

export const studyPlansPreview = (() => {
  const path = "/studyplans/:id/preview";
  const toPath = compile(path);
  return {
    path,
    resolve(params: Partial<Record<string, string | string[]>>) {
      return toPath(params);
    }
  };
})();
