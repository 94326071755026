import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getDefaultNewUserRole, getUserRoles } from "../../../../redux/selectors/roles";
import { Role } from "../../../../services/nav-api/roles";
import { User } from "../../../../services/nav-api/types";

// -------------------------------------------------------------------------------------------------
// - const
// -------------------------------------------------------------------------------------------------

const tenantAdminName = "Tenant Admin";
const tenantUserName = "Tenant User";

// -------------------------------------------------------------------------------------------------
// - Filters
// -------------------------------------------------------------------------------------------------

export enum RoleFilter {
  NEW_USER = "NEW_USER",
  EDIT_USER = "EDIT_USER",
  EDIT_LEGACY = "EDIT_LEGACY"
}

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useUserRoles = () => {
  const defaultUserRole = useSelector(getDefaultNewUserRole);
  const roles = useSelector(getUserRoles);
  const groups = React.useMemo(
    () => ({
      all: roles,
      // -------------------------------------------------------------------------------------------
      //  Tenant Users
      // -------------------------------------------------------------------------------------------
      tenantUsers: _.chain(roles)
        .filter(({ name }) => _.isEqual(name, tenantUserName))
        .value(),

      tenantUserIds: _.chain(roles)
        .filter(({ name }) => _.isEqual(name, tenantUserName))
        .map(({ id }) => id)
        .value(),

      // -------------------------------------------------------------------------------------------
      //  Tenant Admin
      // -------------------------------------------------------------------------------------------
      tenantAdmins: _.chain(roles)
        .filter(({ name }) => _.isEqual(name, tenantAdminName))
        .value(),

      tenantAdminIds: _.chain(roles)
        .filter(({ name }) => _.isEqual(name, tenantAdminName))
        .map(({ id }) => id)
        .value(),

      // -------------------------------------------------------------------------------------------
      //  Tenant Admin
      // -------------------------------------------------------------------------------------------

      tenantUserAdmins: _.chain(roles)
        .filter(({ name }) => _.includes([tenantAdminName, tenantUserName], name))
        .value(),

      tenantUserAdminIds: _.chain(roles)
        .filter(({ name }) => _.includes([tenantAdminName, tenantUserName], name))
        .map(({ id }) => id)
        .value()
    }),
    [JSON.stringify(roles)]
  );

  return {
    defaultUserRole,
    isTenantAdmin: React.useCallback(
      (roleId: number | null) => _.includes(groups.tenantAdminIds, roleId),
      [JSON.stringify(groups)]
    ),

    isTenantUser: React.useCallback(
      (roleId: number | null) => _.includes(groups.tenantUserIds, roleId),
      [JSON.stringify(groups)]
    ),

    isTenantUserAdmin: React.useCallback(
      (roleId: number | null) => _.includes(groups.tenantUserAdminIds, roleId),
      [JSON.stringify(groups)]
    ),

    roles: (remoteUser: User | null): Array<Role & { disabled: boolean }> => {
      if (!_.isNil(remoteUser)) {
        const isTenantUserAdmin = _.includes(groups.tenantUserAdminIds, remoteUser.roleId);
        return !isTenantUserAdmin
          ? _.map(groups.all, r => ({ ...r, disabled: true }))
          : _.map(groups.tenantUserAdmins, r => ({
              ...r,
              disabled: !_.includes(groups.tenantUserAdminIds, r.id)
            }));
      }

      return _.map(groups.tenantUserAdmins, r => ({
        ...r,
        disabled: false
      }));
    }
  };
};
