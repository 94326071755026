import { createSelector } from "reselect";

import _ from "lodash";
import { Tenant } from "../../services/nav-api/tenants/types";
import { RdxStoreState } from "../types/state";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getTenantsById: (state: RdxStoreState) => Tenant[] | undefined = createSelector(
  ({ tenants: { tenantsDropdown } }: RdxStoreState) => tenantsDropdown?.tenantById,
  _.identity
);
