import { Skeleton, Space, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { getGlobalDashboardStats, isFetchCompleted } from "../../../redux/selectors/dashboard/global";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF0000", "#00FF00"];

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantsByTypeChart = () => {
  const stats = useSelector(getGlobalDashboardStats);
  const isDataReady = useSelector(isFetchCompleted);
  const total = _.sumBy(_.keys(stats?.tenantCountByType), (type: string) => stats?.tenantCountByType[type]);
  const data = _.map(
    _.map(_.keys(stats?.tenantCountByType), (type: string) => ({
      type,
      value: stats?.tenantCountByType[type]
    }))
  );

  return isDataReady ? (
    <div
      style={{
        overflowX: "hidden",
        overflowY: "hidden"
      }}
    >
      <ResponsiveContainer height={350}>
        <PieChart>
          <Pie
            isAnimationActive={false}
            outerRadius={120}
            cx="50%"
            cy="50%"
            dataKey="value"
            nameKey="type"
            data={data}
            labelLine
            label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
          >
            {_.map(data, (_, index) => (
              <Cell key={`cell-${index}`} style={{ cursor: "pointer" }} fill={COLORS?.[index]} />
            ))}
          </Pie>
          <Tooltip
            formatter={(value: number, type: string) => [
              <Space direction="vertical">
                <Typography.Text strong>{type}</Typography.Text>
                <Typography.Text>
                  {((value / total) * 100).toFixed(0)}% ({value} in {total} Tenant{_.isEqual(total, 1) ? "" : "s"})
                </Typography.Text>
              </Space>
            ]}
          />
          <Legend align="right" layout="vertical" verticalAlign="middle" />
        </PieChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <Skeleton />
  );
};
