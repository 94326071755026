import { Card, Empty, Flex, Select } from "antd";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { AltID, altIdsByProduct, sectionNameByAltId } from "../../../constants/courses";
import { MESSAGES } from "../../../i18n";
import { getActiveProduct } from "../../../redux/selectors/active-product";
import { getStudentInfo } from "../../../redux/selectors/student-info";
import { DailyStudyTimeResponse, externalApi } from "../../../services/external-api";
import { ANTDBarChart } from "./ANTDBarChart";
import { ANTDLineChart } from "./ANTDLineChart";
import { ChartRange } from "./types";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentStudyTime = () => {
  const [loadingData, setLoadingData] = React.useState(false);
  const [data, setData] = React.useState<DailyStudyTimeResponse | undefined>(undefined);
  const [range, setRange] = React.useState<ChartRange>("week");
  const [sections, setSections] = React.useState<AltID[]>([]);
  const [section, setSection] = React.useState<AltID | undefined>(undefined);

  const { info, loading } = useSelector(getStudentInfo);
  const activeProduct = useSelector(getActiveProduct);
  const monthRangeInitialDate = moment().subtract("29", "day");

  React.useEffect(() => {
    const allSections = altIdsByProduct[activeProduct];
    setSections(allSections);
    setSection(_.head(allSections));
  }, [activeProduct]);

  React.useEffect(() => {
    async function fetch() {
      if (!info?.studentToken) {
        return;
      }
      setLoadingData(true);
      const apiData = await externalApi.dailyStudyTime(
        info?.studentToken,
        activeProduct,
        range === "month" ? monthRangeInitialDate : undefined
      );

      setData(apiData);
      setLoadingData(false);
    }
    fetch();
  }, [info?.studentToken, loading, range]);

  const noData =
    true && //
    !loadingData &&
    data !== undefined &&
    data.days?.length === 0 &&
    data.sections?.length === 0;

  return (
    <Card
      size="small"
      extra={
        <Flex gap={8}>
          <Select
            size="small"
            defaultValue={range}
            value={range}
            style={{ width: 130 }}
            onChange={value => setRange(value)}
            className="sd-study-time-selector"
            getPopupContainer={node => node.parentNode as HTMLElement}
          >
            <Select.Option value="week">{MESSAGES.Last7Days}</Select.Option>
            <Select.Option value="month">{MESSAGES.Last30Days}</Select.Option>
          </Select>
          {sections?.length > 0 ? (
            <Select
              size="small"
              value={section}
              defaultValue={section}
              onChange={value => setSection(value)}
              className="sd-section-selector"
              data-qa-label="sd-study-time-section-selector"
              getPopupContainer={node => node.parentNode as HTMLElement}
              style={{ width: 130 }}
            >
              {_.map(sections, section => (
                <Select.Option key={section} value={section} data-qa-label={`sd-study-time-section-${section}`}>
                  {sectionNameByAltId[section]}
                </Select.Option>
              ))}
            </Select>
          ) : null}
        </Flex>
      }
      loading={loadingData}
      title={
        <div className="ant-card-head-wrapper">
          <div className="ant-card-head-title" data-qa-label="sd-study-time-title">
            Study Time
          </div>
        </div>
      }
    >
      <Flex vertical gap={12}>
        {noData ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <Flex className="w-full" gap={16}>
            <ANTDLineChart loading={loadingData} section={section} rawData={data} range={range} />
            <ANTDBarChart loading={loadingData} section={section} rawData={data} />
          </Flex>
        )}
      </Flex>
    </Card>
  );
};
