import { Form, Select, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";
import { EditorMode } from "../../hooks/useStudyPlanEditor/useDerivedFields";
import { useStudyPlanSections } from "../../hooks/useStudyPlanSections";

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const SectionField = () => {
  const options = useStudyPlanSections();
  return (
    <StudyPlanEditorContext.Consumer>
      {({ editor: { section, mode } }) => (
        <Form.Item
          label="Section"
          required
          extra={
            _.isEqual(EditorMode.EDIT, mode) ? (
              <div className="pt-2">
                <Typography.Text type="warning">This field cannot be modified.</Typography.Text>
              </div>
            ) : null
          }
        >
          <Select
            allowClear
            disabled={_.isEqual(EditorMode.EDIT, mode)}
            data-qa-label="selected-section"
            placeholder="Section..."
            value={section.value}
            onChange={section.setValue}
            options={options}
          />
        </Form.Item>
      )}
    </StudyPlanEditorContext.Consumer>
  );
};
