import _ from "lodash";
import React from "react";
import { User } from "../../../../services/nav-api/types";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export enum UserInfoStatus {
  PENDING_VERIFICATION = "PENDING_VERIFICATION",
  AVAILABLE_BUT_IMMUTABLE = "AVAILABLE_BUT_IMMUTABLE",
  AVAILABLE_AND_MUTABLE = "AVAILABLE_AND_MUTABLE",
  UNAVAILABLE = "AUNVA+LABLE"
}

export enum FormKey {
  USER_EMAIL = "email",
  USER_FIRST_NAME = "firstName",
  USER_LAST_NAME = "lasttName",
  USER_SELECTED_ROLE_ID = "role",
  USER_SELECTED_TENANT_ID = "tenant",
  NOTIFICATIONS_ENABLED = "notificationsEnabled",
  ALLOW_ADD_STUDENTS = "allowAddStudents",
  TAG_IDS = "tagIds"
}

export enum FormStatus {
  IDLE = "IDLE",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED"
}

export enum SimplifiedRole {
  TENANT_ADMIN = "TENANT_ADMIN",
  TENANT_USER = "TENANT_USER",
  OTHER = "OTHER"
}

export type UserInfo = {
  available?: boolean;
  firstName?: string;
  lastName?: string;
  status: UserInfoStatus;
};

export type TenantOption = {
  label: string;
  value: number;
};

export type FormVal = number | number[] | string | boolean | null;
export type FormValues = Record<FormKey, FormVal>;
export type Field<T, O = {}> = {
  value: T | null;
  setValue: (val?: T | null) => void;
  resetValue: () => void;
} & O;

export interface FormControllerValue {
  serverUser: Field<
    User,
    {
      notFound: boolean | null;
      isLoading: boolean;
      startLoading: () => void;
      finishLoading: () => void;
    }
  >;
  userInfoStatus: Field<
    UserInfoStatus,
    {
      isIn: (statusList: Array<UserInfoStatus>) => boolean;
      isEqualTo: (statusList: UserInfoStatus) => boolean;
      isLoading: boolean;
      startLoading: () => void;
      finishLoading: () => void;
      getError: () => string | null;
      setError: (e: string | null) => void;
    }
  >;

  email: Field<string>;
  firstName: Field<string>;
  lastName: Field<string>;
  notificationsEnabled: Field<boolean>;
  selectedRoleId: Field<number>;
  selectedTenantId: Field<number>;
  allowAddStudents: Field<boolean>;
  tagIds: Field<number[]>;
  reset: () => void;
  editingMode: boolean;
}

const defaultField = {
  value: null,
  setValue: _.noop,
  resetValue: _.noop,
  isLocked: false,
  lock: _.noop
};

// -------------------------------------------------------------------------------------------------
// - Context
// -------------------------------------------------------------------------------------------------

export const FormControllerContext = React.createContext<FormControllerValue>({
  serverUser: {
    notFound: null,
    value: null,
    setValue: _.noop,
    resetValue: _.noop,
    isLoading: false,
    startLoading: _.noop,
    finishLoading: _.noop
  },
  userInfoStatus: {
    value: null,
    setValue: _.noop,
    resetValue: _.noop,
    isIn: () => false,
    isEqualTo: () => false,
    isLoading: false,
    startLoading: _.noop,
    finishLoading: _.noop,
    getError: () => null,
    setError: _.noop
  },

  email: defaultField,
  firstName: defaultField,
  lastName: defaultField,
  notificationsEnabled: defaultField,
  selectedRoleId: defaultField,
  selectedTenantId: defaultField,
  tagIds: defaultField,
  allowAddStudents: defaultField,
  editingMode: false,

  reset: _.noop
});
