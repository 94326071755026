import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { AltID } from "../../../../constants/courses";
import { getSectionsSelectOptions } from "../../../../redux/selectors/sections-units";
import { initialMultipleSectionsIncludedByReportType, initialSingleSectionIncludedByReportType } from "../settings";
import { ReportType, SectionIncludedByReportType, SectionsIncludedByReportType } from "../types";

// -------------------------------------------------------------------------------------------------
// - Hook (Single Sections)
// -------------------------------------------------------------------------------------------------

export const useSingleSection = (reportType: ReportType) => {
  const sections = useSelector(getSectionsSelectOptions);
  const [singleSectionIncludedByReportType, setSingleSectionIncludedByReportType] =
    React.useState<SectionIncludedByReportType>(initialSingleSectionIncludedByReportType);

  return {
    sections,
    value: singleSectionIncludedByReportType,
    valueForCurReportType: !!reportType ? singleSectionIncludedByReportType[reportType] : null,
    isAvailableForReportType: _.includes(_.keys(initialSingleSectionIncludedByReportType), reportType),
    setValue: (reportType: ReportType, option: AltID) => {
      setSingleSectionIncludedByReportType(previous => ({ ...previous, [reportType]: option }));
    },

    reset: () => {
      setSingleSectionIncludedByReportType(initialSingleSectionIncludedByReportType);
    }
  };
};

// -------------------------------------------------------------------------------------------------
// - Hook (Multiple Sections)
// -------------------------------------------------------------------------------------------------

export const useMultipleSections = (reportType: ReportType) => {
  const sections = useSelector(getSectionsSelectOptions);
  const [multipleSectionsIncludedByReportType, setSectionsIncludedByReportType] =
    React.useState<SectionsIncludedByReportType>(
      initialMultipleSectionsIncludedByReportType(_.map(sections, s => s.value) as AltID[])
    );

  return {
    sections,
    value: multipleSectionsIncludedByReportType,
    valueForCurReportType: !!reportType ? multipleSectionsIncludedByReportType[reportType] : null,
    isAvailableForReportType: _.includes(
      _.keys(initialMultipleSectionsIncludedByReportType(_.map(sections, s => s.value) as AltID[])),
      reportType
    ),
    setValue: (reportType: ReportType, option: string[]) => {
      setSectionsIncludedByReportType(previous => ({ ...previous, [reportType]: option }));
    },

    reset: () => {
      setSectionsIncludedByReportType(
        initialMultipleSectionsIncludedByReportType(_.map(sections, s => s.value) as AltID[])
      );
    }
  };
};
