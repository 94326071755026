import { ClockCircleOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../../../i18n";
import {
  doDismissOperation,
  doSaveAndFinishOperation,
  doStartDeleteOperation,
  doStartEditingOperation,
  doStartExpireOperation
} from "../../../../redux/actions/alerts-writer/operation";
import { OperationType } from "../../../../redux/reducers/alerts-writer/types";
import {
  getOperationAlert,
  getOperationStatusLoading,
  getOperationType
} from "../../../../redux/selectors/alerts-writer/operation";
import { Alert, ApiAlertStatus } from "../../../../services/nav-api/alerts-writer/types";

// -------------------------------------------------------------------------------------------------
// - constants
// -------------------------------------------------------------------------------------------------

const iconByOperationType: { [key in OperationType]: React.ReactNode } = {
  [OperationType.DELETE]: <DeleteOutlined />,
  [OperationType.EXPIRE]: <ClockCircleOutlined />,
  [OperationType.EDIT]: <EditOutlined />
};

const titleByOperationType: { [key in OperationType]: string } = {
  [OperationType.DELETE]: MESSAGES.AlertsDeleteMessage,
  [OperationType.EXPIRE]: MESSAGES.AlertsExpireMessage,
  [OperationType.EDIT]: ""
};

const tooltipByOperationType: { [key in OperationType]: string } = {
  [OperationType.DELETE]: MESSAGES.AlertsDeleteTooltip,
  [OperationType.EXPIRE]: MESSAGES.AlertsExpireTooltip,
  [OperationType.EDIT]: MESSAGES.AlertsEditTooltip
};

// -------------------------------------------------------------------------------------------------
// - OwnProps
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  alert: Alert;
  operationType: OperationType;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const ActionButton = ({ alert, operationType }: OwnProps) => {
  const dispatch = useDispatch<any>();
  const isLoading = useSelector(getOperationStatusLoading);
  const curOperationType = useSelector(getOperationType);
  const curOperationAlert = useSelector(getOperationAlert);
  const isVisible =
    _.isEqual(alert.id, curOperationAlert?.id) && //
    _.isEqual(operationType, curOperationType) &&
    _.isEqual(operationType, OperationType.EDIT) == false;

  return (
    <Popconfirm
      title={titleByOperationType[operationType]}
      open={isVisible}
      onConfirm={() => {
        dispatch(doSaveAndFinishOperation());
      }}
      okButtonProps={{ loading: isLoading }}
      onCancel={() => {
        dispatch(doDismissOperation());
      }}
    >
      <Button
        disabled={
          _.isEqual(OperationType.EXPIRE, operationType) && //
          !_.isEqual(ApiAlertStatus.PUBLISHED, alert.status)
        }
        style={{ marginRight: "5px" }}
        icon={iconByOperationType[operationType]}
        title={tooltipByOperationType[operationType]}
        onClick={e => {
          e.stopPropagation();

          switch (operationType) {
            case OperationType.DELETE:
              dispatch(doStartDeleteOperation(alert));
              break;
            case OperationType.EXPIRE:
              dispatch(doStartExpireOperation(alert));
              break;
            case OperationType.EDIT:
              dispatch(doStartEditingOperation(alert));
              break;
          }
        }}
      />
    </Popconfirm>
  );
};
