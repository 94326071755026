import { isToday, isYesterday } from "date-fns";
import { format as fnsFormat } from "date-fns/format";
import { formatDistanceToNow as fnsFormatDistanceToNow } from "date-fns/formatDistanceToNow";
import { enUS } from "date-fns/locale/en-US";
import { toDate as fnsToDate } from "date-fns/toDate";
import moment from "moment";

const locale = enUS;

type DateArg = Date | number | string;

export const format = (d: DateArg, formatStr: string) => {
  return fnsFormat(fnsToDate(d), formatStr, { locale });
};

export const formatDistanceToNowInDaysOrBigger = (d: DateArg, options: { addSuffix?: boolean } = {}) => {
  d = fnsToDate(d);
  if (isToday(d)) {
    return "today";
  }
  if (isYesterday(d)) {
    return "yesterday";
  }
  return fnsFormatDistanceToNow(d, { locale, ...options });
};

export const getCurDateForReportFilename = () => fnsFormat(new Date(), "MMddyyyy");

export const millisecondsToHours = (ms: number) => {
  const tempTime = moment.duration(ms);
  const hours: number = Math.round(tempTime.asHours());
  return `${hours}h`;
};

export const millisecondsToHoursMin = (ms: number) => {
  const tempTime = moment.duration(ms);
  const hours: number = Math.round(tempTime.asHours());
  const min: number = Math.round(tempTime.minutes());
  return `${hours}h ${min}m`;
};
