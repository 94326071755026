import { Button, Card, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { MESSAGES } from "../../../../../i18n";
import { LicenseValue, WorkingFilterOtherFilters, doSetFilter } from "../../../../../redux/actions/students-list/index";
import {
  getCurrentWorkingFilter,
  getFollowedStudents,
  isFetchCompleted
} from "../../../../../redux/selectors/dashboard/tenant";
import { routes } from "../../../../../routes";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const FollowedStudents = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const value = useSelector(getFollowedStudents);
  const filter = useSelector(getCurrentWorkingFilter);
  const ready = useSelector(isFetchCompleted);
  return (
    <Card
      loading={!ready}
      className="h-full"
      styles={{
        body: {
          height: "100%",
          display: "flex",
          flexDirection: "column"
        }
      }}
    >
      <div className="text-center truncate">
        <Typography.Text className="uppercase" type="secondary">
          {MESSAGES.FollowedStudents}
        </Typography.Text>
      </div>
      <div className="flex items-center h-full">
        <Button
          type="link"
          data-qa-label="followed-students-count"
          className={_.join(
            [
              "text-3xl",
              "text-slate-600",
              "font-medium",
              "w-full" //
            ],
            " "
          )}
          onClick={() => {
            const license: LicenseValue = "active";
            const metrics: WorkingFilterOtherFilters = {
              passedAllExams: false,
              hiddenStudents: "no",
              followedStudents: true
            };

            dispatch(doSetFilter({ ...filter, license, metrics }));
            navigate(routes.students.resolve());
          }}
        >
          {value}
        </Button>
      </div>
    </Card>
  );
};
