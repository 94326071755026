import { Card, Flex, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { altIdsByProduct, sectionAltIdsOrder } from "../../../../../constants/courses";
import { MESSAGES } from "../../../../../i18n";
import { getActiveProduct } from "../../../../../redux/selectors/active-product";
import { isFetchCompleted } from "../../../../../redux/selectors/dashboard/tenant";
import { StudentsActivityChartBySection } from "./StudentsActivityChartBySection";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentsActivityBySection = () => {
  const dataReady = useSelector(isFetchCompleted);
  const activeProduct = useSelector(getActiveProduct);
  return (
    <Card loading={!dataReady}>
      <Flex vertical>
        <Typography.Text className="uppercase" type="secondary" style={{ minHeight: "30px" }}>
          {MESSAGES.StudentsBySection}
        </Typography.Text>
        <Flex wrap>
          {_.chain(altIdsByProduct[activeProduct])
            .sortBy(altId => _.indexOf(sectionAltIdsOrder, altId))
            .map(altId => <StudentsActivityChartBySection key={altId} altId={altId} />)
            .value()}
          <StudentsActivityChartBySection />
        </Flex>
      </Flex>
    </Card>
  );
};
