import { compile } from "path-to-regexp";
import { paths } from "./paths";

export const alertsReader = (() => {
  const path = paths.alertsReader;
  const pathWithParams = `${paths.alertsReader}/:id`;
  const toPathWithParams = compile(pathWithParams);
  return {
    paths: [pathWithParams, path],
    resolve(params?: Partial<Record<string, string | string[]>>) {
      return params ? toPathWithParams(params) : paths.alertsReader;
    }
  };
})();
