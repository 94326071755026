import { createSelector } from "reselect";
import { RdxMySelfState } from "../reducers/myself/types";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getUserFetchStatus: (state: RdxStoreState) => RdxFetchStatus = createSelector(
  ({ myself }: RdxStoreState) => myself,
  ({ status }: RdxMySelfState): RdxFetchStatus => status
);
