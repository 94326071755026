import { Flex, Typography } from "antd";
import React from "react";
import { NameField } from "../EditorFields/FieldName";
import { SectionField } from "../EditorFields/FieldSection";
import { TagIdsField } from "../EditorFields/FieldTagIds";
import { TenantField } from "../EditorFields/FieldTenant";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const EditorStepOverview = () => (
  <Flex vertical gap={6}>
    <Typography.Title level={5} className="grow">
      Details
    </Typography.Title>
    <Typography.Text className="mb-6" type="secondary">
      In this initial step, you will define the details for creating a new study plan. Here, you can set the name of the
      plan, select the tenant, choose the section, and assign the tags that will be associated with the study plan.
    </Typography.Text>

    <NameField />
    <SectionField />
    <TenantField />
    <TagIdsField />
  </Flex>
);
