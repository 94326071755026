import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationToastsContext } from "../../../../components/AppToasts";
import { routes } from "../../../../routes";
import { navApi } from "../../../../services/nav-api";
import { StudyPlanEditorFields } from "./useEditorFields";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type StudyPlanEditorApiCallbacks = {
  loading: boolean;
  onSave: (studyPlanId?: number | null) => Promise<void>;
};

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useStudyPlanApi = (e: StudyPlanEditorFields): StudyPlanEditorApiCallbacks => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const toastsApi = React.useContext(ApplicationToastsContext);
  const navigateTo = useNavigate();

  return {
    loading,
    onSave: async (studyPlanId?: number | null) => {
      try {
        setLoading(true);
        const rsp = await (
          !_.isNil(studyPlanId)
            ? //
              navApi.studyPlans.editStudyPlan
            : navApi.studyPlans.createStudyPlan
        )(studyPlanId ?? e.tenantId.value!, {
          sectionAltId: e.section.toApiRequestValue(),
          name: e.name.value!,
          tagIds: e.tagIds.value,
          lockUntil: e.lockUntil.toApiRequestValue(),
          settings: {
            estimatedDate: false,
            startDate: e.startDate.toApiRequestValue(),
            examDate: e.endDate.toApiRequestValue(),
            pace: e.studyPace.toApiRequestValue(),
            daysOff: e.daysOff.toApiRequestValue(),
            weekDayHours: e.weekDayHours.value,
            reviewDaysBeforeExam: 0,
            includePracticeTest: e.practiceTests.value,
            includeFlashcard: e.flashcards.value,
            includeFR: e.finalReview.value,
            plannedExamDate: 3
          }
        });

        if (studyPlanId) {
          toastsApi.success({
            message: "Study Plan Updated",
            description: `The study plan '${e.name.value!}' has been updated successfully.`
          });
        } else {
          toastsApi.success({
            message: "Study Plan Created",
            description: `The study plan '${e.name.value!}' has been created successfully.`
          });
        }

        navigateTo(
          routes.studyPlansPreview.resolve({
            id: (rsp?.id ?? studyPlanId).toString()
          })
        );
      } catch (e) {
        const error = e as { message: string; status: string };
        toastsApi.error({
          message: error?.message ?? "Unknown error"
        });
      } finally {
        setLoading(false);
      }
    }
  };
};

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

export const studyPlanEditorApiCallbacksInitValue: StudyPlanEditorApiCallbacks = {
  loading: false,
  onSave: () => Promise.resolve()
};
