import dayjs from "dayjs";

// -------------------------------------------------------------------------------------------------
// - Enums
// -------------------------------------------------------------------------------------------------

export enum StudyPlanTaskEditorStatus {
  OPEN_FOR_CREATE = "open_for_create",
  OPEN_FOR_EDIT = "open_for_edit",
  CLOSED = "closed"
}

export type CustomTask = Partial<{
  id: string;
  name: string;
  quarterHours: number;
  date: dayjs.Dayjs;
}>;
