import { Button, Card, Collapse, Empty } from "antd";
import _ from "lodash";
import React from "react";
import { useAlerts } from "../hooks";
import { AlertsBody } from "./AlertsBody";
import { AlertsCaret } from "./AlertsCaret";
import { AlertsExtra } from "./AlertsExtra";
import { AlertsHeader } from "./AlertsHeader";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  alertId?: number;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertsList = ({ alertId }: OwnProps) => {
  const { alerts, markAsRead, resetState } = useAlerts(alertId);
  return (
    <>
      {alerts?.length > 0 ? (
        <Collapse
          bordered={false}
          accordion
          key="AlertsList"
          expandIcon={({ isActive }) => <AlertsCaret isActive={Boolean(isActive)} />}
          defaultActiveKey={alertId}
          onChange={(alertIds: string[]) => {
            markAsRead(parseInt(_.head(alertIds)!));
          }}
        >
          {_.map(alerts, alert => (
            <Collapse.Panel
              key={alert.id} //
              header={<AlertsHeader alert={alert} />}
              extra={<AlertsExtra alert={alert} />}
            >
              <AlertsBody alert={alert} />
            </Collapse.Panel>
          ))}
        </Collapse>
      ) : (
        <Card>
          <Empty description={<span>There are no outstanding notifications to display</span>} />
        </Card>
      )}
      {__DEVELOPMENT__ ? (
        <Button type="link" className="w-full" onClick={() => resetState()}>
          Reset State
        </Button>
      ) : null}
    </>
  );
};
