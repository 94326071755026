import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterTag } from "../../../../core/model";
import { MESSAGES, formatMsg } from "../../../../i18n";
import { doFetchTenantDashboard } from "../../../../redux/actions/dashboard/tenant/fetch";
import { getCurrentActiveFilter } from "../../../../redux/selectors/dashboard/tenant";

// -------------------------------------------------------------------------------------------------
// - Hooks: useFilterTags
// -------------------------------------------------------------------------------------------------

export const useFilterTags = (): FilterTag[] => {
  const dispatch = useDispatch<any>();
  const activeFilter = useSelector(getCurrentActiveFilter);
  return React.useMemo(
    () =>
      activeFilter?.withoutTags || activeFilter?.tags?.length
        ? [
            {
              name: "tags",
              onClear: () => {
                dispatch(doFetchTenantDashboard(true));
              },
              text: formatMsg(
                MESSAGES.FilterTagTags,
                activeFilter.withoutTags //
                  ? MESSAGES.WithoutTags
                  : activeFilter.tags.length
              )
            }
          ]
        : [],
    [
      //
      activeFilter?.withoutTags,
      activeFilter?.tags?.length
    ]
  );
};
