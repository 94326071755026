import _ from "lodash";
import { createSelector } from "reselect";
import { AltID, sectionAltIdsOrder } from "../../constants/courses";
import { RdxStoreState } from "../types/state";
import { getActiveProduct } from "./active-product";

export const getSectionsSelectOptions = createSelector(
  ({ hierarchyContent: { content } }: RdxStoreState) => content,
  content =>
    _.chain(content?.sections)
      .sortBy(({ altId }) => _.indexOf(sectionAltIdsOrder, altId))
      .reduce(
        (a, s) => [
          ...a,
          {
            label: s.abbrev,
            value: s.altId as AltID
          }
        ],
        []
      )
      .value()
);

export const getPlainUnitsList = createSelector(
  ({ hierarchyContent: { content } }: RdxStoreState) => content,
  getActiveProduct,
  (content, product) =>
    _.chain(content?.sections)
      .sortBy(({ altId }) => _.indexOf(sectionAltIdsOrder, altId))
      .reduce(
        (a, s) => [
          ...a,
          ..._.map(s.units, unit => ({
            unitAltId: unit.altId,
            filenameSuffix: _.isEqual(product, "cma") //
              ? `${s.abbrev}-${unit.abbrev}`
              : unit.abbrev
          }))
        ],
        []
      )
      .value()
);

export const getUnitsGroupedBySection = createSelector(
  ({ hierarchyContent: { content } }: RdxStoreState) => content,
  content =>
    _.chain(content?.sections)
      .sortBy(({ altId }) => _.indexOf(sectionAltIdsOrder, altId))
      .reduce(
        (a, s) => [
          ...a,
          {
            label: s.abbrev,
            value: s.altId,
            units: _.map(s.units, unit => ({
              label: `${unit.abbrev} - ${unit.name}`,
              value: unit.altId
            }))
          }
        ],
        []
      )
      .value()
);

export const getSectionsList = createSelector(
  ({ hierarchyContent: { content } }: RdxStoreState) => content?.sections,
  sections =>
    _.chain(sections)
      .sortBy(({ altId }) => _.indexOf(sectionAltIdsOrder, altId))
      .map(({ altId, name, abbrev, variant }) => ({
        altId,
        name,
        abbrev,
        variant
      }))
      .value()
);

export const getUnitsList = createSelector(
  ({ hierarchyContent: { content } }: RdxStoreState) => content?.sections,
  sections =>
    _.chain(sections)
      .sortBy(({ altId }) => _.indexOf(sectionAltIdsOrder, altId))
      .map(section =>
        _.map(section.units, ({ altId, name, abbrev }) => ({
          altId,
          name,
          abbrev,
          section: section.abbrev
        }))
      )
      .flattenDeep()
      .value()
);

export const getModulesList = createSelector(
  ({ hierarchyContent: { content } }: RdxStoreState) => content?.sections,
  sections =>
    _.chain(sections)
      .sortBy(({ altId }) => _.indexOf(sectionAltIdsOrder, altId))
      .map(s =>
        _.map(s.units, u =>
          _.flattenDeep(
            _.map(u.modules, ({ altId, name, abbrev }) => ({
              altId,
              name,
              abbrev,
              section: s.abbrev,
              unit: u.abbrev
            }))
          )
        )
      )
      .flattenDeep()
      .value()
);
