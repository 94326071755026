import { Affix, Card, Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScrollableContentDivId } from "../../components/BaseLayout";
import { ProductFilter } from "../../components/ProductFilter";
import { SearchFilter } from "../../components/SearchFilter";
import { StatusFilter } from "../../components/StatusFilter";
import { StudyPlanFilter } from "../../components/StudyPlanFilter";
import { TenantTypeFilter } from "../../components/TenantTypeFilter";
import { MESSAGES } from "../../i18n";
import { doUpdateWorkingFilter } from "../../redux/actions/tenants/tenants";
import { initialState } from "../../redux/reducers/tenants/tenants";
import { RdxStoreState } from "../../redux/types/state";
import { TenantsTableFiltersActions } from "./TenantsTableFiltersActions";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantsTableFilters = () => {
  const dispatch = useDispatch<any>();
  const workingFilter = useSelector((state: RdxStoreState) =>
    state.tenants.tenants ? state.tenants.tenants.workingFilter : initialState.workingFilter
  );

  return (
    <Affix target={() => document.querySelector<HTMLElement>(`#${ScrollableContentDivId}`)}>
      <Card>
        <Form layout="vertical">
          <SearchFilter
            value={workingFilter.filter}
            placeholder={MESSAGES.FilterSearchNamePlaceholder}
            onChange={filter => {
              dispatch(doUpdateWorkingFilter({ filter }));
            }}
          />
          <TenantTypeFilter
            value={workingFilter.type}
            onChange={type => {
              dispatch(doUpdateWorkingFilter({ type }));
            }}
          />
          <StatusFilter
            value={workingFilter.status}
            onChange={status => {
              dispatch(doUpdateWorkingFilter({ status }));
            }}
          />
          <StudyPlanFilter
            value={workingFilter.hasStudyPlan}
            onChange={hasStudyPlan => {
              dispatch(doUpdateWorkingFilter({ hasStudyPlan }));
            }}
          />
          <ProductFilter
            value={workingFilter.products}
            onChange={product => dispatch(doUpdateWorkingFilter({ products: product }))}
          />
          <TenantsTableFiltersActions />
        </Form>
      </Card>
    </Affix>
  );
};
