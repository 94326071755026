import { Button, Flex, Space, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { colorBySlice, labelByStatsScopeAndSlice, textColorBySlice } from "./constants";
import { StatsData, StatsScope, StatsSlice } from "./model";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  value: StatsData[];
  statsType: StatsScope;
  onClick: (data: StatsData) => void;
}

// -------------------------------------------------------------------------------------------------
// - Utils
// -------------------------------------------------------------------------------------------------

const calcPercentage = (total: number, count: number) => (total > 0 ? (((count * 1.0) / total) * 100).toFixed() : 0);

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Chart = (props: OwnProps) => {
  const total = _.sumBy(props.value, v => v.value);
  return (
    <Flex vertical className="w-full">
      <Flex className="w-full" justify="center" align="center">
        <ResponsiveContainer height={300}>
          <PieChart>
            <Pie
              data={props.value}
              cx="50%"
              cy="50%"
              dataKey="value"
              nameKey="slice"
              outerRadius={120}
              labelLine={false}
              isAnimationActive={false}
              label={({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
                const RADIAN = Math.PI / 180;
                const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                const y = cy + radius * Math.sin(-midAngle * RADIAN);
                return (
                  <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                  </text>
                );
              }}
            >
              {props.value.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colorBySlice[entry.slice]}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.onClick(entry);
                  }}
                />
              ))}
            </Pie>
            <Tooltip
              formatter={(value: number, slice: StatsSlice) => [
                <Space direction="vertical" size="small">
                  <Space direction="horizontal" size="small">
                    <Typography.Text strong>{labelByStatsScopeAndSlice[props.statsType][slice]}: </Typography.Text>
                    <Typography.Text>{calcPercentage(total, value)}%</Typography.Text>
                  </Space>
                  <Typography.Text disabled>
                    <small>
                      {value} in {total}
                    </small>
                  </Typography.Text>
                </Space>
              ]}
            />
          </PieChart>
        </ResponsiveContainer>
      </Flex>
      <Flex vertical={false} gap={12} className="w-full">
        {_.chain(props.value)
          .map((data: StatsData) => {
            const { slice, value } = data;
            const percentage = calcPercentage(total, value);
            const textColor = textColorBySlice[slice];
            const name = labelByStatsScopeAndSlice[props.statsType][slice];
            const qaLabel = `${_.kebabCase(`${props.statsType}`)}-${_.toLower(slice)}-percentage`;
            return (
              <Flex vertical key={`${slice}-${props.statsType}`} className="grow">
                <Flex justify="center">
                  <Button
                    type="link"
                    className={[
                      "m-0",
                      "p-0",
                      "h-full",
                      "font-bold",
                      "text-left",
                      "text-lg",
                      textColor //
                    ].join(" ")}
                    onClick={() => {
                      props.onClick(data);
                    }}
                  >
                    <span data-qa-label={qaLabel}>{percentage}%</span>
                  </Button>
                </Flex>
                <Flex justify="center">
                  <Typography.Text className="m-0 p-0 text-xs text-[#6b6974]">{name}</Typography.Text>
                </Flex>
              </Flex>
            );
          })
          .value()}
      </Flex>
    </Flex>
  );
};
