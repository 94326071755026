import { useDispatch, useSelector } from "react-redux";
import { FilterTag } from "../../../core/model";
import { MESSAGES, formatMsg } from "../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../redux/actions/students-list";
import { initialState } from "../../../redux/reducers/students-list";
import { RdxStoreState } from "../../../redux/types/state";

// -------------------------------------------------------------------------------------------------
// - Hooks: useTagsFilter
// -------------------------------------------------------------------------------------------------

export const useTagsFilter = (): FilterTag[] => {
  const dispatch = useDispatch<any>();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);
  return activeFilter?.filter?.withoutTags || activeFilter?.filter?.tags?.length
    ? [
        {
          name: "tags",
          onClear: () => {
            dispatch(doUpdateWorkingFilter({ tags: initialState.workingFilter.tags, withoutTags: undefined }));
            dispatch(doApplyFilter());
          },
          text: formatMsg(
            MESSAGES.FilterTagTags,
            activeFilter.filter.withoutTags //
              ? MESSAGES.WithoutTags
              : activeFilter.filter.tags.length
          )
        }
      ]
    : [];
};
