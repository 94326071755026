import { createReducer } from "@reduxjs/toolkit";
import keyBy from "lodash/keyBy";
import sortBy from "lodash/sortBy";
import { Tenant } from "../../../services/nav-api/tenants/types";
import { Status } from "../../../services/nav-api/types";
import { fetchTenantsDropdown } from "../../actions/tenants/tenants-dropdown";
import { RdxFetchStatus } from "../../types/status";
import { RdxTenantsDropdownSubState } from "./types";

const createFakeTenant = (id: number, name: string): Tenant => ({
  id,
  name,
  status: Status.Enabled,
  created: Date.now(),
  products: [],
  type: "Firm",
  hasTagForm: false,
  hasStudyPlan: false
});

const NoTenantsFilter = createFakeTenant(0, "No Tenant");
const AllTenantsFilter = createFakeTenant(-1, "All");

const initialState: RdxTenantsDropdownSubState = {
  status: RdxFetchStatus.NONE,
  allIds: [],
  tenantById: {}
};

export default createReducer<RdxTenantsDropdownSubState>(initialState, builder => {
  builder
    .addCase(fetchTenantsDropdown.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }

      if (payload.tenants) {
        state.status = RdxFetchStatus.COMPLETED;
        const tenants = [
          NoTenantsFilter,
          AllTenantsFilter,
          ...sortBy(payload.tenants || [], t => t.name.toLowerCase())
        ];
        state.allIds = tenants.map(t => t.id);
        state.tenantById = keyBy(tenants, t => t.id);
      }
    })
    .addCase(fetchTenantsDropdown.rejected, state => {
      state.status = RdxFetchStatus.FAILED;
    })
    .addCase(fetchTenantsDropdown.pending, state => {
      state.status = RdxFetchStatus.LOADING;
    });
});
