import { Alert, Button, Form, Modal, Popconfirm } from "antd";
import { Field, Formik } from "formik";
import _ from "lodash";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { formatMsg, MESSAGES } from "../../../i18n";
import { StudentActionsContext } from "../hooks/useStudentActions";
import { FieldSelect } from "./FieldSelect";
import { TagsOptions } from "./tags";
import { BulkChangeForm } from "./types";
import { useBulkChangeSubmission } from "./useBulkChangeSubmission";
import { VisibilityOptions } from "./visibility";

// -------------------------------------------------------------------------------------------------
//  constants
// -------------------------------------------------------------------------------------------------

const BULK_FORM_HTML_ID = "bulk-changes-form";
const initialFormData: BulkChangeForm = {
  field: "tags",
  tagsAction: "assign",
  tags: [],
  hidden: false
};

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const StudentsBulkDialog = () => {
  const { bulkChange, onBulkChangeModalClose } = React.useContext(StudentActionsContext);
  const { loading, onSubmit } = useBulkChangeSubmission(bulkChange.token!);

  return (
    <Formik<BulkChangeForm> onSubmit={onSubmit} initialValues={initialFormData}>
      {formProps => {
        return (
          <Modal
            open
            title={MESSAGES.BulkChangeModalTitle}
            onCancel={onBulkChangeModalClose}
            footer={[
              <Button key="cancel" onClick={onBulkChangeModalClose}>
                Cancel
              </Button>,
              <Popconfirm
                key="submit"
                title={
                  <div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: formatMsg(
                          MESSAGES.BulkChangeConfirmationPromptLine1,
                          formProps.values.field === "tags" ? "Tags" : "Visibility",
                          bulkChange.total!
                        )
                      }}
                    />
                    <p>{MESSAGES.BulkChangeConfirmationPromptLine2}</p>
                  </div>
                }
                onConfirm={() => onSubmit(formProps.values)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" loading={loading}>
                  Submit
                </Button>
              </Popconfirm>
            ]}
            okText="Confirm"
            okButtonProps={{
              disabled: !isEmpty(formProps.errors) || !formProps.dirty,
              form: BULK_FORM_HTML_ID,
              htmlType: "submit",
              loading
            }}
          >
            <Form id={BULK_FORM_HTML_ID} layout="vertical" onFinish={formProps.handleSubmit}>
              <Field name="field" className="bulk-change-field">
                {({ field }: any) => (
                  <FieldSelect
                    value={field.value}
                    onChange={v => {
                      formProps.setFieldTouched(field.name);
                      formProps.setFieldValue(field.name, v);
                    }}
                  />
                )}
              </Field>
              {_.isEqual(formProps.values.field, "tags") ? (
                <TagsOptions operation={formProps.values.tagsAction!} />
              ) : (
                <VisibilityOptions />
              )}
            </Form>

            {true &&
            _.isEqual(formProps.values.field, "tags") &&
            _.isEqual(formProps.values.tagsAction, "clear") &&
            _.isEmpty(formProps.values.tags) ? (
              <Alert type="warning" message="Please select at least one tag." />
            ) : null}
          </Modal>
        );
      }}
    </Formik>
  );
};
