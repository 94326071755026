import { Affix, Button, Card, Col, Form, Modal, Row, Switch, Table, Typography } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BaseLayout, ScrollableContentDivId } from "../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../components/NavigationDrawer/types";
import { SearchFilter } from "../../components/SearchFilter";
import { StatusFilter } from "../../components/StatusFilter";
import { composeHandlers, handleCellText, renderWithMaxWidth } from "../../components/TableCellRender";
import { TenantTypeFilter } from "../../components/TenantTypeFilter";
import { MESSAGES } from "../../i18n";
import {
  doApplyFilter,
  doChangeListSettings,
  doClearFilter,
  doFetchPartners,
  doResetListSettings,
  doUpdateWorkingFilter
} from "../../redux/actions/partners";
import { getIsApplyPartnersListFilterDisabled } from "../../redux/selectors/is-apply-partners-list-filter-disabled";
import { getIsClearPartnersListFilterDisabled } from "../../redux/selectors/is-clear-partners-list-filter-disabled";
import { RdxStoreState } from "../../redux/types/state";
import { RdxFetchStatus } from "../../redux/types/status";
import { routes } from "../../routes";
import { HttpClientErrorResponse } from "../../services/http-client/types";
import { navApi } from "../../services/nav-api";
import { Partner } from "../../services/nav-api/partners/types";
import { Status } from "../../services/nav-api/types";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Checked = (props: { id: number; value: boolean }) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch<any>();
  return (
    <Switch
      checkedChildren={MESSAGES.Enabled}
      unCheckedChildren={MESSAGES.Disabled}
      checked={props.value}
      loading={loading}
      onChange={value => {
        setLoading(true);
        navApi.partners
          .putPartnerStatus(props.id, value === false ? Status.Disabled : Status.Enabled)
          .then(() => {
            Modal.success({
              title: MESSAGES.PartnerStatusTitle,
              content: MESSAGES.PartnerStatusSuccess
            });
            dispatch(doFetchPartners());
          })
          .catch((err: HttpClientErrorResponse) =>
            Modal.error({
              title: MESSAGES.PartnerStatusTitle,
              content: err.message ? err.message : MESSAGES.UnknownError
            })
          )
          .finally(() => setLoading(false));
      }}
    />
  );
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Partners = () => {
  const dispatch = useDispatch<any>();
  const data = useSelector(({ partners }: RdxStoreState) => partners);
  const clearFilterDisabled = useSelector(getIsClearPartnersListFilterDisabled);
  const applyFilterDisabled = useSelector(getIsApplyPartnersListFilterDisabled);
  const workingFilter = useSelector((state: RdxStoreState) => state.partners.workingFilter);

  React.useEffect(() => {
    dispatch(doFetchPartners());
  }, []);
  return (
    <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_PARTNERS}>
      <Row gutter={16}>
        <Col xs={18} xxl={19}>
          <Typography.Title level={2}>{MESSAGES.Partners}</Typography.Title>
        </Col>
        <Col xs={6} xxl={5} />
      </Row>
      <Row gutter={16}>
        <Col xs={18} xxl={19}>
          <Card>
            <div className="overflow-x-hidden">
              <Table
                size="small"
                loading={_.isEqual(RdxFetchStatus.LOADING, data.status)}
                dataSource={_.map(data.partners, (r, key) => ({ ...r, key }))}
                columns={[
                  {
                    width: "55%",
                    dataIndex: "name",
                    title: "Partner Name",
                    render: composeHandlers(handleCellText, renderWithMaxWidth(500)),
                    className: "td-test",
                    ellipsis: true
                  },
                  {
                    title: "Tenant",
                    dataIndex: "tenantId",
                    render: function RoleCol(tenantId: number) {
                      if (data.tenants) {
                        try {
                          const tenant = data.tenants.filter(t => t.id === tenantId)[0];
                          return <a href={routes.tenant.resolve({ id: tenant.id.toString() })}>{tenant.name}</a>;
                        } catch (err) {
                          return tenantId;
                        }
                      }
                      return tenantId;
                    },
                    width: "15%",
                    ellipsis: true
                  },
                  {
                    title: "Type",
                    dataIndex: "type",
                    render: handleCellText,
                    width: "10%",
                    ellipsis: true
                  },
                  {
                    title: "Segment ID",
                    dataIndex: "refKey",
                    render: handleCellText,
                    width: "10%",
                    ellipsis: true
                  },
                  {
                    title: "Status",
                    key: "status",
                    width: "100px",
                    render: (_cell: any, { id, status }: Partner) => (
                      <Checked id={id} value={_.isEqual(Status.Enabled, status)} />
                    )
                  }
                ]}
                pagination={{
                  current: data.paging.page,
                  pageSize: data.paging.itemsPerPage,
                  total: data.total,
                  showSizeChanger: true,
                  pageSizeOptions: ["50", "100", "250"],
                  showQuickJumper: true,
                  showLessItems: true,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                }}
                onRow={() => ({ onClick: _.noop })}
                onChange={({ current: page, pageSize }: PaginationProps) => {
                  dispatch(doChangeListSettings(page!, pageSize!));
                }}
              />
            </div>
          </Card>
        </Col>
        <Col xs={6} xxl={5}>
          <Affix target={() => document.querySelector<HTMLElement>(`#${ScrollableContentDivId}`)}>
            <Card>
              <Form layout="vertical">
                <SearchFilter
                  value={workingFilter.name as string}
                  onChange={name => dispatch(doUpdateWorkingFilter({ name }))}
                  placeholder={MESSAGES.FilterSearchNamePlaceholder}
                  minChars={3}
                />
                <TenantTypeFilter
                  value={workingFilter.type}
                  onChange={type => dispatch(doUpdateWorkingFilter({ type }))}
                />
                <StatusFilter
                  value={workingFilter.status}
                  onChange={status => dispatch(doUpdateWorkingFilter({ status }))}
                />
                <div className="flex justify-center w-full gap-x-2">
                  <Button disabled={clearFilterDisabled} htmlType="button" onClick={() => dispatch(doClearFilter())}>
                    {MESSAGES.Clear}
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    disabled={applyFilterDisabled}
                    onClick={(e: React.FormEvent) => {
                      e.preventDefault();
                      if (applyFilterDisabled) {
                        return;
                      }
                      dispatch(doResetListSettings());
                      dispatch(doApplyFilter());
                    }}
                  >
                    {MESSAGES.Apply}
                  </Button>
                </div>
              </Form>
            </Card>
          </Affix>
        </Col>
      </Row>
    </BaseLayout>
  );
};
