import { Alert, Card, Typography } from "antd";
import React from "react";
import { BaseLayout } from "../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../components/NavigationDrawer/types";
import { TagsEditor } from "../../components/TagsEditor";
import { MESSAGES } from "../../i18n";

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

const Tags = () => (
  <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_TAGS}>
    <Typography.Title level={2}>Tags</Typography.Title>
    <Card style={{ marginBottom: "50px" }}>
      <Alert
        type="info"
        style={{ marginBottom: "20px" }}
        message={
          <>
            {MESSAGES.RootLevelTagsInfo}
            <br />
            {MESSAGES.RootLevelTagsInfo2}
          </>
        }
      />
      <TagsEditor />
    </Card>
  </BaseLayout>
);

export default Tags;
