import { Form, Select } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getActiveUserRoles } from "../../redux/selectors/active-user-roles";
import { Role } from "../../services/nav-api/roles";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

export const RoleIdFilterAllValue = -1;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const UsersTableFilterForRoles = (props: { onChange: (value: number) => void; value: number | undefined }) => {
  const roles: Role[] = useSelector(getActiveUserRoles);
  return (
    <Form.Item label="Role">
      <Select
        data-qa-label="role-select"
        value={props.value}
        onChange={props.onChange}
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        options={_.chain(roles)
          .map(role => ({ label: role.name, value: role.id }))
          .thru(val => [{ label: "All", value: RoleIdFilterAllValue }, ...val])
          .value()}
      />
    </Form.Item>
  );
};
