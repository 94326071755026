import { Flex, Typography } from "antd";
import React from "react";
import { Alert } from "../../../services/nav-api/alerts-reader/types";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertsHeader = ({ alert }: { alert: Alert }) => (
  <Flex className="ml-2 h-full" justify="flex-start" align="center" wrap>
    <Typography.Text strong className="line-clamp-2">
      {alert.title}
    </Typography.Text>
  </Flex>
);
