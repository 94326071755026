import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { StudyPlanCalendar } from "../../../../../services/nav-api/studyPlans/model";
import { StudyPlanValue } from "../useStudyPlanViewerData";
import { CustomTask, StudyPlanTaskEditorStatus } from "./types";
import { useApiCallbacks } from "./useApiCallbacks";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

type OpenOperation =
  | {
      operation: "create_empty";
    }
  | {
      operation: "create_at_day";
      atDay: dayjs.Dayjs;
    }
  | {
      operation: "edit";
      taskId: string;
    };

export type EditorValue = {
  status: StudyPlanTaskEditorStatus;
  loading: boolean;
  isCustomTaskInvalid: boolean;
  actions: {
    openEditor: (op: OpenOperation) => void;
    closeEditor: (save?: boolean) => Promise<void>;
    deleteTask: () => Promise<void>;
    setTaskProp: <K extends keyof CustomTask>(key: K, value: CustomTask[K]) => void;
    getTaskProp: <T extends keyof CustomTask>(key: T) => CustomTask[T] | null;
  };
};

// -------------------------------------------------------------------------------------------------
// - Context
// -------------------------------------------------------------------------------------------------

export const editorContext = React.createContext<EditorValue>({
  status: StudyPlanTaskEditorStatus.CLOSED,
  loading: false,
  isCustomTaskInvalid: false,
  actions: {
    openEditor: _.noop,
    closeEditor: () => Promise.resolve(),
    deleteTask: () => Promise.resolve(),
    setTaskProp: _.noop,
    getTaskProp: () => null
  }
});

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useEditor = (sp: StudyPlanValue): EditorValue => {
  const apiCb = useApiCallbacks();
  const [customTask, setCustomTask] = React.useState<CustomTask | null>(null);

  // -----------------------------------------------------------------------------------------------
  // - Helpers
  // -----------------------------------------------------------------------------------------------

  const getEarliestDayWithTask = React.useCallback((calendar?: StudyPlanCalendar[], taskId?: string) => {
    return _.chain(calendar)
      .filter(({ placements }) => _.some(placements, { taskId }))
      .map(c => ({ ...c, day: dayjs(c.day) }))
      .sortBy("day")
      .map("day")
      .first()
      .value();
  }, []);

  const countQuarterHours = React.useCallback((calendar?: StudyPlanCalendar[], taskId?: string) => {
    return _.chain(calendar)
      .map(({ placements }) => _.filter(placements, { taskId }))
      .flattenDeep()
      .sumBy("quarterHours")
      .value();
  }, []);

  const createCustomTaskPlaceholderAtDay = React.useCallback(
    (date: dayjs.Dayjs) => ({
      name: "",
      quarterHours: 1,
      date
    }),
    []
  );

  // -----------------------------------------------------------------------------------------------
  // - Ret Val
  // -----------------------------------------------------------------------------------------------

  return {
    loading: apiCb.loading,
    status: _.isNil(customTask)
      ? StudyPlanTaskEditorStatus.CLOSED
      : _.isNil(customTask.id)
        ? StudyPlanTaskEditorStatus.OPEN_FOR_CREATE
        : StudyPlanTaskEditorStatus.OPEN_FOR_EDIT,

    isCustomTaskInvalid:
      _.isEmpty(customTask?.name) || //
      _.isNil(customTask?.quarterHours) ||
      _.isNil(customTask?.date),

    // ---------------------------------------------------------------------------------------------
    // - Actions
    // ---------------------------------------------------------------------------------------------

    actions: {
      // -------------------------------------------------------------------------------------------
      // - Open editor
      // -------------------------------------------------------------------------------------------

      openEditor: (op: OpenOperation) => {
        switch (op.operation) {
          case "create_empty":
            setCustomTask(createCustomTaskPlaceholderAtDay(sp.summary.startDate!));
            break;
          case "create_at_day":
            setCustomTask(createCustomTaskPlaceholderAtDay(op.atDay!));
            break;
          case "edit":
            setCustomTask(
              _.chain(sp.remoteStudyPlan?.tasks)
                .filter({ id: op.taskId, type: "custom" })
                .map(t => {
                  return {
                    id: t.id,
                    name: t.name,
                    quarterHours: countQuarterHours(sp.remoteStudyPlan?.calendar, t.id),
                    date: getEarliestDayWithTask(sp.remoteStudyPlan?.calendar, t.id)
                  };
                })
                .head()
                .value()
            );

            break;
        }
      },

      // -------------------------------------------------------------------------------------------
      // - Close editor
      // -------------------------------------------------------------------------------------------

      closeEditor: async (save?: boolean) => {
        if (save && !_.isNil(customTask) && !_.isNil(sp.id)) {
          const studyPlanId = sp.id!;
          if (_.isNil(customTask?.id)) {
            await apiCb.createCustomTask(studyPlanId, customTask!);
          } else {
            await apiCb.updateCustomTask(studyPlanId, customTask as CustomTask);
          }
          sp.forceReload();
        }

        setCustomTask(null);
      },

      // -------------------------------------------------------------------------------------------
      // - Delete task
      // -------------------------------------------------------------------------------------------

      deleteTask: async () => {
        if (!_.isNil(sp.id) && !_.isNil(customTask?.id)) {
          await apiCb.deleteCustomTask(sp.id!, customTask?.id!);
          sp.forceReload();
        }

        setCustomTask(null);
      },

      // -------------------------------------------------------------------------------------------
      // - Update task
      // -------------------------------------------------------------------------------------------

      setTaskProp: (key: keyof CustomTask, value: CustomTask[keyof CustomTask]) => {
        setCustomTask({ ...customTask!, [key]: value });
      },

      // -------------------------------------------------------------------------------------------
      // - Get task prop
      // -------------------------------------------------------------------------------------------

      getTaskProp: <T extends keyof CustomTask>(key: T) => {
        return customTask?.[key] ?? null;
      }
    }
  };
};
