import React from "react";
import { StudyPlansListItem } from "../../../../../services/nav-api/studyPlans/model";
import { Field, FromApiResponseValue, StudyPlanEditorValue } from "./model";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type WeekDayHoursFieldValue = StudyPlanEditorValue["weekDayHours"];
export type WeekDayHoursField = Field<
  WeekDayHoursFieldValue,
  {
    fromApiResponseValue: FromApiResponseValue<WeekDayHoursFieldValue>;
  }
>;

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useWeekDayHours = (initValue: WeekDayHoursFieldValue = [0, 0, 0, 0, 0, 0, 0]): WeekDayHoursField => {
  const [value, setValue] = React.useState<WeekDayHoursFieldValue>(initValue);
  return {
    value,
    setValue: (val: WeekDayHoursFieldValue) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(initValue);
    },
    fromApiResponseValue: (rsp: StudyPlansListItem) => {
      const iVal = rsp.settings.weekDayHours as WeekDayHoursFieldValue;
      setValue(iVal);
      return iVal;
    }
  };
};
