import _ from "lodash";
import React from "react";
import { StudyPlanEditorFields } from "../useEditorFields";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type WeeklyDistributionInfoValue = {
  weekDaysHoursCount: number;
  studyTimeWeekValue: number;
  isStudyTimeOk: boolean;
};

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useWeeklyDistributionInfo = (e: StudyPlanEditorFields): WeeklyDistributionInfoValue =>
  React.useMemo(() => {
    const weekDaysHoursCount = _.sumBy(e.weekDayHours.value);
    const studyTimeWeekValue = e.studyTimeWeek.value ?? 0;
    const isStudyTimeOk = _.isEqual(weekDaysHoursCount, studyTimeWeekValue);
    return {
      weekDaysHoursCount,
      studyTimeWeekValue,
      isStudyTimeOk
    };
  }, [
    e.weekDayHours.value,
    e.studyTimeWeek.value
    //
  ]);
