import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getStudyPlanUsageStats, isFetchCompleted } from "../../../../../../../redux/selectors/dashboard/tenant";
import { colorBySlice } from "../constants";
import { StatsData, StatsScope, StatsSlice } from "../model";

// -------------------------------------------------------------------------------------------------
// - Hook: useUsage
// -------------------------------------------------------------------------------------------------

export const useUsageData = (scope: StatsScope): StatsData[] | undefined => {
  const isReady = useSelector(isFetchCompleted);
  const stats = useSelector(getStudyPlanUsageStats);
  return React.useMemo(
    () =>
      _.isEqual(scope, StatsScope.SPS_USAGE) && isReady && !!stats
        ? [
            {
              slice: StatsSlice.S1,
              value: stats.withStudyPlan,
              color: colorBySlice[StatsSlice.S1]
            },
            {
              slice: StatsSlice.S2,
              value: stats.withoutStudyPlan,
              color: colorBySlice[StatsSlice.S1]
            }
          ]
        : undefined,
    [stats, scope]
  );
};
