import { SearchOutlined } from "@ant-design/icons";
import { Form, Input, Typography } from "antd";
import React from "react";
import { MESSAGES } from "../i18n";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const SearchFilter = (props: {
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  minChars?: number;
}) => (
  <Form.Item label="Search">
    <Input
      value={props.value}
      onChange={e => props.onChange(e.currentTarget.value)}
      placeholder={props.placeholder ? props.placeholder : MESSAGES.FilterSearchPlaceholder}
      suffix={<SearchOutlined />}
    />
    {props.minChars && (
      <Typography.Text type="secondary" style={{ width: "90%", fontSize: "11px" }}>
        Minimum {props.minChars} characters.
      </Typography.Text>
    )}
  </Form.Item>
);
