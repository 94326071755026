import { DatePicker, Form } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { SYSTEM_CONFIG } from "../../../../constants/system-config";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
//  Constants
// -------------------------------------------------------------------------------------------------

const curEnv: Enviroment = SYSTEM_CONFIG.environment?.toLowerCase() as Enviroment;

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const StartDateField = () => (
  <StudyPlanEditorContext.Consumer>
    {({ editor: { startDate, endDate }, simulations, remoteStudyPlan }) => (
      <Form.Item label="Start">
        <DatePicker
          allowClear={false}
          className="w-full"
          data-qa-label="start-date"
          format="MM/DD/YYYY"
          value={startDate.value}
          disabled={!_.isNil(remoteStudyPlan.remoteId)}
          disabledDate={cur => {
            if (!_.isEqual(curEnv, "dev") && cur.isBefore(dayjs(), "day")) {
              return true;
            }

            return !_.isNil(endDate) ? cur?.isAfter(endDate.value, "day") : false;
          }}
          onChange={date => {
            simulations.enableSimulations();
            startDate.setValue(date);
          }}
        />
      </Form.Item>
    )}
  </StudyPlanEditorContext.Consumer>
);
