import { createSelector } from "reselect";
import { FEATURES } from "../../constants/ui-features";
import { RdxMySelfState } from "../reducers/myself/types";
import { RdxStoreState } from "../types/state";
import { getHasTenant } from "./has-tenant";

type AllowedFeatures = {
  [feature: number]: boolean;
};

const hasManageTenantStudents = (permissions: string[]) => permissions.indexOf("MANAGE_TENANT_STUDENTS") !== -1;

export const getAllowedFeatures: (state: RdxStoreState) => AllowedFeatures | undefined = createSelector(
  (state: RdxStoreState) => state.myself,
  getHasTenant,
  (myself: RdxMySelfState, hasTenant: boolean) => {
    if (!myself || !myself.user) {
      return;
    }

    return {
      [FEATURES.MANAGE_TENANT_STUDENTS]: hasTenant && hasManageTenantStudents(myself.user.permissions),
      [FEATURES.EDIT_TENANT_STUDENT]: hasTenant && hasManageTenantStudents(myself.user.permissions),
      [FEATURES.ADD_TENANT_STUDENTS]: hasTenant && hasManageTenantStudents(myself.user.permissions)
    };
  }
);
