import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BaseLayout } from "../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../components/NavigationDrawer/types";
import { MESSAGES } from "../../i18n";
import { routes } from "../../routes";
import { UsersTable } from "./UsersTable";
import { UserTableFilterTags } from "./UsersTableFilterTags";
import { UsersTableHeaderActions } from "./UsersTableHeaderActions";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Users = () => {
  const navigate = useNavigate();
  return (
    <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_USERS}>
      {
        // -----------------------------------------------------------------------------------------
        //  Header
        // -----------------------------------------------------------------------------------------
      }
      <Flex align="center" gap={4}>
        <Typography.Title level={2} className="grow" data-qa-label="users-page">
          {MESSAGES.Users}
        </Typography.Title>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          data-qa-label="new-user-btn"
          onClick={() => {
            navigate(routes.usersNew.resolve());
          }}
        >
          {MESSAGES.NewUser}
        </Button>
      </Flex>
      {
        // -----------------------------------------------------------------------------------------
        //  Body
        // -----------------------------------------------------------------------------------------
      }
      <Card>
        <Flex vertical gap={12}>
          <UsersTableHeaderActions />
          <UserTableFilterTags />
          <div className="overflow-x-hidden">
            <UsersTable />
          </div>
        </Flex>
      </Card>
    </BaseLayout>
  );
};
