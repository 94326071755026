import { Form } from "antd";
import _ from "lodash";
import React from "react";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";
import { EditorStep } from "../../hooks/useStudyPlanEditor/useNavigation";
import { EditorStepConfiguration } from "./EditorStepConfiguration";
import { EditorStepOverview } from "./EditorStepOverview";
import { EditorStepReviewMaterials } from "./EditorStepReviewMaterials";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const EditorStepContent = () => (
  <StudyPlanEditorContext.Consumer>
    {({ navigation }) => (
      <Form layout="vertical">
        {_.isEqual(EditorStep.STUDY_PLAN_OVERVIEW, navigation.step) ? (
          <EditorStepOverview />
        ) : _.isEqual(EditorStep.STUDY_PLAN_PARTS, navigation.step) ? (
          <EditorStepReviewMaterials />
        ) : _.isEqual(EditorStep.STUDY_PLAN_CONFIGURATION, navigation.step) ? (
          <EditorStepConfiguration />
        ) : null}
      </Form>
    )}
  </StudyPlanEditorContext.Consumer>
);
