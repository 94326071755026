import { LoginTemplate } from "@beckersdp/becker-ds";
import { Flex } from "antd";
import _ from "lodash";
import React, { SyntheticEvent, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SVGColibri } from "../../components/SVGWrappers/SVGColibri";
import { SVGNavigator } from "../../components/SVGWrappers/SVGNavigator";
import { END_USER_LINKS } from "../../constants/bim-links";
import { MESSAGES } from "../../i18n";
import { doLogin } from "../../redux/actions/authentication/login";
import { RdxStoreState } from "../../redux/types/state";
import { RdxFetchStatus } from "../../redux/types/status";
import { routes } from "../../routes";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Login = () => {
  // Refs
  const inputRef = useRef<HTMLElement | null>(null);
  const pwdRef = useRef<HTMLElement | null>(null);

  // Local state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  // Redux
  const dispatch = useDispatch<any>();
  // const navigate = useNavigate();

  const errorMsg = useSelector(({ authentication: { error } }: RdxStoreState) => error);
  const isFetching = useSelector(({ authentication: { status } }: RdxStoreState) =>
    _.isEqual(status, RdxFetchStatus.LOADING)
  );

  return (
    <LoginTemplate
      className="login-page"
      logo={<SVGNavigator width="300" height="60" />}
      // -------------------------------------------------------------------------------------------
      // - login form
      // -------------------------------------------------------------------------------------------

      loginForm={{
        error: errorMsg,

        // -----------------------------------------------------------------------------------------
        // - email
        // -----------------------------------------------------------------------------------------

        email: {
          value: email,
          onChange: (e: SyntheticEvent<HTMLInputElement>) => {
            setEmail((e.target as HTMLInputElement).value);
          },
          onClear: () => setEmail(""),
          inputRef: inputRef,
          name: "username"
        },

        // -----------------------------------------------------------------------------------------
        // - password
        // -----------------------------------------------------------------------------------------

        password: {
          value: password,
          onChange: (e: SyntheticEvent<HTMLInputElement>) => {
            setPassword((e.target as HTMLInputElement).value);
          },
          inputRef: pwdRef,
          name: "password"
        },
        // -----------------------------------------------------------------------------------------
        // - forgot password
        // -----------------------------------------------------------------------------------------

        forgotPwd: {
          className: "text-blue-900",
          name: "forgot-pwd",
          href: routes.forgotPassword.path
        },
        // -----------------------------------------------------------------------------------------
        // - remember me
        // -----------------------------------------------------------------------------------------

        rememberMe: {
          checked: rememberMe,
          onChange: () => setRememberMe(!rememberMe),
          name: "remember-me"
        },

        // -----------------------------------------------------------------------------------------
        // - login form
        // -----------------------------------------------------------------------------------------

        form: {
          name: "login-form",
          onSubmit: (e: SyntheticEvent) => {
            e.preventDefault();
            dispatch(doLogin(email, password, rememberMe));
          }
        },

        // -----------------------------------------------------------------------------------------
        // - Sign in (Default)
        // -----------------------------------------------------------------------------------------

        submitBtn: {
          loading: isFetching,
          name: "submit-btn"
        },
        // -----------------------------------------------------------------------------------------
        // - Sign in with Azure
        // -----------------------------------------------------------------------------------------

        signInWithAzureBtn: {
          name: "signin-with-azure-btn",
          children: (
            <Flex justify="center">
              Sign In with <SVGColibri /> Colibri
            </Flex>
          ),
          onClick: async (e: SyntheticEvent) => {
            e.preventDefault();
            window.open("/samlLogin", "_self");
          }
        }
      }}
      // -------------------------------------------------------------------------------------------
      // - Footer
      // -------------------------------------------------------------------------------------------

      footer={{
        links: [
          {
            href: END_USER_LINKS.contactUs,
            text: MESSAGES.ContactUs,
            "data-qa-label": "contact-us",
            target: "_blank",
            className: "text-white"
          },
          {
            href: END_USER_LINKS.privacyPolicy,
            text: MESSAGES.PrivacyPolicy,
            target: "_blank",
            className: "text-white"
          },
          {
            href: END_USER_LINKS.cookiePolicy,
            text: MESSAGES.CookiePolicy,
            target: "_blank",
            className: "text-white"
          },
          {
            text: MESSAGES.CookiePreferenceCenter,
            className: "text-white optanon-toggle-display"
          }
        ],
        socialLinks: [
          {
            type: "facebook",
            target: "_blank",
            href: END_USER_LINKS.socialFacebook,
            className: "text-white"
          },
          {
            type: "instagram",
            target: "_blank",
            href: END_USER_LINKS.socialInstagram,
            className: "text-white"
          },
          {
            type: "linkedin",
            target: "_blank",
            href: END_USER_LINKS.socialLinkedin,
            className: "text-white"
          },
          {
            type: "tiktok",
            target: "_blank",
            href: END_USER_LINKS.socialTikTok,
            className: "text-white"
          },
          {
            type: "x",
            target: "_blank",
            href: END_USER_LINKS.socialX,
            className: "text-white"
          },
          {
            type: "youtube",
            target: "_blank",
            href: END_USER_LINKS.socialYoutube,
            className: "text-white"
          }
        ],
        copyrightMsg: MESSAGES.CopyrightMsg
      }}
    />
  );
};

export default Login;
