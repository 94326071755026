import _ from "lodash";
import { createSelector } from "reselect";
import { RdxStoreState } from "../../types/state";
import { getActiveUserSettings } from "../active-user";

// -------------------------------------------------------------------------------------------------
// - Selectors
// -------------------------------------------------------------------------------------------------

const getAlertsState = createSelector(
  ({ alertsReader }: RdxStoreState) => alertsReader,
  s => s
);

export const getAlerts = createSelector(getAlertsState, ({ alerts }) => alerts);
export const getAlertsStatus = createSelector(getAlertsState, ({ status }) => status);
export const getNewAlertsCount = createSelector(getAlerts, alerts => _.filter(alerts, { isRead: false }).length);
export const getAlertsLastUpdate = createSelector(getAlertsState, ({ lastUpdate }) => lastUpdate);
export const getLatestUnreadAlert = createSelector(
  getAlertsState,
  getActiveUserSettings,
  ({ alerts, pushedAlertIds }, settings) => {
    const allPushedAlertIds = _.unionBy(pushedAlertIds, settings?.pushedAlertIds, _.identity);
    return _.chain(alerts)
      .orderBy(["publishDate", "id"], ["desc", "desc"])
      .filter(({ id, isRead }) => _.isEqual(isRead, false) && !_.includes(allPushedAlertIds, id))
      .head()
      .value();
  }
);

export const getListOfPushedAlerts = createSelector(getAlertsState, getActiveUserSettings, (as, us) => [
  ...(as.pushedAlertIds ?? []),
  ...(us?.pushedAlertIds ?? [])
]);
