import _ from "lodash";
import React from "react";
import { StudyPlanEditorFields } from "../useEditorFields";
import { EditorStep } from "../useNavigation";
import { WeeklyDistributionInfoValue } from "./useDerivedWeeklyDistributionInfo";

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useStepProgress = (s: EditorStep, e: StudyPlanEditorFields, w: WeeklyDistributionInfoValue) =>
  React.useMemo(() => {
    switch (s) {
      case EditorStep.STUDY_PLAN_OVERVIEW: {
        const subSteps = [
          _.isEmpty(e.name.value) ? 0 : 1,
          _.isEmpty(e.section.value) ? 0 : 1,
          _.isEmpty(e.tagIds.value) ? 0 : 1,
          _.isEqual(e.tenantId.value, 0) ? 0 : 1
        ];

        const value = Math.floor((_.sumBy(subSteps) / subSteps.length) * 100);
        return {
          value,
          isComplete: _.isEqual(value, 100)
        };
      }

      case EditorStep.STUDY_PLAN_CONFIGURATION: {
        const subSteps = [
          _.isEmpty(e.startDate.value) ? 0 : 1,
          _.isEmpty(e.endDate.value) ? 0 : 1,
          _.gt(e.studyTimeWeek.value, 0) ? 1 : 0,
          w.isStudyTimeOk ? 1 : 0
        ];

        const value = Math.floor((_.sumBy(subSteps) / subSteps.length) * 100);
        return {
          value,
          isComplete: _.isEqual(value, 100)
        };
      }

      default:
        break;
    }

    return {
      value: 100,
      isComplete: true
    };
  }, [
    s,
    e.name.value,
    e.section.value,
    e.tenantId.value,
    e.tagIds.value,
    e.startDate.value,
    e.endDate.value,
    e.studyTimeWeek.value,
    w.isStudyTimeOk
    //
  ]);
