import React from "react";

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const SVGNavigatorSmall = (props: { width?: string; height?: string }) => (
  <svg
    width={props.width ?? "32px"}
    height={props.height ?? "32px"}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M12.455655,19.544345 L1.154312,16.71901 C-0.233522,16.372051 -0.421217,14.476513 0.871622,13.864115 L29.818505,0.152433 C31.112241,-0.460389 32.460389,0.887759 31.847567,2.181495 L18.135885,31.128378 C17.523487,32.421217 15.627949,32.233522 15.28099,30.845688 L12.455655,19.544345 Z"
        id="path-1"
        fill="#F2B12E"
      />
    </g>
  </svg>
);
