import mapValues from "lodash/mapValues";
import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { GetMySelfResponse, GetMyTagIdsResponse, UserSettings, UserSettingsRaw } from "./types";

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const getMySelfSettings = async (): Promise<UserSettings> => {
  const rawSettings: UserSettingsRaw = await HttpClient.get("/rest/session/myself/settings", createHeaders());
  return mapValues({ ...rawSettings }, (value: string) => JSON.parse(value)) as UserSettings;
};

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const updateMySelfSettings = async (settings: Partial<UserSettings>): Promise<void> => {
  const rawUserSettings = mapValues({ ...settings }, (value: string) => JSON.stringify(value));
  return HttpClient.put("/rest/session/myself/settings", createHeaders(), rawUserSettings);
};

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const getMySelf = async (): Promise<GetMySelfResponse> =>
  HttpClient.get("/rest/session/myself", createHeaders());

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const getMyTagIds = async (): Promise<GetMyTagIdsResponse> =>
  HttpClient.get("/rest/session/myself/tags", createHeaders());

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const updateMySelfPassword = async (oldPassword: string, newPassword: string): Promise<void> =>
  HttpClient.put("/rest/session/myself/password", createHeaders(), { oldPassword, newPassword });

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const updateMySelfNotificationsSettings = async (unsubscribeNotifications: boolean): Promise<void> =>
  HttpClient.put("/rest/session/myself/subscriptions", createHeaders(), { alerts: unsubscribeNotifications });
