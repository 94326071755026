import { createSelector } from "reselect";

import { Tenant } from "../../services/nav-api/tenants/types";
import { RdxTenantsState } from "../reducers/tenants";
import { RdxStoreState } from "../types/state";
import { getMyTenant } from "./my-tenant";
import { TagNode } from "./my-tenant-tags-tree";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getMyTenantTags: (state: RdxStoreState) => { byId?: { [id: number]: TagNode }; ids?: number[] } =
  createSelector(
    getMyTenant,
    (state: RdxStoreState) => state.tenants,
    (tenant, { tags, tenantTags }: RdxTenantsState) => {
      if (!tenant) {
        return {};
      }

      const tenantTagsIds = tenantTags!.tagsIdsbyTenantId[tenant.id] || [];

      const tenantTagsById: { [id: number]: TagNode } = tenantTagsIds.reduce(
        (acc: { [id: number]: TagNode }, tagId) => {
          const tag = tags!.byId[tagId];
          acc[tagId] = { ...tag, key: tag.id, title: tag.name, children: [] };
          return acc;
        },
        {}
      );

      return {
        byId: tenantTagsById,
        ids: tenantTagsIds
      };
    }
  );

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getTenantTags = (tenant?: Pick<Tenant, "id" | "name"> | null) =>
  createSelector(
    ({ tenants }: RdxStoreState) => tenants,
    ({ tags, tenantTags }: RdxTenantsState) => {
      if (!tenant) {
        return {};
      }

      const tenantTagsIds = tenantTags!.tagsIdsbyTenantId[tenant.id] || [];
      const tenantTagsById: { [id: number]: TagNode } = tenantTagsIds.reduce(
        (acc: { [id: number]: TagNode }, tagId) => {
          const tag = tags!.byId[tagId];

          acc[tagId] = {
            ...tag,
            key: tag.id,
            title: tag.name,
            children: []
          };
          return acc;
        },
        {}
      );

      return {
        byId: tenantTagsById,
        ids: tenantTagsIds
      };
    }
  );
