import { Card, Col, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { BaseLayout } from "../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../components/NavigationDrawer/types";
import { doFetchTenants } from "../../redux/actions/tenants/tenants";
import { TenantsTable } from "./TenantsTable";
import { TenantsTableFilters } from "./TenantsTableFilters";
import { TenantsTableHeader } from "./TenantsTableHeader";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Tenants = () => {
  const dispatch = useDispatch<any>();

  React.useEffect(() => {
    dispatch(doFetchTenants());
  }, []);

  return (
    <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_TENANTS}>
      <TenantsTableHeader />
      <Row gutter={16}>
        <Col xs={18} xxl={19}>
          <Card>
            <div className="overflow-x-hidden">
              <TenantsTable />
            </div>
          </Card>
        </Col>
        <Col xs={6} xxl={5}>
          <TenantsTableFilters />
        </Col>
      </Row>
    </BaseLayout>
  );
};
