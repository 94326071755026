import React from "react";
import { StudyPlansListItem } from "../../../../../services/nav-api/studyPlans/model";
import { Field, FromApiResponseValue, StudyPlanEditorValue, ToApiRequestValue } from "./model";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type PracticeTestsFieldValue = StudyPlanEditorValue["practiceTests"];
export type PracticeTestsField = Field<
  PracticeTestsFieldValue,
  {
    toApiRequestValue: ToApiRequestValue<boolean>;
    fromApiResponseValue: FromApiResponseValue<PracticeTestsFieldValue>;
  }
>;

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const usePracticeTests = (initialValue: boolean): PracticeTestsField => {
  const [value, setValue] = React.useState<PracticeTestsFieldValue>(initialValue);
  return {
    value,
    setValue: (val: PracticeTestsFieldValue) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(initialValue);
    },
    toApiRequestValue: () => {
      return value;
    },
    fromApiResponseValue: (rsp: StudyPlansListItem): PracticeTestsFieldValue => {
      const iVal = !!rsp.settings.includePracticeTest;
      setValue(iVal);
      return iVal;
    }
  };
};
