import { Card, Checkbox, Form } from "antd";
import React from "react";
import { MESSAGES } from "../../i18n";
import { FormControllerContext, FormKey } from "./hooks/userFormController";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const NotificationsSection = () => (
  <FormControllerContext.Consumer>
    {({ notificationsEnabled }) => (
      <Card title={MESSAGES.Notifications}>
        <Form.Item name={FormKey.NOTIFICATIONS_ENABLED}>
          <Checkbox
            checked={notificationsEnabled.value!}
            onChange={e => {
              notificationsEnabled.setValue(e.target.checked);
            }}
          >
            {MESSAGES.GetNotificationEmails}
          </Checkbox>
        </Form.Item>
      </Card>
    )}
  </FormControllerContext.Consumer>
);
