import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { FilterTag } from "../../../core/model";
import { MESSAGES, formatMsg } from "../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../redux/actions/students-list";
import { defaultWorkingFilter } from "../../../redux/reducers/students-list";
import { RdxStoreState } from "../../../redux/types/state";

// -------------------------------------------------------------------------------------------------
// - Hooks: useStudyPlanRefFilter
// -------------------------------------------------------------------------------------------------

export const useStudyPlanRefFilter = (): FilterTag[] => {
  const dispatch = useDispatch<any>();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);
  return !_.isNil(activeFilter?.filter?.studyPlanRef)
    ? [
        {
          name: "studyPlanRef",
          onClear: () => {
            dispatch(
              doUpdateWorkingFilter({
                studyPlanRef: defaultWorkingFilter.studyPlanRef
              })
            );
            dispatch(doApplyFilter());
          },
          text: formatMsg(MESSAGES.FilterTagStudyPlanValue, activeFilter.filter.studyPlanRef?.name)
        }
      ]
    : [];
};
