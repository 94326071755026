import _ from "lodash";
import { SYSTEM_CONFIG } from "./system-config";

const ENV_MAP: Record<Enviroment, string> = {
  prod: "https://bim.becker.com",
  qa: "https://bim-qa.becker.com",
  dev: "https://bim-dev.becker.com",
  local: "https://bim-dev.becker.com"
};

const curEnv: Enviroment = (SYSTEM_CONFIG.environment?.toLowerCase() as Enviroment) || "qa";
const BIM_BASE_URL = ENV_MAP[curEnv];

function getEndUserLink(key: string): string {
  return `${BIM_BASE_URL}/external/links/${key}?productId=113&redirect`;
}

export const END_USER_LINKS = {
  cookiePolicy: getEndUserLink("CookiePolicy"),
  privacyPolicy: getEndUserLink("PrivacyPolicy"),
  contactUs: getEndUserLink("ContactUs"),
  socialFacebook: getEndUserLink("SocialFacebook"),
  socialLinkedin: getEndUserLink("SocialLinkedin"),
  socialX: getEndUserLink("SocialX"),
  socialInstagram: getEndUserLink("SocialInstagram"),
  socialYoutube: getEndUserLink("SocialYoutube"),
  socialTikTok: getEndUserLink("SocialTikTok"),
  reportsDocs: "https://docs.navigator.becker.com/students/reports",
  userGuide: "https://docs.navigator.becker.com/",
  miniOrangeForgotPwd: _.isEqual(curEnv, "prod")
    ? "https://mini-orange.colibrigroup.com/idp/resetpassword?brandName=becker"
    : "https://stage-mini-orange.colibrigroup.com/idp/resetpassword?brandName=becker"
};
