import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { doResetPushedAlerts } from "../../../redux/actions/alerts-reader/pusher";
import { ReadStatus } from "../../../redux/actions/alerts-reader/types";
import { doChangeAlertReadStatus } from "../../../redux/actions/alerts-reader/update";
import { getAlerts, getAlertsLastUpdate } from "../../../redux/selectors/alerts-reader/alerts";
import { Alert } from "../../../services/nav-api/alerts-reader/types";

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const alertById = (alerts: Alert[], id: number) =>
  _.chain(alerts) //
    .filter({ id })
    .head()
    .value();

// -------------------------------------------------------------------------------------------------
// - Hooks: useAlerts
// -------------------------------------------------------------------------------------------------

export const useAlerts = (alertId?: number) => {
  const dispatch = useDispatch<any>();
  const alerts = useSelector(getAlerts);
  const alertsLastUpdate = useSelector(getAlertsLastUpdate);
  const [runOnce, setRunOnce] = React.useState(false);

  React.useLayoutEffect(() => {
    if (!!alertsLastUpdate && !runOnce) {
      setRunOnce(true);
    }
  }, [alertsLastUpdate]);

  React.useLayoutEffect(() => {
    if (runOnce && !alertById(alerts, alertId!)?.isRead && alertsLastUpdate! > 0) {
      dispatch(doChangeAlertReadStatus(alertId!, ReadStatus.READ));
    }
  }, [runOnce]);

  return {
    //
    // Data Source
    //
    alerts,
    //
    // Methods: Mark Alert as Read
    //
    markAsRead: (alertId: number) => {
      const alert = alertById(alerts, alertId);
      if (!alert?.isRead) {
        dispatch(doChangeAlertReadStatus(alertId, ReadStatus.READ));
      }
    },

    //
    // Methods: Mark all Alerts as Unread
    //
    resetState: () => {
      dispatch(doResetPushedAlerts());
      _.map(alerts, ({ id }) => {
        dispatch(doChangeAlertReadStatus(id, ReadStatus.UNREAD));
      });
    }
  };
};
