import { Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TenantsSelect } from "../../../../components/TenantsSelect";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../../redux/actions/students-list";
import { getMyTenant } from "../../../../redux/selectors/my-tenant";
import { getStudentsTableDetails } from "../../../../redux/selectors/students-table-data-source";
import { RdxStoreState } from "../../../../redux/types/state";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const buildSectionQaLabel = (tenantId?: number) => `tenant-sfilter-${tenantId}`;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantFilter = () => {
  const dispatch = useDispatch<any>();
  const isTenantUser = Boolean(useSelector(getMyTenant));
  const tenantId = useSelector(
    ({
      studentsList: {
        workingFilter: { tenantId }
      }
    }: RdxStoreState) => tenantId
  );

  return !isTenantUser ? (
    <Form.Item label="Tenant" data-qa-label={buildSectionQaLabel(tenantId)}>
      <TenantsSelect
        hideExtraValues
        allowClear
        data-qa-label="select-tenants"
        value={tenantId}
        onChange={(tenantId: number) => dispatch(doUpdateWorkingFilter({ tenantId }))}
      />
    </Form.Item>
  ) : null;
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const QuickStudentsTenantFilter = () => {
  const dispatch = useDispatch<any>();
  const isTenantUser = Boolean(useSelector(getMyTenant));
  const { loading } = useSelector(getStudentsTableDetails);
  const tenantId = useSelector(
    ({
      studentsList: {
        workingFilter: { tenantId }
      }
    }: RdxStoreState) => tenantId
  );

  return !isTenantUser ? (
    <div className="w-[15rem]">
      <TenantsSelect
        allowClear
        hideExtraValues
        className="w-full"
        data-qa-label="quick-select-tenants"
        disabled={loading}
        value={tenantId}
        onChange={(tenantId: number) => {
          dispatch(doUpdateWorkingFilter({ tenantId }));
          dispatch(doApplyFilter());
        }}
      />
    </div>
  ) : null;
};
