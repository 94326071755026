import * as HttpClient from "../http-client";
import { createHeaders } from "./headers";
import { PostUserInviteParams } from "./types";

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const postUserInvite = async (
  params: PostUserInviteParams
): Promise<{
  userId: number;
}> => HttpClient.post(`/rest/admin/users`, createHeaders(), params);
