import { Form, Select } from "antd";
import _ from "lodash";
import React from "react";
import { Status } from "../services/nav-api/types";
const { Option } = Select;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlanFilter = (props: { onChange: (value?: boolean) => void; value?: boolean }) => (
  <Form.Item label="Study Plan">
    <Select
      className="studyplans-filter"
      data-qa-label="studyplans-select"
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      value={_.isNil(props.value) ? "all" : props.value ? "enabled" : "disabled"}
      onChange={(value: "all" | "enabled" | "disabled") => {
        props.onChange(_.isEqual(value, "all") ? undefined : _.isEqual(value, "enabled"));
      }}
    >
      <Option className="option-all" value="all">
        All
      </Option>
      <Option className={`option-enabled`} value="enabled">
        {Status.Enabled}
      </Option>
      <Option className={`option-disabled`} value="disabled">
        {Status.Disabled}
      </Option>
    </Select>
  </Form.Item>
);
