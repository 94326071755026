import { Skeleton, Typography } from "antd";
import React from "react";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const DashboardNumber = ({ value, loading, qaLabel }: { value: number; loading?: boolean; qaLabel: string }) =>
  loading ? (
    <Skeleton />
  ) : (
    <div className="text-center p-0 m-0">
      <Typography.Text className="text-[#505d68] text-[28.5pt]" data-qa-label={qaLabel}>
        {value}
      </Typography.Text>
    </div>
  );
