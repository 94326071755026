import { Calendar, Card, Flex, Layout, Skeleton, Spin } from "antd";
import _ from "lodash";
import React from "react";
import { StudyPlanCustomTasksEditorContext } from "../../hooks/useStudyPlanCustomTasksEditor";
import { StudyPlanContext } from "../../hooks/useStudyPlanViewerData";
import { StudentsListLink } from "../StudentsListLink";
import { StudyPlanCreateTaskButton } from "../StudyPlanCreateTaskBtn";
import { fullCellRenderer } from "./CalendarFullCellRenderer";
import { CalendarHeader } from "./CalendarHeader";
import { CurrentDateContext, useCurrentDate } from "./hooks/useCurrentDate";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlanCalendar = () => {
  const cur = useCurrentDate();
  return (
    <CurrentDateContext.Provider value={cur}>
      <StudyPlanContext.Consumer>
        {plan => (
          <Card
            title="Plan"
            extra={
              <Flex gap={12}>
                <StudentsListLink />
                <StudyPlanCreateTaskButton />
              </Flex>
            }
          >
            <StudyPlanCustomTasksEditorContext.Consumer>
              {spct => (
                <div className="overflow-x-auto">
                  <Spin spinning={plan.loading || spct.loading}>
                    {_.gt(plan.id, 0) ? (
                      <Layout.Content style={{ overflowX: "auto", minWidth: "1000px" }}>
                        <Calendar
                          mode="month"
                          value={cur.date}
                          fullCellRender={fullCellRenderer(plan)}
                          headerRender={config => <CalendarHeader {...config} />}
                          disabledDate={date => _.some(plan.summary.daysOff, dayOff => dayOff.isSame(date, "day"))}
                          validRange={plan.summary.range}
                          onChange={date => {
                            cur.setDate(
                              date.isBefore(plan.summary.startDate)
                                ? plan.summary.startDate
                                : date.isAfter(plan.summary.endDate)
                                  ? plan.summary.endDate
                                  : date
                            );
                          }}
                        />
                      </Layout.Content>
                    ) : (
                      <Skeleton />
                    )}
                  </Spin>
                </div>
              )}
            </StudyPlanCustomTasksEditorContext.Consumer>
          </Card>
        )}
      </StudyPlanContext.Consumer>
    </CurrentDateContext.Provider>
  );
};
