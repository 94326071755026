import _ from "lodash";
import { createSelector } from "reselect";
import { UserSettings } from "../../services/nav-api/myself/types";
import { TenantProduct } from "../../services/nav-api/tenants/types";
import { ContentVersion } from "../actions/contentversion-switch";
import { RdxStoreState } from "../types/state";
import { getActiveProduct } from "./active-product";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getContentVersion: (state: RdxStoreState) => ContentVersion = createSelector(
  ({ myself }: RdxStoreState) => myself.userSettings,
  (userSettings: UserSettings): ContentVersion => userSettings.contentVersion
);

// -------------------------------------------------------------------------------------------------
//  Selector
// -------------------------------------------------------------------------------------------------

export const getIsCPAPreEvolutionSelected: (state: RdxStoreState) => boolean = createSelector(
  getContentVersion,
  getActiveProduct,
  (v: ContentVersion, p: TenantProduct) =>
    _.isEqual(TenantProduct.CPA, p) && _.isEqual(ContentVersion.CPA_PRE_EVOLUTION, v)
);
