import React from "react";
import { ApplicationToastsContext } from "../../../../../components/AppToasts";
import { MESSAGES } from "../../../../../i18n";
import { navApi } from "../../../../../services/nav-api";
import { CustomTask } from "./types";

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useApiCallbacks = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const apiToasts = React.useContext(ApplicationToastsContext);

  // -----------------------------------------------------------------------------------------------
  // - Create custom task
  // -----------------------------------------------------------------------------------------------

  const createCustomTask = React.useCallback(async (studyPlanId: number, task: CustomTask): Promise<number | null> => {
    try {
      setLoading(true);
      const newTaskId = (
        (await navApi.studyPlans.createCustomTask(
          studyPlanId,
          task.name!,
          task.quarterHours!,
          task.date!
          //
        )) as {
          id: number;
        }
      )?.id;

      apiToasts.success({
        message: "Creating task...",
        description: "Task added to the study plan.",
        showProgress: true,
        pauseOnHover: true
      });

      return newTaskId;
    } catch (error) {
      apiToasts.success({
        message: MESSAGES.Error,
        description: `Failed to create task. Please try again later. ${error?.message ?? ""}`,
        showProgress: true,
        pauseOnHover: true
      });
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  // -----------------------------------------------------------------------------------------------
  // - Update existing custom task
  // -----------------------------------------------------------------------------------------------

  const updateCustomTask = React.useCallback(async (studyPlanId: number, task: CustomTask): Promise<void> => {
    try {
      setLoading(true);
      await navApi.studyPlans.updateCustomTask(
        studyPlanId,
        task.id!,
        task.name!,
        task.quarterHours!,
        task.date!
        //
      );

      apiToasts.success({
        message: "Updating task...",
        description: "Task updated successfully.",
        showProgress: true,
        pauseOnHover: true
      });
    } catch (error) {
      apiToasts.success({
        message: MESSAGES.Error,
        description: `Failed to update task. Please try again later. ${error?.message ?? ""}`,
        showProgress: true,
        pauseOnHover: true
      });
    } finally {
      setLoading(false);
    }
  }, []);

  // -----------------------------------------------------------------------------------------------
  // - Delete custom task
  // -----------------------------------------------------------------------------------------------

  const deleteCustomTask = React.useCallback(async (studyPlanId: number, taskId: string): Promise<void> => {
    try {
      setLoading(true);
      await navApi.studyPlans.deleteCustomTask(studyPlanId, taskId);

      apiToasts.success({
        message: "Deleting task...",
        description: "Task removed from the study plan.",
        showProgress: true,
        pauseOnHover: true
      });
    } catch (error) {
      apiToasts.success({
        message: MESSAGES.Error,
        description: `Failed to delete task. Please try again later. ${error?.message ?? ""}`,
        showProgress: true,
        pauseOnHover: true
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, createCustomTask, updateCustomTask, deleteCustomTask };
};
