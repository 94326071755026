import { Card, Flex, Typography } from "antd";
import React from "react";
import { BaseLayout } from "../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../components/NavigationDrawer/types";
import { MESSAGES } from "../../i18n";
import { StudyPlansTable } from "./StudyPlansTable";
import { NewStudyPlanButton } from "./common/NewStudyPlanButton";
import { StudyPlansContext, useStudyPlansList } from "./hooks/useStudyPlanList";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlans = () => {
  const studyPlans = useStudyPlansList();
  return (
    <StudyPlansContext.Provider value={studyPlans}>
      <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.STUDY_PLANS}>
        {
          // ---------------------------------------------------------------------------------------
          //  Header
          // ---------------------------------------------------------------------------------------
        }
        <Flex align="center" gap={4}>
          <Typography.Title level={2} className="grow" data-qa-label="studyplans-page">
            {MESSAGES.StudyPlansTitle}
          </Typography.Title>
          <NewStudyPlanButton />
        </Flex>

        {
          // ---------------------------------------------------------------------------------------
          //  Table / Filters / Actions
          // ---------------------------------------------------------------------------------------
        }

        <Card>
          <Flex vertical gap={12}>
            <div className="overflow-x-hidden">
              <StudyPlansTable />
            </div>
          </Flex>
        </Card>
      </BaseLayout>
    </StudyPlansContext.Provider>
  );
};
