import React from "react";
import { Field } from "../context";

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useSelectedTenantId = (): Field<number> => {
  const [value, setValue] = React.useState<number | null>(null);
  return {
    value,
    setValue: (val: number) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(null);
    }
  };
};
