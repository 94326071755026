import React from "react";
import { StudyPlansListItem } from "../../../../../services/nav-api/studyPlans/model";
import { Field, FromApiResponseValue, StudyPlanEditorValue, ToApiRequestValue } from "./model";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type FlashcardsFieldValue = StudyPlanEditorValue["flashcards"];
export type FlashcardsField = Field<
  FlashcardsFieldValue,
  {
    toApiRequestValue: ToApiRequestValue<boolean>;
    fromApiResponseValue: FromApiResponseValue<FlashcardsFieldValue>;
  }
>;

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useFlashcards = (initialValue: boolean): FlashcardsField => {
  const [value, setValue] = React.useState<FlashcardsFieldValue>(initialValue);
  return {
    value,
    setValue: (val: FlashcardsFieldValue) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(initialValue);
    },
    toApiRequestValue: () => {
      return value;
    },
    fromApiResponseValue: (rsp: StudyPlansListItem): FlashcardsFieldValue => {
      const iVal = !!rsp.settings.includeFlashcard;
      setValue(iVal);
      return iVal;
    }
  };
};
