import { Select } from "antd";
import dayjs from "dayjs";
import generateConfig from "rc-picker/lib/generate/dayjs";
import React from "react";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

interface SharedProps<DateType> {
  value: DateType;
  validRange?: [DateType, DateType];
  onChange: (year: DateType) => void;
}

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const YEAR_SELECT_OFFSET = 10;
const YEAR_SELECT_TOTAL = 20;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const YearSelect = (props: SharedProps<dayjs.Dayjs>) => {
  const { validRange, value, onChange } = props;
  const year = generateConfig.getYear(value || generateConfig.getNow());

  let start = year - YEAR_SELECT_OFFSET;
  let end = start + YEAR_SELECT_TOTAL;

  if (validRange) {
    start = generateConfig.getYear(validRange[0]);
    end = generateConfig.getYear(validRange[1]) + 1;
  }

  const suffix = "";
  const options: { label: string; value: number }[] = [];
  for (let index = start; index < end; index++) {
    options.push({ label: `${index}${suffix}`, value: index });
  }

  return (
    <Select
      value={year}
      options={options}
      disabled={options.length <= 1}
      onChange={numYear => {
        let newDate = generateConfig.setYear(value, numYear);

        if (validRange) {
          const [startDate, endDate] = validRange;
          const newYear = generateConfig.getYear(newDate);
          const newMonth = generateConfig.getMonth(newDate);
          if (newYear === generateConfig.getYear(endDate) && newMonth > generateConfig.getMonth(endDate)) {
            newDate = generateConfig.setMonth(newDate, generateConfig.getMonth(endDate));
          }
          if (newYear === generateConfig.getYear(startDate) && newMonth < generateConfig.getMonth(startDate)) {
            newDate = generateConfig.setMonth(newDate, generateConfig.getMonth(startDate));
          }
        }

        onChange(newDate);
      }}
    />
  );
};
