import { Typography } from "antd";
import _ from "lodash";
import React from "react";
import { sectionNameByAltId } from "../../../../constants/courses";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const SectionField = () => (
  <StudyPlanEditorContext.Consumer>
    {({ editor: { section } }) => {
      return (
        <Typography.Text type={_.isEmpty(section.value) ? "secondary" : undefined}>
          {!_.isEmpty(section.value) ? sectionNameByAltId[section.value!] : null}
        </Typography.Text>
      );
    }}
  </StudyPlanEditorContext.Consumer>
);
