import * as HttpClient from "../http-client";
import { getUrlWithEncodedParams } from "../http-client/utils";
import { createHeaders } from "./headers";
import {
  CreateUserParams,
  CreateUserResponse,
  GetUsersParams,
  GetUsersReportResponse,
  GetUsersResponse,
  PutUsersParams,
  UpdateUserParams,
  User
} from "./types";

// -------------------------------------------------------------------------------------------------
// - Api Methods
// -------------------------------------------------------------------------------------------------

export const getUsers = async (params: GetUsersParams): Promise<GetUsersResponse> =>
  HttpClient.get("/rest/admin/users", createHeaders(), params);

// -------------------------------------------------------------------------------------------------
// - Api Methods
// -------------------------------------------------------------------------------------------------

export const getUserById = async (userId: number): Promise<User> =>
  HttpClient.get(`/rest/admin/users/${userId}`, createHeaders());

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const getUsernameInfo = async (
  username: string
): Promise<{
  firstName?: string;
  lastName?: string;
}> => HttpClient.get(`/rest/admin/users/${encodeURIComponent(username)}/info?`, createHeaders());

// -------------------------------------------------------------------------------------------------
// - Api Methods
// -------------------------------------------------------------------------------------------------

export const putUser = async (userId: number, params: PutUsersParams): Promise<GetUsersResponse> =>
  HttpClient.put(`/rest/admin/users/${userId}`, createHeaders(), params);

// -------------------------------------------------------------------------------------------------
// - Api Methods
// -------------------------------------------------------------------------------------------------

export const createUser = async (params: CreateUserParams): Promise<CreateUserResponse> =>
  HttpClient.post("/rest/admin/users", createHeaders(), params);

// -------------------------------------------------------------------------------------------------
// - Api Methods
// -------------------------------------------------------------------------------------------------

export const updateUser = async (userId: number, params: UpdateUserParams): Promise<void> =>
  HttpClient.put(`/rest/admin/users/${userId}`, createHeaders(), params);

// -------------------------------------------------------------------------------------------------
// - Api Methods
// -------------------------------------------------------------------------------------------------

export const enableUser = async (userId: number): Promise<GetUsersResponse> =>
  HttpClient.post(`/rest/admin/users/${userId}/enabled`, createHeaders());

// -------------------------------------------------------------------------------------------------
// - Api Methods
// -------------------------------------------------------------------------------------------------

export const disableUser = async (userId: number): Promise<GetUsersResponse> =>
  HttpClient.del(`/rest/admin/users/${userId}/enabled`, createHeaders());

// -------------------------------------------------------------------------------------------------
// - Api Methods
// -------------------------------------------------------------------------------------------------

export const deleteUser = async (userId: number) => HttpClient.del(`/rest/admin/users/${userId}`, createHeaders());

// -------------------------------------------------------------------------------------------------
// - Api Methods
// -------------------------------------------------------------------------------------------------

export const generateReport = async (params: GetUsersParams): Promise<GetUsersReportResponse> =>
  HttpClient.post(getUrlWithEncodedParams("/rest/admin/users/report", params), createHeaders());

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const fetchFeatureFlags = async (): Promise<{ ffs?: string[] }> =>
  HttpClient.get(getUrlWithEncodedParams("/rest/unauth/frontend/featureFlags"), createHeaders());
