import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { navApi } from "../../../services/nav-api";
import { ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "../../types/status";
import { doCompleteFetchUnauth } from "././fetchCompletion";
import { doStartFetchUnauth } from "./fetchStart";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doFetchFeatureFlags = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    if (_.isEqual(getState().myself.status, RdxFetchStatus.LOADING)) {
      return;
    }

    dispatch(doStartFetchUnauth());

    let ffs: string[] = [];
    try {
      ffs = (await navApi.unauth.getFeatureFlags())?.ffs ?? [];
    } catch (ex) {
      console.error((ex as HttpClientErrorResponse).message || "error");
    } finally {
      dispatch(doCompleteFetchUnauth(ffs));
    }
  };
};
