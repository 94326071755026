import { Button, Card, Col, Flex, Row, Skeleton, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { doSetFilter } from "../../../../../../redux/actions/students-list";
import { getCurrentWorkingFilter, isFetchCompleted } from "../../../../../../redux/selectors/dashboard/tenant";
import { routes } from "../../../../../../routes";
import { DashboardCol } from "../../../../common/DashboardCol";
import { Chart } from "./Chart";
import { titleByStatsScope, topLabelByStatsScope } from "./constants";
import { StatsScope, StatsSlice } from "./model";
import { useStatsData } from "./useStatsData";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  scope: StatsScope;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlanStats = ({ scope }: OwnProps) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const ready = useSelector(isFetchCompleted);
  const filter = useSelector(getCurrentWorkingFilter);
  const data = useStatsData(scope);

  const onClick = React.useCallback(
    (slice: StatsSlice) => {
      switch (scope) {
        case StatsScope.SPS_USAGE:
          dispatch(
            doSetFilter({
              ...filter,
              license: "active",
              studyPlan: _.isEqual(StatsSlice.S1, slice) //
                ? "yes"
                : "no"
            })
          );
          break;
        case StatsScope.SPS_PERFORMANCE:
          dispatch(
            doSetFilter({
              ...filter,
              license: "active",
              studyPlan: _.isEqual(StatsSlice.S1, slice) //
                ? "yes_on_track"
                : "yes_behind_schedule"
            })
          );
          break;
        case StatsScope.LICENSES:
          dispatch(
            doSetFilter({
              ...filter,
              license: _.isEqual(StatsSlice.S1, slice) //
                ? "in_less_than_3_months"
                : "in_more_than_3_months"
            })
          );
          break;
      }

      navigate(routes.students.resolve());
    },
    [scope]
  );

  return React.useMemo(
    () => (
      <Card loading={!ready}>
        <Row>
          <Col xs={15} sm={16}>
            <Typography.Text className="uppercase" type="secondary">
              {titleByStatsScope[scope]}
            </Typography.Text>
          </Col>
          <DashboardCol />
        </Row>
        {ready ? (
          <Card
            size="small"
            className="grow mt-3"
            title={
              <Flex vertical={false} gap={2} justify="center" align="baseline">
                <Button
                  type="link"
                  data-qa-label={_.kebabCase(`${scope}-slice1-count`)}
                  className={[
                    "p-0",
                    "text-lg",
                    "text-[#505d68]",
                    "font-bold",
                    "cursor-pointer",
                    "align-bottom"
                    //
                  ].join(" ")}
                  onClick={() => {
                    onClick(StatsSlice.S1);
                  }}
                >
                  {data[0]?.value}
                </Button>
                <Typography.Text className="text-[#6b6974] text-xs">{topLabelByStatsScope[scope]}</Typography.Text>
              </Flex>
            }
          >
            <Chart
              value={data}
              statsType={scope}
              onClick={data => {
                onClick(data.slice);
              }}
            />
          </Card>
        ) : (
          <Skeleton />
        )}
      </Card>
    ),
    [data, ready, scope]
  );
};
