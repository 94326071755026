import _ from "lodash";
import React from "react";
import { SYSTEM_CONFIG } from "../../../../../constants/system-config";
import { Field } from "../context";

// -------------------------------------------------------------------------------------------------
// - constants
// -------------------------------------------------------------------------------------------------

const curEnv: Enviroment = (SYSTEM_CONFIG.environment?.toLowerCase() as Enviroment) || "qa";

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useNotificationsEnabled = (): Field<boolean> => {
  const [value, setValue] = React.useState<boolean>(_.isEqual(curEnv, "prod"));

  return {
    value,
    setValue: (val: boolean) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(true);
    }
  };
};
