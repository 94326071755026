import { format } from "date-fns";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { StatusFilterAllValue } from "../../../components/StatusFilter";
import { NoTenantFilterAllValue, TenantIdFilterAllValue } from "../../../components/TenantsSelect";
import { DEFAULT_LIST_PAGE, DEFAULT_LIST_SIZE } from "../../../constants/lists";
import { RoleIdFilterAllValue } from "../../../pages/Users/UsersTableFilterForRoles";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { GetUsersParams, GetUsersResponse, Status } from "../../../services/nav-api/types";
import { Action, ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "../../types/status";

export const FETCH_USERS_COMPLETED = "FETCH_USERS_COMPLETED";
export const FETCH_USERS_STARTED = "FETCH_USERS_STARTED";
export const USERS_LIST_FILTER_COMPLETED = "USERS_LIST_FILTER_COMPLETED";
export const USERS_LIST_FILTER_STARTED = "USERS_LIST_FILTER_STARTED";
export const USERS_LIST_SETTINGS_CHANGED = "USERS_LIST_SETTINGS_CHANGED";
export const WORKING_USERS_FILTER_CLEARED = "WORKING_USERS_FILTER_CLEARED";
export const WORKING_USERS_FILTER_UPDATED = "WORKING_USERS_FILTER_UPDATED";

export type WorkingFilter = {
  searchTerm: string;
  tenantId: number;
  roleId: number;
  status: Status;
  creationDate: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  };
  lastLoginDate: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  };
};

export type UpdateWorkingFilterPayload = Partial<WorkingFilter>;

export const doUpdateWorkingFilter = (filter: UpdateWorkingFilterPayload): Action<UpdateWorkingFilterPayload> => ({
  type: WORKING_USERS_FILTER_UPDATED,
  payload: filter
});

export type FetchUsersCompletedPayload = {
  error?: HttpClientErrorResponse;
  filter?: WorkingFilter;
  users?: GetUsersResponse;
};

const doStartFetchUsers = (): Action => ({
  type: FETCH_USERS_STARTED
});

const doCompleteFetchUsers = (users: GetUsersResponse, filter?: WorkingFilter): Action<FetchUsersCompletedPayload> => ({
  type: FETCH_USERS_COMPLETED,
  payload: {
    users,
    filter
  }
});

const doFailFetchUsers = (
  error: HttpClientErrorResponse,
  filter?: WorkingFilter
): Action<FetchUsersCompletedPayload> => ({
  type: FETCH_USERS_COMPLETED,
  payload: { error, filter }
});

export const doFetchUsers = (): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    if (getState().usersList.status === RdxFetchStatus.LOADING) {
      return;
    }
    dispatch(doStartFetchUsers());
    const filter = cloneDeep(getState().usersList.workingFilter);
    try {
      const users = await navApi.users.getUsers(
        createGetUserParams(filter, getState().myself, getState().usersList.paging)
      );
      dispatch(doCompleteFetchUsers(users, filter));
    } catch (ex) {
      const error: HttpClientErrorResponse = ex;
      dispatch(doFailFetchUsers(error, filter));
    }
  };
};

export type DoChangeListSettingsPayload = {
  paging: { page: number; pageSize: number };
};

export const doChangeListSettings = (page: number, pageSize: number): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    if (getState().usersList.status === RdxFetchStatus.LOADING) {
      return;
    }
    const payload: DoChangeListSettingsPayload = {
      paging: { page, pageSize }
    };
    dispatch({
      type: USERS_LIST_SETTINGS_CHANGED,
      payload
    });
    dispatch(doFetchUsers());
  };
};

export const doResetListSettings = (): ThunkAction => doChangeListSettings(DEFAULT_LIST_PAGE, DEFAULT_LIST_SIZE);

export const doApplyFilter = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    const {
      usersList: { workingFilter, activeFilter, status }
    } = getState();

    const sameFilters = _.isEqual(activeFilter?.filter, workingFilter);
    const isLoading = _.isEqual(status, RdxFetchStatus.LOADING) || Boolean(activeFilter && activeFilter.loading);

    if (sameFilters || isLoading) {
      return;
    }

    dispatch({
      type: USERS_LIST_FILTER_STARTED
    });
    const filter = cloneDeep(getState().usersList.workingFilter);
    try {
      dispatch(doFetchUsers());
      dispatch({
        type: USERS_LIST_FILTER_COMPLETED,
        payload: {
          filter
        }
      });
    } catch (ex) {
      dispatch({
        type: USERS_LIST_FILTER_COMPLETED,
        payload: {
          error: ex
        }
      });
    }
  };
};

export const doClearFilter = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch({
      type: WORKING_USERS_FILTER_CLEARED
    });

    dispatch(doApplyFilter());
  };
};

export function createGetUserParams(
  filter: WorkingFilter,
  myself: RdxStoreState["myself"],
  paging: RdxStoreState["usersList"]["paging"] | null = null
) {
  const args: GetUsersParams = {};
  if (filter.searchTerm.length !== 0) {
    args.filter = filter.searchTerm;
  }

  if (filter.roleId !== RoleIdFilterAllValue) {
    args.roleId = filter.roleId;
  }

  if ((filter.status as any) !== StatusFilterAllValue) {
    args.status = filter.status;
  }

  if (filter.tenantId === NoTenantFilterAllValue) {
    args.tenantId = "null";
  } else if (filter.tenantId !== TenantIdFilterAllValue) {
    args.tenantId = filter.tenantId;
  }

  if (myself.user && myself.user.tenant) {
    args.tenantId = myself.user.tenant.id.toString();
  }

  if (!_.isNil(paging)) {
    args.limit = paging.itemsPerPage;
    args.offset = (paging.page - 1) * paging.itemsPerPage;
  }

  args.creationDateMin = filter.creationDate?.startDate
    ? format(filter.creationDate.startDate, "yyyy-MM-dd")
    : undefined;
  args.creationDateMax = filter.creationDate?.endDate ? format(filter.creationDate.endDate, "yyyy-MM-dd") : undefined;
  args.lastLoginDateMin = filter.lastLoginDate?.startDate
    ? format(filter.lastLoginDate.startDate, "yyyy-MM-dd")
    : undefined;
  args.lastLoginDateMax = filter.lastLoginDate?.endDate
    ? format(filter.lastLoginDate.endDate, "yyyy-MM-dd")
    : undefined;

  return args;
}
