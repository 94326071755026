import { LoadingOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationToastsContext } from "../../components/AppToasts";
import { BaseLayout } from "../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../components/NavigationDrawer/types";
import { EditableTenant, TenantsForm } from "../../components/TenantsForm";
import { routes } from "../../routes";
import { navApi } from "../../services/nav-api";
import { Tenant } from "../../services/nav-api/tenants/types";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantEdit = () => {
  const navigate = useNavigate();
  const [tenant, setTenant] = React.useState<Tenant>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const tenantId = parseInt(id!);

  React.useEffect(() => {
    (async () => {
      try {
        setTenant(await navApi.tenants.getTenant(tenantId));
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <BaseLayout
      activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_TENANTS}
      breadcrumb={[
        { name: "Tenants", href: routes.tenants.resolve() },
        { name: tenant ? tenant.name : "Tenant", href: routes.tenant.resolve({ id: tenantId.toString() }) },
        { name: "Edit" }
      ]}
    >
      <Typography.Title level={2}>Edit Tenant</Typography.Title>

      {tenant ? (
        <ApplicationToastsContext.Consumer>
          {api => (
            <TenantsForm
              tenantId={tenantId}
              tenant={tenant}
              loading={loading}
              onSubmit={async (tp: EditableTenant) => {
                try {
                  setLoading(true);
                  await navApi.tenants.editTenant(
                    tenantId, //
                    tp.name,
                    tp.status,
                    tp.products,
                    tp.type!,
                    tp.hasTagForm,
                    tp.hasStudyPlan
                  );

                  // Notify Success
                  api.success({
                    message: `Tenant edited with success`,
                    showProgress: true,
                    pauseOnHover: true
                  });

                  // Go back
                  navigate(routes.tenant.resolve({ id: tenantId.toString() }));
                } catch (e) {
                  // Notify Error
                  api.error({
                    message: `Error editing tenant`,
                    description: e.message,
                    showProgress: true,
                    pauseOnHover: true
                  });
                } finally {
                  setLoading(false);
                }
              }}
            />
          )}
        </ApplicationToastsContext.Consumer>
      ) : (
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      )}
    </BaseLayout>
  );
};
