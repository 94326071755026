import { CloseCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Flex, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../i18n";
import { doClearFilter } from "../../redux/actions/users-list";
import { initialState } from "../../redux/reducers/users-list";
import { RdxStoreState } from "../../redux/types/state";
import { RdxFetchStatus } from "../../redux/types/status";
import { useUserFilterTags } from "./hooks/useUserFilterTags";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const UserTableFilterTags = () => {
  const dispatch = useDispatch<any>();
  const filterTags = useUserFilterTags();
  const isLoading = useSelector(
    ({ usersList: { activeFilter, status } }: RdxStoreState) =>
      _.isEqual(status, RdxFetchStatus.LOADING) || activeFilter?.loading
  );
  const isResetDisabled = useSelector(({ usersList }: RdxStoreState) =>
    _.isEqual(usersList.workingFilter, initialState.workingFilter)
  );

  return filterTags?.length > 0 ? (
    <Flex align="center" gap={4} wrap className="mb-2">
      <Typography.Text strong>{MESSAGES.FilteredBy}</Typography.Text>

      {_.map(filterTags, ({ text, onClear, disabled, name }, idx) => (
        <Button
          size="small"
          key={`filterTag-${name}.${idx}`}
          data-qa-label={`filter-tag-${name}`}
          icon={<CloseCircleOutlined />}
          disabled={disabled || isLoading}
          onClick={disabled ? _.noop : onClear}
        >
          {text}
        </Button>
      ))}

      <Button
        size="small"
        type="primary"
        icon={<ReloadOutlined />}
        data-qa-label={`filter-tag-reset`}
        disabled={isLoading || isResetDisabled}
        onClick={() => {
          dispatch(doClearFilter());
        }}
      >
        {MESSAGES.ResetFilters}
      </Button>
    </Flex>
  ) : null;
};
