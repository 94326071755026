import { ForgotPasswordTemplate, Typography } from "@beckersdp/becker-ds";
import { Button, Result } from "antd";
import _ from "lodash";
import React from "react";
import { END_USER_LINKS } from "../../constants/bim-links";
import { MESSAGES } from "../../i18n";
import { routes } from "../../routes";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const ForgotPassword = () => {
  const [time, setTime] = React.useState<number>(5);

  // -----------------------------------------------------------------------------------------------
  // - Callbacks
  const goToResetPwd = React.useCallback(() => {
    window.location.assign(END_USER_LINKS.miniOrangeForgotPwd);
  }, []);

  const goToLogin = React.useCallback(() => {
    window.location.assign(routes.login.path);
  }, []);

  // -----------------------------------------------------------------------------------------------
  // - Timer Ctl
  React.useEffect(() => {
    const timer = setInterval(() => {
      setTime(prev => Math.max(prev - 1, 0));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  // -----------------------------------------------------------------------------------------------
  // - Timer Ctl
  React.useEffect(() => {
    if (_.isEqual(time, 0)) {
      goToResetPwd();
    }
  }, [time]);

  return (
    <ForgotPasswordTemplate
      mainPanelContent={
        <Result
          style={{ width: 400 }}
          title={<Typography.Display>Forgot Password</Typography.Display>}
          subTitle={`You are about to be redirected to proceed with the reset password process. (${time}...)`}
          status="warning"
          extra={[
            <Button key="back" onClick={goToLogin}>
              Back to Sign In
            </Button>,
            <Button key="redirect" type="primary" onClick={goToResetPwd}>
              Go to Reset Password
            </Button>
          ]}
        />
      }
      className="login-page"
      // -------------------------------------------------------------------------------------------
      // - Footer
      // -------------------------------------------------------------------------------------------
      footer={{
        links: [
          {
            href: END_USER_LINKS.contactUs,
            text: MESSAGES.ContactUs,
            "data-qa-label": "contact-us",
            target: "_blank",
            className: "text-white"
          },
          {
            href: END_USER_LINKS.privacyPolicy,
            text: MESSAGES.PrivacyPolicy,
            target: "_blank",
            className: "text-white"
          },
          {
            href: END_USER_LINKS.cookiePolicy,
            text: MESSAGES.CookiePolicy,
            target: "_blank",
            className: "text-white"
          },
          {
            text: MESSAGES.CookiePreferenceCenter,
            className: "text-white optanon-toggle-display"
          }
        ],
        socialLinks: [
          {
            type: "facebook",
            target: "_blank",
            href: END_USER_LINKS.socialFacebook,
            className: "text-white"
          },
          {
            type: "instagram",
            target: "_blank",
            href: END_USER_LINKS.socialInstagram,
            className: "text-white"
          },
          {
            type: "linkedin",
            target: "_blank",
            href: END_USER_LINKS.socialLinkedin,
            className: "text-white"
          },
          {
            type: "tiktok",
            target: "_blank",
            href: END_USER_LINKS.socialTikTok,
            className: "text-white"
          },
          {
            type: "x",
            target: "_blank",
            href: END_USER_LINKS.socialX,
            className: "text-white"
          },
          {
            type: "youtube",
            target: "_blank",
            href: END_USER_LINKS.socialYoutube,
            className: "text-white"
          }
        ],
        copyrightMsg: MESSAGES.CopyrightMsg
      }}
    />
  );
};

export default ForgotPassword;
