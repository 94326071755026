import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { navApi } from "../../../services/nav-api";
import { Alert } from "../../../services/nav-api/alerts-reader/types";
import { Action, ThunkAction } from "../../types/action";

// -------------------------------------------------------------------------------------------------
// - Actions
// -------------------------------------------------------------------------------------------------

export const USER_FETCH_ALERTS_STARTED = "USER_FETCH_ALERTS_STARTED";
export const USER_FETCH_ALERTS_COMPLETED = "USER_FETCH_ALERTS_COMPLETED";
export const USER_FETCH_ALERTS_FAILED = "USER_FETCH_ALERTS_FAILED";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

const doStartFetchAlerts = (): Action => ({
  type: USER_FETCH_ALERTS_STARTED
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

const doCompleteFetchAlerts = (alerts: Alert[]): Action<Alert[]> => ({
  type: USER_FETCH_ALERTS_COMPLETED,
  payload: alerts
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

const doFailFetchAlerts = (payload: string): Action<string> => ({
  type: USER_FETCH_ALERTS_FAILED,
  payload
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doFetchAlerts =
  (): ThunkAction =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(doStartFetchAlerts());
    try {
      dispatch(
        doCompleteFetchAlerts(
          _.orderBy(await navApi.alertsReader.fetchAlerts(), ["publishDate", "id"], ["desc", "desc"])
        )
      );
    } catch (ex) {
      dispatch(doFailFetchAlerts((ex as HttpClientErrorResponse).message || "error"));
    }
  };
