import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { doNotifyApiFailure } from "../../../redux/actions/api-failure";
import { getStudentRosterToken } from "../../../redux/selectors/student-roster-token";
import { getStudentsTableDetails } from "../../../redux/selectors/students-table-data-source";
import { navApi } from "../../../services/nav-api";

// -------------------------------------------------------------------------------------------------
//  Types
// -------------------------------------------------------------------------------------------------

export type StudentActionsContextValue = {
  bulkChange: {
    modalOpen: boolean;
    token?: string;
    total?: number;
  };
  operation?: StudentTableActionTypes;
  selection: { active: boolean; ids: number[]; loading: boolean };
  showFilters: boolean;
  onShowFiltersChange: (value: boolean) => void;
  onBulkCurPage: () => void;
  onBulkAllItems: () => void;
  onSelectionConfirm: () => void;
  onSelectionChange: (ids: number[]) => void;
  onSelectionCancel: () => void;
  onBulkChangeModalClose: () => void;
};

export type StudentTableActionTypes = "bulk-change";

type StudentsTableActionsState = {
  selection: {
    active: boolean;
    ids: number[];
    loading: boolean;
  };
  activeOperation?: StudentTableActionTypes;
  bulkChange: {
    modalOpen: boolean;
    token?: string;
    total?: number;
  };
};

// -------------------------------------------------------------------------------------------------
//  Constants
// -------------------------------------------------------------------------------------------------

const GENERATE_TASK_COMPLETED = "GENERATE_TASK_COMPLETED";
const BULK_CHANGE_CUR_PAGE_CLICKED = "BULK_CHANGE_CUR_PAGE_CLICKED";
const BULK_CHANGE_CUR_PAGE_CONFIRMED = "BULK_CHANGE_CUR_PAGE_CONFIRMED";
const BULK_CHANGE_MODAL_CLOSED = "BULK_CHANGE_MODAL_CLOSED";
const BULK_CHANGE_ALL_ITEMS_CLICKED = "BULK_CHANGE_ALL_ITEMS_CLICKED";
const SELECTION_CHANGED = "SELECTION_CHANGED";
const SELECTION_CANCELED = "SELECTION_CANCELED";
const SELECTION_CONFIRMED = "SELECTION_CONFIRMED";

const initialState: StudentsTableActionsState = {
  selection: {
    active: false,
    ids: [],
    loading: false
  },
  bulkChange: {
    modalOpen: false
  }
};

// -------------------------------------------------------------------------------------------------
//  Reducer
// -------------------------------------------------------------------------------------------------

const reducer = (s: StudentsTableActionsState, obj: { type: string; payload?: any }): StudentsTableActionsState => {
  switch (obj.type) {
    case GENERATE_TASK_COMPLETED:
    case SELECTION_CANCELED:
      return {
        ...s,
        activeOperation: undefined,
        selection: {
          active: false,
          ids: [],
          loading: false
        }
      };
    case SELECTION_CHANGED:
      return {
        ...s,
        selection: {
          active: true,
          ids: obj.payload.ids,
          loading: false
        }
      };
    case SELECTION_CONFIRMED:
      return s.activeOperation === "bulk-change"
        ? { ...s, selection: { ...s.selection, loading: true } }
        : {
            ...s,
            selection: {
              ...s.selection,
              active: false
            }
          };
    case BULK_CHANGE_CUR_PAGE_CLICKED:
      return {
        ...s,
        activeOperation: "bulk-change",
        selection: {
          active: true,
          ids: obj.payload.ids || [],
          loading: false
        }
      };

    case BULK_CHANGE_ALL_ITEMS_CLICKED:
    case BULK_CHANGE_CUR_PAGE_CONFIRMED:
      return {
        ...s,
        selection: {
          ...s.selection,
          loading: false
        },
        activeOperation: "bulk-change",
        bulkChange: {
          modalOpen: true,
          token: obj.payload.token,
          total: obj.payload.total
        }
      };

    case BULK_CHANGE_MODAL_CLOSED:
      return {
        ...s,
        activeOperation: undefined,
        selection: {
          active: false,
          ids: [],
          loading: false
        },
        bulkChange: {
          modalOpen: false
        }
      };
    default:
      return s;
  }
};

// -------------------------------------------------------------------------------------------------
//  Context
// -------------------------------------------------------------------------------------------------

export const StudentActionsContext = React.createContext<StudentActionsContextValue>({
  onBulkCurPage: _.noop,
  onBulkAllItems: _.noop,
  onSelectionConfirm: _.noop,
  onSelectionChange: _.noop,
  onSelectionCancel: _.noop,
  onBulkChangeModalClose: _.noop,
  onShowFiltersChange: _.noop,
  selection: { active: false, ids: [], loading: false },
  bulkChange: { modalOpen: false },
  showFilters: false
});

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useStudentActions = (): StudentActionsContextValue => {
  const dispatchRdx = useDispatch<any>();

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [showFilters, setShowFilters] = React.useState(false);

  const { dataSource } = useSelector(getStudentsTableDetails);
  const activeStudentFilter = useSelector(getStudentRosterToken);
  const curPageStudentIds = (dataSource || []).map(s => s.id);

  return {
    selection: state.selection,
    bulkChange: state.bulkChange,
    operation: state.activeOperation,
    showFilters,
    onShowFiltersChange: setShowFilters,
    onBulkCurPage: () => {
      dispatch({
        type: BULK_CHANGE_CUR_PAGE_CLICKED,
        payload: {
          ids: curPageStudentIds
        }
      });
    },

    onBulkAllItems: () => {
      dispatch({
        type: BULK_CHANGE_ALL_ITEMS_CLICKED,
        payload: {
          token: activeStudentFilter.token,
          total: activeStudentFilter.total
        }
      });
    },
    onSelectionConfirm: async () => {
      dispatch({ type: SELECTION_CONFIRMED });
      try {
        if (state.activeOperation === "bulk-change") {
          const { token, total } = await navApi.studentRoster.createStudentRoster({ studentIds: state.selection.ids });
          dispatch({ type: BULK_CHANGE_CUR_PAGE_CONFIRMED, payload: { token, total } });
          return;
        }
      } catch (error) {
        dispatch({ type: SELECTION_CANCELED });
        dispatchRdx(doNotifyApiFailure(error));
      }
    },
    onSelectionChange: (ids: number[]) => {
      dispatch({ type: SELECTION_CHANGED, payload: { ids } });
    },
    onSelectionCancel: () => {
      dispatch({ type: SELECTION_CANCELED });
    },
    onBulkChangeModalClose: () => {
      dispatch({ type: BULK_CHANGE_MODAL_CLOSED });
    }
  };
};
