import { FilterOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { doApplyFilter, doClearFilter, doResetListSettings } from "../../redux/actions/users-list";
import { getIsApplyUsersListFilterDisabled, getIsClearUsersListFilterDisabled } from "../../redux/selectors/users-list";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const UsersTableFiltersActions = () => {
  const dispatch = useDispatch<any>();
  const clearFilterDisabled = useSelector(getIsClearUsersListFilterDisabled);
  const applyFilterDisabled = useSelector(getIsApplyUsersListFilterDisabled);

  return (
    <Flex gap={4}>
      <Button
        className="grow"
        htmlType="button"
        icon={<ReloadOutlined />}
        disabled={clearFilterDisabled}
        onClick={() => {
          dispatch(doClearFilter());
        }}
      >
        Reset
      </Button>
      <Button
        type="primary"
        className="grow"
        icon={<FilterOutlined />}
        disabled={applyFilterDisabled}
        onClick={() => {
          if (applyFilterDisabled) {
            return;
          }

          dispatch(doResetListSettings());
          dispatch(doApplyFilter());
        }}
      >
        Apply
      </Button>
    </Flex>
  );
};
