import { ConfigProvider, Select } from "antd";
import dayjs from "dayjs";
import generateConfig from "rc-picker/lib/generate/dayjs";
import React from "react";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type OwnProps = {
  value: dayjs.Dayjs;
  validRange?: [dayjs.Dayjs, dayjs.Dayjs];
  onChange: (year: dayjs.Dayjs) => void;
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const MonthSelect = (props: OwnProps) => {
  const month = generateConfig.getMonth(props.value || generateConfig.getNow());
  const { locale } = React.useContext(ConfigProvider.ConfigContext);

  let start = 0;
  let end = 11;

  if (props.validRange) {
    const [rangeStart, rangeEnd] = props.validRange;
    const currentYear = generateConfig.getYear(props.value);
    if (generateConfig.getYear(rangeEnd) === currentYear) {
      end = generateConfig.getMonth(rangeEnd);
    }
    if (generateConfig.getYear(rangeStart) === currentYear) {
      start = generateConfig.getMonth(rangeStart);
    }
  }

  const months = generateConfig.locale.getShortMonths!(locale?.locale ?? "en_US");
  const options: { label: string; value: number }[] = [];
  for (let index = start; index <= end; index += 1) {
    options.push({
      label: months[index],
      value: index
    });
  }

  return (
    <Select
      value={month}
      options={options}
      disabled={options.length <= 1}
      onChange={newMonth => {
        props.onChange(generateConfig.setMonth(props.value, newMonth));
      }}
    />
  );
};
