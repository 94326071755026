import React from "react";
import { TimeOffField } from "../../EditorFields/FieldTimeOff";
import { EditorSectionHeader } from "../EditorSectionHeader";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyTimeOffSection = () => (
  <>
    <EditorSectionHeader
      title="Select time off"
      description={
        "Select the dates during which students won't be available to study due to vacations, " +
        "breaks, or other commitments. You can add multiple dates or date ranges."
      }
    />
    <TimeOffField />
  </>
);
