import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { FEATURES } from "../../../constants/ui-features";
import { getAllowedFeatures } from "../../../redux/selectors/allowed_features";
import { getMyTenant } from "../../../redux/selectors/my-tenant";
import { getIsGlobalUser } from "../../../redux/selectors/permissions/is-global-user";
import { getIsSystemAdmin } from "../../../redux/selectors/permissions/is-system-admin";
import { RdxStoreState } from "../../../redux/types/state";

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useStudentTenant = () => {
  const myTenant = useSelector(getMyTenant);
  const isGlobalUser = useSelector(getIsGlobalUser);
  const isSystemAdmin = useSelector(getIsSystemAdmin);
  const allowedFeatures = useSelector(getAllowedFeatures);
  const workingFilter = useSelector(({ studentsList: { workingFilter } }: RdxStoreState) => workingFilter);

  return React.useMemo(
    () => ({
      value: myTenant?.id ?? workingFilter.tenantId,
      isSelected: _.gt(myTenant?.id ?? workingFilter.tenantId, 0),
      isEnabled:
        isGlobalUser || //
        isSystemAdmin ||
        allowedFeatures?.[FEATURES.ADD_TENANT_STUDENTS]
    }),
    [
      //
      myTenant?.id,
      isGlobalUser,
      isSystemAdmin,
      JSON.stringify(allowedFeatures),
      JSON.stringify(workingFilter)
    ]
  );
};
