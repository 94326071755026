import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { initialState } from "../../reducers/alerts-writer/constants";
import { AlertsPagination } from "../../reducers/alerts-writer/types";
import { Action, ThunkAction } from "../../types/action";
import { doFetchAlerts } from "./fetch";

// -------------------------------------------------------------------------------------------------
// - Actions
// -------------------------------------------------------------------------------------------------

export const CHANGE_ALERTS_PAGINATION = "CHANGE_ALERTS_PAGINATION";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

const doChangeAlertsPagination = (paging: AlertsPagination): Action => ({
  type: CHANGE_ALERTS_PAGINATION,
  payload: {
    paging
  }
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doResetAlertsPagination = (): Action => doChangeAlertsPagination(initialState.paging);

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doChangeAlertsPaginationAndFetch =
  (paging: AlertsPagination): ThunkAction =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(doChangeAlertsPagination(paging));
    dispatch(doFetchAlerts());
  };
