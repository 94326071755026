import _ from "lodash";
import React from "react";
import { initialState } from "../../../../redux/reducers/alerts-writer/constants";
import { TableState } from "./types";

// -------------------------------------------------------------------------------------------------
// - Table Context
// -------------------------------------------------------------------------------------------------

export const TableContext = React.createContext<TableState>({
  alertsState: initialState,
  eventHandlers: {
    //
    onEditRow: _.noop,
    onChange: _.noop
  }
});
