import { FormControllerValue } from "../userFormController/context";
import { useCreateUserAction } from "./hooks/createUser";
import { useUpdateUserAction } from "./hooks/updateUser";

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useUserActions = (formCtl: FormControllerValue) => {
  const createUserActions = useCreateUserAction(formCtl);
  const updateUserActions = useUpdateUserAction(formCtl);

  return formCtl.editingMode ? updateUserActions : createUserActions;
};
