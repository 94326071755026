import { Col, Row, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { MESSAGES } from "../../../../i18n";
import { getActiveProduct } from "../../../../redux/selectors/active-product";
import { NumericParam, Param } from "./components/NumericParam";
import { PassRateBySection } from "./components/PassRateBySection";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const CPAView = () => (
  <Row gutter={[16, 16]}>
    <Col xs={24} xxl={20}>
      <PassRateBySection />
    </Col>
    <Col xs={24} md={12} lg={6} xxl={5}>
      <NumericParam param={Param.PASSED_ALL_PARTS} />
    </Col>
    <Col xs={24} md={12} lg={6} xxl={5}>
      <NumericParam param={Param.NO_EXAM_TRACKER_DATA} />
    </Col>
    <Col xs={24} md={12} lg={6} xxl={5}>
      <NumericParam param={Param.PASSED_ANY_PART} />
    </Col>
    <Col xs={24} md={12} lg={6} xxl={5}>
      <NumericParam param={Param.ABOUT_TO_LOSE_OR_LOST_CREDITS} />
    </Col>
  </Row>
);

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const CMAView = () => (
  <Row gutter={[16, 16]}>
    <Col xs={24} lg={12} xxl={11}>
      <PassRateBySection />
    </Col>
    <Col xs={24} lg={4} xxl={3}>
      <NumericParam param={Param.NO_EXAM_TRACKER_DATA} />
    </Col>
    <Col xs={24} lg={4} xxl={3}>
      <NumericParam param={Param.PASSED_ANY_PART} />
    </Col>
    <Col xs={24} lg={4} xxl={3}>
      <NumericParam param={Param.PASSED_ALL_PARTS} />
    </Col>
  </Row>
);

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const ExamTracker = () => {
  const activeProduct = useSelector(getActiveProduct);
  return (
    <Row className="mb-2">
      <Col span={24}>
        <Typography.Title level={5}>{MESSAGES.ExamTracker}</Typography.Title>
        {_.isEqual(activeProduct, "cpa") ? <CPAView /> : <CMAView />}
      </Col>
    </Row>
  );
};
