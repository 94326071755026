import { useDispatch, useSelector } from "react-redux";
import { FilterTag } from "../../../core/model";
import { formatMsg, MESSAGES } from "../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../redux/actions/students-list";
import { initialState } from "../../../redux/reducers/students-list";
import { RdxStoreState } from "../../../redux/types/state";
import { lastActivityLabelByValue } from "../StudentsFilter/metadata";

// -------------------------------------------------------------------------------------------------
// - Hooks: useLastActivityFilter
// -------------------------------------------------------------------------------------------------

export const useLastActivityFilter = (): FilterTag[] => {
  const dispatch = useDispatch<any>();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);

  return activeFilter?.filter?.lastActivity &&
    activeFilter.filter.lastActivity !== initialState.workingFilter.lastActivity
    ? [
        {
          name: "lastActivity",
          onClear: () => {
            dispatch(doUpdateWorkingFilter({ lastActivity: initialState.workingFilter.lastActivity }));
            dispatch(doApplyFilter());
          },
          text: formatMsg(
            MESSAGES.FilterTagLastActivitySection,
            lastActivityLabelByValue(activeFilter.filter.lastActivity)
          )
        }
      ]
    : [];
};
