import { navApi } from "../../../../../services/nav-api";
import { getTaskBlobWhenReady } from "../../../../../services/task";
import { getCurDateForReportFilename } from "../../../../../utils/date-utils";
import { ReportParams, ReportResponse } from "../../types";

// -------------------------------------------------------------------------------------------------
// - Generator
// -------------------------------------------------------------------------------------------------

export const genTimesheetReport = async (params: ReportParams): Promise<ReportResponse> => ({
  filename: `timesheet-${params.timesheetPeriodByReportType[params.type]!.format(
    "YYYY-MM"
  )}-${getCurDateForReportFilename()}.xlsx`,
  blob: await getTaskBlobWhenReady((await navApi.studentRoster.timesheetReport(params)).taskToken)
});
