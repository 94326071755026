import { Checkbox, Form } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getActiveProduct } from "../../../../redux/selectors/active-product";
import { TenantProduct } from "../../../../services/nav-api/tenants/types";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const FinalReviewField = () => {
  const product = useSelector(getActiveProduct);
  return (
    <StudyPlanEditorContext.Consumer>
      {({ editor: { finalReview }, simulations }) =>
        _.isEqual(TenantProduct.CPA, product) ? (
          <Form.Item>
            <Checkbox
              checked={finalReview.value!}
              onChange={e => {
                simulations.enableSimulations();
                finalReview.setValue(e.target.checked);
              }}
            >
              Final Review
            </Checkbox>
          </Form.Item>
        ) : null
      }
    </StudyPlanEditorContext.Consumer>
  );
};
