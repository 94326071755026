import React from "react";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const SVGColibri = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 233.29 194.66"
    className="mx-2"
    style={{
      width: 18,
      height: 18
    }}
  >
    <path
      fill="#d60436"
      d="M170.81,78.24c-9.26-3.84-26.33-10.69-48.22,7.31-3.07-4.36-6.65-8.72-10.74-13.02C81.04,40.18,57.09,19.02,56.85,18.81c-.93-.82-3.89-3.46-4.31-3.84h-.09c-.68,1.19-1.41,4.83-1.56,6.14-1.91,16.82,.93,32.18,5.88,45.76-12.73-6.36-29.08-14.16-29.28-14.26-1.22-.59-5.57-2.5-6.06-2.72-.02-.01-.05,0-.05,.02-.2,1.32,.7,5.71,1.14,7.06,7.44,22.78,24.59,41.72,45.82,51.63,5.36,2.5,16,5.58,23.9,4.12v-.04c-4.27-4.93-10.64-14.1-14.53-20.25-1.81-2.86-3.62-5.89-5.37-9.06,17.06,10.33,30.31,23.37,23.02,41.59-.01,.03-.02,.06-.03,.09-5.66,10.93-12.67,23.73-22.47,35.42-5.98,7.13-15.31,13.98-25.04,17.23-2.33,.78,4.91,3.93,13.97,.13,7.19-3.02,13.84-9.27,19.3-16.07,9.14-11.4,16.69-25.65,21.57-35.14,5.74-11.16,11.21-21.78,18.55-29.86,4.86,8.67,7.27,17.07,6.93,24.67-.26,5.86-2.24,11.01-5.75,15.06-.23,.26-.48,.51-.74,.74-.57,.51-1.14,1.03-1.72,1.52-6.82,5.57-16.17,10.31-25.41,12.02-2.42,.45,4.31,4.58,13.81,2.06,1.45-.38,2.89-.89,4.32-1.5,2.98-1.15,5.91-2.79,8.76-4.84,9.58-6.89,18.16-17.43,24.7-25.53,4.9-6.07,9.13-11.32,13-14.6,11.51-9.76,23.11-13.85,35.1-15.05,12.25-1.23,17.74-1.14,17.74-1.51-.68-.27-9.21-1.55-17.23-3.44-8.02-1.89-14.71-4.35-23.9-8.17ZM32.63,63.08c8.19,3.95,20.6,10,28.38,14.02,4.85,10.43,10.67,19.61,15.99,27.33-19.28-7.15-35.59-22.32-44.37-41.35Zm71.37,45.69c-3.99-18.74-25.58-30.69-37.42-36.89-5.57-12.61-9.5-26.99-9.05-42.73,8.74,8.01,27.15,25.31,49.09,48.35,4.1,4.31,7.64,8.65,10.61,12.97-5.14,5.3-9.35,11.54-13.23,18.31Zm50.43-7.86c-4.39,3.72-8.82,9.21-13.95,15.57-1.68,2.09-3.44,4.24-5.25,6.4,.03-.37,.07-.73,.08-1.1,.42-9.4-2.64-19.7-8.79-30.12,18.89-15.92,32.49-10.42,41.57-6.73,.89,.36,1.74,.7,2.53,1l1.76,.67c1.98,.76,3.77,1.44,5.59,2.07-8.91,2.26-16.48,6.26-23.54,12.25Z"
    />
  </svg>
);
