import { Flex, Tag } from "antd";
import _ from "lodash";
import React from "react";
import { TagNode } from "../../../../redux/selectors/my-tenant-tags-tree";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
// - Utils
// -------------------------------------------------------------------------------------------------

const findNodeById = (tagId: number, nodes?: TagNode[] | null): TagNode | null =>
  _.reduce(nodes, (a, n) => a ?? (_.isEqual(n.id, tagId) ? n : findNodeById(tagId, n.children)), null);

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TagIDsField = () => (
  <StudyPlanEditorContext.Consumer>
    {({ editor: { tenantTags, tagIds } }) => (
      <Flex vertical={false} wrap justify="left" align="left">
        {_.chain(tagIds.value)
          .map(tagId => findNodeById(tagId, tenantTags.tree))
          .filter(n => !_.isNil(n))
          .map(n => (
            <Tag key={n.id} closable={false}>
              {n.name}
            </Tag>
          ))
          .value()}
      </Flex>
    )}
  </StudyPlanEditorContext.Consumer>
);
