import { StyleProvider } from "@ant-design/cssinjs";
import { defaultTheme, EDSGlobalStyles } from "@beckersdp/becker-ds";
import { App as AntdApplication } from "antd";
import React from "react";
import { Provider } from "react-redux";
import { Store } from "redux";
import { ThemeProvider } from "styled-components";
import { ApiFailureModal } from "./components/ApiFailureModal";
import { EnvironmentInfo } from "./components/EnvironmentInfo";
import { Router } from "./router";
import { TrackUnhandledErrorsBoundary } from "./services/error-tracking/unhandled-errors-boundary";
import { setAntdDefaults } from "./styles/antd-set-defaults";
import { theme } from "./styles/theming";

import _ from "lodash";
import { ApplicationToasts, ApplicationToastsContext } from "./components/AppToasts";
import "./public/styles/global-styles";

setAntdDefaults();

// -------------------------------------------------------------------------------------------------
//  Types
// -------------------------------------------------------------------------------------------------
declare global {
  interface Window {
    errorMessage?: string | null;
  }
}

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

const App = ({ store }: { store: Store }) => (
  <ApplicationToasts>
    <ApplicationToastsContext.Consumer>
      {api => (
        <div
          ref={() => {
            //
            // Hack to allow users to launch Privace Preference Center from the main menu
            //
            const elInput = document.createElement("input");
            elInput.setAttribute("type", "hidden");
            elInput.setAttribute("role", "button");
            elInput.setAttribute("id", "ot-sdk-btn");
            elInput.setAttribute("class", "ot-sdk-show-settings");

            document.body.insertBefore(elInput, document.body.firstChild);

            // Look for a property in `window` object that contains a list of errors
            // injected by the backend. These errors can be used to display validation
            // messages or alerts to the user.
            if (!_.isNil(window.errorMessage)) {
              setTimeout(() => {
                api.error({
                  message: "Error",
                  description: window.errorMessage
                });

                window.errorMessage = null;
              }, 1000);
            }
          }}
        >
          <TrackUnhandledErrorsBoundary>
            <StyleProvider hashPriority="high">
              <AntdApplication>
                <ThemeProvider theme={{ ...theme, ...defaultTheme }}>
                  <Provider store={store}>
                    <Router />
                    <ApiFailureModal />
                    <EnvironmentInfo />
                  </Provider>
                  <EDSGlobalStyles />
                </ThemeProvider>
              </AntdApplication>
            </StyleProvider>
          </TrackUnhandledErrorsBoundary>
        </div>
      )}
    </ApplicationToastsContext.Consumer>
  </ApplicationToasts>
);

export default App;
