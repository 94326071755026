import { Checkbox } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { doUpdateFilter } from "../../../../../redux/actions/dashboard/tenant/filter";
import { getHasRestrictedUserTags } from "../../../../../redux/selectors/my-tenant-tags-tree";
import { getStudentFilterTagsTree } from "../../../../../redux/selectors/student-filter-tags-tree";
import { RdxStoreState } from "../../../../../redux/types/state";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const WithoutTags = () => {
  const dispatch = useDispatch<any>();
  const hasRestrictedUserTags = useSelector(getHasRestrictedUserTags);
  const studentsTree = useSelector(getStudentFilterTagsTree);
  const withoutTags = useSelector(
    ({
      tenantDashboard: {
        workingFilter: { withoutTags }
      }
    }: RdxStoreState) => Boolean(withoutTags)
  );

  return !hasRestrictedUserTags && !studentsTree.loading && (studentsTree.tree ?? []).length > 0 ? (
    <StyledCheckbox
      checked={withoutTags}
      onChange={() => {
        dispatch(doUpdateFilter({ withoutTags: !withoutTags, tags: [] }));
      }}
    >
      Without Tags
    </StyledCheckbox>
  ) : null;
};

// -------------------------------------------------------------------------------------------------
// - Styled Component
// -------------------------------------------------------------------------------------------------

const StyledCheckbox = styled(Checkbox)`
  margin-top: 10px !important;
  margin-left: 10px !important;
  margin-bottom: 3px !important;
`;
