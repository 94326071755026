import { Field, StudyPace, StudyPlanEditorValue } from "./model";
import { DaysOffField, useDaysOff } from "./useFieldDaysOff";
import { EndDateField, useEndDate } from "./useFieldEndDate";
import { FinalReviewField, useFinalReview } from "./useFieldFinalReview";
import { FlashcardsField, useFlashcards } from "./useFieldFlashcards";
import { LockUntilField, useLockUntil } from "./useFieldLockUntil";
import { NameField, useName } from "./useFieldName";
import { PracticeTestsField, usePracticeTests } from "./useFieldPracticeTests";
import { SectionField, useSection } from "./useFieldSection";
import { StartDateField, useStartDate } from "./useFieldStartDate";
import { StudyPaceField, useStudyPace } from "./useFieldStudyPace";
import { useStudyTimeWeek } from "./useFieldStudyTimeWeek";
import { TagIdsField, useTagIds } from "./useFieldTagIds";
import { TenantIdField, useTenantId } from "./useFieldTenantId";
import { useWeekDayHours, WeekDayHoursField } from "./useFieldWeeDayHours";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type StudyPlanEditorFields = {
  tenantId: TenantIdField;
  name: NameField;
  section: SectionField;
  startDate: StartDateField;
  endDate: EndDateField;
  practiceTests: PracticeTestsField;
  flashcards: FlashcardsField;
  finalReview: FinalReviewField;
  studyPace: StudyPaceField;
  weekDayHours: WeekDayHoursField;
  daysOff: DaysOffField;
  studyTimeWeek: Field<number | undefined>;
  tagIds: TagIdsField;
  lockUntil: LockUntilField;
};

// -------------------------------------------------------------------------------------------------
// - Context
// -------------------------------------------------------------------------------------------------

export const useEditorFields = (): StudyPlanEditorFields => {
  const endDate = useEndDate();
  const name = useName();
  const section = useSection();
  const startDate = useStartDate();
  const tenantId = useTenantId();
  const practiceTests = usePracticeTests(true);
  const flashcards = useFlashcards(true);
  const finalReview = useFinalReview(true);
  const studyPace = useStudyPace(StudyPace.AVERAGE);
  const weekDayHours = useWeekDayHours();
  const daysOff = useDaysOff();
  const studyTimeWeek = useStudyTimeWeek();
  const tagIds = useTagIds();
  const lockUntil = useLockUntil();

  return {
    tenantId,
    name,
    section,
    startDate,
    endDate,
    practiceTests,
    flashcards,
    finalReview,
    studyPace,
    weekDayHours,
    daysOff,
    studyTimeWeek,
    tagIds,
    lockUntil
  };
};

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const buildDefault = <T extends keyof StudyPlanEditorValue>(
  initVal?: StudyPlanEditorValue[T]
): Field<StudyPlanEditorValue[T], any> => ({
  setValue: (_k?: StudyPlanEditorValue[T]) => {},
  resetValue: () => {},
  value: initVal
});

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

export const studyPlanEditorFieldsInitValue: StudyPlanEditorFields = {
  tenantId: buildDefault<"tenantId">(),
  name: buildDefault<"name">(),
  section: buildDefault<"section">(),
  startDate: buildDefault<"startDate">(),
  endDate: buildDefault<"endDate">(),
  practiceTests: buildDefault<"practiceTests">(),
  flashcards: buildDefault<"flashcards">(),
  finalReview: buildDefault<"finalReview">(),
  studyPace: buildDefault<"studyPace">(),
  weekDayHours: buildDefault<"weekDayHours">(),
  daysOff: buildDefault<"daysOff">(),
  studyTimeWeek: buildDefault<"studyTimeWeek">(),
  tagIds: buildDefault<"tagIds">(),
  lockUntil: buildDefault<"lockUntil">()
};
