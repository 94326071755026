import { ColumnProps } from "antd/es/table";
import { createCreationDateColumn } from "./ColCreationDate";
import { createNameColumn } from "./ColName";
import { createSectionColumn } from "./ColSection";
import { createStartEndDateColumns } from "./ColStartEndDates";
import { createStatusColumn } from "./ColStatus";
import { createTagsColumn } from "./ColTags";
import { createTenantColumn } from "./ColTenant";
import { CreateColumnProps } from "./model";

// -------------------------------------------------------------------------------------------------
// - Builders
// -------------------------------------------------------------------------------------------------

export const createColumns = (props: CreateColumnProps): ColumnProps<{}>[] => [
  ...createNameColumn(props),
  ...createTenantColumn(props),
  ...createSectionColumn(props),
  ...createStartEndDateColumns(props),
  ...createTagsColumn(props),
  ...createCreationDateColumn(props),
  ...createStatusColumn(props)
];
