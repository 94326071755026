import React from "react";
import { MESSAGES } from "../../../i18n";
import menuItemCookiePolicy from "./MIAccountCookiePolicy";
import menuItemCookiePref from "./MIAccountCookiePref";
import menuItemPrivacyPolicy from "./MIAccountPrivacyPolicy";

const key = "privacy-group";
const qaLabel = "privacyBtn";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default [
  {
    key,
    label: <span data-qa-label={qaLabel}>{MESSAGES.NavPrivacy}</span>,
    children: [
      //
      ...menuItemPrivacyPolicy,
      ...menuItemCookiePolicy,
      ...menuItemCookiePref
    ]
  }
];
