import { Card, Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import {
  handleCellText,
  handleDateCell,
  handleStudentExamExamDateCell,
  handleStudentExamExamGradeCell,
  handleStudentExamExpirationDateCell
} from "../../components/TableCellRender";
import { getStudentExams } from "../../redux/selectors/student-exams";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentExamTracker = () => {
  const studentExams = useSelector(getStudentExams);
  const loading = !studentExams || studentExams.loading;

  return (
    <Card
      size="small"
      loading={loading}
      title={
        <div className="ant-card-head-wrapper">
          <div className="ant-card-head-title" data-qa-label="sd-exam-tracker-title">
            Exam Tracker
          </div>
        </div>
      }
    >
      <Table
        size="small"
        rowKey="course"
        loading={loading}
        pagination={false}
        rowClassName="cursor-pointer"
        dataSource={studentExams?.exams ?? []}
        columns={[
          {
            dataIndex: "course",
            key: "course",
            title: "Section",
            render: handleCellText
          },
          {
            dataIndex: "purchaseDate",
            key: "purchaseDate",
            title: "Purchase Date",
            render: handleDateCell
          },
          {
            dataIndex: "expirationDate",
            key: "expirationDate",
            title: "Expiration Date",
            render: handleStudentExamExpirationDateCell
          },
          {
            dataIndex: "examDate",
            key: "examDate",
            title: "Exam Date",
            render: handleStudentExamExamDateCell
          },
          {
            dataIndex: "examGrade",
            key: "examGrade",
            title: "Exam Grade",
            render: handleStudentExamExamGradeCell
          }
        ]}
      />
    </Card>
  );
};
