import _ from "lodash";
import { createSelector } from "reselect";
import { initialState, RdxUsersState } from "../reducers/users-list";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";

// ---------------------------------------------------------------------------------------------------------------------
// - getUserList
// ---------------------------------------------------------------------------------------------------------------------

const getUserList = createSelector(
  ({ usersList }: RdxStoreState) => usersList,
  usersList => usersList
);

// ---------------------------------------------------------------------------------------------------------------------
// - getUsersListWorkingFilter
// ---------------------------------------------------------------------------------------------------------------------

export const getUsersListWorkingFilter = createSelector(getUserList, ({ workingFilter }) => workingFilter);

// ---------------------------------------------------------------------------------------------------------------------
// - getUsersListActiveFilter
// ---------------------------------------------------------------------------------------------------------------------

export const getUsersListActiveFilter = createSelector(getUserList, ({ activeFilter }) => activeFilter);

// ---------------------------------------------------------------------------------------------------------------------
// - getIsUsersListFilterEqualsToAppliedFilter
// ---------------------------------------------------------------------------------------------------------------------

const getIsUsersListFilterEqualsToAppliedFilter = createSelector(
  getUsersListWorkingFilter,
  getUsersListActiveFilter,
  (workingFilter, activeFilter): boolean => (!activeFilter ? false : _.isEqual(activeFilter.filter, workingFilter))
);

// ---------------------------------------------------------------------------------------------------------------------
// - getIsUsersListLoading
// ---------------------------------------------------------------------------------------------------------------------

export const getIsUsersListLoading = createSelector(
  getUserList,
  (users: RdxUsersState) =>
    users.status === RdxFetchStatus.LOADING || Boolean(users.activeFilter && users.activeFilter.loading)
);

// ---------------------------------------------------------------------------------------------------------------------
// - getIsClearUsersListFilterDisabled
// ---------------------------------------------------------------------------------------------------------------------

export const getIsClearUsersListFilterDisabled = createSelector(
  getUsersListWorkingFilter,
  getUsersListActiveFilter,
  getIsUsersListLoading,
  (workingFilter, activeFilter, loading): boolean => {
    return (
      (_.isEqual(initialState.workingFilter, workingFilter) &&
        (!activeFilter || _.isEqual(initialState.workingFilter, activeFilter.filter))) ||
      loading
    );
  }
);

// ---------------------------------------------------------------------------------------------------------------------
// - getIsApplyUsersListFilterDisabled
// ---------------------------------------------------------------------------------------------------------------------

export const getIsApplyUsersListFilterDisabled: (state: RdxStoreState) => boolean = createSelector(
  getIsUsersListLoading,
  getIsUsersListFilterEqualsToAppliedFilter,
  (loading: boolean, equals: boolean): boolean => {
    return equals || loading;
  }
);
