export enum NavigationDrawerOptionKey {
  ADMIN_NOTIFICATIONS = "admin:notifications",
  ADMIN_PARTNERS = "admin:partners",
  ADMIN_TAGS = "admin:tags",
  ADMIN_TENANTS = "admin:tenants",
  ADMIN_USERS = "admin:users",
  DASHBOARD = "dashboard",
  PROFILE_MYACCOUNT = "profile:my-account",
  PROFILE_NOTIFICATIONS = "profile:notifications",
  STUDENTS = "students",
  STUDY_PLANS = "studyPlans",
  STUDY_PLAN_EDITOR = "studyPlanEditor",
  STUDY_PLAN_VIEWER = "studyPlanViewer"
}
