import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { MESSAGES } from "../../../i18n";
import { useStudyPlanWritePermission } from "../../../redux/hooks/useStudyPlansPermissions";
import { routes } from "../../../routes";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const NewStudyPlanButton = () => {
  const navigate = useNavigate();
  const canCreateStudyPlan = useStudyPlanWritePermission();
  return canCreateStudyPlan ? (
    <Button
      type="primary"
      onClick={() => {
        navigate(routes.studyPlansNew.path);
      }}
    >
      {MESSAGES.CreateStudyPlan}
    </Button>
  ) : null;
};
