import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Descriptions, Flex, Modal, Typography } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { ApplicationToastsContext } from "../../components/AppToasts";
import { BaseLayout } from "../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../components/NavigationDrawer/types";
import { EMPTY_CELL_SYMBOL } from "../../components/TableCellRender";
import { TagsEditor } from "../../components/TagsEditor";
import { MESSAGES } from "../../i18n";
import { routes } from "../../routes";
import { HttpClientErrorResponse } from "../../services/http-client/types";
import { navApi } from "../../services/nav-api";
import { Partner } from "../../services/nav-api/partners/types";
import { Tenant } from "../../services/nav-api/tenants/types";
import { format } from "../../utils/date-utils";
import { CPATagsForm } from "./CPATagsForm";
import { AddPartnerFormProps, PartnersForm } from "./PartnersForm";
import { PartnersTable } from "./PartnersTable";

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const getValue = (key: keyof Tenant, info?: Tenant) =>
  !info || !info[key] ? EMPTY_CELL_SYMBOL : typeof info[key] === "object" ? (info[key] as any).join(", ") : info[key];

const getDateValue = (key: keyof Tenant, info?: Tenant) =>
  !info || !info[key] ? EMPTY_CELL_SYMBOL : format(info![key] as any, "P");

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const descriptionsItemStyle: Partial<Record<"label" | "content", React.CSSProperties>> = {
  label: { width: "150px" }
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantDetails = () => {
  const navigate = useNavigate();
  const [reloadTs, setReloadTs] = React.useState<number>();
  const [tenant, setTenant] = React.useState<Tenant>();
  const [loading, setLoading] = React.useState(false);
  const [partners, setPartners] = React.useState<Partner[]>();
  const { id } = useParams<{ id: string }>();
  const tenantId = parseInt(id!);

  const fetchPartners = () => {
    setLoading(true);
    navApi.partners
      .getPartners({ tenantId })
      .then(data => setPartners(data.partners))
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    navApi.tenants
      .getTenant(tenantId)
      .then(tenant => {
        if (tenant.products) {
          tenant.products = tenant.products.map(p => p.toUpperCase()) as any;
        }
        return tenant;
      })
      .then(tenant => setTenant(tenant));
    fetchPartners();
  }, []);

  return (
    <BaseLayout
      activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_TENANTS}
      breadcrumb={[
        {
          name: MESSAGES.Tenants,
          href: routes.tenants.resolve()
        },
        {
          name: MESSAGES.Details
        }
      ]}
    >
      {!tenant ? (
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      ) : (
        <>
          <Typography.Title level={2}>{tenant.name}</Typography.Title>
          <Flex vertical gap={12}>
            <Card
              title={
                <Flex justify="space-between" align="center">
                  <>Details</>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => {
                      navigate(routes.tenantEdit.resolve({ id: tenantId.toString() }));
                    }}
                  >
                    {MESSAGES.Edit}
                  </Button>
                </Flex>
              }
            >
              <Descriptions bordered size="small" column={1}>
                <Descriptions.Item label="Name" styles={descriptionsItemStyle}>
                  {getValue("name", tenant)}
                </Descriptions.Item>
                <Descriptions.Item label="Type" styles={descriptionsItemStyle}>
                  {getValue("type", tenant)}
                </Descriptions.Item>
                <Descriptions.Item label="Status" styles={descriptionsItemStyle}>
                  {getValue("status", tenant)}
                </Descriptions.Item>
                <Descriptions.Item label="Creation Date" styles={descriptionsItemStyle}>
                  {getDateValue("created", tenant)}
                </Descriptions.Item>
              </Descriptions>
            </Card>

            <Card title="Products" variant="outlined">
              <Checkbox.Group
                disabled
                value={tenant.products}
                options={[
                  { label: "CPA", value: "CPA" },
                  { label: "CMA", value: "CMA" }
                ]}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px"
                }}
              />
            </Card>
            <Card title="Additional features" variant="outlined">
              {tenant.hasStudyPlan ? (
                <Typography.Text>Study plans are available for this tenant</Typography.Text>
              ) : (
                <Typography.Text>Study plans are not available for this tenant</Typography.Text>
              )}
            </Card>

            <ApplicationToastsContext.Consumer>
              {api => (
                <Card title={MESSAGES.Partners}>
                  <PartnersTable
                    tenantId={tenantId}
                    loading={loading}
                    partners={partners}
                    onAdd={() => {
                      const instance = Modal.info({
                        icon: null,
                        footer: null,
                        title: MESSAGES.AssignPartnerToTenant,
                        closable: true,
                        content: (
                          <PartnersForm
                            type={tenant.type}
                            onSubmit={async (args: AddPartnerFormProps) => {
                              try {
                                await navApi.partners.putPartnerTenant(parseInt(args.partner as string), tenantId);

                                fetchPartners();

                                api.success({
                                  message: MESSAGES.Success,
                                  description: MESSAGES.TenantPartnerSuccess
                                });
                              } catch (e) {
                                const err: HttpClientErrorResponse = e;
                                api.error({
                                  message: MESSAGES.Error,
                                  description: err.message ? err.message : MESSAGES.UnknownError
                                });
                              } finally {
                                instance.destroy();
                              }
                            }}
                          />
                        )
                      });
                    }}
                    onDelete={async (partnerId: number) => {
                      try {
                        await navApi.partners.deletePartnerTenant(partnerId);

                        fetchPartners();

                        api.success({
                          message: MESSAGES.Success,
                          description: MESSAGES.TenantPartnerRemoveSuccess
                        });
                      } catch (e) {
                        const err: HttpClientErrorResponse = e;
                        api.error({
                          message: MESSAGES.Error,
                          description: err.message ? err.message : MESSAGES.UnknownError
                        });
                      }
                    }}
                  />
                </Card>
              )}
            </ApplicationToastsContext.Consumer>

            <Card title="Tags">
              <TagsEditor
                tenantId={tenantId}
                onRefresh={ts => {
                  setReloadTs(ts);
                }}
              />
            </Card>

            <Card title={MESSAGES.TenantCPATagFormTitle}>
              {tenant.hasTagForm ? (
                <CPATagsForm tenantId={tenantId} key={reloadTs} />
              ) : (
                <Typography.Text>CPA Tag Form is not available for this tenant</Typography.Text>
              )}
            </Card>
          </Flex>
        </>
      )}
    </BaseLayout>
  );
};
