import React from "react";
import { MESSAGES } from "../../../i18n";

const key = "cookie-pref";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default [
  {
    key,
    label: (
      <a
        data-qa-label="menu-cookie--preference-policy"
        onClick={() => {
          document.getElementById("ot-sdk-btn")!.click();
        }}
      >
        {MESSAGES.NavCookiePrefCenter}
      </a>
    )
  }
];
