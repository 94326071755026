import { Button, Checkbox, Flex, Form, Modal } from "antd";
import sortBy from "lodash/sortBy";
import React from "react";
import { useSelector } from "react-redux";
import { CheckableTreeView } from "../../components/CheckableTreeView";
import { useEditStudentRequest } from "../../hooks/use-edit-student-request";
import { MESSAGES } from "../../i18n";
import { TagNode } from "../../redux/selectors/my-tenant-tags-tree";
import { getStudentEditData } from "../../redux/selectors/student-tags";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentEditModal = (props: { onClose: () => void; studentId: number; tenantId: number }) => {
  const { assignedTagIds = [], excluded: initialExcluded, tree } = useSelector(getStudentEditData);
  const { loading, onFormSubmit } = useEditStudentRequest(props.tenantId, props.studentId, tree ? tree.data : []);
  const [checkedIds, setCheckedIds] = React.useState(assignedTagIds);
  const [excluded, setExcluded] = React.useState(Boolean(initialExcluded));

  React.useEffect(() => {
    setCheckedIds(assignedTagIds);
  }, [assignedTagIds]);

  const isFormDirty = () =>
    excluded !== Boolean(initialExcluded) || sortBy([...assignedTagIds]).join("") !== sortBy([...checkedIds]).join("");

  return (
    <Modal title={MESSAGES.EditStudentTitle} open onCancel={props.onClose} footer={null}>
      <Form layout="vertical">
        <Form.Item label="Visibility">
          <Checkbox
            checked={excluded}
            onChange={() => {
              setExcluded(!excluded);
            }}
          >
            Hidden
          </Checkbox>
        </Form.Item>

        <Form.Item label="Tags">
          <CheckableTreeView
            tree={tree?.data ?? []}
            checked={checkedIds}
            loading={!!tree?.loading}
            onChange={setCheckedIds}
            renderNode={(n: TagNode) => n.name}
            checkBehavior="assign"
          />
        </Form.Item>

        <Flex justify="end" gap={4}>
          <Button htmlType="button" onClick={props.onClose}>
            {MESSAGES.CancelLabel}
          </Button>
          <Button
            type="primary"
            disabled={!isFormDirty()}
            loading={loading}
            onClick={async () => {
              await onFormSubmit(checkedIds, excluded);
              props.onClose();
            }}
          >
            {MESSAGES.SubmitLabel}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};
