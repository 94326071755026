import { Form } from "antd";
import React from "react";
import { CheckableTreeView } from "../../../../components/CheckableTreeView";
import { CheckBehavior } from "../../../../components/CheckableTreeView/types";
import { TagNode } from "../../../../redux/selectors/my-tenant-tags-tree";
import { useTenantTagTree } from "../hooks/useTenantTagTree";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TagsTreeOption = (props: {
  value: number[];
  onChange: (checked: number[]) => void;
  checkBehavior: CheckBehavior;
}) => {
  const { tree, loading } = useTenantTagTree();
  return (
    <Form.Item label="Tags">
      <CheckableTreeView
        loading={loading}
        tree={tree as TagNode[]}
        renderNode={(n: TagNode) => n.name}
        checkBehavior={props.checkBehavior}
        checked={props.value || []}
        onChange={props.onChange}
      />
    </Form.Item>
  );
};
