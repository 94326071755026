import { Form, Input, Spin } from "antd";
import React from "react";
import { FormControllerContext, UserInfoStatus } from "../hooks/userFormController";
import { HelpMessage } from "./HelpMessage";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const EmailField = () => (
  <FormControllerContext.Consumer>
    {({ userInfoStatus, email, reset, editingMode }) => {
      return (
        <Form.Item
          required
          label="Email"
          wrapperCol={{ xs: 24, xl: 12 }}
          hasFeedback={userInfoStatus.isIn([
            UserInfoStatus.UNAVAILABLE,
            UserInfoStatus.AVAILABLE_AND_MUTABLE,
            UserInfoStatus.AVAILABLE_BUT_IMMUTABLE
          ])}
          validateStatus={
            userInfoStatus.isEqualTo(UserInfoStatus.PENDING_VERIFICATION)
              ? undefined
              : userInfoStatus.isIn([
                    UserInfoStatus.AVAILABLE_BUT_IMMUTABLE,
                    UserInfoStatus.AVAILABLE_AND_MUTABLE
                    //
                  ])
                ? "success"
                : "error"
          }
          help={<HelpMessage />}
          rules={[
            {
              required: true,
              message: "Please input you email!"
            }
          ]}
        >
          <Input
            type="email"
            placeholder="Enter an email"
            value={email.value ?? ""}
            disabled={editingMode}
            suffix={<Spin size="small" spinning={userInfoStatus.isLoading} />}
            allowClear={!userInfoStatus.isLoading}
            onClear={() => {
              reset();
            }}
            onChange={e => {
              email.setValue(e.currentTarget.value);
            }}
          />
        </Form.Item>
      );
    }}
  </FormControllerContext.Consumer>
);
