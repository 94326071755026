import "@ant-design/v5-patch-for-react-19";

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app";
import { bootstrap } from "./bootstrap";

const { store } = bootstrap();

const entryPoint = document.getElementById("app")!;
const root = createRoot(entryPoint);

root.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>
);
