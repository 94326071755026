import cloneDeep from "lodash/cloneDeep";
import { createSelector } from "reselect";
import { Tenant } from "../../../services/nav-api/tenants/types";
import { RdxTenantsState } from "../../reducers/tenants";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "../../types/status";
import { getMyTenant } from "../my-tenant";
import { getMyTenantTags, getTenantTags } from "../tenant-tags";
import { addEditingTagToTreeIfNewTag, createTreeTags } from "./create-tags-editor-tree";
import { setNodesDepth } from "./set-depths";
import { TagEditorNode } from "./types";

export * from "./types";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getMyTenantTagsTreeEditor: (state: RdxStoreState) => {
  tree: TagEditorNode[] | undefined;
  loading: boolean;
} = createSelector(
  getMyTenant,
  (state: RdxStoreState) => state.tenants,
  getMyTenantTags,
  (tenant, tenants: RdxTenantsState, { byId, ids }) => {
    const { tenantTags, tagEditor: editingTag } = tenants;

    if (!tenant || !byId || !ids) {
      return { tree: undefined, loading: false };
    }

    byId = cloneDeep(byId);

    const status = tenantTags!.statusByTenantId[tenant.id];

    const tree = createTreeTags(ids, byId, editingTag);
    addEditingTagToTreeIfNewTag(tree, byId, editingTag);
    setNodesDepth(tree);

    return {
      tree,
      loading: Boolean(!status || status === RdxFetchStatus.LOADING)
    };
  }
);

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getTenantTagsTreeEditor: (tenant?: Pick<Tenant, "id">) => (state: RdxStoreState) => {
  tree: TagEditorNode[] | undefined;
  loading: boolean;
} = (tenant: Tenant) =>
  createSelector(
    (state: RdxStoreState) => state.tenants,
    getTenantTags(tenant),
    (tenants: RdxTenantsState, { byId, ids }) => {
      const { tenantTags, tagEditor: editingTag } = tenants;

      if (!tenant || !byId || !ids) {
        return { tree: undefined, loading: false };
      }

      byId = cloneDeep(byId);

      const status = tenantTags!.statusByTenantId[tenant.id];

      const tree = createTreeTags(ids, byId, editingTag);
      addEditingTagToTreeIfNewTag(tree, byId, editingTag);
      setNodesDepth(tree);

      return {
        tree,
        loading: Boolean(!status || status === RdxFetchStatus.LOADING)
      };
    }
  );
