export interface HttpClientErrorResponse {
  id: string;
  statusCode?: number;
  status?: string;
  message?: string;
  requestUrl?: string;
}

export type HttpClientResponse = undefined | null | {};

export function isHttpError(obj?: any): obj is HttpClientErrorResponse {
  return obj && Boolean(obj.statusCode);
}
