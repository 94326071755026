import { FilterFilled, FilterOutlined, FilterTwoTone, ReloadOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Input, Tooltip, Typography } from "antd";
import { ColumnProps } from "antd/es/table";
import { FilterDropdownProps } from "antd/es/table/interface";
import _ from "lodash";
import React from "react";
import { CreateColumnProps } from "./model";

// -------------------------------------------------------------------------------------------------
// - Column builder
// -------------------------------------------------------------------------------------------------

export const createNameColumn = ({ sort, paging, filters: { nameFilter } }: CreateColumnProps): ColumnProps<{}>[] => [
  {
    sorter: true,
    title: <Typography.Text>Name</Typography.Text>,
    sortOrder: _.isEqual(sort?.key, "name") ? sort?.order : undefined,
    dataIndex: "name",
    fixed: "left",
    width: 250,
    render: val => (
      <Tooltip title={val}>
        <Typography.Text ellipsis={true}>{val}</Typography.Text>
      </Tooltip>
    ),
    filterIcon: _.isEqual(nameFilter.value, nameFilter.defaultValue) ? (
      <FilterFilled />
    ) : (
      <FilterTwoTone twoToneColor="green" />
    ),
    filterDropdown: (props: FilterDropdownProps) => {
      const [status, setStatus] = React.useState(nameFilter.value);

      React.useEffect(() => {
        setStatus(_.isEmpty(nameFilter.value) ? null : nameFilter.value);
      }, [props.visible]);

      return (
        <Flex vertical gap={12} style={{ padding: 12 }}>
          <Input.Search
            enterButton
            size="small"
            placeholder="Search for name..."
            value={status ?? ""}
            onChange={e => setStatus(e.currentTarget.value)}
            onSearch={e => {
              paging.setFirstPage();
              nameFilter.setValue(_.isEmpty(e) ? null : e);
              props.close();
            }}
          />
          <Divider style={{ padding: 0, margin: 0 }} />
          <Flex gap={6} justify="end">
            <Button
              size="small"
              className="grow"
              icon={<ReloadOutlined />}
              onClick={() => {
                paging.setFirstPage();
                nameFilter.setValue(nameFilter.defaultValue);
                props.close();
              }}
            >
              Reset
            </Button>
            <Button
              size="small"
              type="primary"
              className="grow"
              icon={<FilterOutlined />}
              onClick={() => {
                paging.setFirstPage();
                nameFilter.setValue(status);
                props.close();
              }}
            >
              Apply
            </Button>
          </Flex>
        </Flex>
      );
    }
  }
];
