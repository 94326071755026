import * as Sentry from "@sentry/browser";
import { SYSTEM_CONFIG } from "../../../constants/system-config";
import { ErrorTrackingService } from "../types";

function init(sampleRate = 1) {
  Sentry.init({
    dsn: "https://b7d06f1f077c4e92b7486955651f835b@sentry.io/1762746",
    environment: SYSTEM_CONFIG.environment,
    sampleRate,
    release: SYSTEM_CONFIG.version
    // ...
  });
}

function notifyError(error: Error) {
  Sentry.captureException(error);
}

function notifyEvent(message: string, extra?: any) {
  Sentry.captureEvent({
    message,
    extra
  });
}

function setUser(id: string) {
  Sentry.withScope(scope => {
    scope.setUser({ id });
  });
}

function setContext(context: { [key: string]: any }) {
  Sentry.setExtras(context);
}

export const sentryErrorTracker: ErrorTrackingService = {
  init,
  setUser,
  setContext,
  notifyError,
  notifyEvent
};
