import _ from "lodash";
import { createSelector } from "reselect";
import { initialState } from "../../reducers/alerts-writer/constants";
import { AlertStatus, RdxAlertsWriterState } from "../../reducers/alerts-writer/types";
import { RdxStoreState } from "../../types/state";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getAlertStatusFilterValue: (state: RdxStoreState) => AlertStatus = createSelector(
  ({ alertsWriter: alerts }: RdxStoreState) => alerts,
  ({ filters }: RdxAlertsWriterState) => {
    return filters.status;
  }
);

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const isResetFilterDisabled: (state: RdxStoreState) => boolean = createSelector(
  ({ alertsWriter: alerts }: RdxStoreState) => alerts,
  ({ filters }: RdxAlertsWriterState) => {
    return _.isEqual(filters.status, initialState.filters.status);
  }
);

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const isApplyFilterDisabled: (state: RdxStoreState) => boolean = createSelector(
  ({ alertsWriter: alerts }: RdxStoreState) => alerts,
  ({ filters, activeFilters }: RdxAlertsWriterState) => {
    return _.isEqual(filters.status, activeFilters?.status);
  }
);
