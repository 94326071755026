import { DEFAULT_LIST_PAGE, DEFAULT_LIST_SIZE } from "../../../constants/lists";
import { RdxFetchStatus } from "../../types/status";
import { AlertStatus, RdxAlertsWriterState } from "./types";

export const initialState: RdxAlertsWriterState = {
  status: RdxFetchStatus.NONE,
  alerts: [],
  paging: {
    page: DEFAULT_LIST_PAGE,
    itemsPerPage: DEFAULT_LIST_SIZE
  },
  filters: {
    status: AlertStatus.ACTIVE
  },
  activeFilters: {
    status: AlertStatus.ACTIVE
  }
};
