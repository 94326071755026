import { SettingOutlined } from "@ant-design/icons";
import React from "react";
import { MESSAGES } from "../../../i18n";
import subMenuItemNotifications from "./MIAdminNotifications";
import subMenuItemPartners from "./MIAdminPartners";
import subMenuItemTags from "./MIAdminTags";
import subMenuItemTenants from "./MIAdminTenants";
import subMenuItemUsers from "./MIAdminUsers";

const key = "admin";
const qaLabel = "menu-item-settings";

// -------------------------------------------------------------------------------------------------
// - OwnProps
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  hasTenantsPermission: boolean | undefined;
  hasPartnersPermission: boolean | undefined;
  hasUsersPermission: boolean | undefined;
  hasTagsPermission: boolean | undefined;
  isSystemAdmin: boolean | undefined;
}

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default (props: OwnProps) =>
  Boolean(
    props.hasTenantsPermission || //
      props.hasPartnersPermission ||
      props.hasUsersPermission ||
      props.hasTagsPermission
  )
    ? [
        {
          key,
          icon: <SettingOutlined />,
          label: <span data-qa-label={qaLabel}>{MESSAGES.NavSettings}</span>,
          children: [
            ...subMenuItemTenants(Boolean(props.hasTenantsPermission)),
            ...subMenuItemPartners(Boolean(props.hasPartnersPermission)),
            ...subMenuItemUsers(Boolean(props.hasUsersPermission)),
            ...subMenuItemTags(Boolean(props.hasTagsPermission)),
            ...subMenuItemNotifications(Boolean(props.isSystemAdmin))
          ]
        }
      ]
    : [];
