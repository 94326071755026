export class TimeUtils {
  /**
   *
   */
  public static roundToMinutes(valueSecs: number) {
    const nearest60Multiple = Math.round(valueSecs / 60) * 60;
    return nearest60Multiple / 60;
  }

  /**
   *
   */
  public static minutesToHM(minutes: number) {
    if (minutes === 0) {
      return "0";
    }
    const value = minutes / 60;
    if (value < 1) {
      return `${Math.ceil(value * 60)}m`;
    }

    const decimalPart = value - Math.floor(value);
    if (decimalPart === 0) {
      return `${value}h`;
    }
    return `${Math.floor(value)}h${Math.ceil(decimalPart * 60)}m`;
  }

  /**
   *
   */
  public static formatter(val: string) {
    const value = parseFloat(val);
    return TimeUtils.minutesToHM(value);
  }
}
