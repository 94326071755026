import { Action } from "../types/action";

export const ANY_ACTION_TYPE = "ANY_ACTION_TYPE";

export const createReducer = <S>(initialState: S, mapping: { [type: string]: (state: S, payload: any) => S }) => {
  return (state = initialState, action: Action<any>): S => {
    const mappedReducer = mapping[action.type] || mapping[ANY_ACTION_TYPE];
    return mappedReducer === undefined ? state : mappedReducer(state, action.payload);
  };
};
