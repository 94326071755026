import { navApi } from "../../../../../services/nav-api";
import { getTaskBlobWhenReady } from "../../../../../services/task";
import { getCurDateForReportFilename } from "../../../../../utils/date-utils";
import { ReportParams, ReportResponse } from "../../types";

// -------------------------------------------------------------------------------------------------
// - Generator
// -------------------------------------------------------------------------------------------------

export const genExamTrackerReport = async (params: ReportParams): Promise<ReportResponse> => ({
  filename: `exam-tracker-${getCurDateForReportFilename()}.xlsx`,
  blob: await getTaskBlobWhenReady((await navApi.studentRoster.examTrackerReport(params)).taskToken)
});
