import React from "react";
import { useSelector } from "react-redux";

import _ from "lodash";
import { getMyTenant } from "../../../../redux/selectors/my-tenant";
import { TagNode } from "../../../../redux/selectors/my-tenant-tags-tree";
import { getTenantTagsTreeEditor } from "../../../../redux/selectors/tags-tree-editor";
import { RdxStoreState } from "../../../../redux/types/state";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const useTenantTagTree = () => {
  const filter = useSelector(({ studentsList: { workingFilter } }: RdxStoreState) => workingFilter);
  const myTenant = useSelector(getMyTenant);
  const tenantId = React.useMemo(() => filter.tenantId ?? myTenant?.id, [filter?.tenantId, myTenant?.id]);

  const { tree = [], loading } = useSelector(
    getTenantTagsTreeEditor(!_.isNil(tenantId) ? { id: tenantId } : undefined)
  );

  return {
    tenantId,
    loading,
    tree: tree as TagNode[]
  };
};
