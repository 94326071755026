import React from "react";
import { StudyPlansListItem } from "../../../../../services/nav-api/studyPlans/model";
import { Field, FromApiResponseValue, StudyPlanEditorValue, ToApiRequestValue } from "./model";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type FinalReviewFieldValue = StudyPlanEditorValue["finalReview"];
export type FinalReviewField = Field<
  FinalReviewFieldValue,
  {
    toApiRequestValue: ToApiRequestValue<boolean>;
    fromApiResponseValue: FromApiResponseValue<FinalReviewFieldValue>;
  }
>;

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useFinalReview = (initialValue: boolean): FinalReviewField => {
  const [value, setValue] = React.useState<FinalReviewFieldValue>(initialValue);
  return {
    value,
    setValue: (val: FinalReviewFieldValue) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(initialValue);
    },
    toApiRequestValue: () => {
      return value;
    },
    fromApiResponseValue: (rsp: StudyPlansListItem): FinalReviewFieldValue => {
      const iVal = !!rsp.settings.includeFR;
      setValue(iVal);
      return iVal;
    }
  };
};
