import { SaveOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Descriptions, Form, Space, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationToastsContext } from "../../components/AppToasts";
import { BaseLayout } from "../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../components/NavigationDrawer/types";
import { EMPTY_CELL_SYMBOL } from "../../components/TableCellRender";
import { MESSAGES } from "../../i18n";
import { doUpdateActiveUser } from "../../redux/actions/myself/updateActiveUser";
import { getActiveUser } from "../../redux/selectors/active-user";
import { getHasTenant } from "../../redux/selectors/has-tenant";
import { RdxStoreState } from "../../redux/types/state";
import { navApi } from "../../services/nav-api";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const MyAccount = () => {
  const dispatch = useDispatch<any>();
  const [unsubscribeAlerts, setUnsubscribeAlerts] = React.useState<boolean>(false);

  const myUser = useSelector(getActiveUser);
  const hasTenant = useSelector(getHasTenant);
  const userRoles = useSelector(({ myself: { userRoles } }: RdxStoreState) => userRoles ?? []);

  React.useEffect(() => {
    if (!_.isNil(myUser?.unsubscribeAlerts)) {
      setUnsubscribeAlerts(myUser?.unsubscribeAlerts);
    }
  }, [myUser?.unsubscribeAlerts]);

  // -----------------------------------------------------------------------------------------------
  // - Renderer
  // -----------------------------------------------------------------------------------------------

  return (
    <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.PROFILE_MYACCOUNT}>
      <Typography.Title level={2}>{MESSAGES.NavMyAccount}</Typography.Title>
      <Space direction="vertical" className="w-full">
        <Card title="Information">
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item label="First Name">{myUser ? myUser.firstName : EMPTY_CELL_SYMBOL}</Descriptions.Item>
            <Descriptions.Item label="Last Name">{myUser ? myUser.lastName : EMPTY_CELL_SYMBOL}</Descriptions.Item>
            <Descriptions.Item label="Email">{myUser ? myUser.username : EMPTY_CELL_SYMBOL}</Descriptions.Item>
            <Descriptions.Item label="Role">
              {myUser
                ? userRoles
                  ? userRoles.filter(r => r.id === myUser.roleId)[0].name
                  : EMPTY_CELL_SYMBOL
                : EMPTY_CELL_SYMBOL}
            </Descriptions.Item>
            {hasTenant ? (
              <Descriptions.Item label="Tenant">
                {myUser && myUser.tenant ? myUser.tenant.name : EMPTY_CELL_SYMBOL}
              </Descriptions.Item>
            ) : null}
          </Descriptions>
        </Card>

        <ApplicationToastsContext.Consumer>
          {api => (
            <Form
              id="notificationsForm"
              onFinish={async () => {
                try {
                  // Update the user settings
                  await navApi.myself.updateMySelfNotificationsSettings(unsubscribeAlerts);

                  // Fetch the user again to update the state
                  dispatch(doUpdateActiveUser({ unsubscribeAlerts }));

                  // Show a success message
                  api.success({
                    message: MESSAGES.Success,
                    description: MESSAGES.ChangeSettingsSuccess,
                    showProgress: true,
                    pauseOnHover: true
                  });
                } catch (err) {
                  api.error({
                    message: MESSAGES.Error,
                    description: err.message ? err.message : MESSAGES.UnknownError,
                    showProgress: true,
                    pauseOnHover: true
                  });
                }
              }}
            >
              <Card
                title={MESSAGES.Notifications}
                extra={
                  !_.isEqual(myUser?.unsubscribeAlerts, unsubscribeAlerts) ? (
                    <Button type="link" htmlType="submit" icon={<SaveOutlined />}>
                      {MESSAGES.Save}
                    </Button>
                  ) : null
                }
              >
                <Form.Item>
                  <Checkbox
                    checked={!unsubscribeAlerts}
                    onChange={e => {
                      setUnsubscribeAlerts(!e.target.checked);
                    }}
                  >
                    {MESSAGES.GetNotificationEmails}
                  </Checkbox>
                </Form.Item>
              </Card>
            </Form>
          )}
        </ApplicationToastsContext.Consumer>
      </Space>
    </BaseLayout>
  );
};

// -------------------------------------------------------------------------------------------------
// - Exports
// -------------------------------------------------------------------------------------------------

export default MyAccount;
