import { Typography } from "antd";
import React from "react";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarDayCellEndDate = () => (
  <li key="end-date">
    <Typography.Text className="text-xs text-white bg-[#004986] px-1" strong>
      End Date
    </Typography.Text>
  </li>
);
