import { RightOutlined } from "@ant-design/icons";
import { Card, Descriptions, Flex, Skeleton, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMyTenant } from "../../../../redux/selectors/my-tenant";
import { routes } from "../../../../routes";
import { SPStatusSteps } from "../../common/SPStatusSteps";
import { SPTenantTags } from "../../common/SPTenantTag";
import { StudyPlanContext } from "../hooks/useStudyPlanViewerData";

const NoWrapStyles: { style: React.CSSProperties } = {
  style: { whiteSpace: "nowrap" }
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlanSummary = () => {
  const myTenant = useSelector(getMyTenant);
  return (
    <StudyPlanContext.Consumer>
      {plan => (
        <Card className="w-full" title="Plan Summary">
          <Flex gap={24}>
            {!plan.loading ? (
              <div className="grow">
                <Descriptions column={1} size="small" bordered>
                  <Descriptions.Item {...NoWrapStyles} label="Name">
                    {plan.summary.name}
                  </Descriptions.Item>
                  <Descriptions.Item {...NoWrapStyles} label="Section">
                    {plan.summary.section}
                  </Descriptions.Item>
                  {_.isNil(myTenant?.id) ? (
                    <Descriptions.Item {...NoWrapStyles} label="Tenant">
                      <Flex gap={4} vertical>
                        <Link to={routes.tenant.resolve({ id: plan?.summary?.tenantId?.toString() ?? "" })}>
                          {plan?.summary?.tenantName}
                        </Link>
                        {!plan.studyPlanTenant?.hasStudyPlan ? (
                          <Typography.Text type="secondary" italic className="text-xs">
                            (Does not have access to this study plan due to tenant configuration)
                          </Typography.Text>
                        ) : null}
                      </Flex>
                    </Descriptions.Item>
                  ) : null}
                  <Descriptions.Item {...NoWrapStyles} label="Start/End Date">
                    <Typography.Text>{plan.summary.startDateFmt}</Typography.Text>
                    <RightOutlined />
                    <Typography.Text>{plan.summary.endDateFmt}</Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item {...NoWrapStyles} label={plan.summary.studyDurationTitle}>
                    {plan.summary.studyDuration}
                  </Descriptions.Item>
                  <Descriptions.Item {...NoWrapStyles} label="Tags">
                    <SPTenantTags tenantId={plan.summary.tenantId!} tagIds={plan.summary.tagIds} />
                  </Descriptions.Item>
                  <Descriptions.Item {...NoWrapStyles} label="Locked Until">
                    {plan.summary.lockUntilFmt}
                  </Descriptions.Item>
                  <Descriptions.Item {...NoWrapStyles} label="Created">
                    {plan.summary.created}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            ) : (
              <Skeleton />
            )}
            <div style={{ minWidth: 300, maxWidth: 300, width: 300 }}>
              <SPStatusSteps />
            </div>
          </Flex>
        </Card>
      )}
    </StudyPlanContext.Consumer>
  );
};
