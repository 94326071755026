import { DEFAULT_LIST_PAGE, DEFAULT_LIST_SIZE } from "../../../constants/lists";
import { Partner } from "../../../services/nav-api/partners/types";
import { Tenant } from "../../../services/nav-api/tenants/types";
import { Status } from "../../../services/nav-api/types";
import {
  DoChangeListSettingsPayload,
  FETCH_PARTNERS_COMPLETED,
  FETCH_PARTNERS_STARTED,
  FetchPartnersCompleted,
  PARTNERS_LIST_SETTINGS_CHANGED,
  UpdateWorkingFilterPayload,
  WORKING_PARTNERS_FILTER_CLEARED,
  WORKING_PARTNERS_FILTER_UPDATED,
  WorkingFilter
} from "../../actions/partners";
import { RdxFetchStatus } from "../../types/status";
import { createReducer } from "../../utils/redux-utils";

export interface RdxPartnersState {
  workingFilter: WorkingFilter;
  activeFilter?: {
    filter?: WorkingFilter;
    loading: boolean;
  };
  status: RdxFetchStatus;
  partners: Partner[] | undefined;
  tenants: Tenant[] | undefined;
  total: number;
  paging: {
    page: number;
    totalItems?: number;
    itemsPerPage: number;
  };
  error?: string;
}

export const initialState: RdxPartnersState = {
  status: RdxFetchStatus.NONE,
  partners: [],
  tenants: [],
  paging: {
    page: DEFAULT_LIST_PAGE,
    itemsPerPage: DEFAULT_LIST_SIZE
  },
  total: 0,
  workingFilter: {
    name: "",
    type: "All",
    status: Status.Enabled
  }
};

export default createReducer<RdxPartnersState>(initialState, {
  [WORKING_PARTNERS_FILTER_UPDATED]: (state, payload: UpdateWorkingFilterPayload) => ({
    ...state,
    workingFilter: { ...state.workingFilter, ...payload }
  }),
  [WORKING_PARTNERS_FILTER_CLEARED]: state => ({
    ...state,
    workingFilter: { ...initialState.workingFilter }
  }),
  [FETCH_PARTNERS_STARTED]: state => ({
    ...state,
    status: RdxFetchStatus.LOADING,
    error: undefined,
    activeFilter: {
      ...(state.activeFilter || {}),
      loading: true
    }
  }),
  [FETCH_PARTNERS_COMPLETED]: (state, { error, partners, tenants, filter, total }: FetchPartnersCompleted) => {
    if (error) {
      return {
        ...state,
        status: RdxFetchStatus.FAILED,
        error: error.message,
        total: 0,
        activeFilter: {
          ...state.activeFilter,
          loading: false
        }
      };
    }
    return {
      ...state,
      partners,
      tenants,
      status: RdxFetchStatus.COMPLETED,
      total,
      activeFilter: {
        filter,
        loading: false
      }
    };
  },
  [PARTNERS_LIST_SETTINGS_CHANGED]: (
    state,
    { paging: { page, pageSize: itemsPerPage } }: DoChangeListSettingsPayload
  ) => ({
    ...state,
    paging: {
      ...state.paging,
      page,
      itemsPerPage
    }
  })
});
