import { TenantProduct } from "../../../services/nav-api/tenants/types";
import { ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doChangeActiveProduct = (activeProduct: TenantProduct): ThunkAction => {
  return async (_, getState: () => RdxStoreState, { navApi }): Promise<void> => {
    try {
      const settings = { ...getState().myself.userSettings, activeProduct };
      await navApi.myself.updateMySelfSettings(settings);
    } catch (error) {
      console.error(error);
    } finally {
      window.location.assign("/");
    }
  };
};
