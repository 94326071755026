import { createSelector } from "reselect";
import { doFetchStudentDetails } from "../actions/student-details/student-details";
import { ThunkAction } from "../types/action";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";

export const reRefreshStudentDetails: (state: RdxStoreState) => ThunkAction | undefined = createSelector(
  (state: RdxStoreState) => state.studentDetails,
  (studentDetails): ThunkAction | undefined => {
    if (studentDetails.status == RdxFetchStatus.STALE) {
      return doFetchStudentDetails();
    }

    return undefined;
  }
);
