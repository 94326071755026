import { CloseOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Space } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { DatePickerButton } from "../../../../components/DatePickerButton";
import { DateRangePickerButton } from "../../../../components/DateRangePickerButton";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const TimeOffField = () => (
  <StudyPlanEditorContext.Consumer>
    {({ editor: { daysOff }, simulations }) => (
      <Form.Item>
        <Flex vertical gap={12}>
          <Space>
            <DatePickerButton
              onChange={val => {
                simulations.enableSimulations();
                daysOff.addDate(val);
              }}
            >
              Add a day off
            </DatePickerButton>
            <DateRangePickerButton
              onChange={(dates: [dayjs.Dayjs, dayjs.Dayjs]) => {
                daysOff.addDateRange(dates?.[0], dates?.[1]);
              }}
            >
              Add a range of days off
            </DateRangePickerButton>
          </Space>
          <Space>
            {daysOff.getDateRanges().map((r, i) => (
              <Button
                key={i}
                icon={<CloseOutlined />}
                onClick={() => {
                  simulations.enableSimulations();
                  daysOff.removeRange(r);
                }}
              >
                {_.isEqual(r.start.toISOString(), r.end.toISOString())
                  ? `${r.start.format("MM/DD/YYYY")}`
                  : `${r.start.format("MM/DD/YYYY")} - ${r.end.format("MM/DD/YYYY")}`}
              </Button>
            ))}
          </Space>
        </Flex>
      </Form.Item>
    )}
  </StudyPlanEditorContext.Consumer>
);
