import { Flex } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { doChangeCurrentStudent, doFetchStudentDetails } from "../../redux/actions/student-details/student-details";
import { StudentAffiliation } from "./StudentAffiliation";
import { StudentDetails } from "./StudentDetails";
import { StudentExamTracker } from "./StudentExamTracker";
import { StudentPerformance } from "./StudentPerformance";
import { StudentStudyTime } from "./StudentStudyTime";

// -------------------------------------------------------------------------------------------------
// - OwnProps
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  id: number;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Student = ({ id }: OwnProps) => {
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(doChangeCurrentStudent(id));
    dispatch(doFetchStudentDetails());
  }, [id]);

  return (
    <Flex vertical gap={8}>
      <StudentDetails />
      <StudentAffiliation />
      <StudentStudyTime />
      <StudentPerformance />
      <StudentExamTracker />
    </Flex>
  );
};
