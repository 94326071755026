import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ApplicationToastsContext } from "../../../../components/AppToasts";
import { fetchTenantsDropdown } from "../../../../redux/actions/tenants/tenants-dropdown";
import { getTenantsDropdownDataSource } from "../../../../redux/selectors/tenants-dropdown-data-source";
import { navApi } from "../../../../services/nav-api";
import { StudyPlanCalendar, StudyPlansListItem, StudyPlanTask } from "../../../../services/nav-api/studyPlans/model";
import { useStudyPlanData } from "./useStudyPlanData";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type StudyPlanValue = {
  loading: boolean;
  loadingStatus: boolean;
  id?: number;
  tasks?: { [key: string]: StudyPlanTask };
  calendar?: StudyPlanCalendar<dayjs.Dayjs>[];
  reloadTs: number | null;
  error?: {
    message: string;
    status: string;
  };
  remoteStudyPlan: StudyPlansListItem | null;
  summary: {
    startDate?: dayjs.Dayjs;
    startDateFmt: string;
    endDate?: dayjs.Dayjs;
    endDateFmt: string;
    daysOff?: dayjs.Dayjs[];
    created: string;

    lockUntil: dayjs.Dayjs | null;
    lockUntilFmt: string;

    name: string;
    section: string;
    tags: string[];
    studyDurationTitle: string;
    studyDuration: number | null;
    tenantId?: number;
    tenantName?: string;
    tagIds?: number[];
    status?: StudyPlansListItem["status"];
    range?: [dayjs.Dayjs, dayjs.Dayjs];
  };

  studyPlanTenant?: {
    id: number;
    name: string;
    hasStudyPlan: boolean;
  };
  onStatusChanged: (status: StudyPlansListItem["status"]) => void;
  forceReload: () => void;
};

// -------------------------------------------------------------------------------------------------
// - Context
// -------------------------------------------------------------------------------------------------

export const StudyPlanContext = React.createContext<StudyPlanValue>({
  loading: true,
  loadingStatus: true,
  onStatusChanged: _.noop,
  forceReload: _.noop,
  remoteStudyPlan: null,
  reloadTs: null,
  summary: {
    created: "",
    endDateFmt: "",
    lockUntil: null,
    lockUntilFmt: "",
    name: "",
    section: "",
    startDateFmt: "",
    tags: [],
    studyDurationTitle: "",
    studyDuration: null
  }
});

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useStudyPlanViewer = (): StudyPlanValue => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = React.useState<boolean>(false);
  const [remoteStudyPlan, setRemoteStudyPlan] = React.useState<StudyPlansListItem | null>(null);
  const [error, setError] = React.useState<StudyPlanValue["error"]>();

  const toastsApi = React.useContext(ApplicationToastsContext);
  const params = useParams<{ id?: string }>();
  const studyPlanData = useStudyPlanData(remoteStudyPlan);
  const [reloadTs, setReloadTs] = React.useState<number | null>(null);

  // -----------------------------------------------------------------------------------------------
  // - Handle StudyPlans Cache
  // -
  const dispatch = useDispatch<any>();
  const tenantsList = useSelector(getTenantsDropdownDataSource);
  const [studyPlanTenant, setStudyPlanTenant] = React.useState<
    | {
        id: number;
        name: string;
        hasStudyPlan: boolean;
      }
    | undefined
  >();

  React.useEffect(() => {
    dispatch(fetchTenantsDropdown());
  }, []);

  React.useEffect(() => {
    if (_.gt(studyPlanData?.summary?.tenantId, 0)) {
      setStudyPlanTenant(
        _.chain(tenantsList) //
          .filter({ id: studyPlanData?.summary?.tenantId })
          .head()
          .value()
      );
    }
  }, [studyPlanData?.summary?.tenantId, JSON.stringify(tenantsList)]);

  // -----------------------------------------------------------------------------------------------
  // - Callbacks: Fetch Studyplans
  // -
  const fetchStudyPlan = React.useCallback(async (studyPlanId: number) => {
    try {
      setLoading(true);
      setRemoteStudyPlan(await navApi.studyPlans.fetchStudyPlanById(studyPlanId));
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  // -----------------------------------------------------------------------------------------------
  // - Effects
  // -
  React.useEffect(() => {
    if (!_.isNil(params.id)) {
      fetchStudyPlan(parseInt(params.id));
    }
  }, [params.id, reloadTs]);

  // -----------------------------------------------------------------------------------------------
  // - Ret val
  // -
  return {
    ...studyPlanData,
    remoteStudyPlan,
    loading: loading,
    loadingStatus,
    error,
    studyPlanTenant,
    reloadTs,
    forceReload: () => setReloadTs(new Date().getTime()),
    onStatusChanged: (status: StudyPlansListItem["status"]) => {
      if (!_.isNil(remoteStudyPlan?.id)) {
        (async () => {
          try {
            setLoadingStatus(true);
            await navApi.studyPlans.updateStudyPlansStatus(remoteStudyPlan?.id, status);

            setRemoteStudyPlan({ ...remoteStudyPlan, status });
          } catch (e) {
            toastsApi.error({
              message: "Error",
              description: e.message ?? e.title ?? "Unknown error"
            });
          } finally {
            setLoadingStatus(false);
          }
        })();
      }
    }
  };
};
