import dayjs from "dayjs";
import _ from "lodash";
import { StudyPlanTask } from "../../../../../../services/nav-api/studyPlans/model";
import { StudyPlanValue } from "../../../hooks/useStudyPlanViewerData";
import { CalendarDayValue } from "./CalendarDayContext";

// -------------------------------------------------------------------------------------------------
// - Utils
// -------------------------------------------------------------------------------------------------

export class CalendarCellUtils {
  private static findTasksIn(day: dayjs.Dayjs, plan: StudyPlanValue): Array<StudyPlanTask & { quarterHours?: number }> {
    return _.chain(plan.calendar)
      .filter(ical => day.isSame(ical.day, "day"))
      .map(ical =>
        _.chain(ical.placements)
          .map(placement => ({ ...plan?.tasks?.[placement.taskId], quarterHours: placement.quarterHours }))
          .value()
      )
      .flattenDeep()
      .compact()
      .value() as Array<StudyPlanTask & { quarterHours?: number }>;
  }

  private static isStartDate(day: dayjs.Dayjs, plan: StudyPlanValue) {
    return day.isSame(plan.summary.startDate, "day");
  }

  private static isEndDate(day: dayjs.Dayjs, plan: StudyPlanValue) {
    return day.isSame(plan.summary.endDate, "day");
  }

  private static isDayOff(day: dayjs.Dayjs, plan: StudyPlanValue) {
    return _.some(plan.summary.daysOff, dayOff => dayOff.isSame(day, "day"));
  }

  public static calculateDateInfo(day: dayjs.Dayjs, plan: StudyPlanValue): CalendarDayValue {
    return {
      day,
      isStartDate: this.isStartDate(day, plan),
      isEndDate: this.isEndDate(day, plan),
      isDayOff: this.isDayOff(day, plan),
      tasks: this.findTasksIn(day, plan),
      calendar: plan.calendar!
    };
  }
}
