import { Alert } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { doDiscardOperationError } from "../../../../redux/actions/alerts-writer/operation";
import { getOperationError } from "../../../../redux/selectors/alerts-writer/operation";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertError = () => {
  const dispatch = useDispatch<any>();
  const error = useSelector(getOperationError);
  return error ? (
    <div style={{ marginBottom: "24px" }}>
      <Alert
        message={error}
        type="error"
        closable
        onClose={() => {
          dispatch(doDiscardOperationError());
        }}
      />
    </div>
  ) : null;
};
