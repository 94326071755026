import { useDispatch, useSelector } from "react-redux";
import { formatMsg, MESSAGES } from "../../..//i18n";
import { FilterTag } from "../../../core/model";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../redux/actions/students-list";
import { initialState } from "../../../redux/reducers/students-list";
import { RdxStoreState } from "../../../redux/types/state";
import { format } from "../../../utils/date-utils";

// -------------------------------------------------------------------------------------------------
// - Hooks: usePurchasedDateFilter
// -------------------------------------------------------------------------------------------------

export const usePurchasedDateFilter = (): FilterTag[] => {
  const dispatch = useDispatch<any>();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);
  return activeFilter?.filter?.purchasedDate?.startDate
    ? [
        {
          name: "purchaseDate",
          onClear: () => {
            dispatch(doUpdateWorkingFilter({ purchasedDate: initialState.workingFilter.purchasedDate }));
            dispatch(doApplyFilter());
          },
          text: formatMsg(
            MESSAGES.FilterTagPurDate,
            `${format(activeFilter.filter.purchasedDate.startDate, "P")} - ${format(
              activeFilter.filter.purchasedDate.endDate!,
              "P"
            )}`
          )
        }
      ]
    : [];
};
