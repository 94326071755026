import { Card, Empty, Flex, Typography } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { AltID, sectionColorByAltId } from "../../../constants/courses";
import { MESSAGES } from "../../../i18n";
import { DailyStudyTimeResponse } from "../../../services/external-api";
import { TimeUtils } from "./timeUtils";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

type ChartData = Array<{ studyTime: number; unitAltId: string }>;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const ANTDBarChart = ({
  rawData,
  loading,
  section
}: {
  rawData: DailyStudyTimeResponse | undefined;
  loading: boolean;
  section?: AltID;
}) => {
  const [data, setData] = useState<ChartData>([]);
  const [totalStudyTime, setTotalStudyTime] = useState(0);

  useEffect(() => {
    if (!rawData || !section) {
      return;
    }

    const sectionData = _.chain(rawData.sections) //
      .filter({ sectionAltId: section })
      .head()
      .value();

    const rawUnits = sectionData?.units || [];
    const weekData = rawUnits.map(unit => ({
      studyTime: TimeUtils.roundToMinutes(unit.secs),
      unitAltId: unit.unitAltId
    })) as ChartData;

    const unitsTotalMinutes = weekData.reduce((a, b) => a + b.studyTime, 0);
    let sectionTotalMinutes = TimeUtils.roundToMinutes(sectionData?.secs || 0);

    if (unitsTotalMinutes > sectionTotalMinutes) {
      sectionTotalMinutes = unitsTotalMinutes;
    }

    setTotalStudyTime(sectionTotalMinutes);

    setData(weekData);
  }, [loading, rawData, section]);

  const showNoData = !loading && data.reduce((a, b) => a + b.studyTime, 0) === 0;
  return (
    <Card classNames={{ body: "h-full" }} className="grow">
      <Flex vertical align="center" justify="space-between" gap={4}>
        <Typography.Text type="secondary" className="w-full">
          {MESSAGES.UnitStudyTime.toUpperCase()}
        </Typography.Text>
        {showNoData && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {!showNoData && data.length > 0 && (
          <>
            <Flex gap={4}>
              <Typography.Text strong>{TimeUtils.minutesToHM(totalStudyTime)}</Typography.Text>
              <Typography.Text>{MESSAGES.TotalTime}</Typography.Text>
            </Flex>
            <ResponsiveContainer width="100%" height="100%" minHeight={220} maxHeight={220}>
              <BarChart margin={{ top: 10 }} data={data}>
                <XAxis
                  dataKey="unitAltId"
                  tick={{
                    fontSize: 12,
                    fontFamily: "Arial, sans-serif",
                    fill: sectionColorByAltId[section!]
                  }}
                />
                <YAxis
                  dataKey="studyTime"
                  tickCount={Math.min(Math.max(...data.map(d => d.studyTime)) + 1, 5)}
                  tickFormatter={TimeUtils.formatter}
                  tick={{
                    fontSize: 12,
                    fontFamily: "Arial, sans-serif",
                    fill: sectionColorByAltId[section!]
                  }}
                />
                <Bar dataKey="studyTime" fill={sectionColorByAltId[section!]} />
                <Tooltip
                  labelFormatter={value => `${MESSAGES.Unit}: ${value}`}
                  formatter={value => [`${MESSAGES.StudyTime}: ${TimeUtils.formatter(value.toString())}`]}
                />
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
      </Flex>
    </Card>
  );
};
