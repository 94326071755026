import { DataNode } from "antd/es/tree";
import { createSelector } from "reselect";
import { createTagsTree } from "../../utils/tree-utils/create-tree";
import { filterTree } from "../../utils/tree-utils/filter-tree";
import { RdxTenantsState } from "../reducers/tenants";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";
import { getMyTenant } from "./my-tenant";
import { getMyTenantTags } from "./tenant-tags";

export interface TagNode extends DataNode {
  id: number;
  name: string;
  enrolledStudentCount: number;
  children: TagNode[];
  parentId?: number;
}

export const getHasRestrictedUserTags = createSelector(
  (state: RdxStoreState) => state.tenants.tags?.restrictedIds,
  restrictedIds => !!restrictedIds
);

export const getMyTenantTagsTree: (state: RdxStoreState) => { tree: TagNode[] | null; loading: boolean } =
  createSelector(
    getMyTenant,
    (state: RdxStoreState) => state.tenants,
    getMyTenantTags,
    (tenant, { tenantTags, tags }: RdxTenantsState, { byId, ids }) => {
      if (!tenant || !byId || !ids || !tags || tags.status !== RdxFetchStatus.COMPLETED) {
        return { tree: null, loading: false };
      }

      let tree = createTagsTree<TagNode>(byId, ids);
      if (tags.restrictedIds) {
        tree = filterTree(tree, tags.restrictedIds);
      }

      const status = tenantTags!.statusByTenantId[tenant.id];

      return {
        tree,
        loading: Boolean(!status || status === RdxFetchStatus.LOADING)
      };
    }
  );
