import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { StudyPlansListItem } from "../../../../../services/nav-api/studyPlans/model";
import { Field, FromApiResponseValue, StudyPlanEditorValue, ToApiRequestValue } from "./model";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type LockUntilFieldValue = StudyPlanEditorValue["lockUntil"];
export type LockUntilField = Field<
  LockUntilFieldValue,
  {
    toApiRequestValue: ToApiRequestValue<string>;
    fromApiResponseValue: FromApiResponseValue<LockUntilFieldValue>;
  }
>;

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useLockUntil = (): LockUntilField => {
  const [value, setValue] = React.useState<LockUntilFieldValue>(null);
  return {
    value,

    setValue: (val: LockUntilFieldValue) => {
      setValue(val);
    },

    resetValue: () => {
      setValue(null);
    },

    toApiRequestValue: (): string => {
      return value?.format("YYYY-MM-DD") ?? "";
    },

    fromApiResponseValue: (rsp: StudyPlansListItem): LockUntilFieldValue => {
      let iVal = null;
      if (!_.isNil(rsp.lockUntil)) {
        iVal = dayjs(rsp.lockUntil);
        setValue(iVal);
      }
      return iVal;
    }
  };
};
