import { WorkingFilterTypeWithoutAll } from "../../../redux/actions/partners";
import { Status } from "../types";

export type Tenant = {
  id: number;
  name: string;
  status: Status;
  created: number;
  products: TenantProduct[];
  type: WorkingFilterTypeWithoutAll;
  hasTagForm: boolean;
  hasStudyPlan: boolean;
};

export type GetTenantsResponse = {
  tenants: Tenant[];
  total: number;
};

export type PostTenantsResponse = {
  tenantId: number;
};

export type GetTenantStudentEntryTagsResponse = {
  activeRootTags: Array<{
    id: number;
    name: string;
    minSelections: number;
    maxSelections: number;
  }>;
  availableRootTags: Array<{ id: number; name: string }>;
  mode: "off" | "on" | "staffOnly";
};

export type PutTenantStudentEntryTagsRequest = {
  activeRootTags: Array<{
    id: number;
    minSelections: number;
    maxSelections: number;
  }>;
  mode: GetTenantStudentEntryTagsResponse["mode"];
};

export type Tag = {
  id: number;
  name: string;
  parentId?: number;
  enrolledStudentCount: number;
};

export type GetTenantTagsResponse = {
  tags: Tag[];
};

export enum TenantProduct {
  CMA = "cma",
  CPA = "cpa"
}
