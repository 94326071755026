import { TenantProduct } from "../services/nav-api/tenants/types";

export enum AltID {
  A = "A",
  B = "B",
  F = "F",
  R = "R",
  BAR = "BAR",
  ISC = "ISC",
  TCP = "TCP",
  P1 = "P1",
  P2 = "P2"
}

export const sectionNameByAltId: { [key in AltID]: string } = {
  A: "AUD",
  B: "BEC",
  F: "FAR",
  R: "REG",
  BAR: "BAR",
  ISC: "ISC",
  TCP: "TCP",
  P1: "Part 1",
  P2: "Part 2"
};

export const sectionColorByAltId: { [key in AltID]: string } = {
  F: "#004986",
  A: "#DC6B2F",
  R: "#87657A",
  B: "#168E95",
  BAR: "#146D86",
  ISC: "#E39500",
  TCP: "#A848A8",
  P1: "#D93717",
  P2: "#660066"
};

export const altIdsByProduct: { [key in TenantProduct]: AltID[] } = {
  cpa: [AltID.F, AltID.A, AltID.R, AltID.B, AltID.BAR, AltID.ISC, AltID.TCP],
  cma: [AltID.P1, AltID.P2]
};

export const sectionAltIdsOrder: AltID[] = [
  AltID.F,
  AltID.A,
  AltID.R,
  AltID.B,
  AltID.BAR,
  AltID.ISC,
  AltID.TCP,
  AltID.P1,
  AltID.P2
];
