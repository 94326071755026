import _ from "lodash";
import { altIdsByProduct, sectionNameByAltId } from "../../../../../constants/courses";
import { formatMsg, MESSAGES } from "../../../../../i18n";
import { TenantProduct } from "../../../../../services/nav-api/tenants/types";

// -------------------------------------------------------------------------------------------------
// - Hook: useOptions
// -------------------------------------------------------------------------------------------------

export class ExamTrackerOptionUtils {
  public static getOptionsByProduct(product: TenantProduct) {
    return [
      {
        label: MESSAGES.Any,
        value: "any"
      },
      ..._.map(altIdsByProduct[product], altId => ({
        label: formatMsg(MESSAGES.PassedSection, sectionNameByAltId[altId]),
        value: altId
      })),
      {
        label: MESSAGES.PassedAllParts,
        value: "passed_all_parts"
      },
      {
        label: MESSAGES.PassedAnyPart,
        value: "passed_any_part"
      },
      ...(_.isEqual(product, "cpa")
        ? [
            {
              label: MESSAGES.AboutToLoseOrLostCredits,
              value: "about_to_lose_or_lost_credits"
            }
          ]
        : []),
      {
        label: MESSAGES.NoExamTrackerData,
        value: "no_data"
      }
    ];
  }

  public static getLabelByValue(product: TenantProduct) {
    return Object.fromEntries(this.getOptionsByProduct(product).map(item => [item.value, item.label]));
  }
}
