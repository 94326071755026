import dayjs from "dayjs";
import React from "react";
import { StudyPlansListItem } from "../../../../../services/nav-api/studyPlans/model";
import { Field, FromApiResponseValue, StudyPlanEditorValue, ToApiRequestValue } from "./model";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type StartDateFieldValue = StudyPlanEditorValue["startDate"];
export type StartDateField = Field<
  StartDateFieldValue,
  {
    toApiRequestValue: ToApiRequestValue<string>;
    fromApiResponseValue: FromApiResponseValue<StartDateFieldValue>;
  }
>;

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useStartDate = (): StartDateField => {
  const [value, setValue] = React.useState<StartDateFieldValue>(dayjs());
  return {
    value,

    setValue: (val: StartDateFieldValue) => {
      setValue(val);
    },

    resetValue: () => {
      setValue(dayjs());
    },

    toApiRequestValue: (): string => {
      return value?.format("YYYY-MM-DD") ?? "";
    },

    fromApiResponseValue: (rsp: StudyPlansListItem): StartDateFieldValue => {
      const iVal = dayjs(rsp.settings.startDate);
      setValue(iVal);
      return iVal;
    }
  };
};
