import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Input, Space, Spin, Tree } from "antd";
import { DataNode } from "antd/es/tree";
import _ from "lodash";
import RCTree from "rc-tree";
import React, { RefObject, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../i18n";
import { doFetchTenantTags } from "../../redux/actions/tenants/tags";
import { getMyTenant } from "../../redux/selectors/my-tenant";
import { getTenantTagsTreeEditor, TagEditorNode } from "../../redux/selectors/tags-tree-editor";
import { TagsEditorContext, TagsEditorContextProps } from "./context";
import { TagContextMenu } from "./ContextMenu";
import { useTagsEditorHook } from "./hooks";

// -------------------------------------------------------------------------------------------------
// - useTenantTags
// -------------------------------------------------------------------------------------------------

const useTenantTags = (tenantId: number | undefined) => {
  const dispatch = useDispatch<any>();

  React.useEffect(() => {
    if (_.gt(tenantId, 0)) {
      dispatch(doFetchTenantTags(tenantId!));
    }
  }, [tenantId]);
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const EditModeNode = ({ name }: TagEditorNode) => {
  const [value, setValue] = React.useState(name);
  const contextProps: TagsEditorContextProps = React.useContext(TagsEditorContext);
  return (
    <form
      onSubmit={e => {
        e.preventDefault();

        if (value === name || !value || !value.trim()) {
          return;
        }
        contextProps.onSubmitEdit(value);
      }}
    >
      <Input
        size="small"
        placeholder={MESSAGES.TagNamePlaceholder}
        value={value}
        onChange={e => setValue(e.target.value)}
        autoFocus
        onBlur={contextProps.onCancelEdit}
        type="text"
      />
    </form>
  );
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TagsEditor = (props: { tenantId?: number; onRefresh?: (ts: number) => void }) => {
  useTenantTags(props.tenantId);

  const treeRef = useRef<RCTree>(null);
  const tenant = _.gt(props.tenantId, 0) ? { id: props.tenantId! } : useSelector(getMyTenant);
  const { loading, tree = [] } = useSelector(getTenantTagsTreeEditor(tenant));
  const tagsManagerProviderValue = useTagsEditorHook(tenant!.id, treeRef as RefObject<RCTree>);
  const walkTree = React.useCallback(
    (tags: Array<TagEditorNode & DataNode>): Array<TagEditorNode & DataNode> =>
      _.reduce(tags, (a, t) => [...a, { ...t, key: t.id as React.Key, children: walkTree(t.children) }], []),
    []
  );

  React.useEffect(() => {
    if (!_.isNil(props.onRefresh) && _.gt(tagsManagerProviderValue.ts, 0)) {
      props.onRefresh(tagsManagerProviderValue.ts!);
    }
  }, [tagsManagerProviderValue.ts]);

  if (loading || !tree) {
    return <Spin indicator={<LoadingOutlined />} />;
  }

  return (
    <TagsEditorContext.Provider value={tagsManagerProviderValue}>
      <Space direction="vertical">
        <Tree
          ref={treeRef}
          treeData={walkTree(tree)}
          autoExpandParent
          selectable={false}
          showLine={{
            showLeafIcon: false
          }}
          draggable
          expandedKeys={tagsManagerProviderValue.expandedIds}
          onExpand={tagsManagerProviderValue.setExpandedIds}
          titleRender={node => (
            <TagsEditorContext.Consumer>
              {({ loadingByTagId, isCreatingTag, totalEnrollmentsByTagId }: TagsEditorContextProps) => {
                const loading = !node.id ? isCreatingTag : loadingByTagId[node.id];
                return (
                  <Flex align="center">
                    {node.edit ? (
                      <EditModeNode {...node} />
                    ) : (
                      <TagContextMenu
                        id={node.id!}
                        name={node.name}
                        depthLevel={node.depthLevel!}
                        hasChildren={Boolean(node.children?.length)}
                        enrolledStudentsCount={node.enrolledStudentCount}
                        totalEnrolledStudentsCount={totalEnrollmentsByTagId[node.id!]}
                      >
                        <span data-qa-label="tree-node" data-qa-treenodeid={node.id}>
                          {node.name}
                        </span>
                      </TagContextMenu>
                    )}

                    {loading ? <Spin indicator={<LoadingOutlined spin className="font-xs" />} /> : null}
                  </Flex>
                );
              }}
            </TagsEditorContext.Consumer>
          )}
          onDrop={info => {
            tagsManagerProviderValue.onDrop(
              parseInt(info.dragNode.key as string),
              parseInt((info.node as any).props.eventKey!)
            );
          }}
        />

        <Button
          icon={<PlusOutlined />}
          data-qa-label="add-category-btn"
          size="small"
          onClick={() => {
            tagsManagerProviderValue.onCreate();
          }}
        >
          {MESSAGES.AddNewRootLevelTag}
        </Button>
      </Space>
    </TagsEditorContext.Provider>
  );
};
