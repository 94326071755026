import React from "react";
import { Field } from "./model";

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useStudyTimeWeek = (initValue?: number): Field<number | undefined> => {
  const [value, setValue] = React.useState<number | undefined>(initValue);
  return {
    value,
    setValue: (val: number) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(initValue);
    }
  };
};
