import dayjs from "dayjs";
import qString from "qs";
import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import {
  CreateStudyPlanRQ,
  GetSimulatedStudyPlan,
  GetSimulatedStudyPlanRSP,
  GetStudyPlansListRQ,
  GetStudyPlansListRSP,
  StudyPlansListItem
} from "./model";

// -------------------------------------------------------------------------------------------------
// - Api Methods
// -------------------------------------------------------------------------------------------------

export const fetchStudyPlanById = async (studyPlanId: number | string): Promise<StudyPlansListItem> =>
  HttpClient.get(`/rest/admin/studyPlans/${studyPlanId}`, createHeaders());

export const fetchStudyPlansList = async (params: Partial<GetStudyPlansListRQ> = {}): Promise<GetStudyPlansListRSP> =>
  HttpClient.get("/rest/admin/studyPlans", createHeaders(), params, { skipNulls: true, arrayFormat: "comma" });

export const fetchSimulatedStudyPlan = async (params: GetSimulatedStudyPlan): Promise<GetSimulatedStudyPlanRSP> => {
  return HttpClient.get("/rest/admin/studyPlans/simulation", createHeaders(), params, {
    arrayFormat: "comma",
    skipNulls: true,
    encode: true,
    allowEmptyArrays: false
  });
};

export const createStudyPlan = async (tenantId: number, data: CreateStudyPlanRQ): Promise<{ id: number }> => {
  return HttpClient.post(`/rest/admin/studyPlans?${qString.stringify({ tenantId })}`, createHeaders(), data);
};

export const editStudyPlan = async (studyPlanId: number, data: CreateStudyPlanRQ): Promise<{ id: number }> =>
  HttpClient.put(`/rest/admin/studyPlans/${studyPlanId}`, createHeaders(), data);

export const updateStudyPlansStatus = async (
  studyPlanId: number,
  status: "draft" | "published" | "archived"
): Promise<{ id: number }> =>
  HttpClient.put(`/rest/admin/studyPlans/${studyPlanId}/status`, createHeaders(), { status });

// -------------------------------------------------------------------------------------------------
// - Custom Tasks: Create task
// -------------------------------------------------------------------------------------------------

export const createCustomTask = async (
  studyPlanId: number | string,
  name: string,
  quarterHours: number,
  date: dayjs.Dayjs
): Promise<{ id: number }> => {
  return await HttpClient.post(`/rest/admin/studyPlans/${studyPlanId}/customTasks`, createHeaders(), {
    name,
    quarterHours,
    date: date.toISOString()
  });
};

// -------------------------------------------------------------------------------------------------
// - Custom Tasks: Update task
// -------------------------------------------------------------------------------------------------

export const updateCustomTask = async (
  studyPlanId: number | string,
  id: string,
  name: string,
  quarterHours: number,
  date: dayjs.Dayjs
): Promise<void> => {
  return await HttpClient.put(`/rest/admin/studyPlans/${studyPlanId}/customTasks/${id}`, createHeaders(), {
    name,
    quarterHours,
    date: date.toISOString()
  });
};

// -------------------------------------------------------------------------------------------------
// - Custom Tasks: Delete task
// -------------------------------------------------------------------------------------------------

export const deleteCustomTask = async (studyPlanId: number | string, taskId: string): Promise<void> => {
  return await HttpClient.del(`/rest/admin/studyPlans/${studyPlanId}/customTasks/${taskId}`, createHeaders());
};
