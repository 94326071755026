import { Affix, Button, Card, Form, Select } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { $enum } from "ts-enum-util";
import { ScrollableContentDivId } from "../../../../components/BaseLayout";
import { MESSAGES } from "../../../../i18n";
import {
  doApplyAlertsFilter,
  doResetAlertsFilter,
  doSetAlertsFilter
} from "../../../../redux/actions/alerts-writer/filters";
import { AlertStatus } from "../../../../redux/reducers/alerts-writer/types";
import {
  getAlertStatusFilterValue,
  isApplyFilterDisabled,
  isResetFilterDisabled
} from "../../../../redux/selectors/alerts-writer/filters";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Filters = () => {
  const dispatch = useDispatch<any>();
  const status = useSelector(getAlertStatusFilterValue);
  const resetFilterDisabled = useSelector(isResetFilterDisabled);
  const applyFilterDisabled = useSelector(isApplyFilterDisabled);

  return (
    <Affix target={() => document.querySelector<HTMLElement>(`#${ScrollableContentDivId}`)}>
      <Card>
        <Form layout="vertical">
          <Form.Item label="Status">
            <Select
              value={status}
              className="w-full"
              getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
              onChange={(status: AlertStatus) => {
                dispatch(doSetAlertsFilter({ status }));
              }}
            >
              {_.map($enum(AlertStatus).getValues(), status => (
                <Select.Option key={status} value={status}>
                  {_.capitalize(_.toLower(status))}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="flex w-full gap-x-2">
            <Button
              data-qa-label="clear-btn"
              disabled={resetFilterDisabled}
              htmlType="button"
              onClick={() => {
                dispatch(doResetAlertsFilter());
              }}
            >
              {MESSAGES.AlertsFiltersReset}
            </Button>
            <Button
              data-qa-label="submit-btn"
              htmlType="submit"
              type="primary"
              disabled={applyFilterDisabled}
              onClick={(e: React.FormEvent) => {
                e.preventDefault();
                dispatch(doApplyAlertsFilter());
              }}
            >
              {MESSAGES.AlertsFiltersApply}
            </Button>
          </div>
        </Form>
      </Card>
    </Affix>
  );
};
