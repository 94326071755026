import { createSelector } from "reselect";
import { RdxStudentDetails } from "../reducers/student-details";
import { RdxStudentsListState } from "../reducers/students-list";
import { RdxStoreState } from "../types/state";

export const getStudentDetailsPageTitle: (state: RdxStoreState) => string = createSelector(
  (state: RdxStoreState) => state.studentsList,
  (state: RdxStoreState) => state.studentDetails,
  (studentsList: RdxStudentsListState, { curStudentId, details }: RdxStudentDetails) => {
    if (!curStudentId) {
      return "";
    }

    const { [curStudentId]: student } = studentsList.studentById;

    if (student) {
      return `${student.firstName} ${student.lastName}`;
    }

    if (details) {
      const { firstName, lastName } = details;
      return `${firstName} ${lastName}`;
    }

    return "";
  }
);
