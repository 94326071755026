import { SaveOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Empty, Flex, Form, Input, Tabs, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { ApplicationToastsContext } from "../../components/AppToasts";
import { BaseLayout } from "../../components/BaseLayout";
import { CheckableTreeView } from "../../components/CheckableTreeView";
import { RenderChildrenIf } from "../../components/ConditionalRenderers";
import { NavigationDrawerOptionKey } from "../../components/NavigationDrawer/types";
import { TenantsSelect } from "../../components/TenantsSelect";
import { MESSAGES, formatMsg } from "../../i18n";
import { ADD_NEW_STUDENTS_COMPLETED } from "../../redux/actions/students-list";
import { getMyTenant } from "../../redux/selectors/my-tenant";
import { TagNode } from "../../redux/selectors/my-tenant-tags-tree";
import { routes } from "../../routes";
import { BAD_REQUEST, NOT_FOUND_STATUS_CODE } from "../../services/http-client/constant";
import { navApi } from "../../services/nav-api";
import { PostMannuallyAssociatedErrorResponse } from "../../services/nav-api/students-mannually-associated/types";
import { TagUtils } from "../../utils/TagUtils";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const ResponseContentViewer = ({
  unmatchedUsernames,
  skippedUsernames,
  addedCount
}: {
  unmatchedUsernames?: string[];
  skippedUsernames?: string[];
  addedCount?: number;
}) => {
  const hasSkippedU = _.gt(skippedUsernames?.length, 0);
  const hasUnmatchedU = _.gt(unmatchedUsernames?.length, 0);
  return (
    <Flex vertical gap={12}>
      <Tabs
        size="small"
        items={[
          ...(!_.isNil(addedCount)
            ? [
                {
                  key: "summary",
                  label: "Summary",
                  children: (
                    <Flex justify="center" align="center" style={{ minHeight: 150 }}>
                      <Typography.Text>
                        {formatMsg(
                          _.isEqual(addedCount, 1) //
                            ? MESSAGES.StudentsAddCountSingular
                            : MESSAGES.StudentsAddCountPlural,
                          addedCount
                        )}
                      </Typography.Text>
                    </Flex>
                  )
                }
              ]
            : []),

          ...(hasSkippedU
            ? [
                {
                  key: "s",
                  label: (
                    <Badge size="small" showZero offset={[10, 0]} count={skippedUsernames?.length ?? 0}>
                      <Typography.Text>{MESSAGES.StudentsAddTab_Skipped}</Typography.Text>
                    </Badge>
                  ),
                  children: (
                    <Flex vertical style={{ minHeight: 150 }}>
                      <Typography.Text>{MESSAGES.StudentsAddedSuccessfullyButWithExceptions_Skipped}</Typography.Text>
                      <Typography.Text>
                        <code>{_.join(skippedUsernames, ", ")}</code>
                      </Typography.Text>
                    </Flex>
                  )
                }
              ]
            : []),
          ...(hasUnmatchedU
            ? [
                {
                  key: "u",
                  label: (
                    <Badge size="small" showZero offset={[10, 0]} count={unmatchedUsernames?.length ?? 0}>
                      <Typography.Text>{MESSAGES.StudentsAddTab_Unmatched}</Typography.Text>
                    </Badge>
                  ),
                  children: (
                    <Flex vertical style={{ minHeight: 150 }}>
                      <Typography.Text>{MESSAGES.StudentsAddedSuccessfullyButWithExceptions_Unmatched}</Typography.Text>
                      <Typography.Text>
                        <code>{_.join(unmatchedUsernames, ", ")}</code>
                      </Typography.Text>
                    </Flex>
                  )
                }
              ]
            : [])
        ]}
      />
    </Flex>
  );
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const StudentsAdd = () => {
  const { id: tenantIdStr } = useParams<{ id: string }>();

  const [tenantId, setTenantId] = React.useState<number | null>(null);
  const [tagIds, setTagIds] = React.useState<number[]>([]);
  const [usernames, setUsernames] = React.useState<string>("");
  const [loadingForm, setLoadingForm] = React.useState<boolean>(false);
  const [loadingTags, setLoadingTags] = React.useState<boolean>(false);
  const [tree, setTree] = React.useState<TagNode[]>([]);

  const myTenant = useSelector(getMyTenant);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  React.useEffect(() => {
    setTenantId(!_.isNil(tenantIdStr) ? parseInt(tenantIdStr) : null);
  }, [tenantIdStr]);

  const memoizedFormValue = React.useMemo(() => {
    const ul = usernames
      .replace(/\n/g, ",")
      .split(/[ ,]+/)
      .filter(e => e !== "");

    const hasValidTenant = !_.isNil(tenantId) && tenantId > 0;
    const hasValidUsernames = _.reduce(
      ul,
      (a, u) => a && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(u),
      true
    );

    return {
      hasValidTenant,
      hasValidUsernames,
      isValid: hasValidTenant && hasValidUsernames,
      data: {
        tagIds,
        tenantId,
        usernames: ul ?? []
      }
    };
  }, [usernames, tagIds, tenantId]);

  React.useEffect(() => {
    setTree([]);
    setTagIds([]);
    if (!_.isNil(tenantId)) {
      (async () => {
        try {
          setLoadingTags(true);
          const rsp = await navApi.tenants.getTenantTags(tenantId);
          setTree(TagUtils.buildTagsTreeFromTags(rsp.tags));
        } catch (e) {
          console.error(e);
        } finally {
          setLoadingTags(false);
        }
      })();
    }
  }, [tenantId]);

  return (
    <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.STUDENTS}>
      <Typography.Title level={2}>{MESSAGES.AddStudents}</Typography.Title>
      <Card>
        <Form layout="vertical">
          <Form.Item
            required
            label={MESSAGES.Tenant}
            wrapperCol={{ xs: 24, lg: 12 }}
            hasFeedback={!memoizedFormValue.hasValidTenant}
            validateStatus={memoizedFormValue.hasValidTenant ? "success" : "error"}
          >
            <TenantsSelect //
              allowClear
              value={tenantId!}
              onChange={setTenantId}
              disabled={!_.isNil(myTenant)}
            />
          </Form.Item>

          <Form.Item
            required
            label={MESSAGES.StudentEmails}
            hasFeedback={!memoizedFormValue.hasValidUsernames}
            validateStatus={memoizedFormValue.hasValidUsernames ? "success" : "error"}
          >
            <Input.TextArea
              rows={6}
              allowClear
              value={usernames}
              placeholder={MESSAGES.StudentsAddPlaceholder}
              onChange={e => {
                setUsernames(e.currentTarget.value ?? "");
              }}
            />
          </Form.Item>

          <Form.Item label={MESSAGES.Tags}>
            <Card styles={{ body: { maxHeight: "50vh", overflowY: "auto" } }}>
              <RenderChildrenIf
                condition={tree?.length > 0}
                fallback={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    imageStyle={{ height: 48 }}
                    description="No tags Available."
                  />
                }
              >
                <CheckableTreeView
                  loading={loadingTags}
                  tree={tree}
                  checked={tagIds}
                  onChange={setTagIds}
                  renderNode={(n: TagNode) => n.name}
                  checkBehavior="assign"
                />
              </RenderChildrenIf>
            </Card>
          </Form.Item>

          <Flex justify="end" align="center" gap={8}>
            <Button
              onClick={() => {
                navigate(routes.students.resolve());
              }}
            >
              {MESSAGES.CancelLabel}
            </Button>
            <ApplicationToastsContext.Consumer>
              {api => (
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                  loading={loadingForm}
                  //disabled={!memoizedFormValue.isValid}
                  onClick={async () => {
                    setLoadingForm(true);

                    try {
                      const rsp = await navApi.studentsMannualyAssociated.postMannuallyAssociated(tenantId!, {
                        add: memoizedFormValue.data
                      });

                      const unmatched = rsp?.unmatchedUsernames;
                      const skipped = rsp?.skippedUsernames;
                      const addedCount = rsp?.matchedCount;
                      const hasUnmatchedU = _.gt(unmatched?.length, 0);
                      const hasSkippedU = _.gt(skipped?.length, 0);

                      (hasUnmatchedU || hasSkippedU
                        ? api.warning //
                        : api.success)({
                        style: { width: 640 },
                        message: MESSAGES.StudentsAddTitle,
                        description:
                          hasUnmatchedU || hasSkippedU ? (
                            <ResponseContentViewer
                              unmatchedUsernames={unmatched}
                              skippedUsernames={skipped}
                              addedCount={addedCount}
                            />
                          ) : (
                            formatMsg(
                              _.isEqual(addedCount, 1) //
                                ? MESSAGES.StudentsAddCountSingular
                                : MESSAGES.StudentsAddCountPlural,
                              addedCount
                            )
                          )
                      });

                      dispatch({
                        type: ADD_NEW_STUDENTS_COMPLETED
                      });
                    } catch (ex) {
                      const err: PostMannuallyAssociatedErrorResponse = ex;
                      switch (err.statusCode) {
                        case BAD_REQUEST:
                        case NOT_FOUND_STATUS_CODE:
                          const { unmatchedUsernames, skippedUsernames } = err;
                          if (unmatchedUsernames?.length! > 0 || skippedUsernames?.length! > 0) {
                            api.error({
                              message: MESSAGES.Error,
                              description: (
                                <ResponseContentViewer //
                                  unmatchedUsernames={unmatchedUsernames}
                                  skippedUsernames={skippedUsernames}
                                />
                              )
                            });
                            return;
                          } else if (err.message != null && err.message !== "No error msg") {
                            api.error({
                              message: MESSAGES.Error,
                              description: (
                                <div>
                                  <Typography.Text>{err.message}</Typography.Text>
                                </div>
                              )
                            });
                            return;
                          }
                          break;
                      }

                      api.error({
                        message: MESSAGES.Error,
                        description: MESSAGES.StudentsAddError
                      });
                    } finally {
                      setLoadingForm(false);
                    }
                  }}
                >
                  {MESSAGES.AddLabel}
                </Button>
              )}
            </ApplicationToastsContext.Consumer>
          </Flex>
        </Form>
      </Card>
    </BaseLayout>
  );
};

export default StudentsAdd;
