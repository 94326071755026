import { Divider } from "antd";
import React from "react";
import { StudyPaceField } from "../../EditorFields/FieldStudyPace";
import { EditorSectionHeader } from "../EditorSectionHeader";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPaceSection = () => (
  <>
    <EditorSectionHeader
      title="What is the study pace?"
      description={
        "Set the rate at which the student should complete all tasks. " +
        "The average default pace is based on the average time it takes learners to complete the course."
      }
    />
    <StudyPaceField />
    <Divider />
  </>
);
