import { Tooltip } from "antd";
import { ColumnProps } from "antd/es/table";
import _ from "lodash";
import React from "react";
import { StudentTag } from "../../../../services/nav-api/student-details/types";
import { CreateColumnProps } from "./model";

// -------------------------------------------------------------------------------------------------
// - Column builder
// -------------------------------------------------------------------------------------------------

export const createTagsColumn = (_props: CreateColumnProps): ColumnProps<{}>[] => [
  {
    ellipsis: true,
    title: "Tags",
    dataIndex: "tags",
    render: val => {
      const names = _.chain(val)
        .map((tag: StudentTag) => tag.name)
        .join(", ")
        .value();
      return (
        <Tooltip title={names}>
          <span>{names}</span>
        </Tooltip>
      );
    }
  }
];
