import { Typography } from "antd";
import _ from "lodash";
import React from "react";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const EndDateField = () => (
  <StudyPlanEditorContext.Consumer>
    {({ editor: { endDate } }) => (
      <Typography.Text type={_.isNil(endDate.value) ? "secondary" : undefined}>
        {endDate?.value?.format("MMM D, YYYY")}
      </Typography.Text>
    )}
  </StudyPlanEditorContext.Consumer>
);
