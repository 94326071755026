import { DatePicker, Form } from "antd";
import _ from "lodash";
import React from "react";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";
import { EditorMode } from "../../hooks/useStudyPlanEditor/useDerivedFields";

// -------------------------------------------------------------------------------------------------
//  hooks
// -------------------------------------------------------------------------------------------------

const useOnce = () => {
  const [value, setValue] = React.useState(true);
  return { value, toggle: () => setValue(prev => !prev) };
};

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const EndDateField = () => {
  const once = useOnce();
  return (
    <StudyPlanEditorContext.Consumer>
      {({ editor: { endDate, startDate, lockUntil, mode }, simulations }) => (
        <Form.Item label="End" required>
          <DatePicker
            allowClear
            className="w-full"
            data-qa-label="end-date"
            format="MM/DD/YYYY"
            value={endDate.value}
            defaultPickerValue={endDate.value ?? startDate.value}
            disabledDate={cur => (!_.isNil(startDate.value) ? cur?.isBefore(startDate.value, "day") : false)}
            onChange={date => {
              simulations.enableSimulations();
              endDate.setValue(date);

              if (!_.isEqual(EditorMode.EDIT, mode) && once.value && _.isNil(lockUntil.value)) {
                lockUntil.setValue(date);
                once.toggle();
              }
            }}
          />
        </Form.Item>
      )}
    </StudyPlanEditorContext.Consumer>
  );
};
