import { WorkingFilter } from "../../../redux/actions/partners";
import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { Status } from "../types";
import { GetPartnersResponse } from "./types";

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const getPartners = async (args?: WorkingFilter): Promise<GetPartnersResponse> =>
  HttpClient.get(`/rest/admin/partners`, createHeaders(), args);

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const putPartnerStatus = async (partnerId: number, status: Status): Promise<{}> => {
  const url = `/rest/admin/partners/${partnerId}/status`;
  return HttpClient.put(url, createHeaders(), { status });
};

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const putPartnerTenant = async (partnerId: number, tenantId: number): Promise<{}> => {
  const url = `/rest/admin/partners/${partnerId}/tenant`;
  return HttpClient.put(url, createHeaders(), { tenantId });
};

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const deletePartnerTenant = async (partnerId: number): Promise<{}> => {
  const url = `/rest/admin/partners/${partnerId}/tenant`;
  return HttpClient.del(url, createHeaders());
};
