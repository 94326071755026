import { Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../../i18n";
import { doFetchTenantDashboard } from "../../../redux/actions/dashboard/tenant/fetch";
import { getActiveProduct } from "../../../redux/selectors/active-product";
import { ExamTracker } from "./ExamTracker";
import { Filters } from "./Filters";
import { FilterTags } from "./FilterTags";
import { MoreDetails } from "./MoreDetails";
import { StudentsActivity } from "./StudentsActivity";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantDashboard = () => {
  const dispatch = useDispatch<any>();
  const product = useSelector(getActiveProduct);

  React.useEffect(() => {
    dispatch(doFetchTenantDashboard());
  }, []);

  return (
    <>
      <div className="flex w-full">
        <div className="grow">
          <Typography.Title level={3}>
            {_.isEqual(product, "cpa") //
              ? MESSAGES.CPADashboard
              : MESSAGES.CMADashboard}
          </Typography.Title>
        </div>
        <Filters />
      </div>
      <FilterTags />
      <div className="flex flex-col gap-y-6">
        <StudentsActivity />
        <ExamTracker />
        <MoreDetails />
      </div>
    </>
  );
};
