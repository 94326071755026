import { Card, Table, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { handleCellPercentage, handleCellText } from "../../components/TableCellRender";
import { MESSAGES } from "../../i18n";
import { getProductConfig } from "../../product-config";
import { getActiveProduct } from "../../redux/selectors/active-product";
import {
  PerformanceDashboardItem,
  getStudentPerformanceDashboard
} from "../../redux/selectors/student-performance-dashboard";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentPerformance = () => {
  const { dataSource, loading } = useSelector(getStudentPerformanceDashboard);
  const activeProduct = useSelector(getActiveProduct);
  return (
    <Card
      size="small"
      loading={loading}
      title={
        <div className="ant-card-head-wrapper">
          <div className="ant-card-head-title" data-qa-label="sd-performance-title">
            {MESSAGES.StudentPerformance}
          </div>
        </div>
      }
    >
      <Table
        rowClassName="cursor-pointer"
        dataSource={dataSource}
        pagination={false}
        loading={loading}
        size="small"
        rowKey="key"
        columns={[
          // ---------------------------------------------------------------------------------------
          // - Section
          // ---------------------------------------------------------------------------------------

          {
            title: "Section",
            align: "left",
            render: (obj: PerformanceDashboardItem) => (
              <Typography.Text key={`section-${obj.altId}`} title={obj.name}>
                {obj.name}
              </Typography.Text>
            )
          },

          // ---------------------------------------------------------------------------------------
          // - Videos
          // ---------------------------------------------------------------------------------------

          {
            title: "Videos",
            key: "videos",
            children: [
              {
                title: "% Completed",
                dataIndex: "conceptVideosProgress",
                render: handleCellPercentage
              }
            ]
          },

          // ---------------------------------------------------------------------------------------
          // - Multiple Choice Questions
          // ---------------------------------------------------------------------------------------

          {
            title: "Multiple Choice Questions",
            key: "mcq-group",
            children: [
              {
                title: "# Questions",
                dataIndex: "mcqsTotal",
                key: "mcqsTotal",
                render: handleCellText
              },
              {
                title: "# Correct",
                dataIndex: "mcqsCorrect",
                key: "mcqsCorrect",
                render: handleCellText
              },
              {
                title: "% Correct",
                dataIndex: "mcqsProgress",
                key: "mcqsProgress",
                render: handleCellPercentage
              }
            ]
          },

          // ---------------------------------------------------------------------------------------
          // - Simulations
          // ---------------------------------------------------------------------------------------

          ...(getProductConfig().performanceDashboard.simulations
            ? [
                {
                  title: "Simulations",
                  children: [
                    {
                      title: "# Questions",
                      dataIndex: "simsTotal",
                      render: handleCellText
                    },
                    {
                      title: "# Correct",
                      dataIndex: "simsCorrect",
                      render: handleCellText
                    },
                    {
                      title: "% Correct",
                      dataIndex: "simsProgress",
                      render: handleCellPercentage
                    }
                  ]
                }
              ]
            : []),

          // ---------------------------------------------------------------------------------------
          // - Essays
          // ---------------------------------------------------------------------------------------

          ...(getProductConfig().performanceDashboard.essays
            ? [
                {
                  title: "Essays",
                  children: [
                    {
                      render: handleCellText,
                      title: "# Questions",
                      dataIndex: "essaysTotal"
                    },
                    {
                      render: handleCellText,
                      title: "# Correct",
                      dataIndex: "essaysCorrect"
                    },
                    {
                      render: handleCellPercentage,
                      title: "% Correct",
                      dataIndex: "essaysProgress"
                    }
                  ]
                }
              ]
            : []),

          // ---------------------------------------------------------------------------------------
          // -
          // ---------------------------------------------------------------------------------------

          {
            render: handleCellPercentage,
            title: _.isEqual(activeProduct, "cpa") ? "EDR" : "% Total",
            dataIndex: "progress",
            key: "progress"
          }
        ]}
      />
    </Card>
  );
};
