import { Form } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TenantsSelect } from "../../components/TenantsSelect";
import { doApplyFilter, doUpdateWorkingFilter } from "../../redux/actions/users-list";
import { getIsGlobalUser } from "../../redux/selectors/permissions/is-global-user";
import { RdxFetchStatus } from "../../redux/types/status";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const UsersTableFilterForTenants = (props: {
  onChange: (value?: number) => void;
  value: number | undefined;
}) => (
  <Form.Item label="Tenant">
    <TenantsSelect //
      onChange={props.onChange}
      value={props.value}
      style={{ width: "100%" }}
    />
  </Form.Item>
);

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const UsersTableFilterForTenantsQuick = () => {
  const dispatch = useDispatch<any>();
  const globalUser = useSelector(getIsGlobalUser);
  const loading = useSelector(({ usersList: { status } }) => _.isEqual(RdxFetchStatus.LOADING, status));
  const value = useSelector(
    ({
      usersList: {
        workingFilter: { tenantId }
      }
    }) => tenantId
  );

  return globalUser ? (
    <div className="w-[15rem]">
      <TenantsSelect
        value={value}
        disabled={loading}
        allowClear
        className="w-full"
        data-qa-label="quick-select-users-tenants"
        onChange={tenantId => {
          dispatch(doUpdateWorkingFilter({ tenantId }));
          dispatch(doApplyFilter());
        }}
      />
    </div>
  ) : null;
};
