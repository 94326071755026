import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getMyTenant } from "../../../../../redux/selectors/my-tenant";
import { StudyPlansListItem } from "../../../../../services/nav-api/studyPlans/model";
import { Field, FromApiResponseValue, StudyPlanEditorValue, ToApiRequestValue } from "./model";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type TenantIdFieldValue = StudyPlanEditorValue["tenantId"];
export type TenantIdField = Field<
  TenantIdFieldValue,
  {
    toApiRequestValue: ToApiRequestValue<TenantIdFieldValue>;
    fromApiResponseValue: FromApiResponseValue<TenantIdFieldValue>;
  }
>;

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useTenantId = (initVal: TenantIdFieldValue = null): TenantIdField => {
  const [value, setValue] = React.useState<TenantIdFieldValue>(initVal);
  const myTenant = useSelector(getMyTenant);

  // Lock/Unlock Tenant
  React.useEffect(() => {
    if (_.gt(myTenant?.id, 0)) {
      setValue(myTenant?.id!);
    }
  }, [myTenant]);

  return {
    value,
    setValue: (val: TenantIdFieldValue) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(initVal);
    },
    toApiRequestValue: (): TenantIdFieldValue => {
      return value!;
    },
    fromApiResponseValue: (rsp: StudyPlansListItem): TenantIdFieldValue => {
      const iVal = rsp.tenant.id;
      setValue(iVal);
      return iVal;
    }
  };
};
