import { Typography } from "antd";
import React from "react";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const WeekDaysInfoTitle = () => (
  <StudyPlanEditorContext.Consumer>
    {({ editor: { weekDaysInfo } }) => <Typography.Text type="secondary">{weekDaysInfo.title}:</Typography.Text>}
  </StudyPlanEditorContext.Consumer>
);

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const WeekDaysInfoField = () => (
  <StudyPlanEditorContext.Consumer>
    {({ editor: { weekDaysInfo } }) => (
      <Typography.Text>
        {weekDaysInfo.value
          ? weekDaysInfo.value.daysBetween <= 15
            ? weekDaysInfo.value.daysBetween
            : weekDaysInfo.value.weeksBetween
          : null}
      </Typography.Text>
    )}
  </StudyPlanEditorContext.Consumer>
);
