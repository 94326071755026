import _ from "lodash";
import React from "react";
import {
  StudyPlanEditorDerivedValues,
  studyPlanEditorDerivedValuesInitValues,
  useDerivedFields
} from "./useDerivedFields";
import { StudyPlanEditorFields, studyPlanEditorFieldsInitValue, useEditorFields } from "./useEditorFields";
import { StudyPlanEditorNavigation, studyPlanEditorNavigationInitValues, useNavigation } from "./useNavigation";
import { remoteStudyPlanInitValue, RemoteStudyPlanValue, useRemoteStudyPlan } from "./useRemoteStudyPlan";
import {
  studyPlanEditorSimulationsInitValue,
  StudyPlanEditorSimulationsValue,
  useSimulatedData
} from "./useSimulations";
import { StudyPlanEditorApiCallbacks, studyPlanEditorApiCallbacksInitValue, useStudyPlanApi } from "./useStudyPlanApi";
import { tenantTagsInitValue, TenantTagsValue, useTenantTags } from "./useTenantTags";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type StudyPlanEditor = {
  editor: StudyPlanEditorFields &
    StudyPlanEditorDerivedValues & {
      tenantTags: TenantTagsValue;
    };
  navigation: StudyPlanEditorNavigation;
  simulations: StudyPlanEditorSimulationsValue;
  apiCallbacks: StudyPlanEditorApiCallbacks;
  remoteStudyPlan: RemoteStudyPlanValue;
};

// -------------------------------------------------------------------------------------------------
// - Context
// -------------------------------------------------------------------------------------------------

export const useStudyPlanEditor = (): StudyPlanEditor => {
  const editorFields = useEditorFields();
  const tenantTags = useTenantTags(editorFields.tenantId.value);
  const apiCallbacks = useStudyPlanApi(editorFields);
  const simulations = useSimulatedData(editorFields);
  const remoteStudyPlan = useRemoteStudyPlan(editorFields);
  const navigation = useNavigation(_.gt(remoteStudyPlan.remoteId, 0));
  const derivedFields = useDerivedFields(editorFields, remoteStudyPlan, navigation.step);

  return {
    editor: {
      ...editorFields,
      ...derivedFields,
      tenantTags
    },

    navigation,
    simulations,
    apiCallbacks,
    remoteStudyPlan
  };
};

// -------------------------------------------------------------------------------------------------
// - Context
// -------------------------------------------------------------------------------------------------

export const StudyPlanEditorContext = React.createContext<StudyPlanEditor>({
  editor: {
    ...tenantTagsInitValue,
    ...studyPlanEditorFieldsInitValue,
    ...studyPlanEditorDerivedValuesInitValues
  },

  navigation: studyPlanEditorNavigationInitValues,
  simulations: studyPlanEditorSimulationsInitValue,
  apiCallbacks: studyPlanEditorApiCallbacksInitValue,
  remoteStudyPlan: remoteStudyPlanInitValue
});
