import { Form, Select } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../../../i18n";
import { doUpdateWorkingFilter, LastActivityValue } from "../../../../redux/actions/students-list";
import { RdxStoreState } from "../../../../redux/types/state";
import { lastActivityOptions } from "../metadata";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const LastActivityFilter = () => {
  const dispatch = useDispatch<any>();
  const lastActivity = useSelector(({ studentsList: s }: RdxStoreState) => s.workingFilter.lastActivity);

  return (
    <Form.Item label={MESSAGES.LastActivityFilter}>
      <Select<string>
        value={lastActivity}
        data-qa-label="last-activity"
        getPopupContainer={({ parentNode }) => {
          return parentNode as HTMLElement;
        }}
        onChange={(lastActivity: LastActivityValue) => {
          dispatch(doUpdateWorkingFilter({ lastActivity }));
        }}
      >
        {_.map(lastActivityOptions, ({ label, value }) => (
          <Select.Option value={value} key={value} data-qa-label={`la-sfilter-${value}`}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
