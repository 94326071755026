import { Alert } from "../../../services/nav-api/alerts-reader/types";
import {
  USER_FETCH_ALERTS_COMPLETED,
  USER_FETCH_ALERTS_FAILED,
  USER_FETCH_ALERTS_STARTED
} from "../../actions/alerts-reader/fetch";
import { PUSH_ALERTS, PUSH_ALERTS_RESET } from "../../actions/alerts-reader/pusher";
import { FORCE_ALERTS_RELOAD } from "../../actions/alerts-reader/update";
import { RdxFetchStatus } from "../../types/status";
import { createReducer } from "../../utils/redux-utils";
import { initialState } from "./constants";
import { RdxAlertsReaderState } from "./types";

// -------------------------------------------------------------------------------------------------
// - Reducer
// -------------------------------------------------------------------------------------------------

export default createReducer(initialState, {
  // -----------------------------------------------------------------------------------------------
  // -
  // - Fetch Alerts
  // -
  // -----------------------------------------------------------------------------------------------

  [USER_FETCH_ALERTS_STARTED]: (state): RdxAlertsReaderState => ({
    ...state,
    status: RdxFetchStatus.LOADING
  }),

  [USER_FETCH_ALERTS_COMPLETED]: (state, alerts: Alert[]): RdxAlertsReaderState => ({
    ...state,
    status: RdxFetchStatus.COMPLETED,
    alerts,
    lastUpdate: new Date().getTime()
  }),

  [USER_FETCH_ALERTS_FAILED]: (state): RdxAlertsReaderState => ({
    ...state,
    status: RdxFetchStatus.FAILED
  }),

  // -----------------------------------------------------------------------------------------------
  // -
  // - Force alerts reload (Through Redux Reactors) [re-fetch-alerts]
  // -
  // -----------------------------------------------------------------------------------------------

  [FORCE_ALERTS_RELOAD]: (state): RdxAlertsReaderState => ({
    ...state,
    status: RdxFetchStatus.STALE
  }),

  // -----------------------------------------------------------------------------------------------
  // -
  // - Push Alerts
  // -
  // -----------------------------------------------------------------------------------------------

  [PUSH_ALERTS]: (state, alertId: number): RdxAlertsReaderState => ({
    ...state,
    pushedAlertIds: [...state.pushedAlertIds, alertId]
  }),

  [PUSH_ALERTS_RESET]: (state): RdxAlertsReaderState => ({
    ...state,
    pushedAlertIds: []
  })
});
