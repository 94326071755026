import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import React from "react";
import { StudyPlansListItem } from "../../../../../services/nav-api/studyPlans/model";
import { Field, FromApiResponseValue, StudyPlanEditorValue, ToApiRequestValue } from "./model";

// -------------------------------------------------------------------------------------------------
//  Types
// -------------------------------------------------------------------------------------------------

export type DateRange = { start: Dayjs; end: Dayjs };
export type DateRangeList = Array<DateRange>;
export type DaysOffFieldValue = StudyPlanEditorValue["daysOff"];
export type DaysOffField = Field<
  DaysOffFieldValue,
  {
    addDate: (date: dayjs.Dayjs) => void;
    addDateRange: (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => void;
    getDateRanges: () => DateRangeList;
    removeRange: (dateRange: DateRange) => void;
    toApiRequestValue: ToApiRequestValue<string[]>;
    fromApiResponseValue: FromApiResponseValue<DaysOffFieldValue>;
  }
>;

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

const buildIntermediateDates = (start: Dayjs, end: Dayjs): Dayjs[] => {
  if (start.isAfter(end)) {
    throw new Error("Start date must be before or equal to end date.");
  }

  const dates: Dayjs[] = [];
  let currentDate = start;

  while (currentDate.isBefore(end) || currentDate.isSame(end, "day")) {
    dates.push(currentDate);
    currentDate = currentDate.add(1, "day");
  }

  return dates;
};

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useDaysOff = (iValue: DaysOffFieldValue = []): DaysOffField => {
  const [value, setValue] = React.useState<DaysOffFieldValue>(iValue);
  return {
    value,
    setValue: (val: DaysOffFieldValue) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(iValue);
    },

    // ---------------------------------------------------------------------------------------------
    // - Add date
    // -
    addDate: (date: dayjs.Dayjs) => {
      setValue(_.uniqBy([...value, date], o => o.toISOString()));
    },

    // ---------------------------------------------------------------------------------------------
    // - Add date range
    // -
    addDateRange: (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
      let dates: Dayjs[] = [];
      try {
        dates = buildIntermediateDates(startDate, endDate);
      } catch (e) {}

      setValue(_.uniqBy([...value, ...dates], o => o.toISOString()));
    },

    // ---------------------------------------------------------------------------------------------
    // - Get dates grouped by range
    // -
    getDateRanges: (): DateRangeList => {
      if (!value || value.length === 0) return [];

      const datesSorted = value.sort((a, b) => a.valueOf() - b.valueOf());
      const ranges: DateRangeList = [];

      let cStart = datesSorted[0];
      let dStart = datesSorted[0];

      for (let i = 1; i < datesSorted.length; i++) {
        const cur = datesSorted[i];

        if (cur.diff(dStart, "day") <= 1) {
          dStart = cur;
        } else {
          ranges.push({ start: cStart, end: dStart });
          cStart = cur;
          dStart = cur;
        }
      }

      ranges.push({ start: cStart, end: dStart });
      return ranges;
    },

    // ---------------------------------------------------------------------------------------------
    // - Get dates grouped by range
    // -
    removeRange: (range: DateRange): void => {
      setValue(_.filter(value, date => date.isBefore(range.start, "day") || date.isAfter(range.end, "day")));
    },

    // ---------------------------------------------------------------------------------------------
    // - Prepare dates to be consumed by the api client
    // -
    toApiRequestValue: () => _.map(value, date => date.format("YYYY-MM-DD")),

    // ---------------------------------------------------------------------------------------------
    // - Prepare dates to be consumed by the api client
    // -
    fromApiResponseValue: (rsp: StudyPlansListItem): DaysOffFieldValue => {
      const iVal = _.chain(rsp.settings.daysOff)
        .filter(dayOff => !_.isEmpty(dayOff))
        .map(dayjs)
        .value();

      setValue(iVal);
      return iVal;
    }
  };
};
