import { Checkbox, Form } from "antd";
import React from "react";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const FlashcardsField = () => (
  <StudyPlanEditorContext.Consumer>
    {({ editor: { flashcards }, simulations }) => (
      <Form.Item>
        <Checkbox
          checked={flashcards.value!}
          onChange={e => {
            simulations.enableSimulations();
            flashcards.setValue(e.target.checked);
          }}
        >
          Flashcards
        </Checkbox>
      </Form.Item>
    )}
  </StudyPlanEditorContext.Consumer>
);
