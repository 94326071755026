import { Typography } from "antd";
import { ColumnProps } from "antd/es/table";
import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { StudyPlansListItem } from "../../../../services/nav-api/studyPlans/model";
import { CreateColumnProps } from "./model";

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const formatDates = (dateStr?: string) =>
  _.isNil(dateStr) //
    ? "-"
    : dayjs(dateStr, "YYYY-MM-DD").format("MMM D, YYYY");

// -------------------------------------------------------------------------------------------------
// - Column builder
// -------------------------------------------------------------------------------------------------

export const createStartEndDateColumns = ({ sort }: CreateColumnProps): ColumnProps<{}>[] => [
  {
    sorter: true,
    width: 120,
    dataIndex: "startDate",
    sortOrder: _.isEqual(sort?.key, "startDate") ? sort?.order : undefined,
    title: <Typography.Text ellipsis={true}>Start Date</Typography.Text>,
    render: (_cell: any, plan: StudyPlansListItem) => (
      <Typography.Text>{formatDates(plan.settings.startDate)}</Typography.Text>
    )
  },
  {
    sorter: true,
    width: 120,
    dataIndex: "examDate",
    sortOrder: _.isEqual(sort?.key, "examDate") ? sort?.order : undefined,
    title: <Typography.Text ellipsis={true}>End Date</Typography.Text>,
    render: (_cell: any, plan: StudyPlansListItem) => (
      <Typography.Text>{formatDates(plan.settings.examDate)}</Typography.Text>
    )
  }
];
