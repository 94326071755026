import { SaveOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Flex, Form, Input, Select, Space, Switch, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router";
import { WorkingFilterTypeTypesWithoutAll } from "../redux/actions/partners";
import { routes } from "../routes";
import { Tenant, TenantProduct } from "../services/nav-api/tenants/types";
import { Status } from "../services/nav-api/types";
const CheckboxGroup = Checkbox.Group;

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

export type EditableTenant = //
  Omit<Tenant, "created" | "id"> & //
    Partial<Pick<Tenant, "created" | "id">>;

interface OwnProps {
  tenant?: Tenant;
  tenantId?: number;
  loading: boolean;
  onSubmit: (props: EditableTenant) => void;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantsForm = (props: OwnProps) => {
  const navigate = useNavigate();
  const [tenant, setTenant] = React.useState<EditableTenant>();
  const isInvalid = React.useMemo(
    () =>
      _.isEmpty(tenant?.products) || //
      _.isEmpty(tenant?.name),
    [
      //
      tenant?.products,
      tenant?.name
    ]
  );

  React.useEffect(() => {
    if (!_.isNil(props.tenant)) {
      setTenant({ ...props.tenant });
    } else {
      setTenant({
        name: "",
        status: Status.Disabled,
        hasTagForm: false,
        hasStudyPlan: false,
        type: "Firm",
        products: []
      });
    }
  }, [JSON.stringify(props.tenant)]);

  return !_.isNil(tenant) ? (
    <Form layout="vertical">
      <Flex gap={24} vertical>
        {/* --- Section: Details --- */}
        {/* --- Section: Details --- */}
        {/* --- Section: Details --- */}

        <Card title="Details">
          <Form.Item label="Name" required wrapperCol={{ xs: 24, xl: 12 }}>
            <Input
              allowClear
              type="text"
              maxLength={100}
              value={tenant.name}
              placeholder="Tenant name"
              onChange={e => setTenant({ ...tenant, name: e.target.value })}
            />
          </Form.Item>

          <Form.Item label="Type" wrapperCol={{ xs: 24, xl: 12 }}>
            <Select
              value={tenant.type}
              onChange={value => setTenant({ ...tenant, type: value })}
              options={_.map(WorkingFilterTypeTypesWithoutAll, type => ({
                label: type,
                value: type
              }))}
            />
          </Form.Item>

          {props.tenant ? (
            <Form.Item label="Status" wrapperCol={{ xs: 24, xl: 12 }}>
              <Switch
                checkedChildren="Enabled"
                unCheckedChildren="Disabled"
                checked={_.isEqual(tenant.status, Status.Enabled)}
                onChange={value =>
                  setTenant({
                    ...tenant,
                    status: value //
                      ? Status.Enabled
                      : Status.Disabled
                  })
                }
              />
            </Form.Item>
          ) : null}
        </Card>

        {/* --- Section: Settings --- */}
        {/* --- Section: Settings --- */}
        {/* --- Section: Settings --- */}

        <Card title="Settings">
          <Form.Item
            label="Products"
            required
            extra={
              _.isEmpty(tenant.products) ? (
                <Space className="pt-2">
                  <Typography.Text type="warning">At least one product must be selected</Typography.Text>
                </Space>
              ) : null
            }
          >
            <CheckboxGroup
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8
              }}
              value={tenant.products}
              onChange={products => setTenant({ ...tenant, products })}
              options={_.values(TenantProduct).map(product => ({
                label: product.toUpperCase(),
                value: product
              }))}
            />
          </Form.Item>
          <Form.Item label="Study Plans">
            <Switch
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              value={tenant.hasStudyPlan}
              onChange={value => setTenant({ ...tenant, hasStudyPlan: value })}
            />
          </Form.Item>
          <Form.Item label="CPA Tag Form">
            <Switch
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              value={tenant.hasTagForm}
              onChange={value => setTenant({ ...tenant, hasTagForm: value })}
            />
          </Form.Item>
        </Card>

        <Card>
          <Flex justify="start" gap={16}>
            <Button
              disabled={props.loading}
              onClick={() => {
                navigate(
                  props.tenantId //
                    ? routes.tenant.resolve({ id: props.tenantId.toString() })
                    : routes.tenants.resolve()
                );
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={props.loading}
              disabled={isInvalid}
              icon={<SaveOutlined />}
              onClick={() => {
                props.onSubmit(tenant);
              }}
            >
              Save
            </Button>
          </Flex>
        </Card>
      </Flex>
    </Form>
  ) : null;
};
