import { Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../../../i18n";
import { SelectedSectionValue, doUpdateWorkingFilter } from "../../../../redux/actions/students-list";
import { getActiveProduct } from "../../../../redux/selectors/active-product";
import { RdxStoreState } from "../../../../redux/types/state";
import { selectedSectionOptions } from "../metadata";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const SelectedSectionFilter = () => {
  const dispatch = useDispatch<any>();
  const activeProduct = useSelector(getActiveProduct);
  const selectedSection = useSelector(
    ({
      studentsList: {
        workingFilter: { selectedSection }
      }
    }: RdxStoreState) => selectedSection
  );

  return (
    <Form.Item label={MESSAGES.SelectedSectionFilter}>
      <Select<string>
        value={selectedSection}
        data-qa-label="selected-section"
        getPopupContainer={({ parentNode }) => {
          return parentNode as HTMLElement;
        }}
        onChange={(selectedSection: SelectedSectionValue) => {
          dispatch(doUpdateWorkingFilter({ selectedSection }));
        }}
      >
        {selectedSectionOptions(activeProduct).map(({ label, value }) => (
          <Select.Option value={value} key={value} data-qa-label={`ca-sfilter-${value}`}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
