import { Table } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import { SorterResult } from "antd/lib/table/interface";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router";
import { routes } from "../../../routes";
import { StudyPlansListItem } from "../../../services/nav-api/studyPlans/model";
import { SortKey, SortOrder, StudyPlansContext } from "../hooks/useStudyPlanList";
import { createColumns } from "./Columns";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlansTable = () => {
  const navigateTo = useNavigate();
  return (
    <StudyPlansContext.Consumer>
      {({ loading, data, sort, paging, filters }) => (
        <Table
          rowKey="id"
          key="id"
          size="small"
          loading={loading}
          dataSource={data}
          scroll={{ x: 1000 }}
          columns={createColumns({ sort, filters, paging })}
          rowClassName="cursor-pointer"
          sortDirections={["ascend", "descend", "ascend"]}
          pagination={paging.value}
          onRow={(row: StudyPlansListItem) => ({
            onClick: () => {
              navigateTo(routes.studyPlansPreview.resolve({ id: row.id.toString() }));
            }
          })}
          onChange={(pVal: PaginationProps, _props: any, sr: SorterResult) => {
            if (!_.isEqual(sr.field, sort.key) || !_.isEqual(sr.order, sort.order)) {
              sort.onChange(sr.field as SortKey, sr.order as SortOrder);
            }

            if (!_.isEqual(pVal.pageSize, paging.value.pageSize) || !_.isEqual(pVal.current, paging.value.current)) {
              paging.onChange(pVal);
            }
          }}
        />
      )}
    </StudyPlansContext.Consumer>
  );
};
