import { compile } from "path-to-regexp";

export const userDetails = (() => {
  const path = "/admin/user-details/:id";
  const toPath = compile(path);
  return {
    path,
    resolve(params: Partial<Record<string, string | string[]>>) {
      return toPath(params);
    }
  };
})();
