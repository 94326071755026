import { GetMySelfResponse } from "../../../services/nav-api/myself/types";
import { TenantProduct } from "../../../services/nav-api/tenants/types";
import { ContentVersion } from "../../actions/contentversion-switch";
import {
  FETCH_MYSELF_COMPLETED,
  FETCH_MYSELF_FAILED,
  FETCH_MYSELF_STARTED,
  UPDATE_ACTIVE_PRODUCT,
  UPDATE_ACTIVE_USER,
  UPDATE_CONTENT_VERSION,
  UPDATE_EULA_ACCEPTED_COMPLETED,
  UPDATE_PUSHED_ALERTS
} from "../../actions/myself/actions";
import { RdxFetchStatus } from "../../types/status";
import { createReducer } from "../../utils/redux-utils";
import { MySelfPayload, RdxMySelfState } from "./types";

// -------------------------------------------------------------------------------------------------
// - Reducer
// -------------------------------------------------------------------------------------------------

export default createReducer(
  {
    status: RdxFetchStatus.NONE,
    user: undefined,
    userSettings: undefined,
    userRoles: undefined
  },
  {
    [FETCH_MYSELF_STARTED]: (): RdxMySelfState => ({
      status: RdxFetchStatus.LOADING,
      user: undefined,
      userSettings: undefined,
      userRoles: undefined,
      error: undefined
    }),

    [FETCH_MYSELF_COMPLETED]: (_, { user, userSettings, userRoles }: MySelfPayload): RdxMySelfState => ({
      status: RdxFetchStatus.COMPLETED,
      error: undefined,
      user,
      userRoles,
      userSettings
    }),

    [UPDATE_ACTIVE_USER]: (state, activeUser: Partial<GetMySelfResponse>): RdxMySelfState => {
      const ns = { ...state };
      ns.user! = {
        ...ns.user!,
        ...activeUser
      };
      return ns;
    },

    [FETCH_MYSELF_FAILED]: (_, error): RdxMySelfState => ({
      status: RdxFetchStatus.FAILED,
      user: undefined,
      userSettings: undefined,
      userRoles: undefined,
      error
    }),

    [UPDATE_EULA_ACCEPTED_COMPLETED]: (state: RdxMySelfState, acceptedEulaVersion: string): RdxMySelfState => {
      const ns = { ...state };
      if (ns.userSettings) {
        ns.userSettings = { ...(ns.userSettings || {}), acceptedEulaVersion };
      }

      return ns;
    },

    [UPDATE_PUSHED_ALERTS]: (state: RdxMySelfState, pushedAlertIds: number[]): RdxMySelfState => {
      const ns = { ...state };
      if (ns.userSettings) {
        ns.userSettings = { ...(ns.userSettings || {}), pushedAlertIds };
      }

      return ns;
    },

    [UPDATE_ACTIVE_PRODUCT]: (state, activeProduct: TenantProduct): RdxMySelfState => {
      const ns = { ...state };
      if (ns.userSettings) {
        ns.userSettings = { ...(ns.userSettings || {}), activeProduct };
      }

      return ns;
    },

    [UPDATE_CONTENT_VERSION]: (state, { version }: { version: ContentVersion }): RdxMySelfState => {
      const ns = { ...state };
      if (ns.userSettings && version) {
        ns.userSettings = { ...(ns.userSettings || {}), contentVersion: version };
      }

      return ns;
    }
  }
);
