import { Typography } from "antd";
import React from "react";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarDayCellStartDate = () => (
  <li key="start-date">
    <Typography.Text className="text-xs text-white bg-[#004986] px-1" strong>
      Start Date
    </Typography.Text>
  </li>
);
