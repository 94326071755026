import { Flex, Typography } from "antd";
import React from "react";
import { PiCalendarSlashDuotone } from "react-icons/pi";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarDayCellDayOff = () => (
  <li>
    <Typography.Text disabled>
      <Flex vertical={false} align="center" justify="start" gap={6}>
        <PiCalendarSlashDuotone />
        Day off
      </Flex>
    </Typography.Text>
  </li>
);
