import { UnauthPayload } from "../../reducers/unauth/types";
import { Action } from "../../types/action";
import { FETCH_UNAUTH_COMPLETED } from "./actions";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doCompleteFetchUnauth = (ffs: string[]): Action<UnauthPayload> => ({
  type: FETCH_UNAUTH_COMPLETED,
  payload: { ffs }
});
