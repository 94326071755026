import _ from "lodash";
import React from "react";
import { MESSAGES } from "../../../../i18n";
import { initialHierarchyLevelIncludedByReportType } from "../settings";
import { HierarchyLevel, HierarchyLevelIncludedByReportType, ReportType } from "../types";

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const findLabelByLevelAndReportType = (level: HierarchyLevel, reportType: ReportType) => {
  if (_.isEqual(reportType, ReportType.HOMEWORK_PERFORMANCE_REPORT)) {
    switch (level) {
      case HierarchyLevel.SECTION:
        return MESSAGES.SectionDetailsName_Sections;
      case HierarchyLevel.UNIT:
        return MESSAGES.SectionDetailsName_Units;
      case HierarchyLevel.MODULE:
        return MESSAGES.SectionDetailsName_Modules;
    }
  } else {
    switch (level) {
      case HierarchyLevel.SECTION:
        return MESSAGES.SectionDetailsName_Sections;
      case HierarchyLevel.UNIT:
        return MESSAGES.SectionDetailsName_UnitsAndExams;
      case HierarchyLevel.MODULE:
        return MESSAGES.SectionDetailsName_Modules;
    }
  }
};

const findLevelsByReportType = (reportType: ReportType) =>
  _.chain([
    ...(!_.isEqual(reportType, ReportType.HOMEWORK_PERFORMANCE_REPORT) ? [HierarchyLevel.SECTION] : []),
    HierarchyLevel.UNIT,
    HierarchyLevel.MODULE
  ])
    .map(value => ({ label: findLabelByLevelAndReportType(value, reportType), value }))
    .value();

// -------------------------------------------------------------------------------------------------
// - Hook
// -------------------------------------------------------------------------------------------------

export const useHierarchyLevel = (reportType: ReportType) => {
  const [hierarchyLevelIncludedByReportType, setHierarchyLevelIncludedByReportType] =
    React.useState<HierarchyLevelIncludedByReportType>(initialHierarchyLevelIncludedByReportType);

  return {
    value: hierarchyLevelIncludedByReportType,
    options: findLevelsByReportType(reportType),
    valueForCurReportType: !!reportType ? hierarchyLevelIncludedByReportType[reportType] : null,
    isAvailableForReportType: _.includes(_.keys(hierarchyLevelIncludedByReportType), reportType),
    setValue: (reportType: ReportType, option: HierarchyLevel) => {
      setHierarchyLevelIncludedByReportType(previous => ({ ...previous, [reportType]: option }));
    },
    reset: () => {
      setHierarchyLevelIncludedByReportType(initialHierarchyLevelIncludedByReportType);
    }
  };
};
