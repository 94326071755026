import { useDispatch, useSelector } from "react-redux";
import { FilterTag } from "../../../core/model";
import { formatMsg, MESSAGES } from "../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../redux/actions/students-list";
import { RdxStoreState } from "../../../redux/types/state";

// -------------------------------------------------------------------------------------------------
// - Hooks: useTenantFilter
// -------------------------------------------------------------------------------------------------

export const useTenantFilter = (): FilterTag[] => {
  const dispatch = useDispatch<any>();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);
  const { tenantById = {} } = useSelector((state: RdxStoreState) => state.tenants.tenantsDropdown!);

  return activeFilter?.filter?.tenantId
    ? [
        {
          name: "tenant",
          onClear: () => {
            dispatch(
              doUpdateWorkingFilter({
                tenantId: undefined
              })
            );
            dispatch(doApplyFilter());
          },
          text: formatMsg(MESSAGES.FilterTagTenant, tenantById[activeFilter.filter.tenantId]?.name || "")
        }
      ]
    : [];
};
