import queryString, { BooleanOptional, IStringifyOptions } from "qs";

export const getUrlWithEncodedParams = (
  url: string,
  params?: { [key: string]: any },
  paramOptions?: IStringifyOptions<BooleanOptional>
) => {
  if (params) {
    return `${url}?${queryString.stringify(params, paramOptions)}`;
  }
  return url;
};
