import { Card, Empty, Flex, Typography } from "antd";
import _ from "lodash";
import isNil from "lodash/isNil";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { AltID, sectionColorByAltId } from "../../../constants/courses";
import { MESSAGES } from "../../../i18n";
import { DailyStudyTimeResponse } from "../../../services/external-api";
import { StudyCalendarUtils } from "../../StudyPlans/hooks/useStudyPlanEditor/utils/StudyCalendarUtils";
import { TimeUtils } from "./timeUtils";
import { ChartRange } from "./types";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

type ChartData = Array<{ xField: number | string; studyTime: number }>;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const ANTDLineChart = ({
  rawData,
  range,
  loading,
  section
}: {
  rawData: DailyStudyTimeResponse | undefined;
  range: ChartRange;
  loading: boolean;
  section?: AltID;
}) => {
  const [data, setData] = useState<ChartData>([]);
  const [average, setAverage] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!rawData || !section) {
      return;
    }

    if (range === "week") {
      const weekData = (rawData.days || []).map(({ day, sections }) => {
        const date = moment(day);
        const time =
          _.chain(sections) //
            .filter({ sectionAltId: section })
            .head()
            .value()?.secs ?? 0;

        return {
          xField: date.format("DD-MM"),
          studyTime: TimeUtils.roundToMinutes(time)
        };
      }) as ChartData;
      setData(weekData);
      return;
    }

    const monthData = (rawData.days || []).map(({ day, sections }) => {
      const date = moment(day);
      const time =
        _.chain(sections) //
          .filter({ sectionAltId: section })
          .head()
          .value()?.secs ?? 0;

      return {
        xField: date.format("DD-MM"),
        studyTime: TimeUtils.roundToMinutes(time)
      };
    }) as ChartData;
    setData(monthData);
  }, [loading, rawData, section]);

  useEffect(() => {
    if (data) {
      const total = data.map(d => d.studyTime).reduce((a, b) => a + b, 0);
      setAverage(Math.ceil(total / data.length));
    }
  }, [data]);

  const showNoData = !loading && (isNaN(average!) || isNil(average) || average === 0);
  return (
    <Card className="grow">
      <Flex vertical align="center" justify="space-between" gap={4}>
        <Typography.Text type="secondary" className="w-full">
          {MESSAGES.DailyStudyTime.toUpperCase()}
        </Typography.Text>
        {showNoData ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <>
            <Flex gap={4}>
              <Typography.Text strong>{TimeUtils.minutesToHM(average!)}</Typography.Text>
              <Typography.Text>{MESSAGES.AverageDailyTime}</Typography.Text>
            </Flex>
            <LineChart
              width={400}
              height={210}
              margin={{ top: 10 }}
              data={_.map(data, ({ xField, studyTime }) => ({
                name: xField,
                value: studyTime
              }))}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <Line type="monotone" dataKey="value" stroke={sectionColorByAltId[section!]} activeDot={{ r: 8 }} />
              <XAxis
                dataKey="name"
                tick={{
                  fontSize: 12,
                  fontFamily: "Arial, sans-serif",
                  fill: sectionColorByAltId[section!]
                }}
              />
              <YAxis
                dataKey="value"
                tickCount={Math.min(Math.max(...data.map(d => d.studyTime)) + 1, 5)}
                tickFormatter={TimeUtils.formatter}
                tick={{
                  fontSize: 12,
                  fontFamily: "Arial, sans-serif",
                  fill: sectionColorByAltId[section!]
                }}
              />
              <Tooltip
                labelFormatter={value => `${MESSAGES.Day}: ${value}`}
                formatter={value => [
                  `${MESSAGES.StudyTime}: ${_.isEqual(value, 0) ? "0m" : StudyCalendarUtils.formatDuration(value as number)}`
                ]}
              />
            </LineChart>
          </>
        )}
      </Flex>
    </Card>
  );
};
