import { Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getTenantsDropdownDataSource } from "../../../../redux/selectors/tenants-dropdown-data-source";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

const useTenantName = () => {
  const tenants = useSelector(getTenantsDropdownDataSource);
  const { editor } = React.useContext(StudyPlanEditorContext);
  return _.chain(tenants)
    .filter(t => _.isEqual(t.id, editor.tenantId.value))
    .head()
    .value()?.name;
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantField = () => {
  const tenantName = useTenantName();
  return <Typography.Text type={_.isEmpty(tenantName) ? "secondary" : undefined}>{tenantName}</Typography.Text>;
};
