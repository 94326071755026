import { Button, Card, Flex, Skeleton, Spin, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router";
import { BaseLayout } from "../../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../../components/NavigationDrawer/types";
import { MESSAGES } from "../../../i18n";
import { useStudyPlanWritePermission } from "../../../redux/hooks/useStudyPlansPermissions";
import { routes } from "../../../routes";
import { StudyPlanEditorContext, useStudyPlanEditor } from "../hooks/useStudyPlanEditor";
import { EditorRemoteErrorResult } from "./EditorRemoteErrorResult";
import { EditorStepContent } from "./EditorStepContent";
import { EditorStepFooter } from "./EditorStepFooter";
import { EditorStepNav } from "./EditorStepNav";
import { EditorSummary } from "./EditorSummary";
import { EditorUnauthResult } from "./EditorUnauthResult";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlansEditor = () => {
  const navigateTo = useNavigate();
  const studyPlanEditor = useStudyPlanEditor();
  const canEditStudyPlan = useStudyPlanWritePermission();
  return (
    <BaseLayout
      activeNavDrawerKey={NavigationDrawerOptionKey.STUDY_PLAN_EDITOR}
      breadcrumb={[
        {
          name: MESSAGES.StudyPlansTitle,
          href: routes.studyPlans.resolve()
        },
        {
          name: _.isNil(studyPlanEditor.remoteStudyPlan.remoteId) ? (
            MESSAGES.NewStudyPlanTitle
          ) : (
            <Typography.Text>
              Editing{" "}
              <Typography.Text strong italic>
                {_.isEmpty(studyPlanEditor.editor.name.value) //
                  ? "Unnamed Study Plan"
                  : `${studyPlanEditor.editor.name.value}`}
              </Typography.Text>
            </Typography.Text>
          )
        }
      ]}
    >
      {
        // -----------------------------------------------------------------------------------------
        //  Header
        // -----------------------------------------------------------------------------------------
      }
      <Flex align="center" gap={4}>
        <Typography.Title level={2} className="grow" data-qa-label="study-plans-editor">
          {_.isNil(studyPlanEditor.remoteStudyPlan.remoteId)
            ? MESSAGES.NewStudyPlanTitle //
            : MESSAGES.EditStudyPlanTitle}
        </Typography.Title>
        {_.gt(studyPlanEditor.remoteStudyPlan.remoteId, 0) ? (
          <Button
            loading={studyPlanEditor.remoteStudyPlan.loading}
            onClick={() => {
              navigateTo(
                routes.studyPlansPreview.resolve({
                  id: studyPlanEditor.remoteStudyPlan.remoteId!.toString()
                  //
                })
              );
            }}
          >
            Back to Details
          </Button>
        ) : null}
      </Flex>

      {
        // -----------------------------------------------------------------------------------------
        //  Body
        // -----------------------------------------------------------------------------------------
      }

      {canEditStudyPlan ? (
        studyPlanEditor.remoteStudyPlan.hasError ? (
          <EditorRemoteErrorResult />
        ) : studyPlanEditor.remoteStudyPlan.loading ? (
          <Card>
            <Skeleton active />
          </Card>
        ) : (
          <Spin spinning={studyPlanEditor.apiCallbacks.loading}>
            <StudyPlanEditorContext.Provider value={studyPlanEditor}>
              <Flex gap={6}>
                <Flex vertical gap={6}>
                  <Card className="grow">
                    <EditorStepNav />
                    <EditorStepContent />
                  </Card>
                  <Card>
                    <EditorStepFooter />
                  </Card>
                </Flex>
                <div>
                  <Card style={{ width: 450, minWidth: 450, maxWidth: 450 }}>
                    <EditorSummary />
                  </Card>
                </div>
              </Flex>
            </StudyPlanEditorContext.Provider>
          </Spin>
        )
      ) : (
        <EditorUnauthResult />
      )}
    </BaseLayout>
  );
};
