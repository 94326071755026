import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import _ from "lodash";
import { CellRenderInfo } from "rc-picker/lib/interface";
import React from "react";
import { useStudyPlanWritePermission } from "../../../../../../redux/hooks/useStudyPlansPermissions";
import { StudyCalendarUtils } from "../../../../hooks/useStudyPlanEditor/utils/StudyCalendarUtils";
import { StudyPlanCustomTasksEditorContext } from "../../../hooks/useStudyPlanCustomTasksEditor";
import { StudyPlanContext, StudyPlanValue } from "../../../hooks/useStudyPlanViewerData";
import { CalendarDayCellTasks } from "./CalendarDayCellTasks";
import { CalendarDayContext } from "./CalendarDayContext";
import { CalendarCellUtils } from "./CalendarDayUtils";

// -------------------------------------------------------------------------------------------------
// - Renderer
// -------------------------------------------------------------------------------------------------

export const fullCellRenderer =
  (studyPlan: StudyPlanValue) => (day: dayjs.Dayjs, info: CellRenderInfo<dayjs.Dayjs>) => {
    const canCreateTask = useStudyPlanWritePermission();
    const curDay = info.today.isSame(day, "day");
    const dayDur = StudyCalendarUtils.getStudyTimePerDayFmt(day, studyPlan.calendar!);
    const [mouseHover, setMouseHover] = React.useState(false);
    const isCurDayDisabled =
      _.some(studyPlan.summary.daysOff, dayOff => dayOff.isSame(day, "day")) ||
      day.isBefore(studyPlan!.summary!.range?.[0]) ||
      day.isAfter(studyPlan!.summary!.range?.[1]);

    return (
      <StudyPlanContext.Consumer>
        {plan => (
          <div
            onMouseEnter={() => setMouseHover(true)}
            onMouseLeave={() => setMouseHover(false)}
            className={classNames(
              `${info.prefixCls}-cell-inner`,
              `${info.prefixCls}-calendar-date`,
              {
                [`${info.prefixCls}-calendar-date-today`]: curDay
              }
              //
            )}
          >
            <Flex className={`${info.prefixCls}-calendar-date-value`}>
              <strong>{day.date()}</strong>
              <div className="grow" />
              {!_.isNil(dayDur) ? (
                <small>
                  <i>{dayDur}</i>
                </small>
              ) : null}

              <StudyPlanCustomTasksEditorContext.Consumer>
                {({ actions }) =>
                  mouseHover && !isCurDayDisabled && canCreateTask ? (
                    <Button
                      icon={<PlusCircleOutlined />}
                      title="Create a task"
                      className="px-0 mx-0"
                      type="link"
                      size="small"
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        actions.openEditor({
                          operation: "create_at_day",
                          atDay: day
                        });
                      }}
                    />
                  ) : null
                }
              </StudyPlanCustomTasksEditorContext.Consumer>
            </Flex>

            <div className={`${info.prefixCls}-calendar-date-content`}>
              <CalendarDayContext.Provider value={CalendarCellUtils.calculateDateInfo(day, plan)}>
                <CalendarDayCellTasks />
              </CalendarDayContext.Provider>
            </div>
          </div>
        )}
      </StudyPlanContext.Consumer>
    );
  };
