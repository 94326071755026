import _ from "lodash";
import React from "react";
import { StudyPlansListItem } from "../../../../../services/nav-api/studyPlans/model";
import { Field, FromApiResponseValue, StudyPlanEditorValue, ToApiRequestValue } from "./model";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type TagIdsFieldValue = StudyPlanEditorValue["tagIds"];
export type TagIdsField = Field<
  TagIdsFieldValue,
  {
    toApiRequestValue: ToApiRequestValue<number[]>;
    fromApiResponseValue: FromApiResponseValue<number[]>;
  }
>;

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useTagIds = (initVal: TagIdsFieldValue = []): TagIdsField => {
  const [value, setValue] = React.useState<TagIdsFieldValue>(initVal);
  return {
    value,
    setValue: (val: TagIdsFieldValue) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(initVal);
    },
    toApiRequestValue: () => {
      return value!;
    },
    fromApiResponseValue: (rsp: StudyPlansListItem): TagIdsFieldValue => {
      const iVal = _.map(rsp.tags, ({ id }) => id);
      setValue(iVal);
      return iVal;
    }
  };
};
