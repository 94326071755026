import React from "react";

import { MESSAGES } from "../../i18n";
import { GenericApiFailureModal } from "./ModalForGenericApiFailure";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const NeedReloadModal = ({ onClose }: { onClose: () => void }) => (
  <GenericApiFailureModal
    message={MESSAGES.ErrPageNeedsReload}
    buttons={[
      {
        onClick: () => {
          onClose();
          window.location.reload();
        },
        label: MESSAGES.ApiFailureMultipleSessionBtnCaption
      }
    ]}
  />
);
