import _ from "lodash";
import { Node } from "./types";

// -------------------------------------------------------------------------------------------------
//  Utils
// -------------------------------------------------------------------------------------------------

export const getAllRootIds = (tree: Node[] | null) => {
  return _.map(tree, n => n.id);
};
