import { Badge } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { getNewAlertsCount } from "../../../redux/selectors/alerts-reader/alerts";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  qaLabel?: string;
  children: React.ReactNode | React.ReactNode[];
  maxWidth?: number | string;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertsBadge = ({ qaLabel, children, maxWidth }: OwnProps) => {
  const count = useSelector(getNewAlertsCount);
  return (
    <Badge size="small" count={count} overflowCount={100} data-qa-label={qaLabel}>
      <div
        style={
          maxWidth
            ? {
                maxWidth,
                overflow: "hidden",
                textOverflow: "ellipsis"
              }
            : {}
        }
      >
        {children}
      </div>
    </Badge>
  );
};
