import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { altIdsByProduct } from "../../../../constants/courses";
import {
  getKey4ActiveStudents,
  getKey4ActiveStudentsByAltId,
  getKey4EtAboutToLoseOrLostCredits,
  getKey4EtNoExamTrackerData,
  getKey4EtPassedAllParts,
  getKey4EtPassedAnyPart,
  getKey4EtPassedByAltId,
  getKey4EtReportedByAltId,
  getKey4MdExpiredLicensesInLessThan3Months,
  getKey4MdExpiredLicensesInMoreThan3Months,
  getKey4MdFollowedStudents,
  getKey4MdStudyPlanPerformance,
  getKey4MdStudyPlanUsage,
  getKey4MdSudentsWithoutSudyTime
} from "../../../../services/nav-api/tenant-dashboard/keys";
import { RdxStats, RdxTenantDashboardState } from "../../../reducers/dashboard/tenant/model";
import { getActiveProduct } from "../../../selectors/active-product";
import { shouldFetch } from "../../../selectors/dashboard/tenant";
import { getTenantDashboardRosterFilter } from "../../../selectors/tenant-dashboard-roster-filter";
import { Action, ThunkAction } from "../../../types/action";
import { RdxStoreState } from "../../../types/state";
import { doActivateFilter, doClearFilter } from "./filter";

// -------------------------------------------------------------------------------------------------
// - Actions
// -------------------------------------------------------------------------------------------------

export const DASHBOARD_FETCH_STARTED = "DASHBOARD_FETCH_STARTED";
export const DASHBOARD_FETCH_COMPLETED = "DASHBOARD_FETCH_COMPLETED";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

const doFetchStarted = (): Action<number> => ({
  type: DASHBOARD_FETCH_STARTED,
  payload: 0
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

const doFetchCompleted = (
  payload: Partial<RdxTenantDashboardState> = {}
): Action<Partial<RdxTenantDashboardState>> => ({
  type: DASHBOARD_FETCH_COMPLETED,
  payload
});

// -------------------------------------------------------------------------------------------------
// - Action BUilder
// -------------------------------------------------------------------------------------------------

export const doFetchTenantDashboard =
  (clearFilter: boolean = false): ThunkAction =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState, { navApi }): Promise<void> => {
    if (!shouldFetch(getState())) {
      return;
    }

    if (clearFilter) {
      dispatch(doClearFilter());
    }

    dispatch(doFetchStarted());

    try {
      const product = getActiveProduct(getState());
      const availableAltIds = altIdsByProduct[product!];
      const token = (
        await navApi.studentRoster.createStudentRoster(
          getTenantDashboardRosterFilter(getState()) //
        )
      ).token;

      dispatch(doActivateFilter());

      const aggregations = (await navApi.tenantDashboard.fetchData(token, product!))?.aggregations;
      const stats: RdxStats = {
        studentsActivity: {
          activeStudents: aggregations[getKey4ActiveStudents()],
          inactiveStudents:
            aggregations[getKey4ActiveStudents()] -
            _.reduce(availableAltIds, (a, altId) => a + aggregations[getKey4ActiveStudentsByAltId(altId)], 0),

          activeStudentsBySection: _.reduce(
            availableAltIds,
            (acc, altId) => ({
              ...acc,
              [altId]: aggregations[getKey4ActiveStudentsByAltId(altId)]
            }),
            {}
          )
        },
        examTracker: {
          passedAllParts: aggregations[getKey4EtPassedAllParts()],
          passedAnyPart: aggregations[getKey4EtPassedAnyPart()],
          noExamTrackerData: aggregations[getKey4EtNoExamTrackerData()],
          aboutToLooseCredits: aggregations[getKey4EtAboutToLoseOrLostCredits()],
          passRateBySection: _.reduce(
            availableAltIds,
            (acc, altId) => ({
              ...acc,
              [altId]:
                (aggregations[getKey4EtPassedByAltId(altId)] * 1.0) / aggregations[getKey4EtReportedByAltId(altId)]
            }),
            {}
          )
        },

        licensesStats: {
          expiredLicensesInMoreThan3Months: aggregations[getKey4MdExpiredLicensesInMoreThan3Months()],
          expiredLicensesInLessThan3Months: aggregations[getKey4MdExpiredLicensesInLessThan3Months()]
        },

        studyPlanUsageStats: {
          withStudyPlan: aggregations[getKey4MdStudyPlanUsage(true)],
          withoutStudyPlan: aggregations[getKey4MdStudyPlanUsage(false)]
        },

        studyPlanPerformanceStats: {
          behindSchedule: aggregations[getKey4MdStudyPlanPerformance(true)],
          onTrack: aggregations[getKey4MdStudyPlanPerformance(false)]
        },

        studentsWithoutStudyTime: aggregations[getKey4MdSudentsWithoutSudyTime()],
        followedStudents: aggregations[getKey4MdFollowedStudents()]
      };

      dispatch(doFetchCompleted({ stats }));
    } catch (e) {
      // nothing to do
    }

    dispatch(doFetchCompleted());
  };
