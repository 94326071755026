import dayjs from "dayjs";
import React from "react";
import { StudyPlanCalendar, StudyPlanTask } from "../../../../../../services/nav-api/studyPlans/model";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type CalendarDayValue = {
  day: dayjs.Dayjs;
  isStartDate: boolean;
  isEndDate: boolean;
  isDayOff: boolean;
  tasks: Array<StudyPlanTask & { quarterHours?: number }>;
  calendar: StudyPlanCalendar<dayjs.Dayjs>[];
};

// -------------------------------------------------------------------------------------------------
// - Context
// -------------------------------------------------------------------------------------------------

export const CalendarDayContext = React.createContext<CalendarDayValue>({
  day: dayjs(),
  isStartDate: false,
  isEndDate: false,
  isDayOff: false,
  tasks: [],
  calendar: []
});
