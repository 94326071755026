import { Breadcrumb, Button, Flex, Result } from "antd";
import _ from "lodash";
import React, { ErrorInfo } from "react";
import { Link } from "react-router-dom";
import { MESSAGES } from "../i18n";
import { routes } from "../routes";
import { errorTracker } from "../services/error-tracking";
import { NavigationDrawer } from "./NavigationDrawer";
import { NavigationDrawerOptionKey } from "./NavigationDrawer/types";

// -------------------------------------------------------------------------------------------------
// - types
// -------------------------------------------------------------------------------------------------

type ComponentProps = React.HTMLProps<HTMLDivElement> & {
  activeNavDrawerKey: NavigationDrawerOptionKey;
  breadcrumb?: { name: React.ReactNode; href?: string }[];
};

// -------------------------------------------------------------------------------------------------
// - constants
// -------------------------------------------------------------------------------------------------

export const ScrollableContentDivId = "scrollable-content";

// -------------------------------------------------------------------------------------------------
// - component
// -------------------------------------------------------------------------------------------------
export class BaseLayout extends React.Component<
  ComponentProps,
  { catchedError: { error: Error; info: ErrorInfo } | undefined }
> {
  state = {
    catchedError: undefined
  };

  /**
   * Catch Error
   * @param error
   * @param info
   */
  componentDidCatch(error: Error, info: ErrorInfo) {
    errorTracker.notifyError(error);
    this.setState({ catchedError: { error, info } });
  }

  /**
   * Render content
   */
  renderContent() {
    const { children, breadcrumb } = this.props;

    if (this.state.catchedError) {
      return (
        <Result
          status="error"
          title={MESSAGES.RenderContentErrorTitle}
          subTitle={MESSAGES.RenderContentErrorSubTitle}
          extra={[
            <Link to={routes.home.resolve()} key="back-home">
              <Button type="primary">Back Home</Button>
            </Link>
          ]}
        />
      );
    }

    return (
      <>
        {breadcrumb && breadcrumb.length ? (
          <Breadcrumb
            style={{ marginBottom: "16px" }}
            items={_.map(breadcrumb, ({ name, href }) => ({ title: href ? <Link to={href}>{name}</Link> : name }))}
          />
        ) : null}
        {children}
      </>
    );
  }

  /**
   * Render
   */
  render() {
    const { activeNavDrawerKey } = this.props;

    return (
      <Flex
        wrap={false}
        vertical={false}
        className="bg-gray-100"
        style={{
          minHeight: "100vh",
          maxHeight: "100vh",
          height: "100vh"
        }}
      >
        <NavigationDrawer active={activeNavDrawerKey} />
        <div className="overflow-auto h-full w-full grow">
          <div className="p-4">{this.renderContent()}</div>
        </div>
      </Flex>
    );
  }
}
