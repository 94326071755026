import { Card, Flex, Form, Typography } from "antd";
import Tree, { DataNode } from "antd/es/tree";
import _ from "lodash";
import React from "react";
import { RenderChildrenIf } from "../../../../../components/ConditionalRenderers";
import { TagNode } from "../../../../../redux/selectors/my-tenant-tags-tree";
import { StudyPlanEditorContext } from "../../../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const walkTree = (n: TagNode[] | null): DataNode[] =>
  _.reduce(
    n,
    (a, n) => {
      const children = walkTree(n.children);
      const isLeaf = _.isEmpty(children);
      return [
        ...a,
        {
          key: n.id,
          children,
          selectable: isLeaf,
          checkable: isLeaf,
          title: n.name
        }
      ];
    },
    []
  );

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const TagIdsField = () => (
  <StudyPlanEditorContext.Consumer>
    {({ editor: { tenantId, tenantTags, tagIds } }) =>
      _.gt(tenantId.value, 0) ? (
        <Form.Item required label="Please select the tags to which this study plan should be applied.">
          <Flex vertical gap={12}>
            <Card loading={tenantTags.loading}>
              <RenderChildrenIf condition={_.isEmpty(tenantTags.tree)}>
                <Typography.Text type="warning">No tags are available for the selected tenant.</Typography.Text>
              </RenderChildrenIf>
              <Tree
                virtual
                showLine
                checkable
                checkStrictly
                autoExpandParent
                defaultExpandAll
                selectable={false}
                height={450}
                checkedKeys={tagIds.value}
                treeData={!tenantTags.loading ? walkTree(tenantTags.tree) : []}
                titleRender={({ title, key }) => (
                  <Typography.Text strong={_.includes(tagIds.value, key)} key={key}>
                    <span>{title as string}</span>
                  </Typography.Text>
                )}
                onCheck={({ checked }: { checked: React.Key[]; halfChecked: React.Key[] }) => {
                  tagIds.setValue(checked as number[]);
                }}
              />
            </Card>
          </Flex>
        </Form.Item>
      ) : null
    }
  </StudyPlanEditorContext.Consumer>
);
