import _ from "lodash";
import React from "react";
import { useParams } from "react-router";
import { navApi } from "../../../../services/nav-api";
import { StudyPlansListItem } from "../../../../services/nav-api/studyPlans/model";
import { StudyPlanEditorFields } from "./useEditorFields";
import { StudyCalendarUtils } from "./utils/StudyCalendarUtils";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type RemoteStudyPlanValue = {
  loading: boolean;
  hasError: boolean;
  response: StudyPlansListItem | null;
  remoteId?: number;
};

// -------------------------------------------------------------------------------------------------
// - Context
// -------------------------------------------------------------------------------------------------

export const useRemoteStudyPlan = (e: StudyPlanEditorFields): RemoteStudyPlanValue => {
  const params = useParams<{ id?: string }>();
  const [hasError, setHasError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [response, setResponse] = React.useState<StudyPlansListItem | null>(null);

  // -----------------------------------------------------------------------------------------------
  // -
  // - Callbacks
  // -

  const fetchStudyPlan = React.useCallback(async (studyPlanId: number | string) => {
    try {
      setLoading(true);
      const rsp = await navApi.studyPlans.fetchStudyPlanById(studyPlanId);

      e.name.fromApiResponseValue(rsp);
      e.section.fromApiResponseValue(rsp);
      e.tenantId.fromApiResponseValue(rsp);
      e.tagIds.fromApiResponseValue(rsp);
      e.studyPace.fromApiResponseValue(rsp);
      e.practiceTests.fromApiResponseValue(rsp);
      e.flashcards.fromApiResponseValue(rsp);
      e.finalReview.fromApiResponseValue(rsp);
      e.weekDayHours.fromApiResponseValue(rsp);
      e.daysOff.fromApiResponseValue(rsp);
      e.lockUntil.fromApiResponseValue(rsp);

      const val = StudyCalendarUtils.getStudyTimePerWeek(
        e.startDate.fromApiResponseValue(rsp).toDate(),
        e.endDate.fromApiResponseValue(rsp)?.toDate()!,
        e.daysOff.fromApiResponseValue(rsp) ?? [],
        rsp.calendar
      );

      e.studyTimeWeek.setValue(val);

      setResponse(rsp);
    } catch (e) {
      setHasError(true);
      console.error(JSON.stringify(e, null, "\t"));
    } finally {
      setLoading(false);
    }
  }, []);

  // -----------------------------------------------------------------------------------------------
  // -
  // - Effect: Fetch remote data
  // -

  React.useEffect(() => {
    const studyPlanId = params.id;
    if (_.isNil(studyPlanId)) {
      return;
    }

    fetchStudyPlan(studyPlanId);
  }, [params.id]);

  return {
    loading,
    response,
    hasError,
    remoteId: !_.isNil(params?.id) ? parseInt(params?.id) : undefined
  };
};

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

export const remoteStudyPlanInitValue: RemoteStudyPlanValue = {
  hasError: false,
  loading: false,
  response: null
};
