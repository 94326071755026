import { FilterOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { doApplyFilter, doResetFilter } from "../../../../redux/actions/students-list";
import { getIsApplyStudentsListFilterDisabled } from "../../../../redux/selectors/is-apply-students-list-filter-disabled";
import { getIsClearStudentsListFilterDisabled } from "../../../../redux/selectors/is-clear-students-list-filter-disabled";
import { RdxStoreState } from "../../../../redux/types/state";
import { StudentActionsContext } from "../../hooks/useStudentActions";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const FormActions = () => {
  const dispatch = useDispatch<any>();
  const applyFilterDisabled = useSelector(getIsApplyStudentsListFilterDisabled);
  const clearFilterDisabled = useSelector(getIsClearStudentsListFilterDisabled);
  const workingFilter = useSelector((state: RdxStoreState) => state.studentsList.workingFilter);
  const applyButtonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (applyButtonRef.current) {
      applyButtonRef.current.focus();
    }
  }, [workingFilter]);

  return (
    <StudentActionsContext.Consumer>
      {({ onSelectionCancel, onShowFiltersChange }) => (
        <Flex gap={4}>
          <Button
            disabled={clearFilterDisabled}
            icon={<ReloadOutlined />}
            className="grow"
            htmlType="button"
            onClick={() => {
              dispatch(doResetFilter());
              onShowFiltersChange(false);
            }}
          >
            Reset
          </Button>
          <Button
            ref={applyButtonRef}
            icon={<FilterOutlined />}
            className="grow"
            htmlType="submit"
            type="primary"
            onClick={() => {
              onSelectionCancel();
              onShowFiltersChange(false);
              dispatch(doApplyFilter());
            }}
            disabled={applyFilterDisabled}
          >
            Apply
          </Button>
        </Flex>
      )}
    </StudentActionsContext.Consumer>
  );
};
