import { createSelector } from "reselect";

import { RdxTenantsState } from "../reducers/tenants";
import { RdxStoreState } from "../types/state";
import { TagNode } from "./my-tenant-tags-tree";
import { getStudentFilterTenantId } from "./student-filter-tenant-id";

export const getStudentFilterTenantTags: (state: RdxStoreState) => {
  byId?: { [id: number]: TagNode };
  ids?: number[];
} = createSelector(
  getStudentFilterTenantId,
  (state: RdxStoreState) => state.tenants,
  (tenantId, { tags, tenantTags }: RdxTenantsState) => {
    if (!tenantId) {
      return {};
    }

    const tenantTagsIds = tenantTags!.tagsIdsbyTenantId[tenantId] || [];

    const tenantTagsById: { [id: number]: TagNode } = tenantTagsIds.reduce((acc: { [id: number]: TagNode }, tagId) => {
      const tag = tags!.byId[tagId];

      acc[tagId] = { ...tag, key: tag.id, title: tag.name, children: [] };
      return acc;
    }, {});

    return {
      byId: tenantTagsById,
      ids: tenantTagsIds
    };
  }
);
