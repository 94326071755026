import { Form, Input } from "antd";
import React from "react";
import { FormControllerContext, UserInfoStatus } from "./hooks/userFormController";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const FirstName = () => (
  <FormControllerContext.Consumer>
    {({ firstName, userInfoStatus }) => (
      <Form.Item
        required
        label="First Name"
        wrapperCol={{ xs: 24, xl: 12 }}
        rules={[
          {
            required: true,
            message: "Please input you first name!"
          }
        ]}
      >
        <Input
          value={firstName.value ?? ""}
          placeholder="Enter your first name"
          onChange={e => {
            firstName.setValue(e.currentTarget.value ?? "");
          }}
          disabled={userInfoStatus.isIn([
            UserInfoStatus.PENDING_VERIFICATION,
            UserInfoStatus.UNAVAILABLE,
            UserInfoStatus.AVAILABLE_BUT_IMMUTABLE
            //
          ])}
        />
      </Form.Item>
    )}
  </FormControllerContext.Consumer>
);
