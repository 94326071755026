import Pusher from "pusher-js";
import { PUSHER_APP_KEY } from "../../../constants/pusher";
import { TaskStatus } from "../tasks/types";

// -------------------------------------------------------------------------------------------------
// - Globals: Create Pusher
// -------------------------------------------------------------------------------------------------

const pusher = new Pusher(PUSHER_APP_KEY, { cluster: "mt1" });

// -------------------------------------------------------------------------------------------------
// - WebSockets
// -------------------------------------------------------------------------------------------------

export const taskTokenChange = (token: string, cb: (data: { status: TaskStatus; message: string }) => void) => {
  const channel = pusher.subscribe(`task_${token}`);
  channel.bind("StatusChange", cb);
  return () => channel.unbind();
};
