import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import _ from "lodash";
import React from "react";
import { StudyPlanEditorContext } from "../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const EditorStepFooter = () => (
  <StudyPlanEditorContext.Consumer>
    {({ navigation, editor: { progress } }) => {
      return (
        <Flex vertical={false} justify="center" align="center">
          <Button icon={<LeftOutlined />} onClick={navigation.actions.moveBack}>
            {navigation.ui.moveBackTitle}
          </Button>
          <div className="grow" />
          {!_.isNil(navigation.ui.moveForwardTitle) ? (
            <Button
              icon={<RightOutlined />}
              disabled={!progress.isComplete}
              onClick={
                progress.isComplete //
                  ? navigation.actions.moveForward
                  : undefined
              }
            >
              {navigation.ui.moveForwardTitle}
            </Button>
          ) : null}
        </Flex>
      );
    }}
  </StudyPlanEditorContext.Consumer>
);
