import { Form, Input } from "antd";
import React from "react";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const NameField = () => (
  <StudyPlanEditorContext.Consumer>
    {({ editor: { name } }) => (
      <Form.Item label="Name" required>
        <Input
          allowClear
          maxLength={50}
          value={name.value!}
          data-qa-label="name"
          placeholder="Study plan name...."
          onChange={e => {
            name.setValue(e.currentTarget.value);
          }}
        />
      </Form.Item>
    )}
  </StudyPlanEditorContext.Consumer>
);
