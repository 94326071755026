import * as HttpClient from "../../http-client";
import { getUrlWithEncodedParams } from "../../http-client/utils";
import { createUnAuthHeaders } from "../headers";

// -------------------------------------------------------------------------------------------------
// - Forgot Password Utils: Initiate
// -------------------------------------------------------------------------------------------------

export const initiate = async (args: {
  username: string;
}): Promise<{
  userMsg: string;
  success: boolean;
}> => {
  const url = getUrlWithEncodedParams("/rest/unauth/forgotPassword");
  return HttpClient.post(url, createUnAuthHeaders(), args);
};

// -------------------------------------------------------------------------------------------------
// - Forgot Password Utils: Reset Password
// -------------------------------------------------------------------------------------------------

export const resetPassword = async (password: string, token: string): Promise<void> => {
  return HttpClient.put(`/rest/unauth/forgotPassword/${token}`, createUnAuthHeaders(), { password });
};

// -------------------------------------------------------------------------------------------------
// - Forgot Password Utils: Validate Token
// -------------------------------------------------------------------------------------------------

export const validateToken = async (token: string): Promise<{ username: string }> => {
  return HttpClient.get(`/rest/unauth/forgotPassword/${token}`, createUnAuthHeaders(), { token });
};
