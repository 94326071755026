import { createAsyncThunk } from "@reduxjs/toolkit";
import { navApi } from "../../../services/nav-api";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "../../types/status";

export const fetchTenantsDropdown = createAsyncThunk(
  "TENANT_DROPDOWN",
  async () => {
    const response = await navApi.tenants.getTenants({ limit: 1000, status: "Enabled" });
    return response;
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RdxStoreState;
      if (state.tenants.tenantsDropdown?.status === RdxFetchStatus.LOADING) {
        return false;
      }

      return undefined;
    }
  }
);
