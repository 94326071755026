import has from "lodash/has";
import { StudentRosterDetails } from "../../../services/nav-api/student-roster/types";
import { STUDENT_DETAILS_EDIT_COMPLETED } from "../../actions/student-details/constants";
import { EditStudentCompletedPayload } from "../../actions/student-details/student-details";
import {
  ADD_NEW_STUDENTS_COMPLETED,
  BULK_CHANGE_STUDENT_STATUS_COMPLETED,
  BULK_CHANGE_STUDENT_STATUS_STARTED,
  CompleteListStudentsPayload,
  CompleteStudentsListFilterPayload,
  LOAD_STUDENTS_LIST_COMPLETED,
  LOAD_STUDENTS_LIST_STARTED,
  STUDENTS_LIST_FILTER_COMPLETED,
  STUDENTS_LIST_FILTER_STARTED,
  UPDATE_STUDENT_FOLLOWED,
  UpdateStudentFollowedPayload,
  UpdateWorkingFilterPayload,
  WORKING_STUDENTS_FILTER_UPDATED,
  WorkingFilter
} from "../../actions/students-list";
import { RdxFetchStatus } from "../../types/status";
import { createReducer } from "../../utils/redux-utils";
import {
  DoChangeListSettingsPayload,
  LOAD_STUDENTS_LIST_FAILED,
  STUDENTS_LIST_SETTINGS_CHANGED,
  WORKING_STUDENTS_FILTER_CLEARED
} from "./../../actions/students-list";
import {
  BULK_CHANGE_STUDENT_TAGS_COMPLETED,
  BulkChangeCompletedPayload,
  MoveStudentsCompletedPayload,
  TENANT_TAGS_MOVE_STUDENTS_COMPLETED
} from "./../../actions/tenants/tags";

export type RdxStudentsListSortProps = { key: keyof StudentRosterDetails; order: "ascend" | "descend" };

export interface RdxStudentsListState {
  workingFilter: WorkingFilter;
  activeFilter?: {
    token?: string;
    filter?: WorkingFilter;
    loading: boolean;
  };
  dataSource?: number[];
  studentById: { [id: number]: StudentRosterDetails };
  paging: {
    page: number;
    totalItems?: number;
    itemsPerPage: number;
  };
  status?: RdxFetchStatus;
  sort?: RdxStudentsListSortProps;
  lastFetch?: number;
}

export const initialState: RdxStudentsListState = {
  workingFilter: {
    searchTerm: "",
    searchFields: ["username", "firstName", "lastName", "fullName"],
    tags: [],
    status: "included",
    studyTime: "any",
    studyPlan: "any",
    selectedSection: "any",
    lastActivity: "any",
    examTracker: "any",
    withoutTags: false,
    license: "active",
    metrics: {
      passedAllExams: false,
      hiddenStudents: "no",
      followedStudents: false
    },
    purchasedDate: {
      startDate: undefined,
      endDate: undefined
    }
  },
  studentById: {},
  paging: {
    page: 1,
    itemsPerPage: 50
  },
  sort: {
    key: "lastName",
    order: "ascend"
  },
  status: RdxFetchStatus.NONE
};

export const defaultWorkingFilter: WorkingFilter = { ...initialState.workingFilter, license: "any" };
export const initialWorkingFilter: WorkingFilter = { ...initialState.workingFilter };

export default createReducer(initialState, {
  [WORKING_STUDENTS_FILTER_UPDATED]: (state, payload: UpdateWorkingFilterPayload) => {
    const workingFilter = { ...state.workingFilter, metrics: { ...state.workingFilter.metrics }, ...payload };
    if (payload.withoutTags) {
      workingFilter.tags = [];
    }
    if (payload.tags && payload.tags.length) {
      workingFilter.withoutTags = false;
    }
    // payload.tenantId exists but its value is undefined then reset tenant specific filters
    if (has(payload, "tenantId") && payload.tenantId === undefined) {
      workingFilter.tags = [];
      workingFilter.metrics.hiddenStudents = "no";
    }

    return {
      ...state,
      workingFilter
    };
  },
  [WORKING_STUDENTS_FILTER_CLEARED]: state => ({
    ...state,
    workingFilter: { ...initialState.workingFilter }
  }),
  [LOAD_STUDENTS_LIST_STARTED]: state => ({
    ...state,
    status: RdxFetchStatus.LOADING
  }),
  [LOAD_STUDENTS_LIST_FAILED]: state => ({
    ...state,
    dataSource: [],
    status: RdxFetchStatus.FAILED
  }),
  [LOAD_STUDENTS_LIST_COMPLETED]: (state, payload: CompleteListStudentsPayload) => {
    const studentById = payload.students.reduce((byId: { [id: number]: StudentRosterDetails }, student) => {
      byId[student.id] = student;
      return byId;
    }, {});
    const dataSource = payload.students.map(s => s.id);

    return {
      ...state,
      dataSource,
      studentById,
      activeFilter: {
        ...state.activeFilter!,
        loading: false
      },
      paging: {
        ...state.paging,
        totalItems: payload.total
      },
      status: RdxFetchStatus.COMPLETED,
      lastFetch: payload.timestamp
    };
  },
  [STUDENTS_LIST_FILTER_STARTED]: state => ({
    ...state,
    activeFilter: {
      ...(state.activeFilter || {}),
      loading: true
    }
  }),
  [STUDENTS_LIST_FILTER_COMPLETED]: (state, { error, token, filter }: CompleteStudentsListFilterPayload) => {
    if (error) {
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          loading: false
        },
        status: RdxFetchStatus.FAILED
      };
    }

    return {
      ...state,
      activeFilter: {
        token: token,
        filter,
        loading: false
      },
      paging: {
        ...state.paging,
        page: 1
      }
    };
  },
  [STUDENTS_LIST_SETTINGS_CHANGED]: (
    state,
    { paging: { page, pageSize: itemsPerPage }, sort }: DoChangeListSettingsPayload
  ) => ({
    ...state,
    paging: {
      ...state.paging,
      page,
      itemsPerPage
    },
    sort
  }),
  [BULK_CHANGE_STUDENT_TAGS_COMPLETED]: (state, { error }: BulkChangeCompletedPayload) => {
    if (error) {
      return state;
    }
    return {
      ...state,
      status: RdxFetchStatus.STALE
    };
  },
  [BULK_CHANGE_STUDENT_STATUS_STARTED]: state => ({ ...state }),
  [BULK_CHANGE_STUDENT_STATUS_COMPLETED]: (state, { error }: BulkChangeCompletedPayload) => {
    if (error) {
      return state;
    }
    return {
      ...state,
      status: RdxFetchStatus.STALE
    };
  },
  [ADD_NEW_STUDENTS_COMPLETED]: state => ({
    ...state,
    status: RdxFetchStatus.STALE
  }),
  [STUDENT_DETAILS_EDIT_COMPLETED]: (state, { error }: EditStudentCompletedPayload) => {
    if (error) {
      return state;
    }
    return {
      ...state,
      status: RdxFetchStatus.STALE
    };
  },
  [TENANT_TAGS_MOVE_STUDENTS_COMPLETED]: (state, { error }: MoveStudentsCompletedPayload) => {
    if (error) {
      return state;
    }
    return {
      ...state,
      status: RdxFetchStatus.STALE
    };
  },
  [UPDATE_STUDENT_FOLLOWED]: (state, { studentId, followed }: UpdateStudentFollowedPayload) => {
    const studentDetails = state.studentById[studentId];

    if (!studentDetails) {
      return state;
    }

    return {
      ...state,
      studentById: {
        ...state.studentById,
        [studentId]: {
          ...studentDetails,
          followed
        }
      }
    };
  }
});
