import React from "react";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

type Alignment = "left" | "center" | "right";
type OwnProps = React.HTMLProps<HTMLDivElement> & { alignment?: Alignment };

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const DashboardCardTitle = (props: OwnProps) => (
  <div
    style={{
      color: "#6b6974",
      fontSize: "9pt",
      textTransform: "uppercase",
      width: "100%",
      textAlign: props.alignment ?? "center",
      paddingBottom: "3pt"
    }}
  >
    {props.children}
  </div>
);
