import { format } from "date-fns";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { FilterTag } from "../../../core/model";
import { MESSAGES, formatMsg } from "../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../redux/actions/users-list";
import { initialState } from "../../../redux/reducers/users-list";
import { getUsersListActiveFilter } from "../../../redux/selectors/users-list";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const initialValue = initialState.workingFilter.lastLoginDate;

// -------------------------------------------------------------------------------------------------
// - Hooks: useLastLoginDateFilter
// -------------------------------------------------------------------------------------------------

export const useLastLoginDateFilter = (): FilterTag[] => {
  const dispatch = useDispatch<any>();
  const activeFilter = useSelector(getUsersListActiveFilter);
  const value = activeFilter?.filter?.lastLoginDate;

  return !(
    _.isEqual(value?.startDate!, initialValue.startDate!) && //
    _.isEqual(value?.endDate!, initialValue.endDate!)
  )
    ? [
        {
          name: "lastLogin",
          onClear: () => {
            dispatch(
              doUpdateWorkingFilter({
                lastLoginDate: initialValue
              })
            );
            dispatch(doApplyFilter());
          },
          text: formatMsg(
            MESSAGES.FilterTagLastLoginDate,
            `${format(value?.startDate!, "P")} - ${format(value?.endDate!, "P")}`
          )
        }
      ]
    : [];
};
