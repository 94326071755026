import { StudentRosterDetails } from "../student-roster/types";
import { AltID } from "./../../../constants/courses";

export type StudentTag = {
  id: number;
  name: string;
  parent?: StudentTag;
};

export type StudentAffiliation = {
  ugrdUniversity: {
    name: string;
    graduationYear: number;
    graduationMonth: number;
  };
  grdUniversity?: {
    name: string;
    graduationYear: number;
    graduationMonth: number;
  };
  society?: {
    name: string;
  };
  isMilitaryAffiliated?: boolean;
  employer?: {
    name: string;
    isGovernmental: boolean;
  };
};

export type StudentTenant = {
  id: number;
  name: string;
  isExcluded: boolean;
  tags: StudentTag[];
};

export enum ExamStatus {
  COMPLETED = "Completed",
  EXPIRED = "Expired",
  FAILED = "Failed",
  NEW = "New",
  VALID = "Valid",
  COMPLETED_OR_EXPIRED = "CompletedOrExpired"
}

export type StudentFullDetails = StudentRosterDetails & {
  examsPassed: number;
  studentToken: string;
  affiliation?: StudentAffiliation;
  examInfo: {
    sections: {
      sectionAltId: string;
      attempts: {
        examDate: string;
        examGrade: number;
        examStatus: ExamStatus;
      }[];
    }[];
  };
  tenants: StudentTenant[];
  followed?: boolean;
  enrollments: {
    sectionAltId: AltID;
    purchaseDate?: string;
    expirationDate?: string;
    unlimited: boolean;
  }[];
};
