import { createSelector } from "reselect";
import { TenantProduct } from "../../services/nav-api/tenants/types";
import { RdxStoreState } from "../types/state";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getActiveProduct: (state: RdxStoreState) => TenantProduct = createSelector(
  (state: RdxStoreState) => state.myself.userSettings,
  settings => settings?.activeProduct || TenantProduct.CPA
);
