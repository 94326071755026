import { Checkbox, Form } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RenderChildrenIf } from "../../../../../components/ConditionalRenderers";
import { MESSAGES } from "../../../../../i18n";
import { WorkingFilterOtherFilters, doUpdateWorkingFilter } from "../../../../../redux/actions/students-list";
import { getIsGlobalUser } from "../../../../../redux/selectors/permissions/is-global-user";
import { getStudentFilterTenantId } from "../../../../../redux/selectors/student-filter-tenant-id";
import { RdxStoreState } from "../../../../../redux/types/state";
import { TenantFilters } from "./TenantFilters";

// -------------------------------------------------------------------------------------------------
// - Model
// -------------------------------------------------------------------------------------------------

interface FilterMetadata {
  label: string;
  key: keyof Omit<WorkingFilterOtherFilters, "hiddenStudents">;
  show: boolean;
}

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const getFilters = (showTenantFilters: boolean): FilterMetadata[] =>
  [
    {
      label: MESSAGES.FilterMetricFollowed,
      key: "followedStudents",
      show: showTenantFilters
    } as FilterMetadata
  ].filter(filter => filter.show);

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const OtherFilters = () => {
  const dispatch = useDispatch<any>();
  const isGlobalUser = useSelector(getIsGlobalUser);
  const showFollowedFilter = !isGlobalUser;
  const filters = getFilters(showFollowedFilter);
  const selectedTenantId = useSelector(getStudentFilterTenantId);
  const checkedByKey = useSelector(
    ({
      studentsList: {
        workingFilter: {
          metrics //
        }
      }
    }: RdxStoreState) => metrics
  );

  return filters.length > 0 || selectedTenantId! > 0 ? (
    <Form.Item label="Other filters">
      <RenderChildrenIf condition={filters.length > 0}>
        {_.map(filters, ({ label, key }) => (
          <Checkbox
            key={key}
            checked={checkedByKey[key]}
            data-qa-label={`cbx-${label.replace(/\s/g, "-")}`}
            onChange={() => {
              dispatch(
                doUpdateWorkingFilter({
                  metrics: {
                    ...checkedByKey,
                    [key]: !checkedByKey[key]
                  }
                })
              );
            }}
          >
            {label}
          </Checkbox>
        ))}
      </RenderChildrenIf>
      <TenantFilters />
    </Form.Item>
  ) : null;
};
