import { Typography } from "antd";
import { ColumnProps } from "antd/es/table";
import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { StudyPlansListItem } from "../../../../services/nav-api/studyPlans/model";
import { CreateColumnProps } from "./model";

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const formatDates = (dateStr?: string) =>
  !_.isNil(dateStr) //
    ? dayjs(dateStr).format("MMM D, YYYY")
    : "-";

// -------------------------------------------------------------------------------------------------
// - Column builder
// -------------------------------------------------------------------------------------------------

export const createCreationDateColumn = ({ sort }: CreateColumnProps): ColumnProps<{}>[] => [
  {
    sorter: true,
    width: 120,
    dataIndex: "created",
    sortOrder: _.isEqual(sort?.key, "created") ? sort?.order : undefined,
    title: <Typography.Text ellipsis={true}>Created</Typography.Text>,
    render: (_cell: any, plan: StudyPlansListItem) => <Typography.Text>{formatDates(plan.created)}</Typography.Text>
  }
];
