import { Card, Descriptions, Empty } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RenderChildrenIf } from "../../components/ConditionalRenderers";
import { EMPTY_CELL_SYMBOL } from "../../components/TableCellRender";
import { getStudentAffiliation } from "../../redux/selectors/student-affiliation";
import { StudentAffiliation as MStudentAffiliation } from "../../services/nav-api/student-details/types";

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const getValue = (selector: (v: MStudentAffiliation) => any, affiliation?: MStudentAffiliation) => {
  try {
    return selector(affiliation!) || EMPTY_CELL_SYMBOL;
  } catch {
    return EMPTY_CELL_SYMBOL;
  }
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentAffiliation = () => {
  const { affiliation, loading } = useSelector(getStudentAffiliation) || {
    affiliation: undefined,
    loading: undefined
  };

  return (
    <Card
      size="small"
      loading={loading}
      title={
        <div className="ant-card-head-wrapper">
          <div className="ant-card-head-title" data-qa-label="sd-affiliation-title">
            Affiliation Details
          </div>
        </div>
      }
    >
      <RenderChildrenIf condition={!!affiliation} fallback={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
        <Descriptions
          bordered
          column={2}
          size="small"
          data-qa-label="sd-affiliation-body"
          items={[
            {
              key: "undergraduateUniversity",
              label: "Undergraduate University",
              children: getValue(v => v.ugrdUniversity.name, affiliation)
            },

            {
              key: "estimatedGratuatedDate",
              label: "Estimated Graduated Date",
              children: getValue(
                v => `${v.ugrdUniversity.graduationMonth} / ${v.ugrdUniversity.graduationYear}`,
                affiliation
              )
            },

            {
              key: "graduatedUniversity",
              label: "Graduated University",
              children: getValue(v => v.grdUniversity!.name, affiliation)
            },

            {
              key: "estimatedGratuatedDate",
              label: "Estimated Graduated Date",
              children: affiliation?.grdUniversity
                ? `${affiliation.grdUniversity!.graduationMonth} / ${affiliation.grdUniversity!.graduationYear}`
                : EMPTY_CELL_SYMBOL
            },

            {
              key: "employer",
              label: "Employer",
              children: getValue(
                v => v.employer!.name + (v.employer!.isGovernmental ? " (Gov Agency)" : ""),
                affiliation
              )
            },

            {
              key: "society",
              label: "Society",
              children: getValue(v => v.society!.name, affiliation)
            },

            {
              key: "usMilitary",
              label: "US Military",
              children: getValue(v => (v.isMilitaryAffiliated ? "Yes" : "No"), affiliation)
            }
          ]}
        />
      </RenderChildrenIf>
    </Card>
  );
};
