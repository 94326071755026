import { AltID } from "../../../constants/courses";

export const getKey4ActiveStudents = () => "active-students";
export const getKey4ActiveStudentsByAltId = (altId: AltID) => `students-activity-${altId}`;
export const getKey4EtPassedByAltId = (altId: AltID) => `ex-passed-${altId}`;
export const getKey4EtReportedByAltId = (altId: AltID) => `ex-reported-${altId}`;
export const getKey4EtPassedAllParts = () => `ex-passed-all-parts`;
export const getKey4EtNoExamTrackerData = () => `ex-no-exam-tracker-data`;
export const getKey4EtPassedAnyPart = () => `ex-passed-any-part`;
export const getKey4EtAboutToLoseOrLostCredits = () => `ex-lost-credits`;
export const getKey4MdExpiredLicensesInMoreThan3Months = () => `md-expired-licenses+3M`;
export const getKey4MdExpiredLicensesInLessThan3Months = () => `md-expired-licenses-3M`;
export const getKey4MdStudyPlanUsage = (hasStudyPlan: boolean) => `md-studyplan-usage-${hasStudyPlan}`;
export const getKey4MdStudyPlanPerformance = (behindSchedule: boolean) => `md-studyplan-performance-${behindSchedule}`;
export const getKey4MdSudentsWithoutSudyTime = () => `md-students-without-stime`;
export const getKey4MdFollowedStudents = () => `md-followed-students`;
