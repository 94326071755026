import { CheckCircleTwoTone } from "@ant-design/icons";
import { Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import memoize from "lodash/memoize";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { MESSAGES } from "../../../../i18n";
import { OperationType } from "../../../../redux/reducers/alerts-writer/types";
import { Alert, ApiAlertStatus } from "../../../../services/nav-api/alerts-writer/types";
import { ActionButton } from "./ActionButton";

// -------------------------------------------------------------------------------------------------
// - constants
// -------------------------------------------------------------------------------------------------

const iDateFormat = "YYYY-MM-DD";
const oDateFormat = "MM-DD-YYYY";
const colorByStatus: { [key in ApiAlertStatus]?: string } = {
  [ApiAlertStatus.PUBLISHED]: "green",
  [ApiAlertStatus.SCHEDULED]: "yellow",
  [ApiAlertStatus.EXPIRED]: "volcano"
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const TextCell = (props: { value: string }) => <StyledTextWrapper>{props.value}</StyledTextWrapper>;
const DateCell = (props: { value: string }) => (
  <StyledTextWrapper>{moment(props.value, iDateFormat).format(oDateFormat)}</StyledTextWrapper>
);

// -------------------------------------------------------------------------------------------------
// - Columns
// -------------------------------------------------------------------------------------------------

export const createColumns = memoize((): ColumnProps<{}>[] => [
  {
    title: MESSAGES.AlertsTableColumnTitle,
    dataIndex: "title",
    render: value => <TextCell value={value} />,
    width: "20%",
    ellipsis: {
      showTitle: true
    }
  },
  {
    title: MESSAGES.AlertsTableColumnPublishDate,
    dataIndex: "publishDate",
    render: value => <DateCell value={value} />,
    align: "center",
    ellipsis: true
  },
  {
    title: MESSAGES.AlertsTableColumnExpirationDate,
    dataIndex: "expirationDate",
    render: value => <DateCell value={value} />,
    align: "center",
    ellipsis: true
  },
  {
    title: MESSAGES.AlertsTableColumnEmail,
    dataIndex: "sendEmail",
    align: "center",
    width: "10%",
    render: value =>
      value ? (
        <div style={{ alignSelf: "center" }}>
          <CheckCircleTwoTone />
        </div>
      ) : null
  },
  {
    title: MESSAGES.AlertsTableColumnStatus,
    key: "status",
    dataIndex: "status",
    align: "center",
    width: "10%",
    render: (status: ApiAlertStatus) => (
      <div style={{ alignSelf: "center" }}>
        <Tag color={colorByStatus[status]}>{status}</Tag>
      </div>
    )
  },
  {
    title: MESSAGES.AlertsTableColumnActions,
    align: "center",
    render: (_, alert: Alert) => (
      <>
        <div style={{ overflowX: "hidden" }}>
          <ActionButton alert={alert} operationType={OperationType.DELETE} />
          <ActionButton alert={alert} operationType={OperationType.EDIT} />
          <ActionButton alert={alert} operationType={OperationType.EXPIRE} />
        </div>
      </>
    )
  }
]);

const StyledTextWrapper = styled.div`
  align-self: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: default;
`;
