import { CalendarOutlined } from "@ant-design/icons";
import { Card, Flex, Form, Slider, Typography } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { StudyPlanDay } from "../../../../services/nav-api/studyPlans/model";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";
import { WeekDayHours } from "../../hooks/useStudyPlanEditor/useEditorFields/model";

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

const minStep = 0;
const maxStep = 16;
const allMarks = _.reduce(
  _.range(0, maxStep + 1),
  (a, i) => ({
    ...a,
    [i]: <span />
  }),
  {}
);

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const WeekDayHoursField = (props: { disabled?: boolean }) => (
  <StudyPlanEditorContext.Consumer>
    {({
      editor: {
        weekDayHours,
        weeklyDistributionInfo: { weekDaysHoursCount, studyTimeWeekValue, isStudyTimeOk }
      }
    }) => (
      <Form.Item
        extra={
          !isStudyTimeOk ? (
            <div className="pt-4">
              <Typography.Text type="danger" strong>
                Adjust the settings to ensure that students’ studies are completed by the end date.
              </Typography.Text>
            </div>
          ) : null
        }
      >
        <Flex vertical className="w-full mb-2">
          <Flex>
            <Typography.Text className="grow required-element">
              How will study time be distributed on a weekly basis?
            </Typography.Text>
            <Typography.Text>
              Study time per week:
              <Typography.Text code strong>
                {weekDaysHoursCount}h / {studyTimeWeekValue}h
              </Typography.Text>
            </Typography.Text>
          </Flex>
        </Flex>
        <Flex vertical gap={12}>
          {_.map(_.values(StudyPlanDay), (day: StudyPlanDay, idx: number) => (
            <Card
              key={`slider-of-day-${idx}`}
              styles={{
                body: {
                  paddingTop: 10,
                  paddingBottom: 10
                }
              }}
            >
              <Flex gap={20} justify="center" align="center">
                <CalendarOutlined />
                <Typography.Text style={{ minWidth: 50 }} strong>
                  {dayjs()
                    .day(_.indexOf(_.keys(StudyPlanDay), day) + 1)
                    .format("ddd")}
                </Typography.Text>
                <Flex className="grow" justify="center" align="center" gap={8}>
                  <Typography.Text style={{ minWidth: 20 }}>0h</Typography.Text>
                  <Slider
                    step={1}
                    min={minStep}
                    max={maxStep}
                    marks={allMarks}
                    value={weekDayHours.value[idx]}
                    disabled={props.disabled}
                    className="grow my-0"
                    onChange={val => {
                      const newValue = [...weekDayHours.value] as WeekDayHours;
                      newValue[idx] = val;

                      weekDayHours.setValue(newValue);
                    }}
                  />
                  <Typography.Text style={{ minWidth: 20 }}>16h</Typography.Text>
                </Flex>
                <Typography.Text className="pl-8" strong>
                  {weekDayHours.value[idx]}h
                </Typography.Text>
              </Flex>
            </Card>
          ))}
        </Flex>
      </Form.Item>
    )}
  </StudyPlanEditorContext.Consumer>
);
