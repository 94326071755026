export const paths = {
  accountRegister: "/account/register",
  adminTags: "/admin/tags",
  adminUsers: "/admin/users",
  alertsReader: "/notifications",
  alertsWriter: "/notifications-editor",
  dashboard: "/dashboard",
  detailsPath: "/student-details/:id",
  eula: "/eula",
  forgotPassword: "/forgot-password",
  home: "/home",
  login: "/login",
  partners: "/admin/partners",
  profile: "/profile",
  resetPassword: "/reset-password",
  root: "/",
  students: "/students",
  studyPlans: "/studyplans",
  studyPlansNew: "/studyplans/new",
  studentsAdd: "/students/add",
  tenant: "/admin/tenant/:id",
  tenantNew: "/admin/tenant-new",
  tenants: "/admin/tenants",
  users: "/admin/users",
  usersNew: "/admin/users-new"
};
