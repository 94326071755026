import * as HttpClient from "../http-client";
import { createHeaders } from "./headers";

type HierarchyItemBaseProps = { altId: string; name: string; abbrev: string; variant: string };
export type HierarchyContent = {
  content: {
    sections: Array<
      HierarchyItemBaseProps & {
        units: Array<
          HierarchyItemBaseProps & {
            modules: HierarchyItemBaseProps[];
          }
        >;
      }
    >;
  };
};

export const getHierarchyContent = async (isCpaPreEvolution: boolean): Promise<HierarchyContent> =>
  HttpClient.get("/rest/hierarchyContent", createHeaders(), {
    ccontent: isCpaPreEvolution ? "Stable:4337" : undefined
  });
