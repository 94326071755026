import _ from "lodash";
import { BooleanOptional, IStringifyOptions } from "qs";
import { MESSAGES } from "../../i18n";
import { routes } from "../../routes";
import { genId } from "../../utils/gen-id";
import { HeadersMap } from "../nav-api/headers";
import { NETWORK_ERROR_STATUS_CODE } from "./constant";
import { createInterceptors } from "./interceptor";
import { parseErrorResponse, parseResponse } from "./parser";
import { getUrlWithEncodedParams } from "./utils";

const interceptors = createInterceptors();

async function doFetch(url: string, method: string, headers: HeadersMap, body?: string | FormData): Promise<any> {
  const credentials: RequestCredentials = "include";
  const requestId = genId();
  try {
    let response = await fetch(url, {
      method,
      headers: new Headers(headers),
      body,
      credentials
    });
    response = await interceptors.response.reduce(
      (curResponsePromise, interceptor) => curResponsePromise.then(r => interceptor(r, requestId)),
      Promise.resolve(response)
    );

    if (_.isEqual(response.status, 420)) {
      return window.location.reload();
    }

    if (_.isEqual(response.status, 401) && !_.includes(["/login", "/forgot-password"], window.location.pathname)) {
      return window.location.assign(routes.login.resolve());
    }

    if (response.status >= 200 && response.status < 300) {
      return await parseResponse(response);
    }
    throw await parseErrorResponse(response);
  } catch (err) {
    if (err.statusCode) {
      throw {
        ...err,
        id: requestId,
        requestUrl: url
      };
    }
    throw {
      statusCode: NETWORK_ERROR_STATUS_CODE,
      requestUrl: url,
      message: MESSAGES.HttpErrNetworkMsg,
      id: requestId
    };
  }
}

function postPutDelete(
  url: string,
  method: string,
  headers: HeadersMap,
  body?: { [name: string]: any } | FormData
): Promise<any> {
  const serializedBody = (() => {
    if (!body) {
      return "";
    }
    return body instanceof FormData ? body : JSON.stringify(body);
  })();

  return doFetch(url, method, headers, serializedBody);
}

function get(
  url: string,
  headers: HeadersMap,
  params?: { [name: string]: any },
  paramOptions?: IStringifyOptions<BooleanOptional>
): Promise<any> {
  url = getUrlWithEncodedParams(url, params, paramOptions);
  return doFetch(url, "get", headers);
}

function post(url: string, headers: HeadersMap, body?: { [name: string]: any } | FormData): Promise<any> {
  return postPutDelete(url, "post", headers, body);
}

function put(url: string, headers: HeadersMap, body?: { [name: string]: any } | FormData): Promise<any> {
  return postPutDelete(url, "put", headers, body);
}

function del(url: string, headers: HeadersMap, body?: { [name: string]: any } | FormData): Promise<any> {
  return postPutDelete(url, "delete", headers, body);
}

export { del, get, interceptors, post, put };
