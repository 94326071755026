import React from "react";
import { StudyPlansListItem } from "../../../../../services/nav-api/studyPlans/model";
import { Field, FromApiResponseValue, StudyPlanEditorValue, ToApiRequestValue } from "./model";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type SectionFieldValue = StudyPlanEditorValue["section"];
export type SectionField = Field<
  SectionFieldValue,
  {
    toApiRequestValue: ToApiRequestValue<string>;
    fromApiResponseValue: FromApiResponseValue<string>;
  }
>;

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useSection = (): SectionField => {
  const [value, setValue] = React.useState<SectionFieldValue>(null);
  return {
    value,
    setValue: (val: SectionFieldValue) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(null);
    },
    toApiRequestValue: () => {
      return value!;
    },
    fromApiResponseValue: (rsp: StudyPlansListItem) => {
      const iVal = rsp.sectionAltId;
      setValue(iVal);
      return iVal;
    }
  };
};
