import { createSelector } from "reselect";
import { RdxStudentDetails } from "../reducers/student-details";
import { RdxStoreState } from "../types/state";

export const getHasStudentDetails: (state: RdxStoreState) => boolean = createSelector(
  (state: RdxStoreState) => state.studentDetails,
  ({ curStudentId, details }: RdxStudentDetails) => {
    if (!curStudentId) {
      return false;
    }
    return Boolean(details);
  }
);
