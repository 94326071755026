import { colorBySlice } from "../constants";
import { StatsData, StatsScope, StatsSlice } from "../model";
import { useLicensesData } from "./useLicensesData";
import { usePerformanceData } from "./usePerformanceData";
import { useUsageData } from "./useUsageData";

// -------------------------------------------------------------------------------------------------
// - Hooks: useStatsData
// -------------------------------------------------------------------------------------------------

export const useStatsData = (scope: StatsScope): StatsData[] => {
  const license = useLicensesData(scope);
  const performance = usePerformanceData(scope);
  const usage = useUsageData(scope);
  return (
    usage ??
    performance ??
    license ?? [
      { slice: StatsSlice.S1, value: 0, color: colorBySlice[StatsSlice.S1] },
      { slice: StatsSlice.S2, value: 0, color: colorBySlice[StatsSlice.S2] }
    ]
  );
};
