import { Table } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import { SorterResult } from "antd/lib/table/interface";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { doChangeListSettings, doUpdateStudentFollowed } from "../../../redux/actions/students-list";
import { getIsGlobalUser } from "../../../redux/selectors/permissions/is-global-user";
import { getStudentsTableDetails } from "../../../redux/selectors/students-table-data-source";
import { RdxStoreState } from "../../../redux/types/state";
import { routes } from "../../../routes";
import { StudentRosterDetails } from "../../../services/nav-api/student-roster/types";
import { StudentActionsContext } from "../hooks/useStudentActions";
import { StudentsBulkDialog } from "../StudentsBulkDialog";
import { createColumns } from "./columns";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentsTable = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const { dataSource, loading, paging, sort } = useSelector(getStudentsTableDetails);
  const activeFilter = useSelector((state: RdxStoreState) => state.studentsList.activeFilter);
  const isGlobalUser = useSelector(getIsGlobalUser);

  const handleFollowUpdate = (studentId: number, followed: boolean) => {
    dispatch(doUpdateStudentFollowed(studentId, followed));
  };

  return (
    <StudentActionsContext.Consumer>
      {props => {
        const selectable = props.selection.active;
        const showTenants = Boolean(isGlobalUser);
        const showFollowed = !isGlobalUser;
        const showVisibility = Boolean(
          activeFilter &&
            activeFilter.filter && //
            _.isEqual(activeFilter.filter.metrics.hiddenStudents, "include")
        );

        return (
          <>
            <Table
              rowKey="id"
              size="small"
              rowClassName="cursor-pointer"
              key={showVisibility.toString()}
              dataSource={dataSource}
              loading={loading}
              columns={createColumns(
                sort,
                showVisibility,
                showTenants,
                showFollowed ? handleFollowUpdate : undefined
                //
              )}
              pagination={{
                ...paging,
                disabled: props.selection.active,
                pageSizeOptions: ["50", "100", "250"],
                showQuickJumper: true,
                showLessItems: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
              }}
              onRow={
                !props.selection.active
                  ? (record: StudentRosterDetails) => ({
                      onClick: () => {
                        navigate(routes.studentDetails.resolve({ id: record.id.toString() }));
                      }
                    })
                  : undefined
              }
              onChange={(props: PaginationProps, _: any, r: SorterResult<{}>) => {
                dispatch(
                  doChangeListSettings(
                    props.current!,
                    props.pageSize!,
                    r.field && r.order
                      ? {
                          key: r.field as keyof StudentRosterDetails,
                          order: r.order
                        }
                      : undefined
                  )
                );
              }}
              rowSelection={
                selectable
                  ? {
                      onChange: props.onSelectionChange,
                      selectedRowKeys: props.selection.ids
                    }
                  : undefined
              }
            />
            {props.bulkChange.modalOpen ? <StudentsBulkDialog /> : null}
          </>
        );
      }}
    </StudentActionsContext.Consumer>
  );
};
