import React from "react";
import { StudyPlansListItem } from "../../../../../services/nav-api/studyPlans/model";
import { Field, FromApiResponseValue, StudyPace, StudyPlanEditorValue, ToApiRequestValue } from "./model";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type StudyPaceFieldValue = StudyPlanEditorValue["studyPace"];
export type StudyPaceField = Field<
  StudyPaceFieldValue,
  {
    toApiRequestValue: ToApiRequestValue<number>;
    fromApiResponseValue: FromApiResponseValue<StudyPace>;
  }
>;

// -------------------------------------------------------------------------------------------------
// - constants
// -------------------------------------------------------------------------------------------------

const numericValueByStudyPace: { [key in StudyPace]: number } = {
  [StudyPace.THOROUGH]: 0.65,
  [StudyPace.AVERAGE]: 1,
  [StudyPace.QUICK]: 1.75
};

const studyPaceByNumericValue: { [key in number]: StudyPace } = {
  0.65: StudyPace.THOROUGH,
  1: StudyPace.AVERAGE,
  1.75: StudyPace.QUICK
};

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useStudyPace = (initialValue: StudyPace = StudyPace.AVERAGE): StudyPaceField => {
  const [value, setValue] = React.useState<StudyPaceFieldValue>(initialValue);
  return {
    value,
    setValue: (val: StudyPaceFieldValue) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(initialValue);
    },
    toApiRequestValue: (): number => {
      return numericValueByStudyPace[value];
    },
    fromApiResponseValue: (rsp: StudyPlansListItem) => {
      const pace = studyPaceByNumericValue[rsp.settings.pace];
      setValue(pace);
      return pace;
    }
  };
};
