import { Typography } from "antd";
import React from "react";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const EditorSectionHeader = (props: { title: string; description: string }) => (
  <>
    <Typography.Title level={5}>{props.title}</Typography.Title>
    <Typography.Text type="secondary">{props.description}</Typography.Text>
  </>
);
