import { Badge, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { StudyPlanTask } from "../../../../../../services/nav-api/studyPlans/model";
import { StudyCalendarUtils } from "../../../../hooks/useStudyPlanEditor/utils/StudyCalendarUtils";
import { StudyPlanContext } from "../../../hooks/useStudyPlanViewerData";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarDayCellTaskMiniExam = (props: { task: StudyPlanTask & { quarterHours?: number } }) => (
  <StudyPlanContext.Consumer>
    {({ tasks }) => {
      const allMiniExms = _.filter(tasks, ({ type }) => _.isEqual(type, "miniexam"));
      const miniExamIdx = _.findIndex(allMiniExms, { id: props.task.id });
      return (
        <li
          key={`mini-exam-${props.task.id}`}
          title={
            `Mini Exam ${miniExamIdx + 1}:` + //
            `${StudyCalendarUtils.formatDuration(props.task.quarterHours! * 15)}`
          }
        >
          <Badge color="#9D59DD" text={<Typography.Text strong>Mini Exam {miniExamIdx + 1}</Typography.Text>} />
        </li>
      );
    }}
  </StudyPlanContext.Consumer>
);
