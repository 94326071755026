import _ from "lodash";
import { useSelector } from "react-redux";
import { RxAppRoutesStatus } from "../selectors/app-routes-status";
import { shouldRenderLoginBanner } from "../selectors/ffs";

// -------------------------------------------------------------------------------------------------
//  Hooks
// -------------------------------------------------------------------------------------------------

export const useLoginBanner = (status: RxAppRoutesStatus) => {
  const showLoginBanner = useSelector(shouldRenderLoginBanner);

  if (_.isEqual(RxAppRoutesStatus.LOADING, status)) {
    return false;
  }

  return (
    _.isEqual(RxAppRoutesStatus.UNAUTHENTICATED, status) &&
    _.isEqual(window.location.pathname, "/login") &&
    showLoginBanner
  );
};
