import { Flex } from "antd";
import React from "react";
import { SIDER_MAX_WIDTH } from "../../constants/ui-constants";
import { SVGNavigator } from "../SVGWrappers/SVGNavigator";
import { SVGNavigatorSmall } from "../SVGWrappers/SVGNavigatorSmall";

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const NavigatorLogo = (props: { collapsed: boolean }) => (
  <div className="overflow-hidden">
    <Flex
      className="w-full box-border"
      style={{
        transition: "all linear 0.15s",
        ...(props.collapsed
          ? {
              transform: "translateX(-200px)"
            }
          : {})
      }}
    >
      <Flex
        align="center"
        justify="center"
        className="height-[64px] pt-5 pb-2"
        style={{
          margin: "0 auto",
          flex: `0 0 ${SIDER_MAX_WIDTH}px`
        }}
      >
        <SVGNavigator />
      </Flex>
      <Flex
        align="center"
        justify="center"
        className="width-[80px] height-[64px]"
        style={{
          flex: "0 0 80px"
        }}
      >
        <SVGNavigatorSmall width="24" height="24" />
      </Flex>
    </Flex>
  </div>
);
