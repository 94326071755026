import _ from "lodash";
import { getProductConfig } from "../../../product-config";
import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { TenantProduct } from "../tenants/types";
import { altIdsByProduct } from "./../../../constants/courses";
import {
  getKey4ActiveStudents,
  getKey4ActiveStudentsByAltId,
  getKey4EtAboutToLoseOrLostCredits,
  getKey4EtNoExamTrackerData,
  getKey4EtPassedAllParts,
  getKey4EtPassedAnyPart,
  getKey4EtPassedByAltId,
  getKey4EtReportedByAltId,
  getKey4MdExpiredLicensesInLessThan3Months,
  getKey4MdExpiredLicensesInMoreThan3Months,
  getKey4MdFollowedStudents,
  getKey4MdStudyPlanPerformance,
  getKey4MdStudyPlanUsage,
  getKey4MdSudentsWithoutSudyTime
} from "./keys";
import { StudentAggregations } from "./types";

// -------------------------------------------------------------------------------------------------
// - Students Activity
// -------------------------------------------------------------------------------------------------

const subFilter4ActiveStudents = () => ({
  [getKey4ActiveStudents()]: {
    licenseExpireInMoreThanDays: 0
  }
});

const subFilter4StudentsActivityBySection = (p: TenantProduct) =>
  _.reduce(
    altIdsByProduct[p],
    (a, altId) => ({
      ...a,
      [getKey4ActiveStudentsByAltId(altId)]: {
        licenseExpireInMoreThanDays: 0,
        activeSection: altId
      }
    }),
    {}
  );

// -------------------------------------------------------------------------------------------------
// - Exam Tracker
// -------------------------------------------------------------------------------------------------

const subFilter4EtPassedSection = (p: TenantProduct) =>
  _.reduce(
    altIdsByProduct[p],
    (a, altId) => ({
      ...a,
      [getKey4EtPassedByAltId(altId)]: {
        passedSection: altId
      },
      [getKey4EtReportedByAltId(altId)]: {
        reportedSection: altId
      }
    }),
    {}
  );

const subFilter4EtPassedAllParts = () => ({
  [getKey4EtPassedAllParts()]: {
    licenseExpireInMoreThanDays: 0,
    passedMinParts: getProductConfig().numberOfSections
  }
});

const subFilter4EtNoExamTrackerData = () => ({
  [getKey4EtNoExamTrackerData()]: {
    licenseExpireInMoreThanDays: 0,
    hasReportedScores: false
  }
});

const subFilter4EtPassedAnyPart = () => ({
  [getKey4EtPassedAnyPart()]: {
    licenseExpireInMoreThanDays: 0,
    passedMinParts: 1
  }
});

const subFilter4EtAboutToLoseOrLostCredits = () => ({
  [getKey4EtAboutToLoseOrLostCredits()]: {
    licenseExpireInMoreThanDays: 0,
    creditExpirationInLessThanDays: 90
  }
});

// -------------------------------------------------------------------------------------------------
// - More Details
// -------------------------------------------------------------------------------------------------

const subFilter4MdLicenses = () => ({
  [getKey4MdExpiredLicensesInMoreThan3Months()]: {
    licenseExpireInMoreThanDays: 90
  },
  [getKey4MdExpiredLicensesInLessThan3Months()]: {
    licenseExpireInMoreThanDays: 0,
    licenseExpireInLessThanDays: 90
  }
});

const subFilter4MdStudyPlanUsage = (hasStudyPlan: boolean) => ({
  [getKey4MdStudyPlanUsage(hasStudyPlan)]: {
    licenseExpireInMoreThanDays: 0,
    hasStudyPlan
  }
});

const subFilter4MdStudyPlanPerformance = (behindSchedule: boolean) => ({
  [getKey4MdStudyPlanPerformance(behindSchedule)]: {
    licenseExpireInMoreThanDays: 0,
    hasStudyPlan: true,
    behindSchedule
  }
});

const subFilter4MdStudentsWithoutStudyTime = () => ({
  [getKey4MdSudentsWithoutSudyTime()]: {
    licenseExpireInMoreThanDays: 0,
    studyTimeLessThanSecs: 1
  }
});

const subFilter4MdFollowedStudents = () => ({
  [getKey4MdFollowedStudents()]: {
    isFollowed: true
  }
});

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const fetchData = async (token: string, product: TenantProduct): Promise<StudentAggregations> =>
  HttpClient.post(`/rest/studentRosters/${token}/counters`, createHeaders(), {
    aggregations: {
      // Students Activity
      ...subFilter4ActiveStudents(),
      ...subFilter4StudentsActivityBySection(product),

      // Exam Tracker
      ...subFilter4EtPassedSection(product),
      ...subFilter4EtPassedAllParts(),
      ...subFilter4EtNoExamTrackerData(),
      ...subFilter4EtPassedAnyPart(),
      ...subFilter4EtAboutToLoseOrLostCredits(),

      // More Details
      ...subFilter4MdLicenses(),
      ...subFilter4MdStudyPlanUsage(true),
      ...subFilter4MdStudyPlanUsage(false),
      ...subFilter4MdStudyPlanPerformance(true),
      ...subFilter4MdStudyPlanPerformance(false),
      ...subFilter4MdStudentsWithoutStudyTime(),
      ...subFilter4MdFollowedStudents()
    }
  });
