import _ from "lodash";
import { Node } from "./types";

const nodeContainIds = <T extends Node>(node: T, nodeIds: number[]): boolean =>
  _.includes(nodeIds, node.id) || _.reduce(node.children, (a, n) => a || nodeContainIds(n, nodeIds), false);

export const filterTree = <T extends Node>(tree: T[], validIds: number[]): T[] => {
  return _.reduce(
    tree,
    (a, n) => {
      if (nodeContainIds(n, validIds)) {
        return [...a, { ...n, children: filterTree(n.children, validIds) }];
      } else {
        return [...a];
      }
    },
    []
  );
};
