import { Button, Card, Flex, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { MESSAGES, formatMsg } from "../../i18n";
import { StudentActionsContext } from "./hooks/useStudentActions";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentsActionsBar = () => (
  <StudentActionsContext.Consumer>
    {({ onSelectionCancel, onSelectionConfirm, selection, operation }) =>
      selection.active ? (
        <Card>
          <Flex gap={4}>
            <Typography.Text className="grow">
              {formatMsg(
                _.isEqual(operation, "bulk-change")
                  ? MESSAGES.BulkChangeSelectedItemsMessage
                  : MESSAGES.GenerateReportMessage,
                selection.ids.length
              )}
            </Typography.Text>
            <Button size="small" type="link" onClick={onSelectionCancel} disabled={selection.loading}>
              {MESSAGES.CancelLabel}
            </Button>
            <Button
              size="small"
              type="link"
              disabled={_.isEqual(selection.ids.length, 0)}
              onClick={onSelectionConfirm}
              loading={selection.loading}
            >
              {MESSAGES.ConfirmLabel}
            </Button>
          </Flex>
        </Card>
      ) : null
    }
  </StudentActionsContext.Consumer>
);
