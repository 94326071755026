import { createSelector } from "reselect";
import { RosterStudentFilter } from "../../services/nav-api/student-roster/types";
import { RdxStoreState } from "../types/state";
import { getIsCPAPreEvolutionSelected } from "./contentversion";
import { getMyTenantTagsTree } from "./my-tenant-tags-tree";
import { getStudentFilterTenantId } from "./student-filter-tenant-id";
import { buildStudentRosterFilter } from "./student-roster-filter-common";

// -------------------------------------------------------------------------------------------------
// - getRosterStudentFilter
// -------------------------------------------------------------------------------------------------

export const getRosterStudentFilter: (state: RdxStoreState) => RosterStudentFilter = createSelector(
  (state: RdxStoreState) => state.studentsList,
  getMyTenantTagsTree,
  getStudentFilterTenantId,
  (state: RdxStoreState) => state.tenants,
  getIsCPAPreEvolutionSelected,
  ({ workingFilter }, { tree = [] }, tenantId, { tenantTags }, isCPAPreEvolutionSelected): RosterStudentFilter =>
    buildStudentRosterFilter(workingFilter, tree, tenantId!, tenantTags!, isCPAPreEvolutionSelected)
);
