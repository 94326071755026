import { Button } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { doSetFilter } from "../../../../redux/actions/students-list";
import { getMyTenant } from "../../../../redux/selectors/my-tenant";
import { routes } from "../../../../routes";
import { StudyPlanContext } from "../hooks/useStudyPlanViewerData";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentsListLink = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const myTenant = useSelector(getMyTenant);

  return (
    <StudyPlanContext.Consumer>
      {plan => (
        <Button
          type="link"
          className="p-0 m-0"
          loading={plan.loading}
          onClick={() => {
            dispatch(
              doSetFilter({
                // tags: plan.summary.tagIds,
                studyPlanRef: {
                  id: plan.id!,
                  name: plan.summary.name
                },
                ...(!_.isEqual(myTenant?.id, plan.summary.tenantId)
                  ? {
                      tenantId: plan.summary.tenantId
                    }
                  : {})
              })
            );
            navigate(routes.students.resolve());
          }}
        >
          View Students in Study Plan
        </Button>
      )}
    </StudyPlanContext.Consumer>
  );
};
