import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getActiveUser } from "../selectors/active-user";
import { Permission } from "../selectors/permissions/model";

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useStudyPlanReadPermission = () => {
  const activeUser = useSelector(getActiveUser);
  return React.useMemo(() => {
    return (
      _.intersection(activeUser?.permissions, [
        Permission.MANAGE_STUDY_PLANS,
        Permission.VIEW_STUDY_PLANS
        //
      ]).length > 0
    );
  }, [activeUser?.permissions]);
};

export const useStudyPlanWritePermission = () => {
  const activeUser = useSelector(getActiveUser);
  return React.useMemo(
    () => _.includes(activeUser?.permissions, Permission.MANAGE_STUDY_PLANS),
    [activeUser?.permissions]
  );
};
