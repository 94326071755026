import { Button, Flex, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { useStudyPlanWritePermission } from "../../../../redux/hooks/useStudyPlansPermissions";
import { routes } from "../../../../routes";
import { StudyPlanContext } from "../hooks/useStudyPlanViewerData";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlanViewerHeader = () => {
  const navigateTo = useNavigate();
  const canEditStudyPlan = useStudyPlanWritePermission();

  return (
    <StudyPlanContext.Consumer>
      {plan => (
        <Flex vertical={false} align="center" gap={4} className="w-full">
          <Typography.Title level={2} data-qa-label="study-plans-editor" className="grow">
            {plan.summary.name}
          </Typography.Title>
          {canEditStudyPlan ? (
            <Button
              loading={plan.loading}
              disabled={!plan.studyPlanTenant?.hasStudyPlan}
              onClick={() => {
                navigateTo(routes.studyPlansEdit.resolve({ id: plan?.id?.toString() }));
              }}
            >
              Edit Plan
            </Button>
          ) : null}
        </Flex>
      )}
    </StudyPlanContext.Consumer>
  );
};
