import { FilterOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { doApplyFilter, doClearFilter, doResetListSettings } from "../../redux/actions/tenants/tenants";
import { getIsApplyTenantsListFilterDisabled } from "../../redux/selectors/is-apply-tenants-list-filter-disabled";
import { getIsClearTenantsListFilterDisabled } from "../../redux/selectors/is-clear-tenants-list-filter-disabled";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantsTableFiltersActions = () => {
  const dispatch = useDispatch<any>();
  const clearFilterDisabled = useSelector(getIsClearTenantsListFilterDisabled);
  const applyFilterDisabled = useSelector(getIsApplyTenantsListFilterDisabled);

  return (
    <Flex gap={4}>
      <Button
        htmlType="button"
        className="grow"
        data-qa-label="clear-btn"
        disabled={clearFilterDisabled}
        icon={<ReloadOutlined />}
        onClick={() => {
          dispatch(doClearFilter());
        }}
      >
        Reset
      </Button>
      <Button
        htmlType="submit"
        type="primary"
        className="grow"
        data-qa-label="submit-btn"
        disabled={applyFilterDisabled}
        icon={<FilterOutlined />}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          if (applyFilterDisabled) {
            return;
          }
          dispatch(doResetListSettings());
          dispatch(doApplyFilter());
        }}
      >
        Apply
      </Button>
    </Flex>
  );
};
