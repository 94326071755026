import _ from "lodash";
import { CMA_BASE_URL } from "../../constants/cma-links";
import { AltID } from "../../constants/courses";
import { CPA_BASE_URL } from "../../constants/cpa-links";
import { StudentHierarchyProgress } from "../../redux/actions/student-details/types";
import * as HttpClient from "../http-client";
import { createHeaders } from "../nav-api/headers";

export type DailyStudyTimeResponse = {
  percentileLastWeek?: number; // Compared to every other student's study time during last 7 days, null if too low
  days?: {
    day: string;
    secs: number; // duration in seconds
    sections?: [
      {
        sectionAltId: AltID;
        secs: number; // duration in seconds
      }
    ];
  }[];
  unknown?: { secs: number };
  sections?: {
    sectionAltId: AltID;
    secs: number; // includes time of units and also general time distributed proportionally between sections
    units: {
      unitAltId: string;
      secs: number;
    }[];
  }[];
};

const dailyStudyTime = async (
  studentToken: string,
  activeProduct: string,
  unformatedSince?: moment.Moment
): Promise<DailyStudyTimeResponse> => {
  const url = `${activeProduct === "cpa" ? CPA_BASE_URL : CMA_BASE_URL}/rest/dailyStudyTime`;
  const since = unformatedSince?.format("YYYY-MM-DD");
  return HttpClient.get(url, createHeaders(), { studentToken, since });
};

const hierarchyProgress = async (
  studentToken: string,
  activeProduct: string,
  isCpaPreEvolution: boolean
): Promise<StudentHierarchyProgress> => {
  const url = `${_.isEqual(activeProduct, "cpa") ? CPA_BASE_URL : CMA_BASE_URL}/rest/hierarchyProgress`;
  return HttpClient.get(url, createHeaders(), {
    studentToken,
    expired: true,
    ccontent: isCpaPreEvolution ? "Stable:4337" : undefined
  });
};

export const externalApi = {
  dailyStudyTime,
  hierarchyProgress
};
