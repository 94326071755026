import { Alert, Flex, Form, Spin, Tree, Typography } from "antd";
import { DataNode } from "antd/es/tree";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RenderChildrenIf } from "../../../../components/ConditionalRenderers";
import { doUpdateWorkingFilter } from "../../../../redux/actions/students-list";
import { getHasRestrictedUserTags, TagNode } from "../../../../redux/selectors/my-tenant-tags-tree";
import { getStudentFilterTagsTree } from "../../../../redux/selectors/student-filter-tags-tree";
import { getStudentFilterTenantId } from "../../../../redux/selectors/student-filter-tenant-id";
import { getTenantsById } from "../../../../redux/selectors/tenants";
import { RdxStoreState } from "../../../../redux/types/state";

// -------------------------------------------------------------------------------------------------
// - constants
// -------------------------------------------------------------------------------------------------

const NoTagsNode: TagNode = {
  id: -1,
  key: -1,
  name: "Students with no tags",
  enrolledStudentCount: 0,
  children: []
};

// -------------------------------------------------------------------------------------------------
// - helpers
// -------------------------------------------------------------------------------------------------

const buildTagsRoot = (children: TagNode[]): TagNode[] =>
  !_.isEmpty(children)
    ? [
        {
          id: -2,
          key: -2,
          name: "Tags Hierarchy",
          enrolledStudentCount: 0,
          children
        }
      ]
    : [];

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TagsFilter = () => {
  const dispatch = useDispatch<any>();
  const studentsTree = useSelector(getStudentFilterTagsTree);
  const selectedTenantId = useSelector(getStudentFilterTenantId);
  const hasRestrictedUserTags = useSelector(getHasRestrictedUserTags);
  const workingFilter = useSelector(({ studentsList: { workingFilter } }: RdxStoreState) => workingFilter);
  const tenantsById = useSelector(getTenantsById);
  const withoutTags = Boolean(workingFilter.withoutTags);

  const walkTree = React.useMemo(
    () =>
      (n: TagNode[] | null, pNode: TagNode | null = null): DataNode[] => {
        let nodes: TagNode[] = [];
        if (_.isNil(pNode)) {
          nodes = [...buildTagsRoot(n!), NoTagsNode];
        } else {
          nodes = n ?? [];
        }

        return _.reduce(
          nodes,
          (a, n) => {
            const children = walkTree(n.children, n);
            const isLeaf =
              _.isEmpty(children) ||
              (_.isEqual(NoTagsNode.key, n.id) && //
                !hasRestrictedUserTags &&
                !studentsTree.loading &&
                (studentsTree.tree ?? []).length > 0);
            return [
              ...a,
              {
                key: n.id,
                children,
                selectable: isLeaf,
                checkable: isLeaf,
                title: n.name
              }
            ];
          },
          []
        );
      },
    []
  );

  return selectedTenantId ? (
    <Form.Item
      label="Tags"
      extra={
        <RenderChildrenIf condition={!studentsTree.loading && (studentsTree.tree ?? []).length == 0}>
          <Alert
            type="warning"
            message={
              <Flex gap={4}>
                <Typography.Text>No tags are available for Tenant</Typography.Text>
                <Typography.Text strong>{tenantsById?.[selectedTenantId]?.name}</Typography.Text>
              </Flex>
            }
          />
        </RenderChildrenIf>
      }
    >
      <Spin spinning={studentsTree.loading}>
        <Tree
          virtual
          showLine
          checkable
          checkStrictly
          autoExpandParent
          defaultExpandAll
          selectable={false}
          height={450}
          checkedKeys={[...workingFilter.tags, ...(withoutTags ? [NoTagsNode.key] : [])]}
          treeData={!studentsTree.loading ? walkTree(studentsTree.tree) : []}
          titleRender={({ title, key }) => {
            let isStrong = _.includes(workingFilter.tags, key) || (_.isEqual(NoTagsNode.key, key) && withoutTags);
            let isDisabled = false;
            if (_.isEqual(NoTagsNode.key, key)) {
              isDisabled = !_.isEmpty(workingFilter.tags);
            } else if (!_.isEqual(NoTagsNode.key, key)) {
              isDisabled = withoutTags;
            }

            return (
              <Typography.Text
                key={key}
                italic={_.isEqual(NoTagsNode.key, key)}
                underline={_.isEqual(NoTagsNode.key, key)}
                disabled={isDisabled}
                strong={isStrong}
                //
              >
                <span>{title as string}</span>
              </Typography.Text>
            );
          }}
          onCheck={({ checked }: { checked: React.Key[]; halfChecked: React.Key[] }, info) => {
            if (_.isEmpty(checked)) {
              dispatch(
                doUpdateWorkingFilter({
                  withoutTags: false,
                  tags: []
                })
              );
            } else {
              const withoutTags = _.isEqual(info.node.key, NoTagsNode.key);
              dispatch(
                doUpdateWorkingFilter({
                  withoutTags,
                  tags: withoutTags ? [] : _.filter(checked as number[], key => !_.isEqual(NoTagsNode.key, key))
                })
              );
            }
          }}
        />
      </Spin>
    </Form.Item>
  ) : null;
};
