import { Form, Select } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { doUpdateWorkingFilter } from "../../../../redux/actions/students-list";
import { useStudyPlanReadPermission } from "../../../../redux/hooks/useStudyPlansPermissions";
import { RdxStoreState } from "../../../../redux/types/state";
import { StudyPlansListItem } from "../../../../services/nav-api/studyPlans/model";
import { useStudyPlansList } from "../../../StudyPlans/hooks/useStudyPlanList";

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const buildOptions = (data: StudyPlansListItem[]) => {
  return _.chain(data)
    .map(item => ({
      label: item.name,
      value: item.id
    }))
    .thru(items => [
      {
        label: "Any",
        value: null
      },
      { label: "None", value: -1 },
      ...items
    ])
    .value();
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlanFilter = () => {
  const hasStudyPlansPermission = useStudyPlanReadPermission();
  const { workingFilter } = useSelector((state: RdxStoreState) => state.studentsList);
  return hasStudyPlansPermission && _.gt(workingFilter?.tenantId, 0) ? <StudyPlanFilterContent /> : null;
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const StudyPlanFilterContent = () => {
  const dispatch = useDispatch<any>();
  const { workingFilter } = useSelector((state: RdxStoreState) => state.studentsList);
  const { loading, data } = useStudyPlansList(false, workingFilter?.tenantId);
  return (
    <Form.Item label="Study Plan">
      <Select
        data-qa-label="study-plan"
        value={workingFilter?.studyPlanRef?.id ?? null}
        placeholder="Study Plan"
        loading={loading}
        options={buildOptions(data)}
        onChange={val => {
          dispatch(
            doUpdateWorkingFilter({
              studyPlanRef: _.gte(val, 0)
                ? _.chain(data)
                    .filter(({ id }) => _.isEqual(id, val))
                    .head()
                    .value()
                : _.isEqual(val, -1)
                  ? { id: -1, name: "None" }
                  : undefined
            })
          );
        }}
      />
    </Form.Item>
  );
};
