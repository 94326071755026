import _ from "lodash";
import React from "react";
import { navApi } from "../../../../../services/nav-api";
import { User } from "../../../../../services/nav-api/types";
import { Field } from "../context";

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useExistingUser = (
  userId: number | null
): Field<
  User,
  {
    isLoading: boolean;
    notFound: boolean | null;
    startLoading: () => void;
    finishLoading: () => void;
  }
> => {
  const [value, setValue] = React.useState<User | null>(null);
  const [notFound, setNotFound] = React.useState<boolean | null>(null);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (_.isNil(userId)) {
      return;
    }

    (async () => {
      setLoading(true);
      try {
        setValue(await navApi.users.getUserById(userId));
      } catch (e) {
        console.error(e);
        setNotFound(true);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return {
    value,
    notFound,
    isLoading,
    startLoading: () => {
      setLoading(true);
    },
    finishLoading: () => {
      setLoading(false);
    },
    setValue: (val: User) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(null);
      setNotFound(null);
    }
  };
};
