import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Popover, Row, Tag, Typography } from "antd";
import _ from "lodash";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Can } from "../../components/ConditionalRenderers";
import { EMPTY_CELL_SYMBOL, handleStudyPlanCell } from "../../components/TableCellRender";
import { VisibilityBadge } from "../../components/VisibilityBadge";
import { FEATURES } from "../../constants/ui-features";
import { MESSAGES } from "../../i18n";
import { getHasStudentDetails } from "../../redux/selectors/has-student-details";
import { getIsEnrolledAfterDate } from "../../redux/selectors/is-enrolled-after-date";
import { getIsTenantUserOrAdmin } from "../../redux/selectors/is-tenant-user-or-admin";
import { getMyTenant } from "../../redux/selectors/my-tenant";
import { getIsGlobalUser } from "../../redux/selectors/permissions/is-global-user";
import { StudentInfo as MStudentInfo, getStudentInfo } from "../../redux/selectors/student-info";
import { format, millisecondsToHours, millisecondsToHoursMin } from "../../utils/date-utils";
import { StudentEditModal } from "./StudentEditModal";

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

export const StudentInfoTags = ({ tags }: { tags?: string[] }) => {
  if (!tags || !tags.length) {
    return <>{EMPTY_CELL_SYMBOL}</>;
  }

  return (
    <>
      {tags.map((value, idx) => (
        <Tag key={idx} color="blue" style={{ margin: "5px" }}>
          {value}
        </Tag>
      ))}
    </>
  );
};

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const getValue = (
  key: keyof MStudentInfo,
  info?: MStudentInfo //
) => (!info || !info[key] ? EMPTY_CELL_SYMBOL : info[key]);

const getDateValue = (key: keyof MStudentInfo, info?: MStudentInfo) =>
  !info || !info[key] ? EMPTY_CELL_SYMBOL : format(info![key] as any, "P");

const getFullNameValue = (
  info?: MStudentInfo //
) => (!info ? EMPTY_CELL_SYMBOL : `${info.firstName} ${info.lastName}`);

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentDetails = () => {
  const { info, loading } = useSelector(getStudentInfo);
  const hasDetails = useSelector(getHasStudentDetails);
  const tenant = useSelector(getMyTenant);
  const isGlobalUser = useSelector(getIsGlobalUser);
  const [editVisible, setEditVisible] = useState(false);
  const isEnrolledAfterDate = useSelector(getIsEnrolledAfterDate);
  const isTenantUserOrAdmin = useSelector(getIsTenantUserOrAdmin);

  const renderTagsRows = () => {
    if (!info || !info.tags) {
      return [
        {
          key: "tags",
          label: "Tags",
          children: EMPTY_CELL_SYMBOL
        }
      ];
    }

    if (!tenant && info.tags.length === 1) {
      return [
        {
          key: "tags",
          label: "Tags",
          children: <StudentInfoTags tags={info.tags[0].tagsFullPath} />
        }
      ];
    }

    return info.tags.map(({ tenantName, tagsFullPath }) => ({
      key: tenantName,
      label: `Tags (${tenantName})`,
      children: <StudentInfoTags tags={tagsFullPath} />
    }));
  };

  return (
    <>
      <Card
        size="small"
        loading={loading}
        title={
          <div className="ant-card-head-wrapper">
            <div className="ant-card-head-title" data-qa-label="sd-student-details-title">
              Student Details
            </div>
            <Can feature={FEATURES.EDIT_TENANT_STUDENT} forceFeatureOff={!hasDetails}>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => setEditVisible(true)}
                data-qa-label="sd-info-edit"
              >
                Edit
              </Button>
            </Can>
          </div>
        }
      >
        <StyledDescriptions
          bordered
          colon
          column={2}
          size="small"
          items={[
            // -------------------------------------------------------------------------------------
            // -------------------------------------------------------------------------------------
            {
              key: "fullName",
              label: "Full Name",
              children: getFullNameValue(info)
            },
            // -------------------------------------------------------------------------------------
            // -------------------------------------------------------------------------------------
            {
              key: "firstActivity",
              label: "First Activity",
              children: (
                <Typography.Text>
                  {getDateValue("firstActivity", info)}
                  {!isEnrolledAfterDate ? <Since type="secondary" /> : null}
                </Typography.Text>
              )
            },
            // -------------------------------------------------------------------------------------
            // -------------------------------------------------------------------------------------
            {
              key: "email",
              label: "Email",
              children: `${getValue("username", info)}`
            },
            // -------------------------------------------------------------------------------------
            // -------------------------------------------------------------------------------------
            {
              key: "lastActivity",
              label: "Last Activity",
              children: (
                <Typography.Text>
                  {getDateValue("lastActivity", info)}
                  {info && info.lastActivity ? null : <Since type="secondary" />}
                </Typography.Text>
              )
            },
            {
              key: "studyTime",
              label: "Study Time",
              children: info?.studyTimeSecs ? (
                <Popover content={millisecondsToHoursMin(info.studyTimeSecs * 1000)}>
                  <>{millisecondsToHours(info.studyTimeSecs * 1000)}</>
                </Popover>
              ) : (
                EMPTY_CELL_SYMBOL
              )
            },
            // -------------------------------------------------------------------------------------
            // -------------------------------------------------------------------------------------
            ...(isGlobalUser
              ? [
                  {
                    key: "tenants",
                    label: "Tenants",
                    children:
                      info?.tenants && info.tenants.length > 0 ? (
                        <>
                          {_.map(info.tenants, ({ name, isExcluded }, idx) => (
                            <Row key={idx}>
                              <Col xs={12}>{name}</Col>
                              <Col xs={12} style={{ display: "flex", alignSelf: "center" }}>
                                <Tag color={isExcluded ? undefined : "green"} style={{ marginLeft: "1rem" }}>
                                  {isExcluded ? MESSAGES.Hidden : MESSAGES.Visible}
                                </Tag>
                              </Col>
                            </Row>
                          ))}
                        </>
                      ) : (
                        EMPTY_CELL_SYMBOL
                      )
                  }
                ]
              : []),
            // -------------------------------------------------------------------------------------
            // -------------------------------------------------------------------------------------
            {
              key: "studyPlan",
              label: "Study Plan",
              children: handleStudyPlanCell("studyPlan", info?.studyPlan)
            },
            // -------------------------------------------------------------------------------------
            // -------------------------------------------------------------------------------------
            ...(isTenantUserOrAdmin
              ? [
                  {
                    key: "visibility",
                    label: "Visibility",
                    children:
                      !info || isNil(info.isExcluded) ? (
                        EMPTY_CELL_SYMBOL
                      ) : (
                        <VisibilityBadge disabled={info.isExcluded} />
                      )
                  }
                ]
              : []),

            // -------------------------------------------------------------------------------------
            // -------------------------------------------------------------------------------------
            ...renderTagsRows()
          ]}
        />
      </Card>
      {info && info.id && editVisible ? (
        <StudentEditModal tenantId={tenant!.id} studentId={info.id} onClose={() => setEditVisible(false)} />
      ) : null}
    </>
  );
};

// -------------------------------------------------------------------------------------------------
// - Styled Components
// -------------------------------------------------------------------------------------------------

const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-item-label {
    width: 150px;
  }
`;

const Since = styled(Typography.Text)`
  float: right;
  margin: 1px 0;
  font-size: 12px;
  :before {
    content: "since February 18, 2019";
  }
`;
