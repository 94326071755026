import { configureStore } from "@reduxjs/toolkit";
import { reFetchAlerts } from "../reactors/re-fetch-alerts";
import { reFetchHierarchyContent } from "../reactors/re-fetch-hierarchy-content";
import { reFetchMyTenantsTags } from "../reactors/re-fetch-my-tenant-tags";
import { reFetchStudentFilterTenantTags } from "../reactors/re-fetch-student-filter-tenant-tags";
import { reFetchStudentsList } from "../reactors/re-fetch-students-list";
import { reOnTrustCtl } from "../reactors/re-onetrust-ctl";
import { reRefreshStudentDetails } from "../reactors/re-refresh-student-details";
import alertsReader from "../reducers/alerts-reader";
import alertsWriter from "../reducers/alerts-writer";
import apiFailure from "../reducers/api-failure";
import authentication from "../reducers/authentication";
import core from "../reducers/core";
import globalDashboard from "../reducers/dashboard/global";
import tenantDashboard from "../reducers/dashboard/tenant";
import hierarchyContent from "../reducers/hierarchy-content";
import myself from "../reducers/myself";
import navigationDrawer from "../reducers/navigation-drawer";
import partners from "../reducers/partners";
import studentDetails from "../reducers/student-details";
import studentsList from "../reducers/students-list";
import tenants from "../reducers/tenants";
import unauth from "../reducers/unauth";
import usersList from "../reducers/users-list";
import { addReactorsToStore } from "./add-reactors-to-store";
import { createExtraArgs } from "./extra-args";

export const initializeStore = () => {
  const preloadedState = undefined;
  const store = configureStore({
    reducer: {
      unauth,
      core,
      alertsReader,
      alertsWriter,
      myself,
      apiFailure,
      authentication,
      navigationDrawer,
      studentsList,
      tenants,
      globalDashboard,
      tenantDashboard,
      studentDetails,
      hierarchyContent,
      usersList,
      partners
    },
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: createExtraArgs()
        }
      })
  });

  addReactorsToStore(store, [
    reFetchStudentsList,
    reFetchMyTenantsTags,
    reFetchHierarchyContent,
    reFetchStudentFilterTenantTags,
    reRefreshStudentDetails,
    reFetchAlerts,
    reOnTrustCtl as any
  ]);

  return store;
};
