import { Typography } from "antd";
import React from "react";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyTimeWeekField = () => (
  <StudyPlanEditorContext.Consumer>
    {({ editor: { studyTimeWeek } }) => (
      <Typography.Text>{studyTimeWeek.value ? `${studyTimeWeek.value}h` : null}</Typography.Text>
    )}
  </StudyPlanEditorContext.Consumer>
);
