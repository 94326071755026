import { createSelector } from "reselect";

import { Tenant } from "../../services/nav-api/tenants/types";
import { RdxTenantsState } from "../reducers/tenants";
import { RdxStoreState } from "../types/state";
import { ActiveUser, getActiveUser } from "./active-user";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getMyTenant: (state: RdxStoreState) => Pick<Tenant, "id" | "name"> | undefined = createSelector(
  getActiveUser,
  (user: ActiveUser) => (user && user.tenant ? user.tenant : undefined)
);

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getTenantsList: (state: RdxStoreState) => Tenant[] | undefined = createSelector(
  (state: RdxStoreState) => state.tenants,
  (tenants: RdxTenantsState) => tenants.tenants?.tenants
);
