import { Form, Select } from "antd";
import React from "react";
import { MESSAGES } from "../i18n";
import { Status } from "../services/nav-api/types";
const { Option } = Select;

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

export const StatusFilterAllValue = "All";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StatusFilter = (props: { onChange: (value: Status) => void; value: Status | undefined }) => (
  <Form.Item label={MESSAGES.Status} className="tenants-status">
    <Select
      className="tenants-status-dp"
      data-qa-label="status-select"
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      onChange={props.onChange}
      value={props.value}
    >
      <Option className={`option-status-${StatusFilterAllValue}}`} value={StatusFilterAllValue}>
        All
      </Option>
      <Option className={`option-status-${Status.Enabled}`} value={Status.Enabled}>
        {Status.Enabled}
      </Option>
      <Option className={`option-status-${Status.Disabled}`} value={Status.Disabled}>
        {Status.Disabled}
      </Option>
    </Select>
  </Form.Item>
);
