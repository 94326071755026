import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ApplicationToastsContext } from "../../../../../components/AppToasts";
import { getDefaultNewUserRole } from "../../../../../redux/selectors/roles";
import { routes } from "../../../../../routes";
import { navApi } from "../../../../../services/nav-api";
import { CreateUserParams, UpdateUserParams } from "../../../../../services/nav-api/types";
import { FormControllerValue, FormStatus, UserInfoStatus } from "../../userFormController/context";

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useUpdateUserAction = (formCtl: FormControllerValue) => {
  const navigateTo = useNavigate();
  const api = React.useContext(ApplicationToastsContext);
  const [formStatus, setFormStatus] = React.useState<FormStatus>(FormStatus.IDLE);
  const defaultUserRole = useSelector(getDefaultNewUserRole);

  return {
    // ---------------------------------------------------------------------------------------------
    // - status
    inProgress: _.isEqual(FormStatus.IN_PROGRESS, formStatus),

    // ---------------------------------------------------------------------------------------------
    // - action
    action: async () => {
      setFormStatus(FormStatus.IN_PROGRESS);
      try {
        const formData: Partial<CreateUserParams> = {};

        formData.email = formCtl.email.value!;
        if (formCtl.userInfoStatus.isEqualTo(UserInfoStatus.AVAILABLE_AND_MUTABLE)) {
          formData.firstName = formCtl.firstName.value!;
          formData.lastName = formCtl.lastName.value!;
        }

        // Add permissions
        formData.roleId = formCtl.selectedRoleId.value!;
        if (!_.isEqual(defaultUserRole?.id, formCtl.selectedRoleId.value)) {
          formData.tagIds = formCtl.tagIds.value!;
          formData.allowAddStudents = formCtl.allowAddStudents.value!;
        } else {
          formData.tagIds = [];
        }

        if (!_.isNil(formCtl.serverUser.value?.tenantId)) {
          formData.tenantId = formCtl.selectedTenantId.value!;
        }

        formData.unsubscribeAlerts = !formCtl.notificationsEnabled.value;

        await navApi.users.updateUser(formCtl.serverUser.value?.id!, formData as UpdateUserParams);

        // Send Success
        api.success({
          message: "User successfully updated",
          showProgress: true,
          pauseOnHover: true
        });

        setFormStatus(FormStatus.SUCCEEDED);
        // Move to Users List
        navigateTo(routes.users.resolve());
      } catch (e) {
        const err: { message?: string } | null = e?.message as null;

        // Send Success
        api.error({
          message: "User update failed",
          description: err,
          showProgress: true,
          pauseOnHover: true
        });
        setFormStatus(FormStatus.FAILED);
      }
    }
  };
};
