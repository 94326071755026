import { useDispatch, useSelector } from "react-redux";
import { FilterTag } from "../../../core/model";
import { MESSAGES, formatMsg } from "../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../redux/actions/students-list";
import { initialState } from "../../../redux/reducers/students-list";
import { getActiveProduct } from "../../../redux/selectors/active-product";
import { RdxStoreState } from "../../../redux/types/state";
import { ExamTrackerOptionUtils } from "../StudentsFilter/components/ExamTrackerFilter/option-utils";

// -------------------------------------------------------------------------------------------------
// - Hooks: useExamTrackerFilter
// -------------------------------------------------------------------------------------------------

export const useExamTrackerFilter = (): FilterTag[] => {
  const dispatch = useDispatch<any>();
  const activeProduct = useSelector(getActiveProduct);
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);

  return activeFilter?.filter?.examTracker && activeFilter.filter.examTracker !== initialState.workingFilter.examTracker
    ? [
        {
          name: "examTracker",
          onClear: () => {
            dispatch(doUpdateWorkingFilter({ examTracker: initialState.workingFilter.examTracker }));
            dispatch(doApplyFilter());
          },
          text: formatMsg(
            MESSAGES.FilterTagExamTracker,
            ExamTrackerOptionUtils.getLabelByValue(activeProduct)[activeFilter.filter.examTracker]
          )
        }
      ]
    : [];
};
