import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { Alert } from "./types";

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const fetchAlerts = async (): Promise<Alert[]> =>
  (await HttpClient.get("/rest/alerts/all", createHeaders()))?.alerts ?? [];

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const markAsRead = async (alertId: number): Promise<void> =>
  HttpClient.put(`/rest/alerts/${alertId}/isRead`, createHeaders());

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const markAsUnRead = async (alertId: number): Promise<void> =>
  HttpClient.del(`/rest/alerts/${alertId}/isRead`, createHeaders());
