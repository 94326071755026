import dayjs from "dayjs";
import _ from "lodash";
import React from "react";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type SingleDateType = dayjs.Dayjs;
export type MultiDateType = [start: dayjs.Dayjs | null, end: dayjs.Dayjs | null];
export type DateType = SingleDateType | MultiDateType | null;
export type ApiRequest = { examDateFrom?: string; examDateTo?: string };

export enum Type {
  SINGLE,
  RANGE
}

// -------------------------------------------------------------------------------------------------
// - Hook
// -------------------------------------------------------------------------------------------------

export const useExamDate = () => {
  const [value, setValue] = React.useState<DateType>(null);
  const [type, setType] = React.useState<Type>(Type.SINGLE);

  React.useEffect(() => {
    setValue(null);
  }, [type]);

  return {
    value,
    setValue,
    type,
    setType,
    reset: () => {
      setValue(null);
    },

    toApiRequest: (): ApiRequest => {
      if (_.isEqual(Type.SINGLE, type)) {
        const date = value as SingleDateType;
        const dateFmt = date?.format("YYYY-MM-DD");
        return !_.isNil(date)
          ? {
              examDateFrom: dateFmt,
              examDateTo: dateFmt
            }
          : {};
      }

      const iVal = value as MultiDateType;
      const examDateFrom = iVal?.[0] as dayjs.Dayjs;
      const examDateTo = iVal?.[1] as dayjs.Dayjs;

      return !_.isNil(examDateFrom) && !_.isNil(examDateTo)
        ? {
            examDateFrom: examDateFrom.format("YYYY-MM-DD"),
            examDateTo: examDateTo.format("YYYY-MM-DD")
          }
        : {};
    }
  };
};
