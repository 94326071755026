import { LoadingOutlined } from "@ant-design/icons";
import { Select } from "antd";
import React from "react";
import { useDebouncedCallback } from "use-debounce";
import { MESSAGES } from "../i18n";
import { WorkingFilterTypeWithoutAll } from "../redux/actions/partners";
import { navApi } from "../services/nav-api";
import { Partner } from "../services/nav-api/partners/types";
import { Status } from "../services/nav-api/types";
const { Option } = Select;

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------
export const PartnersSelect = (props: {
  onChange: (value: number) => void;
  value: number | undefined;
  type?: WorkingFilterTypeWithoutAll | undefined;
}) => {
  const { value, type, onChange } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [partners, setPartners] = React.useState<Partner[]>([]);

  React.useEffect(() => {
    setPartners([]);
  }, [value]);

  const handleSearch = useDebouncedCallback(async (name: string) => {
    if (!name || name.length <= 1) {
      setPartners([]);
      return;
    }
    setLoading(true);
    navApi.partners
      .getPartners({ type: type, name, limit: 1000, status: Status.Enabled })
      .then(data => setPartners(data.partners))
      .finally(() => setLoading(false));
  }, 1000);

  return (
    <Select
      showSearch
      value={value}
      onChange={onChange}
      className="partners-selector"
      optionFilterProp="children"
      filterOption={() => true}
      suffixIcon={loading ? <LoadingOutlined spin /> : null}
      onSearch={handleSearch}
      placeholder={MESSAGES.PartnersSelectPlaceholder}
      disabled={!type}
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
    >
      {partners.map(t => (
        <Option key={t.id} value={t.id} className={`option-partner-${t.id}`}>
          {t.name}
        </Option>
      ))}
    </Select>
  );
};
