import _ from "lodash";
import React from "react";
import { TagNode } from "../../../../redux/selectors/my-tenant-tags-tree";
import { navApi } from "../../../../services/nav-api";
import { TagUtils } from "../../../../utils/TagUtils";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type TenantTagsValue = {
  loading: boolean;
  tree: TagNode[];
};

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

export const tenantTagsInitValue: { tenantTags: TenantTagsValue } = {
  tenantTags: { loading: false, tree: [] }
};

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useTenantTags = (tenantId: number | null = null): TenantTagsValue => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [tree, setTree] = React.useState<TagNode[]>([]);

  const fetchTags = React.useCallback(async (tenantId: number) => {
    try {
      setLoading(true);
      const rsp = await navApi.tenants.getTenantTags(tenantId);
      setTree(TagUtils.buildTagsTreeFromTags(rsp.tags));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (_.gt(tenantId, 0)) {
      fetchTags(tenantId!);
    }
  }, [tenantId]);

  return { loading, tree };
};
