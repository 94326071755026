import React from "react";
import { Field } from "../context";

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useLastName = (): Field<string> => {
  const [value, setValue] = React.useState<string | null>(null);
  return {
    value,
    setValue: (val: string) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(null);
    }
  };
};
