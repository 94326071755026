import React from "react";

// -------------------------------------------------------------------------------------------------
// - Context
// -------------------------------------------------------------------------------------------------

interface Context {
  active?: string;
}

// -------------------------------------------------------------------------------------------------
// - AppMenuContext
// -------------------------------------------------------------------------------------------------

export const AppMenuContext = React.createContext<Context>({});
