import { FilterTag } from "../../../core/model";
import { useExamTrackerFilter } from "./useExamTrackerFilter";
import { useLastActivityFilter } from "./useLastActivityFilter";
import { useLicenseFilter } from "./useLicenseFilter";
import { useMetricsFilter } from "./useMetricsFilter";
import { usePurchasedDateFilter } from "./usePurchasedDateFilter";
import { useSearchTermFilter } from "./useSearchTermFilter";
import { useSelectedSectionFilter } from "./useSelectedSectionFilter";
import { useStudyPlanFilter } from "./useStudyPlanFilter";
import { useStudyPlanRefFilter } from "./useStudyPlanRefFilter";
import { useStudyTimeFilter } from "./useStudyTimeFilter";
import { useTagsFilter } from "./useTagsFilter";
import { useTenantFilter } from "./useTenantFilter";

// -------------------------------------------------------------------------------------------------
// - Hooks: useStudentFilterTags
// -------------------------------------------------------------------------------------------------

export const useStudentFilterTags = (): FilterTag[] => {
  const selectedSection = useSelectedSectionFilter();
  const lastActivity = useLastActivityFilter();
  const examTracker = useExamTrackerFilter();
  const license = useLicenseFilter();
  const studyPlanId = useStudyPlanRefFilter();
  const metricsFilter = useMetricsFilter();
  const purchasedDate = usePurchasedDateFilter();
  const searchTerm = useSearchTermFilter();
  const studyPlan = useStudyPlanFilter();
  const studyTime = useStudyTimeFilter();
  const tags = useTagsFilter();
  const tenant = useTenantFilter();

  return [
    ...studyPlanId,
    ...selectedSection,
    ...lastActivity,
    ...examTracker,
    ...license,
    ...metricsFilter,
    ...purchasedDate,
    ...searchTerm,
    ...studyPlan,
    ...studyTime,
    ...tags,
    ...tenant
  ];
};
