// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";

import { getMyTenant } from "../../../../redux/selectors/my-tenant";
import { getDefaultNewUserRole } from "../../../../redux/selectors/roles";
import { navApi } from "../../../../services/nav-api";
import { FormControllerContext } from "../../hooks/userFormController";
import { TenantOption, TenantStatus } from "../model";

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useTenants = () => {
  const myTenant = useSelector(getMyTenant);
  const defaultNewUserRole = useSelector(getDefaultNewUserRole);
  const { selectedTenantId } = React.useContext(FormControllerContext);

  const [status, setStatus] = React.useState<TenantStatus>(TenantStatus.NEEDS_SELECTION);
  const [options, setOptions] = React.useState<TenantOption[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  // -----------------------------------------------------------------------------------------------
  //  Make tenants request
  //
  const makeTenantsRequest = React.useCallback(async () => {
    try {
      setLoading(true);
      setOptions(
        _.map((await navApi.tenants.getTenants({ status: "Enabled", limit: 1000 }))?.tenants, t => ({
          label: t.name,
          value: t.id
        }))
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  // -----------------------------------------------------------------------------------------------
  //  Request tenants
  //
  React.useEffect(() => {
    if (loading) {
      return;
    }

    if (_.gt(myTenant?.id, 0)) {
      setStatus(TenantStatus.LOCKED);
      selectedTenantId.setValue(myTenant?.id);
    } else {
      if (_.isNil(options)) {
        makeTenantsRequest();
      }
    }
  }, [JSON.stringify(myTenant), defaultNewUserRole, selectedTenantId.value]);
  //
  //  Request tenants
  // -----------------------------------------------------------------------------------------------

  return {
    status,
    loading,
    value: selectedTenantId.value,
    options: _.isEqual(TenantStatus.NEEDS_SELECTION, status) //
      ? (options ?? [])
      : !_.isNil(myTenant)
        ? [{ label: myTenant.name, value: myTenant.id }]
        : []
  };
};
