import dayjs from "dayjs";
import { AltID } from "../../../../../constants/courses";
import { StudyPlansListItem } from "../../../../../services/nav-api/studyPlans/model";

export enum StudyPace {
  THOROUGH = "THOROUGH",
  AVERAGE = "AVERAGE",
  QUICK = "QUICK"
}

export enum TimeOffType {
  SINGLE_DAY = "SINGLE_DAY",
  RANGE_OF_DAYS = "RANGE_OF_DAYS"
}

export type WeekDayHours = [number, number, number, number, number, number, number];

export interface StudyPlanEditorValue {
  tenantId: number | null;
  name: string | null;
  section: AltID | null;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs | null;
  lockUntil: dayjs.Dayjs | null;
  practiceTests: boolean;
  flashcards: boolean;
  finalReview: boolean;
  studyPace: StudyPace;
  daysOff: Array<dayjs.Dayjs>;
  weekDayHours: WeekDayHours;
  studyTimeWeek?: number;
  tagIds: number[];
}

export type Field<T, O = {}> = {
  value: T;
  setValue: (val?: T) => void;
  resetValue: () => void;
} & O;

export type ToApiRequestValue<T> = () => T;
export type FromApiResponseValue<R> = (val: StudyPlansListItem) => R;
