import { DatePicker, Form } from "antd";
import React from "react";
import { StudyPlanCustomTasksEditorContext } from "../../hooks/useStudyPlanCustomTasksEditor";
import { StudyPlanContext } from "../../hooks/useStudyPlanViewerData";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlanCustomTaskEditorFieldDate = () => (
  <StudyPlanCustomTasksEditorContext.Consumer>
    {({ actions }) => (
      <StudyPlanContext.Consumer>
        {({ summary: { startDate, endDate } }) => (
          <Form.Item label="Start Date" required>
            <DatePicker
              allowClear={false}
              className="w-full"
              data-qa-label="task-date"
              format="MM/DD/YYYY"
              value={actions.getTaskProp("date") ?? null}
              disabledDate={cur => cur?.isBefore(startDate, "day") || cur?.isAfter(endDate, "day")}
              onChange={date => actions.setTaskProp("date", date)}
            />
          </Form.Item>
        )}
      </StudyPlanContext.Consumer>
    )}
  </StudyPlanCustomTasksEditorContext.Consumer>
);
