import { Form, Select } from "antd";
import _ from "lodash";
import React from "react";
import { StudyCalendarUtils } from "../../../hooks/useStudyPlanEditor/utils/StudyCalendarUtils";
import { StudyPlanCustomTasksEditorContext } from "../../hooks/useStudyPlanCustomTasksEditor";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

// calculate the number of 15m blocks during 16 hours
const QUARTER_HOURS_OPTIONS = _.range(0, (16 * 60) / 15).map(v => ({
  label: `${StudyCalendarUtils.formatDuration((v + 1) * 15)}`,
  value: (v + 1) * 15
}));

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlanCustomTaskEditorFieldDuration = () => (
  <StudyPlanCustomTasksEditorContext.Consumer>
    {({ actions }) => (
      <Form.Item label="Task duration" required>
        <Select
          data-qa-label="task-duration"
          placeholder="Select duration"
          options={QUARTER_HOURS_OPTIONS}
          value={actions.getTaskProp("quarterHours")! * 15}
          onChange={value => actions.setTaskProp("quarterHours", value / 15)}
        />
      </Form.Item>
    )}
  </StudyPlanCustomTasksEditorContext.Consumer>
);
