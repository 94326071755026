import { notification } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import React from "react";

// -------------------------------------------------------------------------------------------------
// - Context
// -------------------------------------------------------------------------------------------------

export const ApplicationToastsContext = React.createContext<NotificationInstance>(null!);

// -------------------------------------------------------------------------------------------------
// - Components
// -------------------------------------------------------------------------------------------------

export const ApplicationToasts = (props: React.HTMLProps<HTMLDivElement>) => {
  const [api, contextHolder] = notification.useNotification();

  React.useEffect(() => {}, []);

  return (
    <ApplicationToastsContext.Provider value={api}>
      {contextHolder}
      {props.children}
    </ApplicationToastsContext.Provider>
  );
};
