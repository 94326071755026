import { Typography } from "antd";
import React from "react";
import { useParams } from "react-router";
import { BaseLayout } from "../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../components/NavigationDrawer/types";
import { MESSAGES } from "../../i18n";
import { AlertsList } from "./components/AlertsList";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const AlertsReader = () => {
  const { id } = useParams<{ id: string }>();
  const alertId = id ? parseInt(id) : undefined;
  return React.useMemo(
    () => (
      <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.PROFILE_NOTIFICATIONS}>
        <Typography.Title level={2}>{MESSAGES.NavNotifications}</Typography.Title>
        <AlertsList alertId={alertId} />
      </BaseLayout>
    ),
    [alertId]
  );
};

export default AlertsReader;
