import includes from "lodash/includes";

import { createNodeParentById } from "../create-mappers";
import { Node } from "../types";

// -------------------------------------------------------------------------------------------------
//  Utils
// -------------------------------------------------------------------------------------------------

const isAnyAncestorChecked = <T extends Node>(tree: T[] | null, nodeId: number, checkedIds: number[]) => {
  const parentById = createNodeParentById(tree);

  if (!parentById[nodeId]) {
    return false;
  }

  let parentId = parentById[nodeId];
  while (parentId) {
    if (includes(checkedIds, parentId)) {
      return true;
    }
    parentId = parentById[parentId]!;
  }

  return false;
};

// -------------------------------------------------------------------------------------------------
//  Utils
// -------------------------------------------------------------------------------------------------

export const excludeRedundantNodes = <T extends Node>(tree: T[] | null, checkedIds: number[]) => {
  return checkedIds.filter(nId => !isAnyAncestorChecked(tree, nId, checkedIds));
};
