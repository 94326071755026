import { FETCH_UNAUTH_COMPLETED, FETCH_UNAUTH_STARTED } from "../../actions/unauth/actions";
import { RdxFetchStatus } from "../../types/status";
import { createReducer } from "../../utils/redux-utils";
import { RdxUnauthState } from "./types";

// -------------------------------------------------------------------------------------------------
// - Reducer
// -------------------------------------------------------------------------------------------------

export default createReducer(
  {
    status: RdxFetchStatus.NONE,
    ffs: undefined
  },
  {
    [FETCH_UNAUTH_STARTED]: (): RdxUnauthState => {
      return {
        status: RdxFetchStatus.LOADING
      };
    },

    [FETCH_UNAUTH_COMPLETED]: (_, { ffs }: RdxUnauthState): RdxUnauthState => {
      return {
        status: RdxFetchStatus.COMPLETED,
        ffs
      };
    }
  }
);
