import { NoTenantFilterAllValue } from "../../../components/TenantsSelect";
import { DEFAULT_LIST_PAGE, DEFAULT_LIST_SIZE } from "../../../constants/lists";
import { RoleIdFilterAllValue } from "../../../pages/Users/UsersTableFilterForRoles";
import { GetUsersResponse, Status } from "../../../services/nav-api/types";
import {
  DoChangeListSettingsPayload,
  FETCH_USERS_COMPLETED,
  FETCH_USERS_STARTED,
  FetchUsersCompletedPayload,
  UpdateWorkingFilterPayload,
  USERS_LIST_FILTER_COMPLETED,
  USERS_LIST_FILTER_STARTED,
  USERS_LIST_SETTINGS_CHANGED,
  WORKING_USERS_FILTER_CLEARED,
  WORKING_USERS_FILTER_UPDATED,
  WorkingFilter
} from "../../actions/users-list";
import { RdxFetchStatus } from "../../types/status";
import { createReducer } from "../../utils/redux-utils";

type UsersDetails = GetUsersResponse;

export interface RdxUsersState {
  workingFilter: WorkingFilter;
  activeFilter?: {
    filter?: WorkingFilter;
    loading: boolean;
  };
  data?: UsersDetails;
  paging: {
    page: number;
    totalItems?: number;
    itemsPerPage: number;
  };
  status: RdxFetchStatus;
  error?: string;
  lastUpdated?: number;
}

export const initialState: RdxUsersState = {
  status: RdxFetchStatus.NONE,
  paging: {
    page: DEFAULT_LIST_PAGE,
    itemsPerPage: DEFAULT_LIST_SIZE
  },
  workingFilter: {
    searchTerm: "",
    roleId: RoleIdFilterAllValue,
    status: Status.Enabled,
    tenantId: NoTenantFilterAllValue,
    creationDate: {
      startDate: undefined,
      endDate: undefined
    },
    lastLoginDate: {
      startDate: undefined,
      endDate: undefined
    }
  }
};

export default createReducer(initialState, {
  [USERS_LIST_FILTER_STARTED]: state => ({ ...state, lastUpdated: Date.now() }),
  [WORKING_USERS_FILTER_UPDATED]: (state, payload: UpdateWorkingFilterPayload) => ({
    ...state,
    workingFilter: { ...state.workingFilter, ...payload }
  }),
  [WORKING_USERS_FILTER_CLEARED]: state => ({
    ...state,
    workingFilter: { ...initialState.workingFilter }
  }),
  [FETCH_USERS_STARTED]: state => ({
    ...state,
    status: RdxFetchStatus.LOADING,
    error: undefined,
    activeFilter: {
      ...(state.activeFilter || {}),
      loading: true
    }
  }),
  [FETCH_USERS_COMPLETED]: (state, { error, users, filter }: FetchUsersCompletedPayload) => {
    if (error) {
      return {
        ...state,
        status: RdxFetchStatus.COMPLETED,
        data: undefined,
        error: error.message,
        activeFilter: {
          ...state.activeFilter,
          loading: false
        }
      };
    }
    return {
      ...state,
      status: RdxFetchStatus.COMPLETED,
      data: users,
      activeFilter: {
        filter,
        loading: false
      }
    };
  },
  [USERS_LIST_SETTINGS_CHANGED]: (
    state,
    { paging: { page, pageSize: itemsPerPage } }: DoChangeListSettingsPayload
  ) => ({
    ...state,
    lastUpdated: Date.now(),
    paging: {
      ...state.paging,
      page,
      itemsPerPage
    }
  }),
  [USERS_LIST_FILTER_COMPLETED]: state => ({ ...state })
});
