import { Typography } from "antd";
import _ from "lodash";
import React from "react";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const NameField = () => (
  <StudyPlanEditorContext.Consumer>
    {({ editor }) => {
      return (
        <Typography.Text type={_.isEmpty(editor.name.value) ? "secondary" : undefined}>
          {!_.isEmpty(editor.name.value) ? editor.name.value : null}
        </Typography.Text>
      );
    }}
  </StudyPlanEditorContext.Consumer>
);
