import _ from "lodash";
import moment from "moment";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { navApi } from "../../../services/nav-api";
import { Alert } from "../../../services/nav-api/alerts-writer/types";
import { AlertsOperation, OperationType } from "../../reducers/alerts-writer/types";
import { Action, ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "../../types/status";
import { doFetchAlerts } from "./fetch";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const dateFormat = "YYYY-MM-DD";

// -------------------------------------------------------------------------------------------------
// - Actions
// -------------------------------------------------------------------------------------------------

export const ALERTS_OPERATION_START = "OPERATION_START";
export const ALERTS_OPERATION_UPDATE = "ALERTS_OPERATION_UPDATE";
export const ALERTS_OPERATION_COMPLETION = "OPERATION_COMPLETION";

export const ALERTS_OPERATION_START_SAVE = "ALERTS_OPERATION_START_SAVE";
export const ALERTS_OPERATION_FINISH_SAVE = "ALERTS_OPERATION_FINISH_SAVE";
export const ALERTS_OPERATION_FINISH_SAVE_WITH_ERROR = "ALERTS_OPERATION_FINISH_SAVE_WITH_ERROR";
export const ALERTS_OPERATION_ERROR_DISCARD = "ALERTS_OPERATION_ERROR_DISCARD";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doStartEditingOperation = (alert?: Alert): Action<AlertsOperation> => ({
  type: ALERTS_OPERATION_START,
  payload: {
    type: OperationType.EDIT,
    status: RdxFetchStatus.NONE,
    alert: alert ?? {
      id: -1,
      title: "",
      body: "",
      publishDate: moment().format(dateFormat),
      expirationDate: moment().add(1, "M").format(dateFormat)
    }
  }
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doStartExpireOperation = (alert: Alert): Action<AlertsOperation> => ({
  type: ALERTS_OPERATION_START,
  payload: {
    type: OperationType.EXPIRE,
    alert
  }
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doStartDeleteOperation = (alert: Alert): Action<AlertsOperation> => ({
  type: ALERTS_OPERATION_START,
  payload: {
    type: OperationType.DELETE,
    alert
  }
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doUpdateAlertInOperation = (alert: Partial<Alert>): Action<Partial<Alert>> => ({
  type: ALERTS_OPERATION_UPDATE,
  payload: alert
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doDismissOperation = (): Action => ({
  type: ALERTS_OPERATION_COMPLETION
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

const doStartSaveOperation = (): Action => ({
  type: ALERTS_OPERATION_START_SAVE
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

const doFinishSaveOperation = (): Action => ({
  type: ALERTS_OPERATION_FINISH_SAVE
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

const doFinishSaveOperationWithError = (error: string): Action<string> => ({
  type: ALERTS_OPERATION_FINISH_SAVE_WITH_ERROR,
  payload: error
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doDiscardOperationError = (): Action => ({
  type: ALERTS_OPERATION_ERROR_DISCARD
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doSaveAndFinishOperation = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    if (_.isEqual(getState().alertsWriter.operation?.status, RdxFetchStatus.LOADING)) {
      return;
    }

    dispatch(doStartSaveOperation());

    try {
      switch (getState().alertsWriter.operation?.type) {
        case OperationType.EDIT:
          await navApi.alertsWriter.saveAlert(getState().alertsWriter.operation?.alert);
          break;
        case OperationType.DELETE:
          await navApi.alertsWriter.deleteAlert(getState().alertsWriter.operation?.alert);
          break;
        case OperationType.EXPIRE:
          await navApi.alertsWriter.expireAlert(getState().alertsWriter.operation?.alert);
          break;
      }

      dispatch(doFinishSaveOperation());
      dispatch(doDismissOperation());
      dispatch(doFetchAlerts());
    } catch (ex) {
      dispatch(doFinishSaveOperationWithError((ex as HttpClientErrorResponse).message || "error"));
    }
  };
};
