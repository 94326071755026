import { Badge, Typography } from "antd";
import React from "react";
import { StudyPlanTask } from "../../../../../../services/nav-api/studyPlans/model";
import { StudyCalendarUtils } from "../../../../hooks/useStudyPlanEditor/utils/StudyCalendarUtils";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarDayCellTaskModule = (props: { task: StudyPlanTask & { quarterHours?: number } }) => (
  <li
    key={`module-${props.task.id}`}
    title={
      `${props.task?.unitAbbrev}-${props.task?.moduleAbbrev}: ` +
      `${StudyCalendarUtils.formatDuration(props.task.quarterHours! * 15)}`
    }
  >
    <Badge
      color="#1E9DB5"
      text={
        <Typography.Text strong>
          {props.task?.unitAbbrev} &#8226; {props.task?.moduleAbbrev}
        </Typography.Text>
      }
    />
  </li>
);
