import _ from "lodash";
import { useSelector } from "react-redux";
import { getIsGlobalUser } from "../../../../../redux/selectors/permissions/is-global-user";
import { getIsSystemAdmin } from "../../../../../redux/selectors/permissions/is-system-admin";
import { StudyPlanEditorFields } from "../useEditorFields";
import { EditorStep } from "../useNavigation";
import { RemoteStudyPlanValue } from "../useRemoteStudyPlan";
import { useStepProgress } from "./useDerivedStepProgress";
import { useStudyPlanValidator } from "./useDerivedStudyPlanValidator";
import { useWeekDaysInfo } from "./useDerivedWeekDaysInfo";
import { useWeeklyDistributionInfo } from "./useDerivedWeeklyDistributionInfo";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export enum EditorMode {
  GLOBAL_ADMIN_CREATION,
  TENANT_ADMIN_CREATION,
  EDIT
}

export type StudyPlanEditorDerivedValues = {
  isValid: boolean;
  mode: EditorMode;
  weekDaysInfo: {
    title: string;
    value:
      | {
          weeksBetween: number;
          daysBetween: number;
        }
      | undefined;
  };

  weeklyDistributionInfo: {
    weekDaysHoursCount: number;
    studyTimeWeekValue: number;
    isStudyTimeOk: boolean;
  };

  progress: {
    value: number;
    isComplete: boolean;
  };
};

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useDerivedFields = (
  editorFields: StudyPlanEditorFields,
  remoteData: RemoteStudyPlanValue,
  editorStep: EditorStep
): StudyPlanEditorDerivedValues => {
  const isGlobalUser = useSelector(getIsGlobalUser);
  const isSystemAdmin = useSelector(getIsSystemAdmin);

  const weeklyDistributionInfo = useWeeklyDistributionInfo(editorFields);
  const isValid = useStudyPlanValidator(editorFields, weeklyDistributionInfo);
  const progress = useStepProgress(editorStep, editorFields, weeklyDistributionInfo);
  const weekDaysInfo = useWeekDaysInfo(editorFields);

  return {
    weeklyDistributionInfo,
    weekDaysInfo,
    progress,
    isValid,

    mode: _.isNil(remoteData.response)
      ? !(isGlobalUser || isSystemAdmin)
        ? EditorMode.TENANT_ADMIN_CREATION
        : EditorMode.GLOBAL_ADMIN_CREATION
      : EditorMode.EDIT
  };
};

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

export const studyPlanEditorDerivedValuesInitValues: StudyPlanEditorDerivedValues = {
  isValid: false,
  mode: EditorMode.TENANT_ADMIN_CREATION,
  weeklyDistributionInfo: {
    weekDaysHoursCount: 0,
    studyTimeWeekValue: 0,
    isStudyTimeOk: false
  },
  weekDaysInfo: {
    title: "",
    value: undefined
  },
  progress: {
    value: 0,
    isComplete: false
  }
};
