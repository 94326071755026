import { StarFilled, StarOutlined } from "@ant-design/icons";
import { Button, Card, Drawer, Flex, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BaseLayout } from "../../components/BaseLayout";
import { ContentVersionSwitch } from "../../components/ContentVersionSwitch";
import { NavigationDrawerOptionKey } from "../../components/NavigationDrawer/types";
import { MESSAGES } from "../../i18n";
import { doRefreshStudentsListIfNeeded, doUpdateStudentFollowed } from "../../redux/actions/students-list";
import { getContentVersion } from "../../redux/selectors/contentversion";
import { getIsGlobalUser } from "../../redux/selectors/permissions/is-global-user";
import { getStudentDetailsPageTitle } from "../../redux/selectors/student-details-page-title";
import { getIsCurStudentFollowed } from "../../redux/selectors/student-followed";
import { routes } from "../../routes";
import { StudentActionsContext, useStudentActions } from "./hooks/useStudentActions";
import { Student } from "./Student";
import { StudentActions } from "./StudentsActions";
import { StudentsActionsBar } from "./StudentsActionsBar";
import { StudentsAddButton } from "./StudentsAddButton";
import { StudentsFilter } from "./StudentsFilter";
import { FormActions } from "./StudentsFilter/components/FormActions";
import { StudentsFilterTags } from "./StudentsFilterTags";
import { StudentsTable } from "./StudentsTable";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Students = (props: { showStudentDetails?: boolean }) => {
  const { id } = useParams<{ id: string }>();
  const studentId = parseInt(id!);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const studentsTableActionsContextProps = useStudentActions();

  const contentversion = useSelector(getContentVersion);
  const followBtnState = useSelector(getIsCurStudentFollowed);
  const isGlobalUser = useSelector(getIsGlobalUser);
  const pageTitle = useSelector(getStudentDetailsPageTitle);

  React.useEffect(() => {
    dispatch(doRefreshStudentsListIfNeeded(true));
  }, [contentversion]);

  return (
    <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.STUDENTS}>
      <StudentActionsContext.Provider value={studentsTableActionsContextProps}>
        {
          // ---------------------------------------------------------------------------------------
          //  Header
          // ---------------------------------------------------------------------------------------
        }
        <Flex align="center" gap={4}>
          <Typography.Title level={2} className="grow" data-qa-label="students-page">
            {MESSAGES.StudentsPageTitle}
          </Typography.Title>
          <ContentVersionSwitch />
          <StudentsAddButton />
        </Flex>
        {
          // ---------------------------------------------------------------------------------------
          //  Table / Filters / Actions
          // ---------------------------------------------------------------------------------------
        }

        <Card>
          <Flex vertical gap={12}>
            <StudentActions />
            <StudentsFilterTags />
            <StudentsActionsBar />
            <div className="overflow-x-hidden">
              <StudentsTable />
            </div>
          </Flex>
        </Card>

        {
          // ---------------------------------------------------------------------------------------
          //  Filters Drawer
          // ---------------------------------------------------------------------------------------
        }

        <Drawer
          closable
          placement="right"
          title={MESSAGES.FiltersDrawerTitle}
          width={Math.min(430, window.innerWidth)}
          open={studentsTableActionsContextProps.showFilters}
          extra={<FormActions />}
          footer={<FormActions />}
          onClose={() => {
            studentsTableActionsContextProps.onShowFiltersChange(false);
          }}
        >
          <StudentsFilter />
        </Drawer>

        {
          // ---------------------------------------------------------------------------------------
          //  Student Details Drawer
          // ---------------------------------------------------------------------------------------
        }

        <Drawer
          closable
          placement="right"
          title={pageTitle}
          extra={
            !isGlobalUser ? (
              <Button
                type="link"
                loading={followBtnState.loading}
                onClick={() => {
                  dispatch(doUpdateStudentFollowed(followBtnState.curStudentId!, !followBtnState.followed));
                }}
              >
                {followBtnState.followed ? MESSAGES.UnfollowThisStudent : MESSAGES.FollowThisStudent}
                {followBtnState.followed ? <StarFilled /> : <StarOutlined />}
              </Button>
            ) : null
          }
          width={Math.min(1100, window.innerWidth)}
          open={!!props.showStudentDetails}
          onClose={() => {
            navigate(routes.students.resolve());
          }}
        >
          <Student id={studentId} />
        </Drawer>
      </StudentActionsContext.Provider>
    </BaseLayout>
  );
};
