import { Button } from "antd";
import _ from "lodash";
import React from "react";
import { useStudyPlanWritePermission } from "../../../../redux/hooks/useStudyPlansPermissions";
import { StudyPlanCustomTasksEditorContext } from "../hooks/useStudyPlanCustomTasksEditor";
import { StudyPlanContext } from "../hooks/useStudyPlanViewerData";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlanCreateTaskButton = () => {
  const canEditStudyPlan = useStudyPlanWritePermission();
  return canEditStudyPlan ? (
    <StudyPlanCustomTasksEditorContext.Consumer>
      {({ loading, actions }) => (
        <StudyPlanContext.Consumer>
          {plan => (
            <Button
              type="link"
              className="p-0 m-0"
              loading={plan.loading || loading}
              disabled={_.isNil(plan.id) || _.isEqual(plan.summary.status, "archived")}
              onClick={() => {
                actions.openEditor({ operation: "create_empty" });
              }}
            >
              Create a task
            </Button>
          )}
        </StudyPlanContext.Consumer>
      )}
    </StudyPlanCustomTasksEditorContext.Consumer>
  ) : null;
};
