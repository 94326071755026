import React from "react";
import { MESSAGES } from "../../i18n";
import { GenericApiFailureModal } from "./ModalForGenericApiFailure";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const MultipleSessionModal = () => (
  <GenericApiFailureModal
    message={MESSAGES.ApiFailureMultipleSession}
    buttons={[
      {
        onClick: () => window.location.reload(),
        label: MESSAGES.ApiFailureMultipleSessionBtnCaption
      }
    ]}
  />
);
