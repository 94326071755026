import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Typography } from "antd";
import download from "downloadjs";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ApplicationToastsContext } from "../../components/AppToasts";
import { routes } from "../../routes";
import { navApi } from "../../services/nav-api";
import { getTaskBlobWhenReady } from "../../services/task";
import { getCurDateForReportFilename } from "../../utils/date-utils";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantsTableHeader = () => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const tenants = useSelector(({ tenants: { tenants } }) => tenants);
  const hasRecords = Boolean(tenants?.paging?.totalItems);

  return (
    <Flex gap={4}>
      <Typography.Title level={2} className="grow">
        Tenants
      </Typography.Title>
      <ApplicationToastsContext.Consumer>
        {api => (
          <Button
            icon={<FileExcelOutlined />}
            loading={loading}
            disabled={!hasRecords}
            data-qa-label="createReportBtn"
            onClick={async () => {
              const filter = tenants?.activeFilter?.filter?.filter;
              const status = tenants?.activeFilter?.filter?.status;

              setLoading(true);
              try {
                const { taskToken } = await navApi.tenants.createTenantsReport({ filter, status });
                const blob = await getTaskBlobWhenReady(taskToken);

                // Download file
                download(blob, `tenants-report-${getCurDateForReportFilename()}.xlsx`, blob.type);

                // Send Success
                api.success({
                  message: `Tenants report created successfully`,
                  showProgress: true,
                  pauseOnHover: true
                });
              } catch (err) {
                // Show error
                api.error({
                  message: `Error creating report`,
                  description: _.isString(err)
                    ? err
                    : "An error occurred while creating the Tenants Report. Please try again.",
                  showProgress: true,
                  pauseOnHover: true
                });
              } finally {
                setLoading(false);
              }
            }}
          >
            Export
          </Button>
        )}
      </ApplicationToastsContext.Consumer>

      <Button
        icon={<PlusOutlined />}
        type="primary"
        data-qa-label="newTenantBtn"
        onClick={() => {
          navigate(routes.tenantNew.resolve());
        }}
      >
        New Tenant
      </Button>
    </Flex>
  );
};
