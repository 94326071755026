import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AlertsFilters, AlertStatus } from "../../reducers/alerts-writer/types";
import { Action, ThunkAction } from "../../types/action";
import { doFetchAlerts } from "./fetch";
import { doResetAlertsPagination } from "./pagination";

// -------------------------------------------------------------------------------------------------
// - Actions
// -------------------------------------------------------------------------------------------------

export const SET_ALERTS_FILTER = "SET_ALERTS_FILTER";
export const ACTIVATE_ALERTS_FILTER = "ACTIVATE_ALERTS_FILTER";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doSetAlertsFilter = (filter: Partial<AlertsFilters>): Action<Partial<AlertsFilters>> => ({
  type: SET_ALERTS_FILTER,
  payload: filter
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

const doActivateAlertsFilter = (): Action => ({
  type: ACTIVATE_ALERTS_FILTER
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doApplyAlertsFilter =
  (): ThunkAction =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(doActivateAlertsFilter());
    dispatch(doResetAlertsPagination());
    dispatch(doFetchAlerts());
  };

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doResetAlertsFilter =
  (): ThunkAction =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(
      doSetAlertsFilter({
        status: AlertStatus.ACTIVE
      })
    );
    dispatch(doActivateAlertsFilter());
    dispatch(doResetAlertsPagination());
    dispatch(doFetchAlerts());
  };
