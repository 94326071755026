import _ from "lodash";
import React from "react";
import { Field, UserInfoStatus } from "../context";

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useUserInfoStatus = (
  initVal: UserInfoStatus
): Field<
  UserInfoStatus,
  {
    isIn: (statusList: Array<UserInfoStatus>) => boolean;
    isEqualTo: (statusList: UserInfoStatus) => boolean;
    isLoading: boolean;
    startLoading: () => void;
    finishLoading: () => void;
    getError: () => string | null;
    setError: (e: string | null) => void;
  }
> => {
  const [value, setValue] = React.useState<UserInfoStatus | null>(initVal);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  return {
    value,
    isLoading,
    startLoading: () => {
      setLoading(true);
    },
    finishLoading: () => {
      setLoading(false);
    },
    setValue: (val: UserInfoStatus) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(initVal);
    },
    isIn: (statusList: Array<UserInfoStatus>) => {
      return _.includes(statusList, value);
    },
    isEqualTo: (other: UserInfoStatus) => {
      return _.isEqual(other, value);
    },

    getError: () => {
      return error;
    },

    setError: (e: string | null) => {
      setError(e);
    }
  };
};
