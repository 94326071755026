import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { TagNode } from "../../../../redux/selectors/my-tenant-tags-tree";
import { getDefaultNewUserRole } from "../../../../redux/selectors/roles";
import { navApi } from "../../../../services/nav-api";
import { TagUtils } from "../../../../utils/TagUtils";
import { FormControllerContext } from "../../hooks/userFormController";
import { useUserRoles } from "./userRoles";

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export type TenantTagsContextValue = {
  loading: boolean;
  tree: TagNode[];
  hasPermissionsAvailable: boolean;
};

export const TenantTagsContext = React.createContext<TenantTagsContextValue>({
  loading: false,
  tree: [],
  hasPermissionsAvailable: false
});

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useTenantTags = (): TenantTagsContextValue => {
  const defaultNewUserRole = useSelector(getDefaultNewUserRole);
  const { selectedTenantId, selectedRoleId } = React.useContext(FormControllerContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [tree, setTree] = React.useState<TagNode[]>([]);
  const userRoles = useUserRoles();

  React.useEffect(() => {
    if (_.isNil(selectedTenantId.value)) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const rsp = await navApi.tenants.getTenantTags(selectedTenantId.value!);
        setTree(TagUtils.buildTagsTreeFromTags(rsp.tags));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [selectedTenantId.value, defaultNewUserRole]);

  return {
    loading,
    tree,
    hasPermissionsAvailable: !!userRoles.isTenantUser(selectedRoleId.value)
  };
};
