import React from "react";
import { useSelector } from "react-redux";
import { BaseLayout } from "../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../components/NavigationDrawer/types";
import { getIsGlobalUser } from "../../redux/selectors/permissions/is-global-user";
import { BeckerDashboard } from "./BeckerDashboard";
import { TenantDashboard } from "./TenantDashboard";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const Dashboard = () => {
  const isGlobalUser = useSelector(getIsGlobalUser);
  return (
    <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.DASHBOARD}>
      {isGlobalUser ? ( //
        <BeckerDashboard />
      ) : (
        <TenantDashboard />
      )}
    </BaseLayout>
  );
};

export default Dashboard;
