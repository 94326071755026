import { FilterFilled, FilterOutlined, FilterTwoTone, ReloadOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Flex, Typography } from "antd";
import { ColumnProps } from "antd/es/table";
import { FilterDropdownProps } from "antd/es/table/interface";
import _ from "lodash";
import React from "react";
import { SPStatusTag } from "../../common/SPStatusTag";
import { Status } from "../../hooks/useStudyPlanList";
import { CreateColumnProps } from "./model";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const options = _.map(["draft", "published", "archived"], (status: Status) => ({
  label: _.capitalize(status),
  value: status
}));

// -------------------------------------------------------------------------------------------------
// - Column builder
// -------------------------------------------------------------------------------------------------

export const createStatusColumn = ({
  sort,
  paging,
  filters: { statusFilter }
}: CreateColumnProps): ColumnProps<{}>[] => [
  {
    sorter: true,
    width: 120,
    title: <Typography.Text>Status</Typography.Text>,
    render: val => <SPStatusTag status={val} />,
    dataIndex: "status",
    sortOrder: _.isEqual(sort?.key, "status") ? sort?.order : undefined,
    filterIcon: _.isEqual(statusFilter.value, statusFilter.defaultValue) ? (
      <FilterFilled />
    ) : (
      <FilterTwoTone twoToneColor="green" />
    ),
    filterDropdown: (props: FilterDropdownProps) => {
      const [status, setStatus] = React.useState<Status[]>(statusFilter.value);

      React.useEffect(() => {
        setStatus(statusFilter.value);
      }, [props.visible]);

      return (
        <Flex vertical gap={12} style={{ padding: 12 }}>
          <Checkbox.Group
            style={{ display: "flex", flexDirection: "column" }}
            options={options}
            value={status}
            onChange={setStatus}
          />

          <Divider style={{ padding: 0, margin: 0 }} />
          <Flex gap={6} justify="end">
            <Button
              size="small"
              icon={<ReloadOutlined />}
              onClick={() => {
                paging.setFirstPage();
                statusFilter.setValue(statusFilter.defaultValue);
                props.close();
              }}
            >
              Reset
            </Button>
            <Button
              size="small"
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => {
                paging.setFirstPage();
                statusFilter.setValue(status);
                props.close();
              }}
            >
              Apply
            </Button>
          </Flex>
        </Flex>
      );
    }
  }
];
