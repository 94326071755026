import { RightOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getMyTenant } from "../../../../redux/selectors/my-tenant";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";
import { EditorMode } from "../../hooks/useStudyPlanEditor/useDerivedFields";
import { EndDateField } from "./FieldEndDate";
import { NameField } from "./FieldName";
import { SectionField } from "./FieldSection";
import { StartDateField } from "./FieldStartDate";
import { StudyTimeWeekField } from "./FieldStudyTimeWeek";
import { TagIDsField } from "./FieldTags";
import { TenantField } from "./FieldTenant";
import { WeekDaysInfoField, WeekDaysInfoTitle } from "./FieldWeekDaysInfo";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const EditorSummary = () => {
  const myTenant = useSelector(getMyTenant);
  return (
    <StudyPlanEditorContext.Consumer>
      {({ apiCallbacks, remoteStudyPlan, editor: { isValid, mode } }) => (
        <Flex justify="center" align="center" vertical>
          <Typography.Title level={4} className="mb-0 pb-0">
            Plan Summary
          </Typography.Title>
          <Divider />
          <table className="studyplan-summary">
            <tbody>
              <tr>
                <td>
                  <Typography.Text type="secondary">Name:</Typography.Text>
                </td>
                <td colSpan={3}>
                  <NameField />
                </td>
              </tr>
              <tr>
                <td>
                  <Typography.Text type="secondary">Section:</Typography.Text>
                </td>
                <td colSpan={3}>
                  <SectionField />
                </td>
              </tr>
              {_.isNil(myTenant) ? (
                <tr>
                  <td>
                    <Typography.Text type="secondary">Tenant:</Typography.Text>
                  </td>
                  <td colSpan={3}>
                    <TenantField />
                  </td>
                </tr>
              ) : null}
              <tr>
                <td>
                  <Typography.Text type="secondary">Tags:</Typography.Text>
                </td>
                <td colSpan={3}>
                  <TagIDsField />
                </td>
              </tr>
              <tr>
                <td>
                  <Typography.Text type="secondary">Start/End Dates:</Typography.Text>
                </td>
                <td>
                  <StartDateField />
                </td>
                <td>
                  <RightOutlined />
                </td>
                <td>
                  <EndDateField />
                </td>
              </tr>
              <tr>
                <td>
                  <Typography.Text type="secondary">Study time per week:</Typography.Text>
                </td>
                <td colSpan={3}>
                  <StudyTimeWeekField />
                </td>
              </tr>
              <tr>
                <td>
                  <WeekDaysInfoTitle />
                </td>
                <td colSpan={3}>
                  <WeekDaysInfoField />
                </td>
              </tr>
            </tbody>
          </table>

          <Divider />
          <Button
            className="w-full"
            type="primary"
            disabled={!isValid}
            onClick={() => {
              apiCallbacks.onSave(remoteStudyPlan.response?.id);
            }}
          >
            {_.isEqual(EditorMode.EDIT, mode) ? "Save and Reschedule" : "Create Study Plan"}
          </Button>
        </Flex>
      )}
    </StudyPlanEditorContext.Consumer>
  );
};
