import { RightOutlined } from "@ant-design/icons";
import { Flex, Skeleton, Tag, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { TagNode } from "../../../redux/selectors/my-tenant-tags-tree";
import { useTenantTags } from "../hooks/useStudyPlanEditor/useTenantTags";

// -------------------------------------------------------------------------------------------------
// - Utils
// -------------------------------------------------------------------------------------------------

const findTagPath = (tagId: number, tree: TagNode[]): React.ReactNode[] => {
  for (let tag of tree) {
    if (_.isEqual(tag.id, tagId)) {
      return [<Typography.Text key={tag.id}>{tag.name}</Typography.Text>];
    }

    const res = findTagPath(tagId, tag.children);
    if (!_.isEmpty(res)) {
      return [<Typography.Text key={tag.id}>{tag.name}</Typography.Text>, ...res];
    }
  }

  return [];
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const SPTenantTags = (props: { tenantId: number; tagIds: number[] | undefined }) => {
  const tenantTags = useTenantTags(props.tenantId);
  return !tenantTags.loading ? (
    <Flex vertical={false} wrap justify="left" align="left" gap={6}>
      {_.chain(props.tagIds)
        .map(tagId => (
          <Tag key={tagId} closable={false}>
            <Flex vertical={false} gap={6}>
              {_.reduce(
                findTagPath(tagId, tenantTags.tree),
                (a: React.ReactNode[], e, i) => {
                  if (i > 0) {
                    a.push(<RightOutlined key={i} />);
                  }

                  a.push(e);
                  return a;
                },
                []
              )}
            </Flex>
          </Tag>
        ))
        .value()}
    </Flex>
  ) : (
    <Skeleton active />
  );
};
