import React from "react";
import { Field } from "../context";

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useAllowAddStudents = (): Field<boolean> => {
  const [value, setValue] = React.useState<boolean | null>(null);
  return {
    value: Boolean(value),
    setValue: (val: boolean) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(null);
    }
  };
};
