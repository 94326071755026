export enum Status {
  Enabled = "Enabled",
  Disabled = "Disabled"
}

export enum UserType {
  LOCAL = "LOCAL",
  BIM = "BIM",
  SAML = "SAML"
}

export type User = {
  id: number;
  idp: UserType;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  roleId: number;
  tenantId: number | null;
  tagIds: number[];
  created: number;
  lastLogin: number;
  pendingInviteToken?: string;
  status: Status;
  userId?: number;
  unsubscribeAlerts: boolean;
  allowAddStudents?: boolean;
};

export type GetUsersResponse = {
  users: User[];
  total: number;
};

export type CreateUserResponse = {
  userId: number;
};

export type GetUsersReportResponse = {
  taskToken: string;
};

export type GetUsersParams = {
  status?: string;
  filter?: string;
  tenantId?: string | number;
  roleId?: number;
  limit?: number;
  offset?: number;
  lastLoginDateMin?: string;
  lastLoginDateMax?: string;
  creationDateMin?: string;
  creationDateMax?: string;
};

export type PutUsersParams = {
  email: string;
  firstName: string;
  lastName: string;
  roleId: number;
  tagIds: number[];
};

export type PostUserInviteParams = {
  email: string;
  firstName?: string;
  lastName?: string;
  roleId: number;
  tagIds?: number[];
  tenantId?: number | null;
};

export type CreateUserParams = {
  email: string;
  firstName?: string; // Only required if there's no student in BIM with this username
  lastName?: string; // Only required if there's no student in BIM with this username
  tenantId: number | null;
  partnerId?: number; // Only required if there's no student in BIM with this username
  roleId: number; // Tenant User | Tenant Admin
  tagIds: number[];
  unsubscribeAlerts?: boolean;
  allowAddStudents?: boolean;
};

export type UpdateUserParams = {
  email: string;
  roleId: number; // Tenant User | Tenant Admin
  tagIds: number[];
  tenantId: number;
  unsubscribeAlerts?: boolean;
  allowAddStudents?: boolean;
};
