import { Badge, Typography } from "antd";
import React from "react";
import { StudyPlanTask } from "../../../../../../services/nav-api/studyPlans/model";
import { StudyCalendarUtils } from "../../../../hooks/useStudyPlanEditor/utils/StudyCalendarUtils";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarDayCellTaskSimExam = (props: { task: StudyPlanTask & { quarterHours?: number } }) => (
  <li
    key={`sim-exam-${props.task.id}`}
    title={
      `Simulated Exam ${props.task.examIndex! + 1}: ` + //
      `${StudyCalendarUtils.formatDuration(props.task.quarterHours! * 15)}`
    }
  >
    <Badge
      color="#9D59DD"
      text={<Typography.Text strong>Simulated Exam {props.task.examIndex! + 1}</Typography.Text>}
    />
  </li>
);
