import { toDate } from "date-fns";
import { createSelector } from "reselect";
import { sectionNameByAltId } from "../../constants/courses";
import { RdxStudentDetails } from "../reducers/student-details";
import { RdxStoreState } from "../types/state";
import { AltID } from "./../../constants/courses";
import { RdxFetchStatus } from "./../types/status";

export type StudentEnrollment = {
  course: string;
  sectionAltId: AltID;
  purchaseDate: Date | undefined;
  expirationDate: Date | undefined;
  isUnlimited: boolean;
};

export const getStudentEnrollments: (state: RdxStoreState) => { enrollments?: StudentEnrollment[]; loading?: boolean } =
  createSelector(
    (state: RdxStoreState) => state.studentDetails,
    ({ curStudentId, details, status }: RdxStudentDetails) => {
      if (!curStudentId || !details) {
        return {};
      }

      if (details) {
        return {
          enrollments: details.enrollments.map(
            (enrollment): StudentEnrollment => ({
              sectionAltId: enrollment.sectionAltId,
              course: sectionNameByAltId[enrollment.sectionAltId],
              purchaseDate: enrollment.purchaseDate ? toDate(enrollment.purchaseDate) : undefined,
              expirationDate: enrollment.expirationDate ? toDate(enrollment.expirationDate) : undefined,
              isUnlimited: enrollment.unlimited
            })
          )
        };
      }

      if (status === RdxFetchStatus.LOADING) {
        return { loading: true };
      }

      return {};
    }
  );
