import { Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { ApplicationToastsContext } from "../../components/AppToasts";
import { BaseLayout } from "../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../components/NavigationDrawer/types";
import { TenantsForm } from "../../components/TenantsForm";
import { routes } from "../../routes";
import { navApi } from "../../services/nav-api";
import { PostTenantsResponse } from "../../services/nav-api/tenants/types";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantNew = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  return (
    <BaseLayout
      activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_TENANTS}
      breadcrumb={[
        {
          name: "Tenants",
          href: routes.tenants.resolve()
        },
        {
          name: "New Tenant"
        }
      ]}
    >
      <Typography.Title level={2}>New Tenant</Typography.Title>
      <ApplicationToastsContext.Consumer>
        {api => (
          <TenantsForm
            loading={loading}
            onSubmit={async tp => {
              try {
                // Start Loading
                setLoading(true);

                // Create new tenant
                const tenantId = (
                  (await navApi.tenants.postTenant(
                    tp.name,
                    tp.type!,
                    tp.products,
                    tp.hasTagForm,
                    tp.hasStudyPlan
                  )) as PostTenantsResponse
                )?.tenantId;

                // Send Success
                api.success({
                  message: `Tenant ${tp.name} created with success`,
                  showProgress: true,
                  pauseOnHover: true
                });

                // Go to the Tenant's page
                navigate(routes.tenant.resolve({ id: tenantId.toString() }));
              } catch (e) {
                // Send Error
                api.error({
                  message: `Error creating tenant ${tp.name}`,
                  description: e.message,
                  showProgress: true,
                  pauseOnHover: true
                });
              } finally {
                // Finish Loading
                setLoading(false);
              }
            }}
          />
        )}
      </ApplicationToastsContext.Consumer>
    </BaseLayout>
  );
};
