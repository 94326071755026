import { Spin, Tree } from "antd";
import { DataNode } from "antd/es/tree";
import _ from "lodash";
import React, { JSX } from "react";
import { TagNode } from "../../redux/selectors/my-tenant-tags-tree";
import { CheckBehavior } from "./types";

// -------------------------------------------------------------------------------------------------
// - Utils
// -------------------------------------------------------------------------------------------------

const walkTree = (nodes: TagNode[] | null): DataNode[] =>
  _.reduce(
    nodes,
    (a, n) => {
      const children = walkTree(n.children);
      return [
        ...a,
        {
          key: n.id,
          children,
          selectable: _.isEmpty(children),
          checkable: _.isEmpty(children),
          title: n.name
        }
      ];
    },
    []
  );

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CheckableTreeView = (props: {
  tree: TagNode[] | null;
  loading: boolean;
  checked: number[];
  onChange: (value: number[]) => void;
  renderNode: (node: TagNode) => JSX.Element | string | undefined | null;
  checkBehavior: CheckBehavior;
  defaultExpandAll?: boolean;
}) => (
  <Spin spinning={props.loading}>
    <Tree
      showLine
      checkable
      checkStrictly
      autoExpandParent
      defaultExpandAll={props.defaultExpandAll}
      selectable={false}
      treeData={walkTree(props.tree)}
      checkedKeys={props.checked}
      onCheck={({ checked }: { checked: React.Key[]; halfChecked: React.Key[] }) => {
        props.onChange(checked as number[]);
      }}
    />
  </Spin>
);
