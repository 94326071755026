import { Form, Modal } from "antd";
import _ from "lodash";
import React from "react";
import {
  StudyPlanCustomTasksEditorContext,
  StudyPlanTaskEditorStatus
} from "../../hooks/useStudyPlanCustomTasksEditor";
import { StudyPlanCustomTaskEditorFieldDate } from "./FieldDate";
import { StudyPlanCustomTaskEditorFieldDuration } from "./FieldDuration";
import { StudyPlanCustomTaskEditorFieldName } from "./FieldName";
import { StudyPlanCustomTaskEditorFooter } from "./Footer";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlanCustomTaskEditor = () => (
  <StudyPlanCustomTasksEditorContext.Consumer>
    {({ loading, status, actions }) => (
      <Modal
        closable
        maskClosable
        confirmLoading={loading}
        footer={() => <StudyPlanCustomTaskEditorFooter />}
        onCancel={() => {
          actions.closeEditor();
        }}
        title={
          _.isEqual(status, StudyPlanTaskEditorStatus.OPEN_FOR_CREATE) //
            ? "Create Task"
            : "Edit Task"
        }
        open={
          _.isEqual(status, StudyPlanTaskEditorStatus.OPEN_FOR_CREATE) ||
          _.isEqual(status, StudyPlanTaskEditorStatus.OPEN_FOR_EDIT)
        }
      >
        <Form layout="vertical">
          <StudyPlanCustomTaskEditorFieldName />
          <StudyPlanCustomTaskEditorFieldDate />
          <StudyPlanCustomTaskEditorFieldDuration />
        </Form>
      </Modal>
    )}
  </StudyPlanCustomTasksEditorContext.Consumer>
);
