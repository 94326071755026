import { Flex, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PolarAngleAxis, RadialBar, RadialBarChart } from "recharts";
import { AltID, sectionColorByAltId, sectionNameByAltId } from "../../../../../constants/courses";
import { doSetFilter } from "../../../../../redux/actions/students-list";
import { getCurrentWorkingFilter, getTenantDashboardStats } from "../../../../../redux/selectors/dashboard/tenant";
import { routes } from "../../../../../routes";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  altId: AltID;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const PassRateChartBySection = ({ altId }: OwnProps) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const stats = useSelector(getTenantDashboardStats);
  const filter = useSelector(getCurrentWorkingFilter);
  const label = sectionNameByAltId[altId];
  const fill = sectionColorByAltId[altId];
  const percent = stats?.examTracker?.passRateBySection //
    ? stats.examTracker.passRateBySection![altId]!
    : 0;

  return (
    <Flex vertical justify="center" align="center" className="grow">
      <RadialBarChart
        cx="50%"
        cy="50%"
        innerRadius="80%"
        outerRadius="100%"
        width={100}
        height={100}
        barSize={200}
        data={[{ fill, percent }]}
        style={{ cursor: "pointer" }}
        onClick={() => {
          dispatch(doSetFilter({ ...filter, license: "active", examTracker: altId }));
          navigate(routes.students.resolve());
        }}
      >
        <PolarAngleAxis type="number" domain={[0, 1]} angleAxisId={0} tick={false} />
        <RadialBar
          background
          maxBarSize={1}
          dataKey="percent"
          isAnimationActive={false}
          label={() => (
            <text x={50} y={50} cx={50} cy={50} offset={5} textAnchor="middle" fill={fill}>
              <tspan x="50" dy="0.355em" data-qa-label={_.toLower(`passrate-by-section-${altId}-count`)}>
                {_.isNaN(percent) ? "0%" : `${Math.round(percent * 100)}%`}
              </tspan>
            </text>
          )}
        />
      </RadialBarChart>

      <Typography.Text
        className={_.join(
          [
            "text-sm",
            "text-center",
            "text-slate-600"
            //
          ],
          " "
        )}
      >
        {label}
      </Typography.Text>
    </Flex>
  );
};
