import { DownOutlined, FilterOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Dropdown, Flex, Input, Typography } from "antd";
import _ from "lodash";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RenderChildrenIf } from "../../components/ConditionalRenderers";
import { MESSAGES, formatMsg } from "../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../redux/actions/students-list";
import { getStudentsTableDetails } from "../../redux/selectors/students-table-data-source";
import { RdxStoreState } from "../../redux/types/state";
import { StudentActionsContext } from "./hooks/useStudentActions";
import { useStudentTenant } from "./hooks/useStudentTenant";
import { QuickStudentsTenantFilter } from "./StudentsFilter/components/TenantFilter";
import { ReportsModal } from "./StudentsReportsModal";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const SEARCH_MIN_CHARS = 3;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentActions = () => {
  const { onBulkAllItems, onBulkCurPage, onShowFiltersChange } = useContext(StudentActionsContext);
  const { paging } = useSelector(getStudentsTableDetails);
  const workingFilter = useSelector((state: RdxStoreState) => state.studentsList.workingFilter);
  const totalItems = paging && paging.total ? paging.total : 0;
  const dispatch = useDispatch<any>();
  const tenantPermissions = useStudentTenant();

  const handleSearchSubmit = React.useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      if (workingFilter.searchTerm.length >= SEARCH_MIN_CHARS) {
        dispatch(doApplyFilter());
      }
    },
    [JSON.stringify(workingFilter)]
  );

  return (
    <Flex gap={4}>
      <QuickStudentsTenantFilter />
      <form className="flex-1" onSubmit={handleSearchSubmit}>
        <Input.Search
          data-qa-label="search-field"
          value={workingFilter.searchTerm}
          placeholder={formatMsg(MESSAGES.FilterSearchPlaceholderWMinChars, SEARCH_MIN_CHARS)}
          enterButton={<Button type="primary" icon={<SearchOutlined />} data-qa-label="search-btn" />}
          onChange={e => {
            dispatch(
              doUpdateWorkingFilter({
                searchTerm: e.currentTarget.value
              })
            );
          }}
          onSearch={() => {
            handleSearchSubmit();
          }}
        />
      </form>

      <Button
        icon={<FilterOutlined />}
        data-qa-label="filtersBtn"
        onClick={() => {
          onShowFiltersChange(true);
        }}
      >
        {MESSAGES.FilterBtnLabel}
      </Button>
      <ReportsModal />

      <RenderChildrenIf condition={tenantPermissions.isEnabled}>
        <Dropdown
          placement="bottomRight"
          menu={{
            onClick: e => {
              switch (e.key) {
                case "menu-item-all":
                  onBulkAllItems();
                  break;
                case "menu-item-current":
                  onBulkCurPage();
                  break;
              }
            },
            items:
              !tenantPermissions.isSelected || _.isEqual(totalItems, 0)
                ? [
                    {
                      key: "feedback-1",
                      label: (
                        <Typography.Text type="warning" data-qa-label="bulkChangeFeedback">
                          <Typography.Text strong type="warning">
                            To proceed with this operation: <br />
                          </Typography.Text>
                          <ul>
                            {!tenantPermissions.isSelected ? <li>a tenant must be selected</li> : null}
                            {_.isEqual(totalItems, 0) ? (
                              <li>there must be at least one student listed in the search results</li>
                            ) : null}
                          </ul>
                        </Typography.Text>
                      )
                    }
                  ]
                : [
                    {
                      key: "menu-item-all",
                      label: <Typography.Text data-qa-label="bulkChangeCurPageBtn">{MESSAGES.All}</Typography.Text>
                    },
                    {
                      key: "menu-item-current",
                      label: <Typography.Text data-qa-label="bulkChangeBtn">{MESSAGES.CurrentPage}</Typography.Text>
                    }
                  ]
          }}
        >
          <Button icon={<SettingOutlined />} data-qa-label="actionsBtn">
            {MESSAGES.BulkChange} <DownOutlined />
          </Button>
        </Dropdown>
      </RenderChildrenIf>
    </Flex>
  );
};
