import { DeleteOutlined } from "@ant-design/icons";
import { Button, Flex, Popconfirm } from "antd";
import _ from "lodash";
import React from "react";
import {
  StudyPlanCustomTasksEditorContext,
  StudyPlanTaskEditorStatus
} from "../../hooks/useStudyPlanCustomTasksEditor";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlanCustomTaskEditorFooter = () => (
  <StudyPlanCustomTasksEditorContext.Consumer>
    {({ loading, status, actions, isCustomTaskInvalid }) => (
      <Flex justify="space-between" gap={12}>
        {_.isEqual(status, StudyPlanTaskEditorStatus.OPEN_FOR_EDIT) ? (
          <Popconfirm
            title="Delete task"
            description="Are you sure you want to delete this task?"
            okText="Delete"
            cancelText="Cancel"
            onConfirm={async () => {
              await actions.deleteTask();
              actions.closeEditor();
            }}
          >
            <Button danger type="link" className="px-0 mx-0" icon={<DeleteOutlined />} loading={loading}>
              Delete Task
            </Button>
          </Popconfirm>
        ) : null}
        <div className="grow" />
        <Button onClick={async () => actions.closeEditor()}>Cancel</Button>
        <Button
          onClick={async () => actions.closeEditor(true)}
          disabled={isCustomTaskInvalid}
          loading={loading}
          type="primary"
        >
          {_.isEqual(status, StudyPlanTaskEditorStatus.OPEN_FOR_CREATE) //
            ? "Create task"
            : "Save"}
        </Button>
      </Flex>
    )}
  </StudyPlanCustomTasksEditorContext.Consumer>
);
