import { Button, Card, Result } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { routes } from "../../../routes";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const EditorUnauthResult = () => {
  const navigateTo = useNavigate();
  return (
    <Card>
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button
            type="primary"
            onClick={() => {
              navigateTo(routes.studyPlans.resolve());
            }}
          >
            Back to the study plans
          </Button>
        }
      />
    </Card>
  );
};
