import { CloseCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Flex, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../i18n";
import { doResetFilter } from "../../redux/actions/students-list/index";
import { initialWorkingFilter } from "../../redux/reducers/students-list/index";
import { RdxStoreState } from "../../redux/types/state";
import { RdxFetchStatus } from "../../redux/types/status";
import { useStudentFilterTags } from "./hooks/useStudentFilterTags";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentsFilterTags = () => {
  const dispatch = useDispatch<any>();
  const filterTags = useStudentFilterTags();
  const isLoading = useSelector(
    ({ studentsList: { activeFilter, status } }: RdxStoreState) =>
      _.isEqual(status, RdxFetchStatus.LOADING) || activeFilter?.loading
  );
  const isResetDisabled = useSelector(({ studentsList }: RdxStoreState) =>
    _.isEqual(studentsList.workingFilter, initialWorkingFilter)
  );

  return filterTags?.length > 0 ? (
    <Flex align="center" gap={4} wrap>
      <Typography.Text strong>{MESSAGES.FilteredBy}</Typography.Text>

      {_.map(filterTags, ({ name, text, onClear }, idx) => (
        <Button
          size="small"
          key={`filterTag-${name}.${idx}`}
          data-qa-label={`filter-tag-${name}`}
          icon={<CloseCircleOutlined />}
          disabled={isLoading}
          onClick={onClear}
        >
          {text}
        </Button>
      ))}
      <Button
        size="small"
        type="primary"
        icon={<ReloadOutlined />}
        data-qa-label={`filter-tag-reset`}
        disabled={isLoading || isResetDisabled}
        onClick={() => {
          dispatch(doResetFilter());
        }}
      >
        {MESSAGES.ResetFilters}
      </Button>
    </Flex>
  ) : null;
};
