import { PerformanceDashboardItem } from ".";
import { formatMsg, MESSAGES } from "../../../i18n";
import {
  StudentHierarchySimExamProgress,
  StudentModuleHierarchyProgress,
  StudentSectionHierarchyProgress,
  StudentUnitHierarchyProgress
} from "../../actions/student-details/types";
import { ProgressMappers } from "./create-progress-mappers";

export function createDataSource(
  { sections, unitsBySectionId, modulesByUnitId }: ProgressMappers,
  isCPA: boolean
): PerformanceDashboardItem[] {
  function createModuleItem(progress: StudentModuleHierarchyProgress): PerformanceDashboardItem & { key: string } {
    return {
      key: `module-${progress.altId}`,
      name: `${progress.abbrev} - ${progress.name}`,
      conceptVideosProgress: progress.conceptVideosProgress ?? progress.lecturesProgress,
      mcqsProgress: progress.mcqsProgress,
      mcqsCorrect: progress.mcqsCorrect,
      mcqsTotal: progress.mcqsTotal,
      simsCorrect: progress.simsCorrect,
      simsTotal: progress.simsTotal,
      simsProgress: progress.simsProgress,
      progress: isCPA ? progress.edr : progress.courseProgress,
      essaysCorrect: progress.essaysCorrect,
      essaysTotal: progress.essaysTotal,
      essaysProgress: progress.essaysProgress
    };
  }

  function createSimExamItem(
    progress: StudentHierarchySimExamProgress,
    parentKey: string,
    index: number
  ): PerformanceDashboardItem & { key: string } {
    return {
      key: `${parentKey}-simexam-${index}`,
      name: `Simulated Exam ${index + 1}`,
      simsCorrect: progress.simsCorrect,
      simsTotal: progress.simsTotal,
      simsProgress: progress.simsProgress,
      mcqsCorrect: progress.mcqsCorrect,
      mcqsTotal: progress.mcqsTotal,
      mcqsProgress: progress.mcqsProgress,
      progress: progress.progress,
      essaysCorrect: progress.essaysCorrect,
      essaysTotal: progress.essaysTotal,
      essaysProgress: progress.essaysProgress
    };
  }

  function createUnitItem(
    progress: StudentUnitHierarchyProgress
  ): (PerformanceDashboardItem & { isME?: boolean; key: string })[] {
    const key = `unit-${progress.altId}`;
    const { simExam: meProgress } = progress;
    const modulesWithProgressInformation = modulesByUnitId[progress.id].map(m => createModuleItem(m));

    return [
      {
        key,
        name: `${progress.abbrev} - ${progress.name}`,
        conceptVideosProgress: progress.conceptVideosProgress ?? progress.lecturesProgress,
        mcqsProgress: progress.mcqsProgress,
        simsProgress: progress.simsProgress,
        simsCorrect: progress.simsCorrect,
        simsTotal: progress.simsTotal,
        mcqsCorrect: progress.mcqsCorrect,
        mcqsTotal: progress.mcqsTotal,
        progress: isCPA ? progress.edr : progress.courseProgress,
        essaysCorrect: progress.essaysCorrect,
        essaysTotal: progress.essaysTotal,
        essaysProgress: progress.essaysProgress,
        children: modulesWithProgressInformation.length ? modulesWithProgressInformation : undefined
      },

      ...(meProgress
        ? [
            {
              isME: true,
              key: key + "-me",
              // MCQs
              mcqsCorrect: meProgress.mcqsCorrect,
              mcqsProgress: meProgress.mcqsProgress,
              mcqsTotal: meProgress.mcqsTotal,
              // SimExams
              simsCorrect: meProgress.simsCorrect,
              simsProgress: meProgress.simsProgress,
              simsTotal: meProgress.simsTotal,
              // Essays
              essaysProgress: meProgress.essaysProgress,
              essaysCorrect: meProgress.essaysCorrect,
              essaysTotal: meProgress.essaysTotal,
              // Overall
              progress: meProgress.progress
            }
          ]
        : [])
    ];
  }

  function createSectionItem(progress: StudentSectionHierarchyProgress): PerformanceDashboardItem & { key: string } {
    const key = `section-${progress.altId}`;
    const seList = (progress.simExams || []).map((s, idx) => createSimExamItem(s, key, idx));
    const uList: PerformanceDashboardItem[] = unitsBySectionId[progress.id]
      .filter(unit => unit.isFR != true && unit.abbrev.charAt(1) != "-")
      .flatMap(m => createUnitItem(m))
      .reduce(
        ({ data, count }, { isME, ...u }) => ({
          count: isME ? count + 1 : count,
          data: [
            ...data,
            isME
              ? {
                  ...u,
                  name: formatMsg(MESSAGES.MiniExamNr, count + 1)
                }
              : u
          ]
        }),
        { data: [], count: 0 }
      ).data;

    return {
      key,
      altId: progress.altId,
      name: `${progress.abbrev} ${progress.variant}`,
      conceptVideosProgress: progress.conceptVideosProgress ?? progress.lecturesProgress,
      mcqsCorrect: progress.mcqsCorrect,
      mcqsTotal: progress.mcqsTotal,
      mcqsProgress: progress.mcqsProgress,
      simsTotal: progress.simsTotal,
      simsCorrect: progress.simsCorrect,
      simsProgress: progress.simsProgress,
      progress: isCPA ? progress.edr : progress.courseProgress,
      essaysCorrect: progress.essaysCorrect,
      essaysTotal: progress.essaysTotal,
      essaysProgress: progress.essaysProgress,
      children: [...uList, ...seList]
    };
  }

  return sections.map(createSectionItem);
}
