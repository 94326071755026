import { PaginationProps } from "antd/lib/pagination";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doFetchAlerts } from "../../../../redux/actions/alerts-writer/fetch";
import { doStartEditingOperation } from "../../../../redux/actions/alerts-writer/operation";
import { doChangeAlertsPaginationAndFetch } from "../../../../redux/actions/alerts-writer/pagination";
import { getAlerts } from "../../../../redux/selectors/alerts-writer/alerts";
import { Alert } from "../../../../services/nav-api/alerts-writer/types";
import { TableContext } from "./context";
import { PureTable } from "./table-pure";
import { TableState } from "./types";

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

const useTableState = (): TableState => {
  const dispatch = useDispatch<any>();
  const alertsState = useSelector(getAlerts);
  const keyedAlerts = useMemo(() => {
    return _.map(alertsState.alerts, a => ({ ...a, key: a.id }));
  }, [alertsState.alerts]);

  useEffect(() => {
    dispatch(doFetchAlerts());
  }, []);

  return {
    alertsState: { ...alertsState, alerts: keyedAlerts },
    eventHandlers: {
      onEditRow: (alert: Alert) => {
        dispatch(doStartEditingOperation(alert));
      },

      onChange: ({ current: page, pageSize }: PaginationProps) => {
        dispatch(
          doChangeAlertsPaginationAndFetch({
            page: page!,
            itemsPerPage: pageSize!
          })
        );
      }
    }
  };
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const NotificationsTable = () => (
  <TableContext.Provider value={useTableState()}>
    <PureTable />
  </TableContext.Provider>
);
