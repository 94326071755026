import * as CryptoJS from "crypto-js";
import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { sectionNameByAltId } from "../../../../constants/courses";
import { StudyPlansListItem } from "../../../../services/nav-api/studyPlans/model";
import { StudyCalendarUtils } from "../../hooks/useStudyPlanEditor/utils/StudyCalendarUtils";
import { StudyPlanValue } from "./useStudyPlanViewerData";

// -------------------------------------------------------------------------------------------------
// - Helpers
// -----------------------------------------------------------------------------------------------

const formatDate = (date: dayjs.Dayjs) => (!_.isNil(date) ? date.format("MM/DD/YYYY") : "");

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const buildSummary = (studyPlan: StudyPlansListItem | null): StudyPlanValue["summary"] => {
  const sdate = dayjs(studyPlan?.settings?.startDate);
  const edate = dayjs(studyPlan?.settings?.examDate);
  const cdate = dayjs(studyPlan?.created);
  const ldate = !_.isNil(studyPlan?.lockUntil) ? dayjs(studyPlan?.lockUntil) : null;
  const daysOff = _.map(studyPlan?.settings?.daysOff, day => dayjs(day));

  const studyDurationInfo = StudyCalendarUtils.buildWeekDaysInfo(sdate, edate, daysOff.length);

  return {
    name: studyPlan?.name ?? "Study Plan",
    section: _.isNil(studyPlan?.sectionAltId) ? "" : sectionNameByAltId[studyPlan?.sectionAltId],

    startDate: sdate,
    endDate: edate,
    created: formatDate(cdate),
    lockUntil: ldate,

    startDateFmt: formatDate(sdate),
    endDateFmt: formatDate(edate),
    lockUntilFmt: ldate ? formatDate(ldate) : "-",

    daysOff: _.map(studyPlan?.settings?.daysOff, day => dayjs(day)),
    status: studyPlan?.status,
    tenantId: studyPlan?.tenant?.id,
    tenantName: studyPlan?.tenant?.name,
    tagIds: _.map(studyPlan?.tags, tag => tag.id),
    tags: _.map(studyPlan?.tags, tag => tag.name),

    studyDurationTitle: studyDurationInfo && studyDurationInfo.daysBetween <= 15 ? "Days of study" : "Weeks of study",
    studyDuration: studyDurationInfo
      ? studyDurationInfo.daysBetween <= 15
        ? studyDurationInfo.daysBetween
        : studyDurationInfo.weeksBetween
      : null,

    range: !_.some([sdate, edate], _.isNil) //
      ? ([sdate!, edate!] as [dayjs.Dayjs, dayjs.Dayjs])
      : undefined
  };
};

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useStudyPlanData = (studyPlan: StudyPlansListItem | null) =>
  React.useMemo(
    () => ({
      id: studyPlan?.id,
      tasks: _.keyBy(studyPlan?.tasks, "id"),
      summary: buildSummary(studyPlan),
      calendar: _.chain(studyPlan?.calendar)
        .map(ical => ({ ...ical, day: dayjs(ical.day) }))
        .value()
    }),
    [CryptoJS.MD5(JSON.stringify(studyPlan)).toString()]
  );
