import { Form, Select } from "antd";
import React from "react";
import { WorkingFilterTypeTypes } from "../redux/actions/partners";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantTypeFilter = (props: { onChange: (value?: any) => void; value: string | undefined }) => (
  <Form.Item label="Type">
    <Select
      className="tenants-type-dp"
      data-qa-label="tenant-type-select"
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      onChange={props.onChange}
      value={props.value}
    >
      {WorkingFilterTypeTypes.map(type => (
        <Select.Option key={type} value={type} className={`option-type-${type}`}>
          {type.replace(/([A-Z])/g, " $1").trim()}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);
