import React from "react";
import { StudyPlansListItem } from "../../../../../services/nav-api/studyPlans/model";
import { Field, FromApiResponseValue, StudyPlanEditorValue, ToApiRequestValue } from "./model";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type NameFieldValue = StudyPlanEditorValue["name"];
export type NameField = Field<
  NameFieldValue,
  {
    toApiRequestValue: ToApiRequestValue<string>;
    fromApiResponseValue: FromApiResponseValue<NameFieldValue>;
  }
>;

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useName = (): NameField => {
  const [value, setValue] = React.useState<NameFieldValue | null>(null);
  return {
    value,
    setValue: (val: NameFieldValue) => {
      setValue(val);
    },
    resetValue: () => {
      setValue(null);
    },
    toApiRequestValue: () => {
      return value ?? "";
    },
    fromApiResponseValue: (rsp: StudyPlansListItem): NameFieldValue => {
      const iVal = rsp.name;
      setValue(iVal);
      return iVal;
    }
  };
};
