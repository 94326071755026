import _ from "lodash";
import { createSelector } from "reselect";
import { doFetchTenantTags } from "../actions/tenants/tags";
import { getActiveUserRoles } from "../selectors/active-user-roles";
import { getMyTenant } from "../selectors/my-tenant";
import { getUserFetchStatus } from "../selectors/user-fetch-status";
import { ThunkAction } from "../types/action";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";

// -------------------------------------------------------------------------------------------------
// - Reactor
// -------------------------------------------------------------------------------------------------

export const reFetchMyTenantsTags: (state: RdxStoreState) => ThunkAction | undefined = createSelector(
  getMyTenant,
  (state: RdxStoreState) => state.tenants,
  getUserFetchStatus,
  (state: RdxStoreState) => state.myself.userRoles,
  getActiveUserRoles,
  (tenant, { tenantTags }, userFetchStatus, roles, activeUserRoles) => {
    if (!tenant || !_.isEqual(RdxFetchStatus.COMPLETED, userFetchStatus) || !roles) {
      return undefined;
    }

    if (activeUserRoles?.length > 0) {
      const [role] = activeUserRoles;

      const status = tenantTags!.statusByTenantId[tenant.id];
      const alreadyHasTenantTags = Boolean(tenantTags!.tagsIdsbyTenantId[tenant.id]);
      const isFetchingOrHasFailed = status && (status === RdxFetchStatus.LOADING || status === RdxFetchStatus.FAILED);
      const isDataStale = tenantTags!.staleByTenantId[tenant.id];

      if (!isFetchingOrHasFailed && (!alreadyHasTenantTags || isDataStale)) {
        return doFetchTenantTags(tenant.id, role.name === "Tenant User");
      }
    }

    return undefined;
  }
);
