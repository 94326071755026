import { CopyOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SwapOutlined } from "@ant-design/icons";
import { Divider, Dropdown, Flex, Modal, Tree, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { formatMsg, MESSAGES } from "../../i18n";
import { getMyTenantTagsTree, TagNode } from "../../redux/selectors/my-tenant-tags-tree";
import { getAllRootIds } from "../../utils/tree-utils/get-all-roots";
import { MAX_DEPTH_LEVEL_ALLOWED } from "./constants";
import { TagsEditorContext } from "./context";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const MoveStudentsTree = (props: { id: number; onClick: (value: TagNode) => void }) => {
  const { tree = [] } = useSelector(getMyTenantTagsTree);
  const walk = React.useCallback(
    (tags: TagNode[] | null, disabledIds: number[]): TagNode[] =>
      _.reduce(
        tags,
        (a, t) => [
          ...a,
          {
            ...t,
            disabled: _.includes(disabledIds, t.id),
            children: walk(t.children, disabledIds)
          }
        ],
        []
      ),
    []
  );

  return (
    <Tree
      blockNode
      showLine
      selectable
      multiple={false}
      defaultExpandAll
      treeData={walk(tree, [...getAllRootIds(tree), props.id])}
      onSelect={(_keys, info) => {
        props.onClick(info.node);
      }}
    />
  );
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TagContextMenu = ({
  id,
  name,
  children,
  depthLevel,
  enrolledStudentsCount,
  totalEnrolledStudentsCount
}: { children?: React.ReactNode | React.ReactNode[] } & {
  enrolledStudentsCount: number;
  totalEnrolledStudentsCount: number;
  depthLevel: number;
  name: string;
  hasChildren: boolean;
  id: number;
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [ts, setTs] = React.useState<number | null>(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (!open) {
      setTs(new Date().getTime());
    }
  }, [open]);

  return (
    <TagsEditorContext.Consumer>
      {({ onClone, onEdit, onCreate, onMoveStudents, onDelete }) => (
        <>
          {contextHolder}
          <Dropdown
            open={open}
            onOpenChange={isOpen => setOpen(isOpen)}
            trigger={["contextMenu", "click"]}
            menu={{
              // -----------------------------------------------------------------------------------
              // - Items
              // -----------------------------------------------------------------------------------
              items: [
                {
                  key: "new",
                  icon: <PlusOutlined />,
                  disabled: MAX_DEPTH_LEVEL_ALLOWED <= depthLevel,
                  label: (
                    <Typography.Text data-qa-label="contextMenuNewBtn" disabled={MAX_DEPTH_LEVEL_ALLOWED <= depthLevel}>
                      {MESSAGES.CreateNewLabel}
                    </Typography.Text>
                  )
                },
                {
                  key: "edit",
                  icon: <EditOutlined />,
                  label: <Typography.Text data-qa-label="contextMenuEditBtn">{MESSAGES.EditLabel}</Typography.Text>
                },
                {
                  key: "clone",
                  icon: <CopyOutlined />,
                  label: <Typography.Text data-qa-label="contextMenuCloneBtn">{MESSAGES.CloneLabel}</Typography.Text>
                },
                {
                  key: "remove",
                  icon: <DeleteOutlined />,
                  label: <Typography.Text data-qa-label="contextMenuDeleteBtn">{MESSAGES.DeleteLabel}</Typography.Text>
                },
                {
                  key: "move",
                  icon: <SwapOutlined />,
                  disabled: enrolledStudentsCount === 0,
                  label: (
                    <Typography.Text data-qa-label="contextMenuMoveBtn" disabled={enrolledStudentsCount === 0}>
                      {MESSAGES.MoveStudentsLabel}
                    </Typography.Text>
                  ),
                  children: [
                    {
                      key: "child",
                      disabled: true,
                      label: (
                        <MoveStudentsTree
                          id={id}
                          key={ts}
                          onClick={(node: TagNode) => {
                            setOpen(false);
                            Modal.confirm({
                              title: `Move students`,
                              content: (
                                <Flex vertical>
                                  <Typography.Text italic>
                                    {MESSAGES.MoveStudentsConfirmationMessageLine1}
                                  </Typography.Text>
                                  <Divider />
                                  <Typography.Text>
                                    {formatMsg(
                                      MESSAGES.MoveStudentsConfirmationMessageLine2,
                                      enrolledStudentsCount,
                                      name,
                                      node.name
                                    )}
                                  </Typography.Text>
                                </Flex>
                              ),
                              onOk() {
                                onMoveStudents(id, node.id);
                              }
                            });
                          }}
                        />
                      )
                    }
                  ]
                }
              ],
              // -----------------------------------------------------------------------------------
              // - Ev Handlers
              // -----------------------------------------------------------------------------------
              onClick: e => {
                switch (e.key) {
                  case "new":
                    onCreate(id);
                    break;
                  case "edit":
                    onEdit(id);
                    break;
                  case "clone":
                    onClone(id);
                    break;
                  case "remove":
                    modal.confirm({
                      title: `Delete ${name}`,
                      okText: "Delete",
                      okType: "danger",
                      content: (
                        <>
                          {depthLevel === 1 && totalEnrolledStudentsCount > 0 && (
                            <>
                              {MESSAGES.TenantTagsDeleteCategory}
                              <br />
                            </>
                          )}
                          {depthLevel > 1 && totalEnrolledStudentsCount > 0 && (
                            <>
                              {MESSAGES.TenantTagsDeleteTag}
                              <br />
                            </>
                          )}
                          {MESSAGES.TenantTagsDeleteConfirmation}
                        </>
                      ),
                      onOk() {
                        onDelete(id);
                      }
                    });
                    break;
                }
              }
            }}
          >
            <Typography.Text>{children}</Typography.Text>
          </Dropdown>
        </>
      )}
    </TagsEditorContext.Consumer>
  );
};
