import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { StudentFullDetails } from "./types";

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const getStudentDetails = async (studentId: number): Promise<StudentFullDetails> =>
  HttpClient.get(`/rest/students/${studentId}/details`, createHeaders());

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const editStudent = async (
  tenantId: number,
  studentId: number,
  tagIds: number[],
  excluded: boolean
): Promise<void> =>
  HttpClient.put(`/rest/students/${studentId}/tenants/${tenantId}`, createHeaders(), {
    tagIds,
    excluded
  });

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const putStudentFollowed = async (studentId: number): Promise<void> =>
  HttpClient.put(`/rest/students/${studentId}/details/followed`, createHeaders());

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const deleteStudentFollowed = async (studentId: number): Promise<void> =>
  HttpClient.del(`/rest/students/${studentId}/details/followed`, createHeaders());
