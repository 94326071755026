import { Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RangeDateFilter } from "../../../components/RangeDateFilter";
import { doUpdateWorkingFilter } from "../../../redux/actions/students-list";
import { RdxStoreState } from "../../../redux/types/state";
import { ExamTrackerFilter } from "./components/ExamTrackerFilter/index";
import { LastActivityFilter } from "./components/LastActivityFilter";
import { LicenseFilter } from "./components/LicenseFilter";
import { OtherFilters } from "./components/OtherFilters";
import { SelectedSectionFilter } from "./components/SelectedSectionFilter";
import { StudyPlanFilter } from "./components/StudyPlanFilter";
import { StudyPlanStatusFilter } from "./components/StudyPlanStatusFilter";
import { StudyTimeFilter } from "./components/StudyTimeFilter";
import { TagsFilter } from "./components/TagsFilter";
import { TenantFilter } from "./components/TenantFilter";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentsFilter = () => {
  const dispatch = useDispatch<any>();
  const workingFilter = useSelector((state: RdxStoreState) => state.studentsList.workingFilter);

  return (
    <Form layout="vertical" className="flex flex-col h-full">
      <TenantFilter />
      <TagsFilter />
      <StudyTimeFilter />
      <LicenseFilter />
      <RangeDateFilter
        label="Purchased Date"
        id="purchased-date-filter"
        value={workingFilter.purchasedDate}
        onChange={purchasedDate => {
          dispatch(doUpdateWorkingFilter({ purchasedDate }));
        }}
      />
      <StudyPlanFilter />
      <StudyPlanStatusFilter />
      <SelectedSectionFilter />
      <LastActivityFilter />
      <ExamTrackerFilter />
      <OtherFilters />
    </Form>
  );
};
