import { useDispatch, useSelector } from "react-redux";
import { FilterTag } from "../../../core/model";
import { MESSAGES, formatMsg } from "../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../redux/actions/students-list";
import { defaultWorkingFilter } from "../../../redux/reducers/students-list";
import { RdxStoreState } from "../../../redux/types/state";
import { licenseLabelByValue } from "../StudentsFilter/metadata";

// -------------------------------------------------------------------------------------------------
// - Hooks: useLicenseFilter
// -------------------------------------------------------------------------------------------------

export const useLicenseFilter = (): FilterTag[] => {
  const dispatch = useDispatch<any>();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);
  return activeFilter?.filter?.license && activeFilter.filter.license !== defaultWorkingFilter.license
    ? [
        {
          name: "license",
          onClear: () => {
            dispatch(doUpdateWorkingFilter({ license: defaultWorkingFilter.license }));
            dispatch(doApplyFilter());
          },
          text: formatMsg(MESSAGES.FilterTagLicense, licenseLabelByValue[activeFilter.filter.license])
        }
      ]
    : [];
};
