import { createSelector } from "reselect";

import _ from "lodash";
import { RdxStoreState } from "../types/state";
import { getActiveUser } from "./active-user";
import { getActiveUserRoles } from "./active-user-roles";
import { getMyTenant } from "./my-tenant";

export const getIsTenantUserOrAdmin: (state: RdxStoreState) => boolean = createSelector(
  getActiveUser,
  getActiveUserRoles,
  getMyTenant,
  (activeUser, roles, tenantUser) => {
    const isTenantUser = Boolean(tenantUser);
    const isTenantAdmin = _.isEqual(
      _.chain(roles).filter({ id: activeUser?.roleId }).head().value()?.name,
      "Tenant Admin"
    );

    return isTenantUser || isTenantAdmin;
  }
);
