import { ButtonColorType } from "antd/es/button";
import _ from "lodash";
import React from "react";
import { Status } from "../hooks/useStudyPlanList";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

export const colorByStatus: { [key in Status]: ButtonColorType } = {
  archived: "red",
  published: "green",
  draft: "cyan"
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const SPStatusTag = (props: { status: Status; label?: string }) => (
  <span
    className={_.join(
      [
        "m-1",
        "py-1",
        "px-2",
        "text-white",
        "rounded-md",
        _.isEqual(props.status, "draft") //
          ? "bg-yellow-500"
          : _.isEqual(props.status, "published") //
            ? "bg-green-500"
            : "bg-red-500"
      ],
      " "
    )}
  >
    <span>{props.label ?? _.capitalize(props.status)}</span>
  </span>
);
