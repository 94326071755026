import { Typography } from "antd";
import React from "react";
import { FormControllerContext, UserInfoStatus } from "../hooks/userFormController";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const HelpMessage = () => (
  <FormControllerContext.Consumer>
    {({ userInfoStatus }) =>
      userInfoStatus.isEqualTo(UserInfoStatus.PENDING_VERIFICATION) ? undefined : userInfoStatus.isIn([
          UserInfoStatus.AVAILABLE_AND_MUTABLE,
          UserInfoStatus.AVAILABLE_BUT_IMMUTABLE
          //
        ]) ? (
        <div className="mt-1 mb-3">
          <Typography.Text strong className="text-green-500">
            Email available
          </Typography.Text>
        </div>
      ) : (
        <div className="mt-1 mb-3">
          <Typography.Text strong className="text-red-500">
            {userInfoStatus.getError() ?? "Email unavailable"}
          </Typography.Text>
        </div>
      )
    }
  </FormControllerContext.Consumer>
);
