import { PaginationProps } from "antd/lib/pagination";
import { StudentRosterDetails } from "../../services/nav-api/student-roster/types";
import { RdxStudentsListSortProps, RdxStudentsListState } from "../reducers/students-list";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";
import { createDeepEqualSelector } from "../utils/selector-utils";
import { getIsStudentsListLoading } from "./is-students-list-loading";

type Students = Omit<RdxStudentsListState, "workingFilter">;
type StudentsTableDetails = {
  dataSource: StudentRosterDetails[] | undefined;
  loading?: boolean;
  error?: boolean;
  paging: PaginationProps;
  sort?: RdxStudentsListSortProps;
};

export const getStudentsTableDetails: (state: RdxStoreState) => StudentsTableDetails = createDeepEqualSelector(
  (state: RdxStoreState) => {
    const { ...other } = state.studentsList;
    return other;
  },
  getIsStudentsListLoading,
  (students: Students, loading: boolean) => ({
    dataSource: (students.dataSource || []).map((id: number) => students.studentById[id]),
    loading,
    error: students.status === RdxFetchStatus.FAILED,
    paging: {
      current: students.paging.page,
      pageSize: students.paging.itemsPerPage,
      total: students.paging.totalItems || 0,
      showSizeChanger: true
    },
    sort: students.sort
  })
);
