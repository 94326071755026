import { Badge, Typography } from "antd";
import React from "react";
import { StudyPlanTask } from "../../../../../../services/nav-api/studyPlans/model";
import { StudyCalendarUtils } from "../../../../hooks/useStudyPlanEditor/utils/StudyCalendarUtils";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarDayCellTaskFlashcard = (props: { task: StudyPlanTask & { quarterHours?: number } }) => (
  <li
    key={`flashcard-${props.task.id}`}
    title={
      `${props.task.unitAbbrev}-Flashcards:` + //
      `${StudyCalendarUtils.formatDuration(props.task.quarterHours! * 15)}`
    }
  >
    <Badge
      color="#4B39AC"
      text={<Typography.Text strong>{props.task.unitAbbrev} &#8226; Flashcards</Typography.Text>}
    />
  </li>
);
