import React from "react";
import { Link } from "react-router-dom";
import { MESSAGES } from "../../../i18n";
import { routes } from "../../../routes";
import { NavigationDrawerOptionKey } from "../types";
import { AlertsBadge } from "./common";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default [
  {
    key: NavigationDrawerOptionKey.PROFILE_NOTIFICATIONS,
    label: (
      <Link to={routes.alertsReader.resolve()} data-qa-label="menu-item-notifications-reader">
        <AlertsBadge>{MESSAGES.NavNotifications}</AlertsBadge>
      </Link>
    )
  }
];
