import capitalize from "lodash/capitalize";
import { useDispatch, useSelector } from "react-redux";
import { FilterTag } from "../../../core/model";
import { formatMsg, MESSAGES } from "../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../redux/actions/students-list";
import { initialState } from "../../../redux/reducers/students-list";
import { RdxStoreState } from "../../../redux/types/state";

// -------------------------------------------------------------------------------------------------
// - Hooks: useFollowedStudentsMetrics
// -------------------------------------------------------------------------------------------------

const useFollowedStudentsMetrics = (): FilterTag[] => {
  const dispatch = useDispatch<any>();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);

  return activeFilter?.filter?.metrics?.followedStudents
    ? [
        {
          name: "followedStudents",
          onClear: () => {
            dispatch(
              doUpdateWorkingFilter({
                metrics: {
                  ...activeFilter.filter!.metrics,
                  followedStudents: initialState.workingFilter.metrics.followedStudents
                }
              })
            );
            dispatch(doApplyFilter());
          },
          text: MESSAGES.FilterTagFollowedStudents
        }
      ]
    : [];
};

// -------------------------------------------------------------------------------------------------
// - Hooks: useHiddenStudentsMetrics
// -------------------------------------------------------------------------------------------------

const useHiddenStudentsMetrics = (): FilterTag[] => {
  const dispatch = useDispatch<any>();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);

  return activeFilter?.filter?.metrics?.hiddenStudents &&
    activeFilter?.filter?.metrics?.hiddenStudents !== initialState.workingFilter.metrics.hiddenStudents
    ? [
        {
          name: "hiddenStudents",
          onClear: () => {
            dispatch(
              doUpdateWorkingFilter({
                metrics: {
                  ...activeFilter.filter!.metrics,
                  hiddenStudents: initialState.workingFilter.metrics.hiddenStudents
                }
              })
            );
            dispatch(doApplyFilter());
          },
          text: formatMsg(MESSAGES.FilterTagHiddenStudents, capitalize(activeFilter.filter.metrics.hiddenStudents))
        }
      ]
    : [];
};

// -------------------------------------------------------------------------------------------------
// - Hooks: useMetricsFilter
// -------------------------------------------------------------------------------------------------

export const useMetricsFilter = (): FilterTag[] => {
  const f1 = useFollowedStudentsMetrics();
  const f2 = useHiddenStudentsMetrics();
  return [...f1, ...f2];
};
