import { InfoCircleOutlined, LockOutlined } from "@ant-design/icons";
import { DatePicker, Form, Tooltip } from "antd";
import _ from "lodash";
import React from "react";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const LockUntilField = () => {
  const {
    editor: { startDate, endDate, lockUntil }
  } = React.useContext(StudyPlanEditorContext);

  React.useEffect(() => {
    if (!_.isNil(lockUntil.value) && lockUntil.value.isBefore(startDate.value, "day")) {
      lockUntil.setValue(startDate.value);
    }
  }, [startDate.value]);

  return (
    <Form.Item label="Locked Until">
      <DatePicker
        allowClear
        className="w-full"
        format="MM/DD/YYYY"
        data-qa-label="lockuntil-date"
        value={lockUntil.value}
        defaultPickerValue={lockUntil.value ?? endDate.value ?? startDate.value}
        disabledDate={cur => (!_.isNil(startDate.value) ? cur?.isBefore(startDate.value, "day") : false)}
        suffixIcon={<LockOutlined />}
        onChange={date => {
          lockUntil.setValue(date);
        }}
        prefix={
          <Tooltip
            title={
              "Lock the study plan until the given date, " +
              "meaning that students won't be able to make " +
              "any changes to the plan. Leave it empty if you " +
              "want to allow your students to change their study plans."
            }
          >
            <InfoCircleOutlined />
          </Tooltip>
        }
      />
    </Form.Item>
  );
};
