import * as HttpClient from "../http-client";
import { getUrlWithEncodedParams } from "../http-client/utils";
import { createHeaders } from "./headers";

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const signIn = async (username: string, password: string, rememberMe = false): Promise<{}> =>
  HttpClient.post(getUrlWithEncodedParams("/rest/session", { rememberMe }), createHeaders(), { username, password });

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const logout = async () => HttpClient.del("/rest/session", createHeaders());
