import { UnknownAction } from "@reduxjs/toolkit";
import tagEditor, { RdxTagEditorSubState } from "./tag-editor";
import tags, { RdxTagsSubState } from "./tags";
import tenantTags, { RdxTenantTagsSubState } from "./tenant-tags";
import tenants, { RdxTenantsSubState } from "./tenants";
import tenantsDropdown from "./tenants-dropdown";
import { RdxTenantsDropdownSubState } from "./types";

export interface RdxTenantsState {
  tenants?: RdxTenantsSubState;
  tags?: RdxTagsSubState;
  tenantTags?: RdxTenantTagsSubState;
  tagEditor?: RdxTagEditorSubState;
  tenantsDropdown?: RdxTenantsDropdownSubState;
}

export default (state: RdxTenantsState = {}, action: UnknownAction): RdxTenantsState => ({
  tenants: tenants(state.tenants, action),
  tags: tags(state.tags, action),
  tenantTags: tenantTags(state.tenantTags, action),
  tagEditor: tagEditor(state.tagEditor, action),
  tenantsDropdown: tenantsDropdown(state.tenantsDropdown, action)
});
