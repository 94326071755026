import { useDispatch, useSelector } from "react-redux";
import { FilterTag } from "../../../core/model";
import { MESSAGES, formatMsg } from "../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../redux/actions/students-list";
import { initialState } from "../../../redux/reducers/students-list";
import { RdxStoreState } from "../../../redux/types/state";
import { studyTimeLabelByValue } from "../StudentsFilter/metadata";

// -------------------------------------------------------------------------------------------------
// - Hooks: useStudyTimeFilter
// -------------------------------------------------------------------------------------------------

export const useStudyTimeFilter = (): FilterTag[] => {
  const dispatch = useDispatch<any>();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);
  return activeFilter?.filter?.studyTime && activeFilter?.filter?.studyTime !== initialState.workingFilter.studyTime
    ? [
        {
          name: "studyTime",
          onClear: () => {
            dispatch(doUpdateWorkingFilter({ studyTime: initialState.workingFilter.studyTime }));
            dispatch(doApplyFilter());
          },
          text: formatMsg(MESSAGES.FilterTagStudyTime, studyTimeLabelByValue[activeFilter.filter.studyTime])
        }
      ]
    : [];
};
