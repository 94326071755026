import { PieChartOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { MESSAGES } from "../../../i18n";
import { routes } from "../../../routes";
import { NavigationDrawerOptionKey } from "../types";

const key = NavigationDrawerOptionKey.DASHBOARD;
const qaLabel = "menu-item-tenant-dashboard";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default [
  {
    key,
    icon: <PieChartOutlined />,
    label: (
      <Link to={routes.dashboard.resolve()} data-qa-label={qaLabel}>
        {MESSAGES.NavDashboard}
      </Link>
    )
  }
];
