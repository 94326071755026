import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { PostMannuallyAssociatedErrorResponse, PostMannuallyAssociatedRequest } from "./types";

// -------------------------------------------------------------------------------------------------
// - Api Method
// -------------------------------------------------------------------------------------------------

export const postMannuallyAssociated = async (
  tenantId: number,
  args: PostMannuallyAssociatedRequest
): Promise<PostMannuallyAssociatedErrorResponse> =>
  HttpClient.post(`/rest/admin/manuallyAssociatedStudents?tenantId=${tenantId}`, createHeaders(), args);
