import _ from "lodash";
import React from "react";
import { StudyPlanEditorFields } from "../useEditorFields";
import { WeeklyDistributionInfoValue } from "./useDerivedWeeklyDistributionInfo";

// -------------------------------------------------------------------------------------------------
// - Hookes
// -------------------------------------------------------------------------------------------------

export const useStudyPlanValidator = (e: StudyPlanEditorFields, info: WeeklyDistributionInfoValue) =>
  React.useMemo(() => {
    return (
      !(
        _.isEmpty(e.name.value) ||
        _.isEmpty(e.section.value) ||
        _.isEmpty(e.tagIds.value) ||
        _.isEmpty(e.startDate.value) ||
        _.isEmpty(e.endDate.value)
      ) &&
      _.gt(e.studyTimeWeek.value, 0) &&
      info.isStudyTimeOk
    );
  }, [
    e.name.value,
    e.section.value,
    e.tagIds.value,
    e.startDate.value,
    e.endDate.value,
    e.studyTimeWeek.value,
    e.weekDayHours.value,
    info.isStudyTimeOk
  ]);
