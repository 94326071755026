import { Checkbox, Form } from "antd";
import React from "react";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const PracticeTestsField = () => (
  <StudyPlanEditorContext.Consumer>
    {({ editor: { practiceTests }, simulations }) => (
      <Form.Item>
        <Checkbox
          checked={practiceTests.value!}
          onChange={e => {
            simulations.enableSimulations();
            practiceTests.setValue(e.target.checked);
          }}
        >
          Practice Tests
        </Checkbox>
      </Form.Item>
    )}
  </StudyPlanEditorContext.Consumer>
);
