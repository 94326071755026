import { Field } from "formik";
import React from "react";
import { TagsOperationOption } from "./operation";
import { TagsTreeOption } from "./TagsTreeOption";

// -------------------------------------------------------------------------------------------------
// - OwnProps
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  operation: "assign" | "clear";
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TagsOptions = ({ operation }: OwnProps) => (
  <>
    <Field name="tagsAction" className="bulk-change-tags-actions">
      {({ field, form }: any) => (
        <TagsOperationOption
          value={field.value}
          onChange={v => {
            form.setFieldTouched(field.name);
            form.setFieldValue(field.name, v);
          }}
        />
      )}
    </Field>
    <Field
      name="tags"
      validate={(ids: number[]) => (!ids.length ? "No tags selected." : undefined)}
      className="bulk-change-tags"
    >
      {({ field, form }: any) => (
        <TagsTreeOption
          value={field.value}
          checkBehavior={operation === "assign" ? "assign" : "filter"}
          onChange={v => {
            form.setFieldTouched(field.name);
            form.setFieldValue(field.name, v);
          }}
        />
      )}
    </Field>
  </>
);
