import { ONETRUST_SET_FLAG } from "../../actions/core/onetrust";
import { createReducer } from "../../utils/redux-utils";
import { RdxCoreState } from "./model";

// -------------------------------------------------------------------------------------------------
// - Reducer
// -------------------------------------------------------------------------------------------------

export default createReducer(
  {
    isGpcEnabled: false
  },
  {
    [ONETRUST_SET_FLAG]: (state, flag: boolean): RdxCoreState => ({
      ...state,
      isGpcEnabled: flag
    })
  }
);
