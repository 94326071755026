import { CalendarOutlined } from "@ant-design/icons";
import { Button, DatePicker, Flex, Popover } from "antd";
import dayjs from "dayjs";
import React from "react";

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const DatePickerButton = (props: {
  onChange: (val: dayjs.Dayjs) => void;
  children: React.ReactNode | React.ReactNode[];
  title?: string;
}) => {
  const [key, setKey] = React.useState<string | null>(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setKey(`key-${Math.random() * 1000}`);
  }, [open]);

  return (
    <Popover
      open={open}
      trigger="click"
      title={props.title}
      onOpenChange={setOpen}
      content={() => (
        <Flex vertical align="center" justify="center">
          <DatePicker
            open
            key={key}
            inputReadOnly
            suffixIcon={null}
            popupClassName="static datepicker-no3dfx"
            getPopupContainer={({ parentNode }) => parentNode as HTMLElement}
            onChange={date => {
              props.onChange(date);
              setOpen(false);
            }}
          />
        </Flex>
      )}
    >
      <Button icon={<CalendarOutlined />}>{props.children}</Button>
    </Popover>
  );
};
