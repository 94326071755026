import { Divider } from "antd";
import _ from "lodash";
import React from "react";
import { StudyPlanEditorContext } from "../../../hooks/useStudyPlanEditor";
import { WeekDayHoursField } from "../../EditorFields/FieldWeeDayHours";
import { EditorSectionHeader } from "../EditorSectionHeader";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyWeekHoursSection = () => (
  <StudyPlanEditorContext.Consumer>
    {({
      editor: {
        weeklyDistributionInfo: { studyTimeWeekValue }
      }
    }) => (
      <>
        <EditorSectionHeader
          title="How should the study time be distributed weekly?"
          description={
            "Adjust the daily study time according to the intended schedule. " +
            "This will affect the number of tasks assigned each day. " +
            "If it becomes necessary to reschedule the planned tasks to stay on track, " +
            "we will automatically adjust the weekly study hours proportionally."
          }
        />

        <WeekDayHoursField disabled={_.isEqual(studyTimeWeekValue, 0)} />
        <Divider />
      </>
    )}
  </StudyPlanEditorContext.Consumer>
);
