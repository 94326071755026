import { QuestionCircleOutlined } from "@ant-design/icons";
import { Modal, Switch } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../i18n";
import { ContentVersion, doSwitchContentVersion } from "../redux/actions/contentversion-switch";
import { getActiveProduct } from "../redux/selectors/active-product";
import { getIsCPAPreEvolutionSelected } from "../redux/selectors/contentversion";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const ContentVersionSwitch = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch<any>();
  const product = useSelector(getActiveProduct);
  const isLegacyContentSelected = useSelector(getIsCPAPreEvolutionSelected);

  return _.isEqual(product, "cpa") ? (
    <>
      {contextHolder}
      <Switch
        data-qa-label="content-switch"
        loading={loading}
        checked={isLegacyContentSelected}
        checkedChildren={MESSAGES.CPAPreEvolutionEnabled}
        unCheckedChildren={MESSAGES.CPAPreEvolutionDisabled}
        onChange={async () => {
          setLoading(true);

          const accepted = await modal.confirm({
            icon: <QuestionCircleOutlined />,
            okText: MESSAGES.ConfirmLabel,
            cancelText: MESSAGES.CancelLabel,

            title: isLegacyContentSelected
              ? MESSAGES.ContentVersionSwitchTitleWhenEnabled
              : MESSAGES.ContentVersionSwitchTitleWhenDisabled,

            content: isLegacyContentSelected
              ? MESSAGES.ContentVersionSwitchMessageWhenEnabled
              : MESSAGES.ContentVersionSwitchMessageWhenDisabled
          });

          if (accepted) {
            dispatch(
              doSwitchContentVersion(
                !isLegacyContentSelected //
                  ? ContentVersion.CPA_PRE_EVOLUTION
                  : ContentVersion.CPA_EVOLUTION,
                () => {
                  setLoading(false);
                }
              )
            );
          } else {
            setLoading(false);
          }
        }}
      />
    </>
  ) : null;
};
