import { EditOutlined } from "@ant-design/icons";
import { Badge, Button, Flex, Typography } from "antd";
import React from "react";
import { useStudyPlanWritePermission } from "../../../../../../redux/hooks/useStudyPlansPermissions";
import { StudyPlanTask } from "../../../../../../services/nav-api/studyPlans/model";
import { StudyCalendarUtils } from "../../../../hooks/useStudyPlanEditor/utils/StudyCalendarUtils";
import { StudyPlanCustomTasksEditorContext } from "../../../hooks/useStudyPlanCustomTasksEditor";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarDayCellTaskCustom = (props: { task: StudyPlanTask & { quarterHours?: number } }) => {
  const canEditTask = useStudyPlanWritePermission();
  return (
    <StudyPlanCustomTasksEditorContext.Consumer>
      {({ actions }) => (
        <li
          key={`custom-task-${props.task.id}`}
          title={`${props.task.name}: ${StudyCalendarUtils.formatDuration(props.task.quarterHours! * 15)}`}
        >
          <Flex gap={2}>
            <div
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: 120
              }}
            >
              <Badge
                color="rgb(0, 117, 62)"
                text={
                  <Typography.Text ellipsis className="max-w-[100px]">
                    {props.task.name}
                  </Typography.Text>
                }
              />
            </div>
            {canEditTask ? (
              <Button
                type="link"
                size="small"
                className="text-truncate px-0 mx-0"
                title={props.task.name}
                icon={<EditOutlined />}
                style={{ maxWidth: 130 }}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();

                  actions.openEditor({
                    operation: "edit",
                    taskId: props.task.id
                  });
                }}
              />
            ) : null}
          </Flex>
        </li>
      )}
    </StudyPlanCustomTasksEditorContext.Consumer>
  );
};
