import { SYSTEM_CONFIG } from "./system-config";

const ENV_MAP: Record<Enviroment, string> = {
  local: "8bd29d6dfe255c3425b0",
  dev: "7727a5bfcd59421ddb4c", // "8bd29d6dfe255c3425b0",
  qa: "7727a5bfcd59421ddb4c",
  prod: "3c3a7e1ca2ee85d771c0"
};

const curEnv: Enviroment = SYSTEM_CONFIG.environment?.toLowerCase() as Enviroment;

export const PUSHER_APP_KEY = ENV_MAP[curEnv] || ENV_MAP["dev"];
