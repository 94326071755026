import mapValues from "lodash/mapValues";
import uniq from "lodash/uniq";
import { RefObject } from "react";
import { useDispatch, useSelector } from "react-redux";

import RCTree from "rc-tree";
import React from "react";
import {
  doCancelEditingTag,
  doCreateTenantTag,
  doDragTenantTag,
  doEditTenantTag
} from "../../redux/actions/tenants/editor-tags";
import { doCloneTag, doDeleteTag, doMoveStudents, doSubmitEditingTag } from "../../redux/actions/tenants/tags";
import { getTotalEnrollmentsByTagId } from "../../redux/selectors/total-enrollments-by-tag-id";
import { RdxStoreState } from "../../redux/types/state";
import { RdxFetchStatus } from "../../redux/types/status";

export const useTagsEditorHook = (tenantId: number, treeRef: RefObject<RCTree>) => {
  const [ts, setTs] = React.useState<number | null>(null);
  const dispatch = useDispatch<any>();
  const { byId, statusById } = useSelector((state: RdxStoreState) => state.tenants.tags!);
  const loadingByTagId = mapValues(statusById, (value: RdxFetchStatus) => value === RdxFetchStatus.LOADING);
  const tagEditor = useSelector((state: RdxStoreState) => state.tenants.tagEditor!);
  const totalEnrollmentsByTagId = useSelector(getTotalEnrollmentsByTagId);
  const [expandedIds, setExpandedIds] = React.useState<React.Key[]>([]);

  return {
    ts,
    expandedIds,
    setExpandedIds,
    totalEnrollmentsByTagId,
    loadingByTagId,
    isCreatingTag: tagEditor ? tagEditor.isCreatingTag : false,
    onEdit: (id: number) => {
      const tag = byId[id];
      dispatch(doEditTenantTag(tag.id, tag.name, tag.parentId));
    },
    onDelete: (id: number) => {
      dispatch(doDeleteTag(tenantId, id));
      setTs(new Date().getTime());
    },
    onCreate: (parentId?: number) => {
      // BEWARE: This is totally coupled to the interals of tree antd.
      // Its a workaround to expand the parent of the node that it's going to be created.
      // The cleanest solution would be to make the expandedKeys controlled but antd has some issues like
      // if you have A > B > C and you want to collapse A it would only work if you collapse B first.
      if (treeRef.current && parentId) {
        const expandedKeys = uniq([...treeRef.current!.state.expandedKeys, parentId.toString()]);
        treeRef.current!.setUncontrolledState({ expandedKeys });
      }
      dispatch(doCreateTenantTag(parentId));
      setExpandedIds(prev => [...prev, parentId as React.Key]);
    },
    onSubmitEdit: (name: string) => {
      dispatch(doSubmitEditingTag(tenantId, name));
      setTs(new Date().getTime());
    },
    onDrop: (id: number, parentId?: number) => {
      dispatch(doDragTenantTag(tenantId, id, parentId));
    },
    onCancelEdit: () => dispatch(doCancelEditingTag()),
    onMoveStudents: (srcTagId: number, destTagId: number) => {
      dispatch(doMoveStudents(tenantId, srcTagId, destTagId));
    },
    onClone: (id: number) => {
      const { name, parentId } = byId[id];
      dispatch(doCloneTag(tenantId, `CLONE-${name}`, id, parentId));
      setTs(new Date().getTime());
    }
  };
};
