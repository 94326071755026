import { DeleteOutlined, PlusOutlined, ReloadOutlined, SaveOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Checkbox, Flex, Select, Switch, Table, Tour, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router";
import { MESSAGES } from "../../../i18n";
import { routes } from "../../../routes";
import reducer, {
  ADD,
  DELETE,
  RESET,
  SELECT_CHANGED,
  TOGGLE_ENABLED,
  TOGGLE_REQUIRED,
  TOGGLE_SET_DISABLED,
  TagItem,
  getDropdownOptions,
  getFormDisabled,
  getHasNoTags,
  getIsLoading,
  getTagsTableItems,
  initialState,
  loadInitialState,
  submitChanges
} from "./reducer";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CPATagsForm = (props: { tenantId: number }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [isTourOpen, setTourOpen] = React.useState(false);

  const navigate = useNavigate();
  const loading = getIsLoading(state);
  const dropdownOptions = React.useMemo(() => getDropdownOptions(state), [state]);
  const tableTagItems = React.useMemo(() => getTagsTableItems(state), [state]);
  const formDisabled = React.useMemo(() => getFormDisabled(state), [state]);

  const ref = React.useRef(null);

  React.useEffect(() => {
    loadInitialState(dispatch, props.tenantId);
  }, []);

  return (
    <Flex vertical gap={4} ref={ref}>
      {getHasNoTags(state) && <Alert showIcon type="warning" message="This tenant has no associated tags." />}
      <Table
        size="small"
        pagination={false}
        data-qa-label="tags-table"
        dataSource={tableTagItems}
        loading={loading}
        // -----------------------------------------------------------------------------------------
        //  HEADER
        // -----------------------------------------------------------------------------------------
        title={() => (
          <Flex gap={4}>
            <Select
              size="small"
              loading={loading}
              value={state.selectedId}
              style={{ minWidth: "200px" }}
              placeholder={MESSAGES.SelectTagPlaceholder}
              onChange={(tagId: number) => dispatch({ type: SELECT_CHANGED, payload: { tagId } })}
              options={_.map(dropdownOptions, ({ id: value, name: label }) => ({ label, value }))}
            />

            <Button
              type="primary"
              size="small"
              icon={<PlusOutlined />}
              loading={loading}
              disabled={!state.selectedId || loading}
              onClick={() => {
                dispatch({ type: ADD });
              }}
            >
              Add
            </Button>
            <div className="grow" />
            <Switch
              loading={loading}
              checked={state.enabled}
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              onChange={() => {
                if (!state.enabled && tableTagItems.length == 0) {
                  setTourOpen(true);
                } else {
                  dispatch({ type: TOGGLE_ENABLED });
                }
              }}
            />
          </Flex>
        )}
        // -----------------------------------------------------------------------------------------
        //  FOOTER
        // -----------------------------------------------------------------------------------------

        footer={() => (
          <Flex gap={4} justify="end">
            <Button
              size="small"
              disabled={loading || isTourOpen}
              icon={<ReloadOutlined />}
              onClick={() => {
                dispatch({ type: RESET });
                navigate(routes.tenants.resolve());
              }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              type="primary"
              icon={<SaveOutlined />}
              loading={loading}
              disabled={formDisabled || isTourOpen}
              onClick={() => {
                submitChanges(dispatch, props.tenantId, state);
              }}
            >
              Save
            </Button>
          </Flex>
        )}
        // -----------------------------------------------------------------------------------------
        //  COLUMNS
        // -----------------------------------------------------------------------------------------

        columns={[
          {
            title: "Name",
            dataIndex: "name"
          },
          {
            title: "Required",
            key: "required",
            align: "center",
            width: 60,
            render: ({ required, id: tagId, loading }: TagItem) => (
              <Checkbox
                disabled={loading}
                checked={Boolean(required)}
                onChange={() => {
                  dispatch({ type: TOGGLE_REQUIRED, payload: { tagId } });
                }}
              />
            )
          },
          {
            key: "actions",
            width: 20,
            align: "right",
            render: ({ loading, id: tagId }: TagItem) => (
              <Button
                danger
                type="link"
                size="small"
                loading={loading}
                icon={<DeleteOutlined />}
                onClick={() => {
                  if (tableTagItems?.length == 1) {
                    dispatch({ type: TOGGLE_SET_DISABLED });
                  }

                  dispatch({ type: DELETE, payload: { tagId } });
                }}
              />
            )
          }
        ]}
      />
      <Tour
        mask
        closable
        closeIcon
        open={isTourOpen}
        onClose={() => {
          setTourOpen(false);
        }}
        steps={[
          {
            target: () => ref?.current,
            placement: "topRight",
            title: "Warning",
            description:
              "Tag Form can't be enabled because it's empty. " + //
              "Please add at least one tag to the form.",
            renderPanel: props => (
              <Card>
                <Flex vertical gap={4}>
                  <Typography.Text strong type="warning">
                    {props.title}
                  </Typography.Text>
                  <Typography.Text>{props.description}</Typography.Text>
                  <Flex justify="end">
                    <Button onClick={props.onFinish}>Close</Button>
                  </Flex>
                </Flex>
              </Card>
            )
          }
        ]}
      />
    </Flex>
  );
};
