import _ from "lodash";
import { createSelector } from "reselect";
import { Role } from "../../services/nav-api/roles";
import { RdxStoreState } from "../types/state";

export const getUserRoles: (state: RdxStoreState) => Role[] = createSelector(
  (state: RdxStoreState) => state?.myself?.userRoles,
  (roles: Role[]) => {
    return roles;
  }
);

export const getNewUserRoles: (state: RdxStoreState) => Role[] = createSelector(getUserRoles, (roles: Role[]) => {
  return _.filter(roles, ({ name }: Role) => _.includes(["Tenant Admin", "Tenant User"], name));
});

export const getDefaultNewUserRole: (state: RdxStoreState) => Role = createSelector(getUserRoles, (roles: Role[]) => {
  return _.chain(roles)
    .filter(({ name }: Role) => _.isEqual(name, "Tenant Admin"))
    .head()
    .value();
});
