import React from "react";
import { useDispatch } from "react-redux";

import { MESSAGES } from "../../i18n";
import { doCompleteLogout } from "../../redux/actions/authentication/logoutCompletion";
import { GenericApiFailureModal } from "./ModalForGenericApiFailure";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const SessionExpiredModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<any>();
  return (
    <GenericApiFailureModal
      message={MESSAGES.ApiFailureSessionExpired}
      title={MESSAGES.ApiFailureSessionTitle}
      buttons={[
        {
          onClick: () => {
            onClose();
            dispatch(doCompleteLogout());
            setTimeout(() => {
              window.location.reload();
            }, 500);
          },
          label: MESSAGES.ApiFailureSessionExpiredBtnCaption
        }
      ]}
    />
  );
};
