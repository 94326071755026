import { Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { Alert } from "../../../services/nav-api/alerts-reader/types";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  alert: Alert;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertsBody = ({ alert }: OwnProps) => (
  <div style={{ paddingTop: "30px" }}>
    <Row>
      <div
        className="ql-editor"
        dangerouslySetInnerHTML={{
          __html: alert.body
        }}
      />
    </Row>
    <Row>
      <Typography.Text
        style={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          marginTop: "24px",
          alignItems: "center"
        }}
      >
        <Typography.Text type="secondary">
          {moment(alert.publishDate, "YYYY-MM-DD").format("MM-DD-YYYY")}
        </Typography.Text>
      </Typography.Text>
    </Row>
  </div>
);
