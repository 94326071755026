import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../i18n";
import { doRemoveError } from "../../redux/actions/api-failure";
import { ApiFailure } from "../../redux/reducers/api-failure";
import { RdxStoreState } from "../../redux/types/state";
import {
  CONFLICT_STATUS_CODE,
  METHOD_FAILURE_STATUS_CODE,
  UNAUTHORIZED_STATUS_CODE
} from "../../services/http-client/constant";
import { GenericApiFailureModal } from "./ModalForGenericApiFailure";
import { MultipleSessionModal } from "./ModalForMultipleSession";
import { NeedReloadModal } from "./ModalForNeedReload";
import { SessionExpiredModal } from "./ModalForSessionExpired";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const ApiFailureModal = () => {
  const apiFailure = useSelector<RdxStoreState, ApiFailure | undefined>(state => state.apiFailure.failures[0]);
  const dispatch = useDispatch<any>();

  if (!apiFailure) {
    return null;
  }

  const { message, statusCode, title, id } = apiFailure;

  const onClose = () => {
    dispatch(doRemoveError(id));
  };

  if (statusCode === CONFLICT_STATUS_CODE) {
    return <MultipleSessionModal />;
  } else if (statusCode === METHOD_FAILURE_STATUS_CODE) {
    return <NeedReloadModal onClose={onClose} />;
  } else if (statusCode === UNAUTHORIZED_STATUS_CODE) {
    return <SessionExpiredModal onClose={onClose} />;
  }

  return (
    <GenericApiFailureModal
      message={message}
      title={title}
      buttons={[{ onClick: onClose, label: MESSAGES.ApiFailureModalGenericCloseBtnCaption }]}
    />
  );
};
