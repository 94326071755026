import { Divider, Flex } from "antd";
import React from "react";
import { FinalReviewField } from "../EditorFields/FieldFinalReview";
import { FlashcardsField } from "../EditorFields/FieldFlashcards";
import { PracticeTestsField } from "../EditorFields/FieldPracticeTests";
import { EditorSectionHeader } from "./EditorSectionHeader";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const EditorStepReviewMaterials = () => (
  <Flex vertical={true} gap={12} justify="center">
    <EditorSectionHeader
      title="Set time periods for the review"
      description={
        "We recommend the inclusion of review materials by allocating time for Practice Tests " +
        "and Flashcard sessions at the end of each unit. The week before the exam should be " +
        "reserved to complete the Final Review. Selecting these options will add additional " +
        "tasks to the study plan."
      }
    />
    <Divider className="pt-0 mt-0" />
    <PracticeTestsField />
    <FlashcardsField />
    <FinalReviewField />
  </Flex>
);
