import { Flex } from "antd";
import React from "react";
import { StudyDatesSection } from "./SectionStudyDates";
import { StudyPaceSection } from "./SectionStudyPace";
import { StudyTimeOffSection } from "./SectionStudyTimeOff";
import { StudyWeekHoursSection } from "./SectionStudyWeekHours";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const EditorStepConfiguration = () => (
  <Flex vertical gap={12}>
    <StudyDatesSection />
    <StudyPaceSection />
    <StudyWeekHoursSection />
    <StudyTimeOffSection />
  </Flex>
);
