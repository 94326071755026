import _ from "lodash";
import { createSelector } from "reselect";
import { RdxUnauthState } from "../reducers/unauth/types";
import { RdxStoreState } from "../types/state";

export const getFeatureFlags = createSelector(
  (state: RdxStoreState) => state.unauth,
  (unauth: RdxUnauthState) => unauth.ffs
);

export const shouldRenderLoginBanner = createSelector(
  (state: RdxStoreState) => state.unauth,
  (unauth: RdxUnauthState) => _.includes(unauth.ffs, "loginBanner")
);
