import { StudentFullDetails } from "../../../services/nav-api/student-details/types";
import {
  STUDENT_DETAILS_CURRENT_STUDENT_CHANGED,
  STUDENT_DETAILS_EDIT_COMPLETED,
  STUDENT_DETAILS_FETCH_COMPLETED,
  STUDENT_DETAILS_FETCH_STARTED,
  STUDENT_DETAILS_PERF_DASHBOARD_FETCH_COMPLETED,
  STUDENT_DETAILS_PERF_DASHBOARD_FETCH_STARTED
} from "../../actions/student-details/constants";
import { FetchStudentPerfDashboardCompletedPayload } from "../../actions/student-details/hierarchy-progress";
import {
  EditStudentCompletedPayload,
  FetchStudentDetailsCompletedPayload
} from "../../actions/student-details/student-details";
import { StudentHierarchyProgress } from "../../actions/student-details/types";
import { UPDATE_STUDENT_FOLLOWED, UpdateStudentFollowedPayload } from "../../actions/students-list";
import { RdxFetchStatus } from "../../types/status";
import { createReducer } from "../../utils/redux-utils";

export interface RdxStudentDetails {
  curStudentId?: number;
  details?: StudentFullDetails;
  status: RdxFetchStatus;
  hierarchyProgress: { data?: StudentHierarchyProgress; status: RdxFetchStatus };
}

const initialState: RdxStudentDetails = {
  status: RdxFetchStatus.NONE,
  hierarchyProgress: {
    status: RdxFetchStatus.NONE
  }
};

export default createReducer(initialState, {
  [STUDENT_DETAILS_CURRENT_STUDENT_CHANGED]: (state, studentId: number) => ({
    ...state,
    curStudentId: studentId
  }),
  [STUDENT_DETAILS_FETCH_STARTED]: state => {
    return {
      ...state,
      details: undefined,
      curStudentId: state.curStudentId,
      status: RdxFetchStatus.LOADING,
      hierarchyProgress: {
        status: RdxFetchStatus.NONE
      }
    };
  },
  [STUDENT_DETAILS_FETCH_COMPLETED]: (state, { details, error }: FetchStudentDetailsCompletedPayload) => {
    if (error) {
      return {
        ...state,
        status: RdxFetchStatus.FAILED
      };
    }

    return {
      ...state,
      status: RdxFetchStatus.COMPLETED,
      details
    };
  },
  [STUDENT_DETAILS_PERF_DASHBOARD_FETCH_STARTED]: state => {
    return {
      ...state,
      hierarchyProgress: {
        status: RdxFetchStatus.LOADING
      }
    };
  },
  [STUDENT_DETAILS_PERF_DASHBOARD_FETCH_COMPLETED]: (
    state,
    { error, hierarchy }: FetchStudentPerfDashboardCompletedPayload
  ) => {
    if (error) {
      return {
        ...state,
        hierarchyProgress: {
          status: RdxFetchStatus.FAILED
        }
      };
    }

    return {
      ...state,
      hierarchyProgress: {
        data: hierarchy!,
        status: RdxFetchStatus.COMPLETED
      }
    };
  },
  [STUDENT_DETAILS_EDIT_COMPLETED]: (state, { error }: EditStudentCompletedPayload) => {
    if (error) {
      return state;
    }
    return {
      ...state,
      status: RdxFetchStatus.STALE
    };
  },
  [UPDATE_STUDENT_FOLLOWED]: (state, { followed, studentId }: UpdateStudentFollowedPayload) => {
    if (state.curStudentId !== studentId || !state.details) {
      return state;
    }

    return {
      ...state,
      details: {
        ...state.details,
        followed
      }
    };
  }
});
