import { Button, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../../../i18n";
import { doDismissOperation, doSaveAndFinishOperation } from "../../../../redux/actions/alerts-writer/operation";
import {
  getCanSaveOperation,
  getOperationStatusLoading,
  getOperationTypeEditing,
  getOperationTypeEditingNew
} from "../../../../redux/selectors/alerts-writer/operation";
import { AlertForm } from "./AlertForm";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertsEditor = () => {
  const dispatch = useDispatch<any>();
  const isEditing = useSelector(getOperationTypeEditing);
  const isEditingNew = useSelector(getOperationTypeEditingNew);
  const isLoading = useSelector(getOperationStatusLoading);
  const canSave = useSelector(getCanSaveOperation);
  const title = isEditingNew //
    ? MESSAGES.AlertsFormAddTitle
    : MESSAGES.AlertsFormEditTitle;

  return (
    <Modal
      title={title}
      width={"60vw"}
      open={isEditing}
      onCancel={() => {
        dispatch(doDismissOperation());
      }}
      footer={[
        <Button
          key="back"
          disabled={isLoading}
          onClick={() => {
            dispatch(doDismissOperation());
          }}
        >
          {MESSAGES.AlertsFormCancel}
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!canSave}
          loading={isLoading}
          onClick={() => {
            dispatch(doSaveAndFinishOperation());
          }}
        >
          {MESSAGES.AlertsFormSave}
        </Button>
      ]}
    >
      <AlertForm />
    </Modal>
  );
};
