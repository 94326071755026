import { TeamOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { MESSAGES } from "../../../i18n";
import { routes } from "../../../routes";
import { NavigationDrawerOptionKey } from "../types";

const key = NavigationDrawerOptionKey.STUDENTS;
const qaLabel = "menu-item-students";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default [
  {
    key,
    icon: <TeamOutlined />,
    label: (
      <Link to={routes.students.resolve()} data-qa-label={qaLabel}>
        {MESSAGES.NavStudents}
      </Link>
    )
  }
];
