import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { FilterTag } from "../../../core/model";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../redux/actions/users-list";
import { initialState } from "../../../redux/reducers/users-list";
import { getUsersListActiveFilter } from "../../../redux/selectors/users-list";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const initialValue = initialState.workingFilter.searchTerm;

// -------------------------------------------------------------------------------------------------
// - Hooks: useSearchTermFilter
// -------------------------------------------------------------------------------------------------

export const useSearchTermFilter = (): FilterTag[] => {
  const dispatch = useDispatch<any>();
  const activeFilter = useSelector(getUsersListActiveFilter);
  const searchTerm = activeFilter?.filter?.searchTerm;

  return !_.isEqual(searchTerm, initialValue)
    ? [
        {
          name: "searchTerm",
          onClear: () => {
            dispatch(
              doUpdateWorkingFilter({
                searchTerm: initialValue
              })
            );
            dispatch(doApplyFilter());
          },
          text: searchTerm!
        }
      ]
    : [];
};
