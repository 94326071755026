import { Flex, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PolarAngleAxis, RadialBar, RadialBarChart } from "recharts";
import { AltID, sectionColorByAltId, sectionNameByAltId } from "../../../../../constants/courses";
import { MESSAGES } from "../../../../../i18n";
import { doSetFilter } from "../../../../../redux/actions/students-list";
import { getCurrentWorkingFilter, getTenantDashboardStats } from "../../../../../redux/selectors/dashboard/tenant";
import { routes } from "../../../../../routes";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  altId?: AltID;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentsActivityChartBySection = ({ altId }: OwnProps) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const stats = useSelector(getTenantDashboardStats);
  const filter = useSelector(getCurrentWorkingFilter);

  const label = altId ? sectionNameByAltId[altId] : MESSAGES.None;
  const fill = altId ? sectionColorByAltId[altId] : "#AAAAAA";
  const total = stats?.studentsActivity?.activeStudents ?? 0;

  let percent = 0.0;
  let count = 0;
  if (altId) {
    count = altId ? (stats?.studentsActivity?.activeStudentsBySection?.[altId] ?? 0) : 0;
    percent = (count! * 1.0) / total!;
  } else {
    count = stats?.studentsActivity?.inactiveStudents ?? 0;
    percent = (count! * 1.0) / total!;
  }

  return (
    <Flex vertical justify="center" align="center" className="grow">
      <RadialBarChart
        cx="50%"
        cy="50%"
        width={100}
        height={100}
        innerRadius="80%"
        outerRadius="100%"
        barSize={200}
        data={[{ fill, percent }]}
        style={{ cursor: "pointer" }}
        onClick={() => {
          dispatch(doSetFilter({ ...filter, license: "active", selectedSection: altId ?? "no_activity" }));
          navigate(routes.students.resolve());
        }}
      >
        <PolarAngleAxis type="number" domain={[0, 1]} angleAxisId={0} tick={false} />
        <RadialBar
          background
          dataKey="percent"
          maxBarSize={1}
          isAnimationActive={false}
          data-qa-label={`students-by-section-${altId}-label`}
          label={() => (
            <text x={50} y={50} cx={50} cy={50} offset={5} textAnchor="middle" fill={fill}>
              <tspan x="50" dy="0.355em" data-qa-label={_.toLower(`students-by-section-${altId}-count`)}>
                {count}
              </tspan>
            </text>
          )}
        />
      </RadialBarChart>
      <Typography.Text
        className={_.join(
          [
            "text-sm",
            "text-center",
            "text-slate-600"
            //
          ],
          " "
        )}
      >
        {label}
      </Typography.Text>
    </Flex>
  );
};
