import { Tag } from "antd";
import _ from "lodash";
import React from "react";
import { Alert } from "../../../services/nav-api/alerts-reader/types";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  alert: Alert;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertsExtra = ({ alert }: OwnProps) =>
  !_.isEqual(alert?.isRead, true) ? <Tag color="green">New</Tag> : null;
