import _ from "lodash";

// -------------------------------------------------------------------------------------------------
//  Utils
// -------------------------------------------------------------------------------------------------

type Node = { id: number; children: Node[]; name: string };

// -------------------------------------------------------------------------------------------------
//  Utils
// -------------------------------------------------------------------------------------------------
const createNodeById = (tree: Node[] | null) => {
  const queue = _.cloneDeep(tree ?? []);
  const map: { [id: number]: Node } = {};
  while (queue.length) {
    const curNode = queue.shift()!;
    map[curNode.id] = curNode;
    queue.push(...curNode.children);
  }
  return map;
};

// -------------------------------------------------------------------------------------------------
//  Utils
// -------------------------------------------------------------------------------------------------

const createNodeParentById = _.memoize((nodes: Node[] | null) => {
  const map: { [key: number]: number | undefined } = {};
  const queue = _.cloneDeep(nodes ?? []);

  queue.forEach(curNode => {
    map[curNode.id] = undefined;
  });

  while (queue.length) {
    const curNode = queue.shift()!;
    queue.push(...curNode.children);
    curNode.children.forEach(childNode => {
      map[childNode.id] = curNode.id;
    });
  }
  return map;
});

// -------------------------------------------------------------------------------------------------
//  Utils
// -------------------------------------------------------------------------------------------------

const createNodeChildrenIdsById = _.memoize((nodes: Node[] | null): { [key: number]: number[] } => {
  const map: { [key: number]: number[] } = {};
  const queue = _.cloneDeep(nodes ?? []);
  while (queue.length) {
    const curNode = queue.shift()!;
    queue.push(...curNode.children);
    map[curNode.id] = curNode.children.map(n => n.id);
  }
  return map;
});

export { createNodeById, createNodeChildrenIdsById, createNodeParentById };
