import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { getDefaultNewUserRole } from "../../../../redux/selectors/roles";
import { navApi } from "../../../../services/nav-api";
import { FormControllerValue, UserInfoStatus } from "./context";
import { useAllowAddStudents } from "./hooks/allowAddStudentsField";
import { useEmail } from "./hooks/emailField";
import { useFirstName } from "./hooks/firstNameField";
import { useLastName } from "./hooks/lastNameField";
import { useNotificationsEnabled } from "./hooks/notificationsEnabledField";
import { useSelectedPartnerId } from "./hooks/partnerIdField";
import { useSelectedRoleId } from "./hooks/selectedRoleIdField";
import { useSelectedTenantId } from "./hooks/selectedTenantIdField";
import { useTagIds } from "./hooks/tagIdsField";
import { useExistingUser as useServerUser } from "./hooks/useExistingUser";
import { useUserInfoStatus } from "./hooks/userInfoStatus";

// -------------------------------------------------------------------------------------------------
// - exports
// -------------------------------------------------------------------------------------------------

export * from "./context";

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const userFormController = (userId: number | null): FormControllerValue => {
  const email = useEmail();
  const firstName = useFirstName();
  const lastName = useLastName();
  const allowAddStudents = useAllowAddStudents();
  const notificationsEnabled = useNotificationsEnabled();
  const selectedPartnerId = useSelectedPartnerId();
  const selectedRoleId = useSelectedRoleId();
  const selectedTenantId = useSelectedTenantId();
  const tagIds = useTagIds();
  const userInfoStatus = useUserInfoStatus(UserInfoStatus.PENDING_VERIFICATION);
  const serverUser = useServerUser(userId);

  // -----------------------------------------------------------------------------------------------
  //  Global State
  //
  const defaultUserRole = useSelector(getDefaultNewUserRole);

  // Applies debounce to email validation to prevent excessive calls while the user is typing
  const [debouncedEmail] = useDebounce(email.value, 1000);

  // -----------------------------------------------------------------------------------------------
  //  Request user info
  //
  React.useEffect(() => {
    if (!_.isNil(userId)) {
      return;
    }

    if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.value!)) {
      (async () => {
        userInfoStatus.startLoading();
        try {
          const rsp = await navApi.users.getUsernameInfo(email.value!);

          firstName.setValue(rsp.firstName ?? "");
          lastName.setValue(rsp.lastName ?? "");
          selectedRoleId.setValue(defaultUserRole?.id);

          userInfoStatus.setError(null);
          userInfoStatus.setValue(
            _.isEmpty(rsp.firstName) && _.isEmpty(rsp.lastName)
              ? UserInfoStatus.AVAILABLE_AND_MUTABLE
              : UserInfoStatus.AVAILABLE_BUT_IMMUTABLE
          );
        } catch (e) {
          const error = e as { message: string };
          userInfoStatus.setValue(UserInfoStatus.UNAVAILABLE);
          userInfoStatus.setError(error?.message ?? "Unknown error");
        } finally {
          userInfoStatus.finishLoading();
        }
      })();
    }
  }, [debouncedEmail]);
  //
  //  Request user info
  // -----------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------
  //  Initialize from based on a existing User
  //
  React.useEffect(() => {
    if (!_.isNil(serverUser.value)) {
      email.setValue(serverUser.value.email);
      firstName.setValue(serverUser.value.firstName);
      lastName.setValue(serverUser.value.lastName);

      selectedRoleId.setValue(serverUser.value.roleId);
      selectedTenantId.setValue(serverUser.value.tenantId);

      tagIds.setValue(serverUser.value.tagIds);
      allowAddStudents.setValue(serverUser.value.allowAddStudents);
      notificationsEnabled.setValue(!serverUser.value.unsubscribeAlerts);
    }
  }, [serverUser.value]);
  //
  //  Request user info
  // -----------------------------------------------------------------------------------------------

  return {
    serverUser,
    userInfoStatus,
    editingMode: !_.isNil(userId),
    email,
    firstName,
    lastName,
    notificationsEnabled,
    selectedRoleId,
    selectedTenantId,
    tagIds,
    allowAddStudents,

    reset: () => {
      allowAddStudents.resetValue();
      email.resetValue();
      firstName.resetValue();
      lastName.resetValue();
      notificationsEnabled.resetValue();
      selectedPartnerId.resetValue();
      selectedRoleId.resetValue();
      selectedTenantId.resetValue();
      tagIds.resetValue();
      userInfoStatus.resetValue();
    }
  };
};
