import { RightOutlined } from "@ant-design/icons";
import React from "react";
import { Motion, spring } from "react-motion";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertsCaret = ({
  isActive
}: {
  isActive: boolean;
} & React.HTMLProps<HTMLDivElement>) => (
  <div style={{ position: "absolute", top: "40px", height: "76px", minHeight: "76px", maxHeight: "76px" }}>
    <Motion
      style={{
        value: spring(isActive ? 90 : 0)
      }}
      defaultStyle={{
        value: 0
      }}
    >
      {s => (
        <RightOutlined
          style={{
            transform: `rotateZ(${s.value}deg)`,
            fontSize: "15px",
            fontWeight: "bold"
          }}
        />
      )}
    </Motion>
  </div>
);
