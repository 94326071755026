import { FilterTag } from "../../../core/model";
import { useCreationDateFilter } from "./useCreationDateFilter";
import { useLastLoginDateFilter } from "./useLastLoginDateFilter";
import { useRoleFilter } from "./useRoleFilter";
import { useSearchTermFilter } from "./useSearchTermFilter";
import { useStatusFilter } from "./useStatusFilter";
import { useTenantFilter } from "./useTenantFilter";

// -------------------------------------------------------------------------------------------------
// - Hooks: useUserFilterTags
// -------------------------------------------------------------------------------------------------

export const useUserFilterTags = (): FilterTag[] => {
  const searchTerm = useSearchTermFilter();
  const tenant = useTenantFilter();
  const role = useRoleFilter();
  const status = useStatusFilter();
  const creationDate = useCreationDateFilter();
  const lastLoginDate = useLastLoginDateFilter();

  return [
    ...searchTerm,
    ...tenant,
    ...role,
    ...status,
    ...creationDate,
    ...lastLoginDate //
  ];
};
