import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Steps } from "antd";
import _ from "lodash";
import React from "react";
import { StudyPlanEditorContext } from "../hooks/useStudyPlanEditor";
import { EditorStep } from "../hooks/useStudyPlanEditor/useNavigation";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const EditorStepNav = () => (
  <StudyPlanEditorContext.Consumer>
    {({ navigation, editor }) => (
      <>
        <Flex vertical={false} justify="center" align="center">
          <Button icon={<LeftOutlined />} type="link" onClick={navigation.actions.moveBack} />
          <Steps
            size="small"
            labelPlacement="vertical"
            items={navigation.editorSteps}
            current={navigation.step}
            percent={editor.progress.value}
            onChange={
              !_.isEqual(EditorStep.STUDY_PLAN_OVERVIEW, navigation.step) ||
              (_.isEqual(EditorStep.STUDY_PLAN_OVERVIEW, navigation.step) && _.isEqual(editor.progress.value, 100))
                ? navigation.actions.moveTo
                : undefined
            }
          />
          <Button
            type="link"
            icon={<RightOutlined />}
            disabled={!editor.progress.isComplete}
            onClick={
              editor.progress.isComplete //
                ? navigation.actions.moveForward
                : undefined
            }
          />
        </Flex>
        <Divider />
      </>
    )}
  </StudyPlanEditorContext.Consumer>
);
