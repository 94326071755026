import { CalendarOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { MESSAGES } from "../../../i18n";
import { routes } from "../../../routes";
import { NavigationDrawerOptionKey } from "../types";

const key = NavigationDrawerOptionKey.STUDY_PLANS;
const qaLabel = "menu-item-studyplans";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default [
  {
    key,
    icon: <CalendarOutlined />,
    label: (
      <Link to={routes.studyPlans.resolve()} data-qa-label={qaLabel}>
        {MESSAGES.NavStudyPlans}
      </Link>
    )
  }
];
