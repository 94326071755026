import { Card, Checkbox, Divider, Empty, Flex, Form, Select, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { CheckableTreeView } from "../../../components/CheckableTreeView";
import { getActiveUser } from "../../../redux/selectors/active-user";
import { TagNode } from "../../../redux/selectors/my-tenant-tags-tree";
import { getUserRoles } from "../../../redux/selectors/roles";
import { FormControllerContext, UserInfoStatus } from "../hooks/userFormController";
import { useTenants } from "./hooks/tenants";
import { TenantTagsContext, useTenantTags } from "./hooks/tenantTags";
import { useUserRoles } from "./hooks/userRoles";
import { TenantStatus } from "./model";

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useIsSysAdminOrAdmin = () => {
  const activeUser = useSelector(getActiveUser);
  const userRoles = useSelector(getUserRoles);
  return React.useMemo(
    () =>
      _.chain(userRoles)
        .filter(r => _.isEqual(r.id, activeUser?.roleId))
        .head()
        .thru(r => _.includes(["Admin", "Sys Admin"], r.name))
        .value(),
    [activeUser?.roleId, JSON.stringify(userRoles)]
  );
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantAndRoleFields = () => {
  const userRoles = useUserRoles();
  const tenants = useTenants();
  const tenantTags = useTenantTags();
  const isSysAdminOrAdmin = useIsSysAdminOrAdmin();

  return (
    <TenantTagsContext.Provider value={tenantTags}>
      <FormControllerContext.Consumer>
        {({ userInfoStatus, selectedRoleId, editingMode, selectedTenantId, tagIds, allowAddStudents, serverUser }) => {
          const isTabsDisabled = userInfoStatus.isIn([
            UserInfoStatus.PENDING_VERIFICATION,
            UserInfoStatus.UNAVAILABLE
            //
          ]);

          const selectOptions = _.chain(userRoles.roles(serverUser.value))
            .map(ur => ({
              label: ur.name,
              value: ur.id,
              disabled: ur.disabled
            }))
            .value();

          const isRoleSelDisabled = _.reduce(selectOptions, (a, o) => a || o.disabled, false);
          const roleWarningCmp = _.chain(selectOptions)
            .filter(o => _.isEqual(o.value, selectedRoleId.value))
            .thru(l =>
              isRoleSelDisabled && l.length === 1 && _.includes(["Admin", "Sys Admin", "User"], l[0].label) ? (
                <div className="py-2">
                  <Typography.Text type="warning">The user’s role can be modified in BIM.</Typography.Text>
                </div>
              ) : null
            )
            .value();

          let tenantSelectDisabled = false;
          if (!isSysAdminOrAdmin) {
            tenantSelectDisabled =
              _.isEmpty(tenants.options) ||
              _.isEqual(TenantStatus.LOCKED, tenants.status) ||
              userInfoStatus.isIn([
                UserInfoStatus.PENDING_VERIFICATION,
                UserInfoStatus.UNAVAILABLE
                //
              ]);
          }

          return (
            <>
              {!(editingMode && _.isNil(selectedTenantId.value)) ? (
                <Form.Item required label="Tenant" wrapperCol={{ xs: 24, xl: 12 }}>
                  <Select
                    value={selectedTenantId.value}
                    loading={tenants.loading}
                    placeholder="Tenant name"
                    options={tenants.options}
                    disabled={tenantSelectDisabled}
                    onChange={e => {
                      selectedTenantId.setValue(e);
                    }}
                    // Filter
                    showSearch
                    optionFilterProp="children"
                    filterOption={(i, o) => ((o?.label as string) ?? "").toLowerCase().includes(i.toLowerCase())}
                  />
                </Form.Item>
              ) : null}
              {(_.gt(selectedTenantId.value, 0) && !isTabsDisabled) || editingMode ? (
                <Form.Item required label="Role" wrapperCol={{ xs: 24, xl: 12 }} extra={roleWarningCmp}>
                  <Flex vertical gap={12}>
                    <Select
                      value={selectedRoleId.value}
                      defaultValue={userRoles.defaultUserRole?.id}
                      onChange={e => selectedRoleId.setValue(e)}
                      options={selectOptions}
                      disabled={isRoleSelDisabled}
                    />
                    {userRoles.isTenantUser(selectedRoleId.value) ? (
                      <Card bordered>
                        {tenantTags.tree?.length == 0 ? (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        ) : (
                          <Flex vertical gap={8}>
                            <Typography.Text>
                              Tenant users may have restricted access to the following tags:
                            </Typography.Text>

                            <div style={{ maxHeight: 450, overflowY: "auto" }}>
                              <CheckableTreeView
                                tree={tenantTags.tree}
                                loading={tenantTags.loading}
                                defaultExpandAll={editingMode}
                                checked={tagIds.value!}
                                renderNode={({ name }: TagNode) => name}
                                checkBehavior="assign"
                                onChange={value => {
                                  tagIds.setValue(value);
                                }}
                              />
                            </div>
                          </Flex>
                        )}
                        <Divider />
                        <Checkbox
                          checked={allowAddStudents.value!}
                          onChange={e => {
                            allowAddStudents.setValue(e.target.checked);
                          }}
                        >
                          Allow adding students
                        </Checkbox>
                      </Card>
                    ) : null}
                  </Flex>
                </Form.Item>
              ) : null}
            </>
          );
        }}
      </FormControllerContext.Consumer>
    </TenantTagsContext.Provider>
  );
};
