import dayjs from "dayjs";
import React from "react";
import { StudyPlansListItem } from "../../../../../services/nav-api/studyPlans/model";
import { Field, FromApiResponseValue, StudyPlanEditorValue, ToApiRequestValue } from "./model";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type EndDateFieldValue = StudyPlanEditorValue["endDate"];
export type EndDateField = Field<
  EndDateFieldValue,
  {
    toApiRequestValue: ToApiRequestValue<string>;
    fromApiResponseValue: FromApiResponseValue<EndDateFieldValue>;
  }
>;

// -------------------------------------------------------------------------------------------------
// - hooks
// -------------------------------------------------------------------------------------------------

export const useEndDate = (): EndDateField => {
  const [value, setValue] = React.useState<EndDateFieldValue>(null);
  return {
    value,

    setValue: (val: EndDateFieldValue) => {
      setValue(val);
    },

    resetValue: () => {
      setValue(null);
    },

    toApiRequestValue: (): string => {
      return value?.format("YYYY-MM-DD") ?? "";
    },

    fromApiResponseValue: (rsp: StudyPlansListItem): EndDateFieldValue => {
      const iVal = dayjs(rsp.settings.examDate);
      setValue(iVal);
      return iVal;
    }
  };
};
