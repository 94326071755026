import React from "react";
import { useSelector } from "react-redux";
import { FEATURES } from "../constants/ui-features";
import { getAllowedFeatures } from "../redux/selectors/allowed_features";

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const Can = ({
  feature,
  children,
  forceFeatureOff
}: {
  forceFeatureOff?: boolean;
  feature: FEATURES;
  children: React.ReactElement;
}) => {
  const allowedFeatures = useSelector(getAllowedFeatures);
  return !allowedFeatures || !allowedFeatures[feature] || forceFeatureOff ? null : children;
};

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const RenderChildrenIf = (props: {
  condition?: boolean;
  children: React.ReactNode | React.ReactNode[];
  fallback?: React.ReactNode | React.ReactNode[];
}) => (props.condition ? props.children : props.fallback);
