import { RightOutlined } from "@ant-design/icons";
import { Divider, Flex } from "antd";
import React from "react";
import { BiSolidHourglassTop } from "react-icons/bi";
import { EndDateField } from "../../EditorFields/FieldDateEnd";
import { StartDateField } from "../../EditorFields/FieldDateStart";
import { LockUntilField } from "../../EditorFields/FieldLockUntil";
import { EditorSectionHeader } from "../EditorSectionHeader";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyDatesSection = () => (
  <>
    <EditorSectionHeader
      title="Study Dates"
      description={
        "Provide the study start date and exam date to help allocate time for tasks " +
        "and ensure full preparation by the exam date."
      }
    />
    <Flex vertical={false} className="w-full" justify="center" align="center">
      <StartDateField />
      <RightOutlined className="px-4" />
      <EndDateField />
      <BiSolidHourglassTop className="px-4" />
      <LockUntilField />
    </Flex>
    <Divider />
  </>
);
