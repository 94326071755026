import { Form, Select } from "antd";
import React from "react";
import { WorkingFilterProductTypes } from "../redux/actions/tenants/tenants";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const ProductFilter = (props: { onChange: (value?: any) => void; value: string | undefined }) => (
  <Form.Item label="Products">
    <Select
      className="w-full"
      value={props.value}
      onChange={props.onChange}
      data-qa-label="tenant-product-select"
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
    >
      {WorkingFilterProductTypes.map(status => (
        <Select.Option key={status} value={status} className={`option-product-${status}`}>
          {status.trim()}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);
