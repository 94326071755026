import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { AltID, altIdsByProduct, sectionAltIdsOrder, sectionNameByAltId } from "../../../constants/courses";
import { getActiveProduct } from "../../../redux/selectors/active-product";

// -------------------------------------------------------------------------------------------------
//  hooks
// -------------------------------------------------------------------------------------------------

export const useStudyPlanSections = () => {
  const product = useSelector(getActiveProduct);
  return React.useMemo(
    () =>
      _.chain(altIdsByProduct[product])
        .sortBy(altId => _.indexOf(sectionAltIdsOrder, altId))
        .filter(altId => !_.includes([AltID.B], altId))
        .map(altId => ({
          label: sectionNameByAltId[altId],
          value: altId
        }))
        .value(),
    [product]
  );
};
