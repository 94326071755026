import _ from "lodash";
import { TagNode } from "../redux/selectors/my-tenant-tags-tree";
import { Tag } from "../services/nav-api/tenants/types";

// -------------------------------------------------------------------------------------------------
// - Utils
// -------------------------------------------------------------------------------------------------

export class TagUtils {
  // -----------------------------------------------------------------------------------------------
  // - Build tags tree
  // -----------------------------------------------------------------------------------------------

  public static tagsToTagNodes(tags: Tag[]): TagNode[] {
    return _.map(tags, t => ({
      ...t,
      key: t.id,
      title: t.name,
      children: []
    }));
  }

  // -----------------------------------------------------------------------------------------------
  // - Build tags tree
  // -----------------------------------------------------------------------------------------------

  public static buildTagsTree(tagNodes: TagNode[] | null): TagNode[] {
    if (_.isNil(tagNodes)) {
      return [];
    }

    const nodeMap = new Map<number, TagNode>(); // Map for quick node lookup
    const roots: TagNode[] = []; // Array to store root nodes

    // Add all nodes to the map
    tagNodes.forEach(node => {
      nodeMap.set(node.id, { ...node, children: [] });
    });

    // Build the tree structure
    tagNodes.forEach(node => {
      if (node.parentId !== undefined) {
        // Find the parent node and add this node as a child
        const parent = nodeMap.get(node.parentId);
        if (parent) {
          parent.children!.push(nodeMap.get(node.id)!);
        }
      } else {
        // If no parentId, it's a root node
        roots.push(nodeMap.get(node.id)!);
      }
    });

    return roots;
  }

  // -----------------------------------------------------------------------------------------------
  // - Build tags tree
  // -----------------------------------------------------------------------------------------------

  public static buildTagsTreeFromTags(tags: Tag[]): TagNode[] {
    return this.buildTagsTree(this.tagsToTagNodes(tags));
  }
}
