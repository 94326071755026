import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Dropdown, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RenderChildrenIf } from "../../components/ConditionalRenderers";
import { MESSAGES } from "../../i18n";
import { routes } from "../../routes";
import { useStudentTenant as useStudentsTenant } from "./hooks/useStudentTenant";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentsAddButton = () => {
  const navigate = useNavigate();
  const studentsTenant = useStudentsTenant();

  return (
    <RenderChildrenIf condition={studentsTenant.isEnabled}>
      {studentsTenant.isSelected ? (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          data-qa-label="addStudentsBtn"
          onClick={() => {
            navigate(routes.studentsAdd.resolve({ id: studentsTenant.value?.toString() }));
          }}
        >
          {MESSAGES.AddStudents}
        </Button>
      ) : (
        <Dropdown
          placement="bottomRight"
          menu={{
            items: [
              {
                key: "feedback",
                label: (
                  <Typography.Text type="warning" data-qa-label="bulkChangeFeedback">
                    To proceed with this operation <br />a tenant must be selected.
                  </Typography.Text>
                )
              }
            ]
          }}
        >
          <Button type="primary" icon={<PlusOutlined />} data-qa-label="addStudentsBtn">
            {MESSAGES.AddStudents} <DownOutlined />
          </Button>
        </Dropdown>
      )}
    </RenderChildrenIf>
  );
};
