import { Form, Input } from "antd";
import React from "react";
import { StudyPlanCustomTasksEditorContext } from "../../hooks/useStudyPlanCustomTasksEditor";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlanCustomTaskEditorFieldName = () => (
  <StudyPlanCustomTasksEditorContext.Consumer>
    {({ actions }) => (
      <Form.Item label="Name" required>
        <Input
          allowClear
          placeholder="Task name"
          data-qa-label="task-name"
          onChange={e => actions.setTaskProp("name", e.target.value)}
          maxLength={100}
          value={actions.getTaskProp("name") ?? ""}
        />
      </Form.Item>
    )}
  </StudyPlanCustomTasksEditorContext.Consumer>
);
