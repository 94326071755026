import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Layout, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SIDER_MAX_WIDTH, SIDER_MIN_WIDTH } from "../../constants/ui-constants";
import { MESSAGES } from "../../i18n";
import { doLogout } from "../../redux/actions/authentication/logout";
import { doCloseNavigationDrawer, doOpenNavigationDrawer } from "../../redux/actions/navigation-drawer";
import { getIsNavigationDrawerOpen } from "../../redux/selectors/navigation-drawer-open";
import { AppEventEmitter } from "../../services/ipc";
import { AppMenu } from "./AppMenu";
import { AppMenuContext } from "./AppMenu/context";
import { NavigatorLogo } from "./NavigatorLogo";
import { ProductSwitch } from "./ProductSwitch";
import { NavigationDrawerOptionKey } from "./types";

// -------------------------------------------------------------------------------------------------
// - component
// -------------------------------------------------------------------------------------------------

export const NavigationDrawer = (props: { active: NavigationDrawerOptionKey }) => {
  const dispatch = useDispatch<any>();
  const open = useSelector(getIsNavigationDrawerOpen);
  const [displayLogoutWarn, setDisplayLogoutWarn] = React.useState(false);

  React.useEffect(() => {
    AppEventEmitter.on("logout", () => {
      setDisplayLogoutWarn(true);
    });

    // Cleanup do listener
    return () => {
      AppEventEmitter.removeAllListeners("mensagem");
    };
  }, []);

  return (
    <AppMenuContext.Provider value={{ active: props.active }}>
      <Layout.Sider
        collapsible
        className="bg-white"
        collapsed={!open}
        width={SIDER_MAX_WIDTH}
        onCollapse={() => {
          dispatch(open ? doCloseNavigationDrawer() : doOpenNavigationDrawer());
        }}
        trigger={
          <Button
            type="link"
            className="absolute bottom-0 left-0"
            icon={
              open ? ( //
                <LeftOutlined />
              ) : (
                <RightOutlined />
              )
            }
            style={{
              width: open //
                ? SIDER_MAX_WIDTH
                : SIDER_MIN_WIDTH
            }}
          />
        }
      >
        <NavigatorLogo collapsed={!open} />
        <ProductSwitch />
        <AppMenu activeKey={props.active} />
      </Layout.Sider>

      <Modal
        open={displayLogoutWarn}
        title={MESSAGES.LogoutTitle}
        onCancel={() => {
          setDisplayLogoutWarn(false);
        }}
        onOk={() => {
          dispatch(doLogout());
        }}
      >
        {MESSAGES.LogoutConfirmTitle}
      </Modal>
    </AppMenuContext.Provider>
  );
};
