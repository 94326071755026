import { StepProps } from "antd";
import _ from "lodash";
import React from "react";
import { AiOutlineCalendar, AiOutlineFile, AiOutlinePartition } from "react-icons/ai";
import { useNavigate } from "react-router";
import { routes } from "../../../../routes";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export enum EditorStep {
  STUDY_PLAN_OVERVIEW = 0,
  STUDY_PLAN_PARTS,
  STUDY_PLAN_CONFIGURATION
}

export type StudyPlanEditorNavigation = {
  step: EditorStep;
  editorSteps: Array<StepProps & { step: EditorStep; key: string }>;
  ui: {
    moveBackTitle: string;
    moveForwardTitle: string;
  };
  actions: {
    moveBack: () => void;
    moveForward: () => void;
    moveTo: (step: EditorStep) => void;
  };
};

// -------------------------------------------------------------------------------------------------
// - constants
// -------------------------------------------------------------------------------------------------

const keyByStep: { [step in EditorStep]: string } = {
  [EditorStep.STUDY_PLAN_OVERVIEW]: "overview",
  [EditorStep.STUDY_PLAN_CONFIGURATION]: "dates",
  [EditorStep.STUDY_PLAN_PARTS]: "parts"
};

const labelByStep: { [step in EditorStep]: string } = {
  [EditorStep.STUDY_PLAN_OVERVIEW]: "Details",
  [EditorStep.STUDY_PLAN_PARTS]: "Review Materials",
  [EditorStep.STUDY_PLAN_CONFIGURATION]: "Configuration"
};

const iconByStep: { [step in EditorStep]: React.ReactNode } = {
  [EditorStep.STUDY_PLAN_OVERVIEW]: <AiOutlineFile size={24} />,
  [EditorStep.STUDY_PLAN_CONFIGURATION]: <AiOutlineCalendar size={24} />,
  [EditorStep.STUDY_PLAN_PARTS]: <AiOutlinePartition size={24} />
};

const editorSteps: Array<StepProps & { step: EditorStep; key: string }> = _.map(
  _.sortBy(_.values(EditorStep).filter(v => typeof v === "number")),
  (step: EditorStep) => ({
    step,
    key: keyByStep[step],
    title: labelByStep[step],
    description: iconByStep[step]
  })
);

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useNavigation = (editinMode: boolean): StudyPlanEditorNavigation => {
  const navigateTo = useNavigate();
  const [step, setStep] = React.useState<EditorStep>(EditorStep.STUDY_PLAN_OVERVIEW);
  return {
    step,
    editorSteps,

    // ---------------------------------------------------------------------------------------------
    // - UI Configs
    // -
    ui: {
      moveBackTitle: React.useMemo(
        () =>
          _.lt(step - 1, 0)
            ? editinMode //
              ? "Back to Details"
              : "Cancel"
            : labelByStep[(step - 1) as EditorStep],
        [step, editinMode]
      ),
      moveForwardTitle: React.useMemo(
        () =>
          labelByStep[
            (Math.max(...(_.filter(_.values(EditorStep), v => typeof v === "number") as number[])),
            step + 1) as EditorStep
          ],
        [step]
      )
    },

    // ---------------------------------------------------------------------------------------------
    // - Actions
    // -
    actions: {
      moveTo: (step: EditorStep) => setStep(step),

      moveBack: () => {
        if (_.isEqual(EditorStep.STUDY_PLAN_OVERVIEW, step)) {
          navigateTo(routes.studyPlans.resolve());
        } else {
          setStep(prev => Math.max(0, prev - 1));
        }
      },

      moveForward: () =>
        setStep(prev =>
          Math.min(Math.max(...(_.filter(_.values(EditorStep), v => typeof v === "number") as number[])), prev + 1)
        )
    }
  };
};

export const studyPlanEditorNavigationInitValues: StudyPlanEditorNavigation = {
  step: EditorStep.STUDY_PLAN_OVERVIEW,
  editorSteps: [],
  ui: {
    moveBackTitle: "",
    moveForwardTitle: ""
  },
  actions: {
    moveBack: _.noop,
    moveForward: _.noop,
    moveTo: _.noop
  }
};
