import { JSX } from "react";
import ReactDOMServer from "react-dom/server";

// ----------------------------------------------------------------------
// - String Utils
// ----------------------------------------------------------------------

export class StringUtils {
  // Static members
  private static INVALID_UNICODE_CHARACTERS = ["\u200B"];
  private static INVALID_UNICODE_CHARACTERS_REGEX = new RegExp(StringUtils.INVALID_UNICODE_CHARACTERS.join("|"), "g");

  public static htmlToPlainText(html: string): string {
    if (!html) {
      return "";
    }

    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent ? div.textContent.trim() : "";
  }

  public static reactElementToPlainText(element: JSX.Element): string {
    return this.htmlToPlainText(ReactDOMServer.renderToString(element).replace("\n", "").replace("[ \t]+", ""));
  }

  public static capitalizeFirstLetter(str: string): string {
    return str && str.length > 0 ? str.charAt(0).toUpperCase() + str.substring(1) : str;
  }

  public static boolToYesNo(value: boolean): string {
    return value !== null && value !== undefined ? (value ? "Yes" : "No") : "";
  }

  public static isBlank(str?: string): boolean {
    return str == null || /^\s*$/.test(str);
  }

  /**
   * Remove unwanted unicode characters from a string
   */
  public static sanitize(value: string | ReadonlyArray<string> | number): string | ReadonlyArray<string> | number {
    if (!value || typeof value !== "string") {
      return value;
    }

    return value.replace(StringUtils.INVALID_UNICODE_CHARACTERS_REGEX, "");
  }
}
