import { FileExcelOutlined, FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Drawer, Flex, Input } from "antd";
import download from "downloadjs";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationToastsContext } from "../../components/AppToasts";
import { MESSAGES, formatMsg } from "../../i18n";
import { createGetUserParams, doApplyFilter, doUpdateWorkingFilter } from "../../redux/actions/users-list";
import { RdxStoreState } from "../../redux/types/state";
import { RdxFetchStatus } from "../../redux/types/status";
import { navApi } from "../../services/nav-api";
import { getTaskBlobWhenReady } from "../../services/task";
import { getCurDateForReportFilename } from "../../utils/date-utils";
import { UsersTableFilterForTenantsQuick } from "./UsersTableFilterForTenants";
import { UsersTableFilters } from "./UsersTableFilters";
import { UsersTableFiltersActions } from "./UsersTableFiltersActions";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const SEARCH_MIN_CHARS = 3;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const UsersTableHeaderActions = () => {
  const dispatch = useDispatch<any>();
  const {
    lastUpdated,
    activeFilter,
    workingFilter,
    status,
    data
    // paging //
  } = useSelector((state: RdxStoreState) => state.usersList);
  const filter = activeFilter?.filter!;
  const mySelf = useSelector((state: RdxStoreState) => state.myself);
  const hasRecords = Boolean(data?.total);
  const [drawerVisible, setDrawerVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (lastUpdated! > 0) {
      setDrawerVisible(false);
    }
  }, [lastUpdated]);

  return (
    <>
      <Flex gap={4}>
        <UsersTableFilterForTenantsQuick />
        <form
          style={{ flex: 1, display: "flex" }}
          onSubmit={e => {
            e.preventDefault();
            dispatch(doApplyFilter());
          }}
        >
          <Input.Search
            data-qa-label="search-field"
            value={workingFilter.searchTerm}
            placeholder={formatMsg(MESSAGES.FilterSearchPlaceholderWMinChars, SEARCH_MIN_CHARS)}
            enterButton={<Button type="primary" icon={<SearchOutlined />} data-qa-label="search-btn" />}
            onChange={e => dispatch(doUpdateWorkingFilter({ searchTerm: e.currentTarget.value }))}
            onSearch={() => {
              dispatch(doApplyFilter());
            }}
          />
        </form>

        <Button data-qa-label="filters-btn" icon={<FilterOutlined />} onClick={() => setDrawerVisible(true)}>
          {MESSAGES.FilterBtnLabel}
        </Button>

        <ApplicationToastsContext.Consumer>
          {api => (
            <Button
              loading={loading}
              data-qa-label="createReportBtn"
              icon={<FileExcelOutlined />}
              disabled={!hasRecords || _.isEqual(status, RdxFetchStatus.LOADING)}
              onClick={async () => {
                setLoading(true);
                try {
                  const { taskToken } = await navApi.users.generateReport(createGetUserParams(filter!, mySelf));
                  const blob = await getTaskBlobWhenReady(taskToken);

                  // Download file
                  download(blob, `users-report-${getCurDateForReportFilename()}.xlsx`, blob.type);

                  // Send Success
                  api.success({
                    message: `Users report created successfully`,
                    showProgress: true,
                    pauseOnHover: true
                  });
                } catch (err) {
                  // Show error
                  api.error({
                    message: `Error creating report`,
                    description: _.isString(err)
                      ? err
                      : "An error occurred while creating the Users report. Please try again.",
                    showProgress: true,
                    pauseOnHover: true
                  });
                } finally {
                  setLoading(false);
                }
              }}
            >
              Export
            </Button>
          )}
        </ApplicationToastsContext.Consumer>
      </Flex>
      <Drawer
        closable
        width={430}
        placement="right"
        open={drawerVisible}
        title={MESSAGES.FiltersDrawerTitle}
        extra={<UsersTableFiltersActions />}
        footer={<UsersTableFiltersActions />}
        onClose={() => {
          setDrawerVisible(false);
        }}
      >
        <UsersTableFilters />
      </Drawer>
    </>
  );
};
