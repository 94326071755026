import React from "react";
import { MESSAGES } from "../../../i18n";
import { AppEventEmitter } from "../../../services/ipc";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const Label = () => {
  return (
    <a
      data-qa-label="menu-logout"
      onClick={() => {
        AppEventEmitter.emit("logout");
      }}
    >
      {MESSAGES.NavLogout}
    </a>
  );
};

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default [
  {
    key: "logout",
    label: <Label />
  }
];
